import { Tooltip } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { DefaultContentCard } from '../../../components/ContentsCards';
import useAuth from '../../../hooks/auth';
import api from '../../../services/api';
import theme from '../../../theme';
import { getConvertTheme } from '../../../utils/getConvertTheme';
import { handleVerifyMembers } from '../../../utils/handleVerifyMembers';
import trimString from '../../../utils/trimString';
import ModalLesson from '../Courses/ModalLesson/indes';
import {
  BackIcon,
  ButtonLesson,
  ButtonLessonText,
  ButtonLessonTitle,
  CourseText,
  CourseTitle,
  CourseWrapper,
  ImgLesson,
  LessonsContainer,
  ModuleContainer,
  ModuleText,
  ModulesWrapper,
  RowLine,
  WebinarContainer,
} from '../Courses/styles';
import EmptyContainer from '../EmptyContainer';
import { DefaultContainer, GridLayout } from '../styles';
import ModalUnlockWebinar from './ModalUnlockWebinar';

export default function ContentsWebinars({ ...props }) {
  const { user } = useAuth();
  const { search, webinars, credits, setLoading, getWebinar, setGetWebinar } = props;
  const [selectedWebinar, setSelectedWebinar] = useState(null);
  const [selectedModules, setSelectedModules] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedLessons, setSelectedLessons] = useState(null);
  const [unlockWebinar, setUnlockWebinar] = useState(null);

  function handleClickWebinar(webinar) {
    setSelectedWebinar(webinar);
    const filteredModules = webinar.attributes.courseModules?.filter((module) =>
      module?.visibility?.some((item) => handleVerifyMembers(user).includes(item))
    );
    setSelectedModules(filteredModules);
  }

  async function handleReleaseWebinar() {
    try {
      setLoading(true);
      const request = {
        data: {
          attributes: {
            courseId: unlockWebinar.id,
          },
        },
      };
      await api.post(`/clients/courses/buy_course`, request);
    } catch (error) {
      toast.error('Erro ao liberar mini curso');
    } finally {
      setGetWebinar(!getWebinar);
      setUnlockWebinar(null);
      setLoading(false);
    }
  }

  function handleCredits(webinar) {
    setUnlockWebinar(webinar);
  }

  const filteredWebinars = webinars?.filter((webinar) => {
    if (!search) return webinar;
    return webinar?.attributes?.title.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <DefaultContainer>
      {!selectedWebinar ? (
        filteredWebinars?.length ? (
          <GridLayout>
            {filteredWebinars?.map((course) => {
              return (
                <DefaultContentCard
                  key={course.id}
                  item={course.attributes}
                  label={course.attributes.locked ? 'Liberar Curso' : 'Abrir Curso'}
                  onClick={() =>
                    course.attributes.locked
                      ? handleCredits({ id: course.id, ...course.attributes })
                      : handleClickWebinar(course)
                  }
                />
              );
            })}
          </GridLayout>
        ) : (
          <EmptyContainer title="Você não tem mini cursos diponíveis no momento." />
        )
      ) : (
        <Tooltip title="Voltar">
          <BackIcon onClick={() => setSelectedWebinar(null)} />
        </Tooltip>
      )}

      {selectedWebinar && (
        <WebinarContainer>
          <CourseWrapper>
            <CourseTitle>{selectedWebinar?.attributes?.title}</CourseTitle>
            <CourseText>{selectedWebinar?.attributes?.description}</CourseText>
            <CourseTitle>{getConvertTheme(selectedWebinar?.attributes?.theme)}</CourseTitle>
          </CourseWrapper>
          {selectedModules?.map((module) => (
            <ModulesWrapper key={module.moduleId}>
              <ModuleContainer
                onClick={() => {
                  if (selectedModule?.moduleId === module.moduleId) setSelectedModule(null);
                  else setSelectedModule(module);
                }}
              >
                <ModuleText>{module.title}</ModuleText>
                <AiOutlineArrowDown
                  style={{
                    transform:
                      selectedModule?.moduleId === module.moduleId
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)',
                    transition: 'all 0.3s ease',
                  }}
                  size={28}
                  color={theme.white}
                />
              </ModuleContainer>

              {selectedModule?.moduleId === module.moduleId && (
                <LessonsContainer selected={selectedModule}>
                  {module.lessons?.map((lesson, index) => {
                    const description = lesson.description.slice(5, 14).includes('blocks')
                      ? JSON.parse(lesson.description)?.blocks[0]?.text
                      : lesson.description;
                    return (
                      <Fragment>
                        <ButtonLesson
                          key={lesson.title}
                          lastIndex={index === module.lessons?.length - 1}
                          onClick={() => setSelectedLessons(lesson)}
                        >
                          <ButtonLessonTitle>{lesson.title}</ButtonLessonTitle>
                          <ImgLesson src={lesson.image} alt="lesson-image" />
                          <ButtonLessonText>
                            <Tooltip title={description}>{trimString(description, 200)}</Tooltip>
                          </ButtonLessonText>
                        </ButtonLesson>
                        {index !== module.lessons?.length - 1 && <RowLine />}
                      </Fragment>
                    );
                  })}
                </LessonsContainer>
              )}
            </ModulesWrapper>
          ))}
        </WebinarContainer>
      )}

      {selectedLessons && <ModalLesson setModal={setSelectedLessons} lesson={selectedLessons} />}

      {unlockWebinar && (
        <ModalUnlockWebinar
          handleClose={setUnlockWebinar}
          unlockWebinar={unlockWebinar}
          creditAvailability={credits}
          handleClick={handleReleaseWebinar}
        />
      )}
    </DefaultContainer>
  );
}
