import { Camera, CheckCircle } from 'react-feather';
import styled from 'styled-components';

export const NewImagePickerContainer = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 15px;
  label {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input {
    display: none;
  }

  img {
    width: 90px;
    height: 90px;
    clear: both;
    position: relative;
    top: 10px;
    border-radius: 50%;
    cursor: pointer;
  }
`;

export const NewImageBannerContainer = styled.div`
  position: relative;
  width: 100%;

  label {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input {
    display: none;
  }

  img {
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    clear: both;
    height: 6rem;
    position: relative;
    width: 100%;
  }
`;

export const ContainerIcon = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 50%;
  display: flex;
  height: 50px;
  justify-content: center;
  transform: translate(190%, -70%);
  width: 50px;
`;

export const ContainerBannerIcon = styled.div`
  align-items: center;
  background-color: ${({ active, theme }) => (active ? theme.errorRed : theme.primary)};
  border-radius: 50%;
  bottom: -1rem;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  left: auto;
  position: absolute;
  right: -1rem;
  top: auto;
  width: 50px;
  z-index: 999;
`;

export const CameraIcon = styled(Camera)`
  size: 40px;
  color: white;
`;

export const CheckCircleIcon = styled(CheckCircle)`
  color: white;
`;

export const ImagePickerContainer = styled.div`
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  max-height: 150px;
  max-width: 150px;

  label {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input {
    display: none;
  }

  img {
    width: 90px;
    height: 90px;
    clear: both;
    position: relative;
    top: 10px;
    border-radius: 50%;
    cursor: pointer;
  }
`;

export const CropperWrapper = styled.div`
  height: auto;
  position: relative;
  width: auto;

  .cropper-view-box,
  .cropper-face {
    border-radius: 50% !important; /* Faz a área de seleção parecer redonda */
  }
`;

export const CropButton = styled.button`
  background: ${({ theme }) => theme.primary};
  border-radius: 50%;
  border: none;
  bottom: -1rem;
  color: white;
  cursor: pointer;
  left: 0;
  padding: 0.7rem;
  position: absolute;
  transform: translateX(-50%);
`;
