/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormikControl from '../../../components/FormikControl';
import { InputWrapper } from '../../../components/FormikElements/styles';
import { ModalSubscriptionPlan } from '../../../components/Modals/ModalSubscription';
import api from '../../../services/api';
import { PtBr } from '../../../services/pt_br';
import { TextError } from '../../../styles/globalStyles';
import theme from '../../../theme';
import convertCurrencyPtBr from '../../../utils/convertCurrencyPtBr';
import handleErrors from '../../../utils/getValuesErrors';
import { LoginLink, LoginParagraph, LoginTitle } from '../../Public/Login/styles';
import {
  BFontBold,
  Button,
  ColumnItem,
  ContainerPlans,
  ContinueButton,
  FontPlanPrice,
  GridCard,
  SFontDark,
} from './styles';

const BasicRegistrationValues = [
  'firstName',
  'lastName',
  'email',
  'birthday',
  'phone',
  'password',
  'confirm_password',
];

const dataCardsPlans = [
  {
    tPlanName: 'Trial',
    planName: 'trial',
    planPrice: 0,
    message: 'Teste a plataforma gratuitamente por 15 dias',
  },
  {
    tPlanName: 'Prata',
    planName: 'silver',
    planPrice: 59.9,
    message: 'Para psicólogos que almejam alcançar um novo patamar',
  },
  {
    tPlanName: 'Ouro',
    planName: 'gold',
    planPrice: 89.9,
    message: 'Ideal para otimizar a rotina de todo psicólogo',
  },
];

function CardsPlans({ ...props }) {
  const { values, message, planName, planPrice, setFieldValue, tPlanName, disableSubmit } = props;

  const active = useMemo(() => {
    return values.planName.includes(planName);
  }, [values.planName]);

  const payment = useMemo(() => {
    if (planName !== 'trial' && active && disableSubmit) return true;
    return false;
  }, [active, disableSubmit, planName]);

  return (
    <ContainerPlans active={active}>
      <ColumnItem>
        <BFontBold active={active}>{tPlanName}</BFontBold>
        <SFontDark active={active}>{message}</SFontDark>

        <FontPlanPrice active={active}>{convertCurrencyPtBr(planPrice)}</FontPlanPrice>

        {payment && <SFontDark active={active}>Informar dados de pagamento</SFontDark>}

        <Button
          active={active}
          name={tPlanName}
          value={planName}
          onClick={({ target }) => {
            if (target.value !== 'trial') {
              setFieldValue('open', true);
              setFieldValue('openPlan', false);
            }
            setFieldValue('planName', target.value);
          }}
          type="button"
        >
          {payment ? 'Confirmar' : active ? 'Ativo' : 'Selecionar'}
        </Button>
      </ColumnItem>
    </ContainerPlans>
  );
}

export function PlansSubscription({ form, disableSubmit, subscription }) {
  const { setFieldValue, values } = form;

  return (
    <GridCard subscription={subscription}>
      {dataCardsPlans
        .filter((plan) => (subscription ? plan.planName !== 'trial' : plan))
        .map((plan) => (
          <CardsPlans
            key={plan.planName}
            values={values}
            setFieldValue={setFieldValue}
            disableSubmit={disableSubmit}
            {...plan}
          />
        ))}
    </GridCard>
  );
}

export default function BasicRegistrationProfessionalScreen({ clinic, form }) {
  const { values, errors, touched, handleChange, handleBlur, validateForm, handleSubmit } = form;
  const history = useHistory();

  useEffect(() => {
    validateForm();
  }, []);

  const checkEmail = async (email) => {
    await api.post('/professionals/check_email', {
      email: email,
    });
  };

  const handleContinue = async () => {
    if (!handleErrors(errors, BasicRegistrationValues)) {
      try {
        await checkEmail(values.email);
        handleSubmit(values);
      } catch (error) {
        if (error.response) {
          if (error.response.data.available === false) {
            toast.error('Email já está em uso!');
          }
        } else {
          toast.error('Ocorreu um erro no formulário, verifique os dados inseridos!');
        }
      }
    }
  };

  const disableSubmit = useMemo(() => {
    return Object.keys(errors).length !== 0 && Object.keys(touched).every((touch) => touch);
  }, [errors, touched]);

  return (
    <InputWrapper>
      <LoginTitle>Dados Básicos</LoginTitle>
      <FormikControl
        bgColor={theme.white}
        control="input"
        label={PtBr.register_input_firstname}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="firstName"
        onBlur={handleBlur}
        onChange={handleChange}
        upLabel
        value={values.firstName}
      />
      <TextError>{errors.firstName && touched.firstName && errors.firstName}</TextError>

      <FormikControl
        bgColor={theme.white}
        control="input"
        label={PtBr.register_input_lastname}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="lastName"
        onBlur={handleBlur}
        onChange={handleChange}
        upLabel
        value={values.lastName}
      />
      <TextError>{errors.lastName && touched.lastName && errors.lastName}</TextError>

      {clinic && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '1rem',
            width: '100%',
          }}
        >
          <FormikControl
            bgColor={theme.white}
            control="input"
            disabled
            label={PtBr.register_input_clinic}
            labelFamily="Poppins"
            labelSize="0.9rem"
            labelWeight="600"
            name="member"
            upLabel
            value={values?.clinicName}
          />
        </div>
      )}

      <FormikControl
        bgColor={theme.white}
        control="input"
        disabled={values?.demoAccessCode}
        label={PtBr.register_input_email}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="email"
        onBlur={handleBlur}
        onChange={handleChange}
        upLabel
        value={values.email}
      />
      <TextError>{errors.email && touched.email && errors.email}</TextError>

      <FormikControl
        bgColor={theme.white}
        control="input"
        label="Número de telefone"
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        mask="(99)99999-9999"
        name="phone"
        onBlur={handleBlur}
        onChange={handleChange}
        upLabel
        value={values.phone}
      />
      <TextError>{errors.phone && touched.phone && errors.phone}</TextError>

      <FormikControl
        bgColor={theme.white}
        control="input"
        label={PtBr.register_input_password}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="password"
        onBlur={handleBlur}
        onChange={handleChange}
        type="password"
        upLabel
        value={values.password}
      />
      <TextError>{errors.password && touched.password && errors.password}</TextError>

      <FormikControl
        bgColor={theme.white}
        control="input"
        label={PtBr.register_input_confirm_password}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="confirm_password"
        onBlur={handleBlur}
        onChange={handleChange}
        type="password"
        upLabel
        value={values.confirm_password}
      />
      <TextError>
        {errors.confirm_password && touched.confirm_password && errors.confirm_password}
      </TextError>

      {!values?.clinicId && !values?.demoAccessCode && (
        <PlansSubscription form={form} disableSubmit={disableSubmit} />
      )}

      <ContinueButton
        disabled={disableSubmit}
        id="finish-register"
        onClick={handleContinue}
        type="button"
      >
        Finalizar cadastro
      </ContinueButton>

      <LoginParagraph style={{ marginTop: '2rem' }}>
        Já possui uma conta?{' '}
        <LoginLink type="button" onClick={() => history.push('/profissional/conectar-se')}>
          Entrar
        </LoginLink>
      </LoginParagraph>

      {values.open && <ModalSubscriptionPlan form={form} />}
    </InputWrapper>
  );
}
