/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoaderSpinner from '../../components/LoaderSpinner';
import { DefaultSearchBar } from '../../components/SearchBar';
import useAuth from '../../hooks/auth';
import api from '../../services/api';
import theme from '../../theme';
import { handleVerifyMembers } from '../../utils/handleVerifyMembers';
import ContentsArticles from './Articles';
import ContentCourses from './Courses';
import ContentsVideos from './Videos';
import ContentsWebinars from './Webinars';
import {
  BoxSection,
  ContainerContent,
  ContainerSection,
  ContainerWrapper,
  Main,
  TextLabel,
} from './styles';

export default function Contents() {
  const { user } = useAuth();
  const location = useLocation();
  const [selected, setSelected] = useState(location?.state?.selected || 'Videos');
  const [search, setSearch] = useState('');
  const [courses, setCourses] = useState([]);
  const [credits, setCredits] = useState(0);
  const [loading, setLoading] = useState({
    courses: true,
    webinars: true,
    user: true,
  });
  const [webinarsData, setWebinarsData] = useState([]);
  const [myWebinars, setMyWebinars] = useState([]);
  const [getWebinar, setGetWebinar] = useState(false);

  useEffect(() => {
    const getCourses = async () => {
      try {
        setLoading({
          ...loading,
          courses: true,
        });
        const { data } = await api.get(`/${user?.type}s/courses`);
        if (data.data) {
          setWebinarsData(
            data.data
              ?.filter(
                (course) =>
                  course.attributes.courseType === 'webinar' &&
                  course.attributes.canBeSold === false
              )
              ?.map((webinar) =>
                webinar?.attributes?.visibility?.some(
                  (item) => !handleVerifyMembers(user).includes(item)
                )
                  ? {
                      ...webinar,
                      attributes: {
                        ...webinar.attributes,
                        locked: true,
                      },
                    }
                  : {
                      ...webinar,
                      attributes: {
                        ...webinar.attributes,
                        locked: false,
                      },
                    }
              )
          );

          setCourses(data.data?.filter((course) => course.attributes.courseType === 'course'));
        }
      } catch (error) {
        toast.error('Erro ao buscar cursos');
      } finally {
        setLoading({
          ...loading,
          courses: false,
        });
      }
    };
    getCourses();
  }, [selected]);

  useEffect(() => {
    const getUser = async () => {
      try {
        setLoading({
          ...loading,
          user: true,
        });
        const { data } = await api.get(`/${user?.type}s/user`);
        if (data.data) {
          setCredits(data.data?.attributes?.credits);
        }
      } catch (error) {
        toast.error('Erro ao buscar usuário');
      } finally {
        setLoading({
          ...loading,
          user: false,
        });
      }
    };
    if (user?.type === 'client') getUser();
  }, [selected, getWebinar]);

  useEffect(() => {
    const getWebinarRelease = async () => {
      try {
        setLoading({
          ...loading,
          webinars: true,
        });
        const { data } = await api.get(`/${user?.type}s/courses/my_courses`);

        if (data.data) {
          setMyWebinars(data.data);
        }
      } catch (error) {
        toast.error('Erro ao buscar mini cursos');
      } finally {
        setLoading({
          ...loading,
          webinars: false,
        });
      }
    };

    if (user?.type === 'client') getWebinarRelease();
  }, [selected, getWebinar]);

  function handleChangeContent(content) {
    setSearch('');
    setSelected(content);
  }

  function sortWebinars(webinars) {
    return webinars.sort((a, b) => {
      if (a.attributes.locked === b.attributes.locked) {
        return 0;
      }
      return a.attributes.locked ? 1 : -1;
    });
  }

  function handleWebinars() {
    let allWebinarsData = [];
    if (myWebinars?.length > 0) {
      myWebinars.forEach((myWebinar) => {
        const index = webinarsData?.findIndex((webinar) => myWebinar.id === webinar.id);
        if (index !== -1) {
          allWebinarsData = webinarsData;
          allWebinarsData[index].attributes.locked = false;
        }
      });
      return sortWebinars(allWebinarsData);
    }
    return sortWebinars(webinarsData);
  }

  const webinars = handleWebinars();

  return (
    <Main>
      <ContainerSection>
        <DefaultSearchBar
          bdColor={theme.neutralGray}
          placeholder={`Pesquisar ${selected}`}
          value={search}
          onChange={({ target }) => setSearch(target.value)}
        />
        <ContainerWrapper>
          <BoxSection
            onClick={() => handleChangeContent('Videos')}
            selected={selected === 'Videos'}
          >
            <TextLabel selected={selected === 'Videos'}>Vídeos</TextLabel>
          </BoxSection>

          <BoxSection
            onClick={() => handleChangeContent('Artigos')}
            selected={selected === 'Artigos'}
          >
            <TextLabel selected={selected === 'Artigos'}>Artigos</TextLabel>
          </BoxSection>

          <BoxSection
            onClick={() => handleChangeContent('Mini Cursos')}
            selected={selected === 'Mini Cursos'}
          >
            <TextLabel selected={selected === 'Mini Cursos'}>Mini Cursos</TextLabel>
          </BoxSection>

          <BoxSection
            onClick={() => handleChangeContent('Cursos')}
            selected={selected === 'Cursos'}
          >
            <TextLabel selected={selected === 'Cursos'}>Cursos</TextLabel>
          </BoxSection>
        </ContainerWrapper>
      </ContainerSection>

      {Object.values(loading)?.every((load) => load === true) ? (
        <LoaderSpinner logo />
      ) : (
        <ContainerContent>
          {selected === 'Cursos' && <ContentCourses search={search} courses={courses} />}
          {selected === 'Mini Cursos' && (
            <ContentsWebinars
              credits={credits}
              getWebinar={getWebinar}
              search={search}
              setGetWebinar={setGetWebinar}
              setLoading={setLoading}
              webinars={webinars}
            />
          )}
          {selected === 'Artigos' && <ContentsArticles search={search} />}
          {selected === 'Videos' && <ContentsVideos search={search} />}
        </ContainerContent>
      )}
    </Main>
  );
}
