/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from '@material-ui/core';
import { CalendarViewMonth } from '@mui/icons-material';
import moment from 'moment';
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { X } from 'react-feather';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PrimaryButton, StaticButton } from '../../../components/Button';
import { EmptyEventCard, EventCard } from '../../../components/EventElements';
import { IconArea, IconAreaPay } from '../../../components/EventElements/styles';
import LoaderSpinner from '../../../components/LoaderSpinner';
import { ModalAlert } from '../../../components/Modals/ModalAlerts';
import { handleUserNames } from '../../../components/UserElements';
import useAuth from '../../../hooks/auth';
import useEvent from '../../../hooks/event';
import ImgBoxMessage from '../../../images/box-message.png';
import api from '../../../services/api';
import { ScrollWrapper } from '../../../styles/globalStyles';
import theme from '../../../theme';
import getProfessionName from '../../../utils/getProfessionName';
import sortedSchedules from '../../../utils/sortedSchedules';
import {
  ButtonSchedule,
  ContainerImage,
  ContainerImageSchedule,
  Dashboard,
  EmptyContainer,
  EmptyFont,
  EmptyImage,
  IconAlert,
  Image,
  ModalBody,
  ModalTitle,
  ModalWrapper,
  PayIcon,
  ProfileBanner,
  SchedulesContainer,
  TextNotification,
  UserAvatar,
  UserCard,
  UserContainer,
  UserImage,
  UserInfo,
  UserName,
  UserProfession,
  WrapperNotification,
  WrapperNotificationCredit,
  WrapperSchedules,
  WrapperSchedulesButton,
  WrapperSchedulesCard,
  WrapperSchedulesSection,
} from './styles';

export function ProfileCard({ ...props }) {
  const { user, client } = props;
  const history = useHistory();
  return (
    <UserCard>
      <UserAvatar>
        <UserImage size="100%" src={user?.avatar} />
      </UserAvatar>
      <UserInfo>
        <UserName>{handleUserNames(user)}</UserName>
        {user?.profession && (
          <UserProfession>{getProfessionName(user.profession, 'toPt')}</UserProfession>
        )}
        {client && (
          <StaticButton onClick={() => history.push('/client/update')} title={'Editar Perfil'} />
        )}
      </UserInfo>
    </UserCard>
  );
}

export function DashboardProfileBanner({ ...props }) {
  const { user, profileBannerStyle } = props;
  const { events, getEvents } = useEvent();
  const [schedules, setSchedules] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchSchedules = async () => {
      const data = await getEvents(controller);
      if (data) {
        setSchedules(events);
      } else {
        toast.error('Não foi possivel carregar agendamentos!');
      }
    };
    fetchSchedules();

    return () => controller.abort();
  }, [user]);

  useEffect(() => {
    const refetchSchedules = async () => {
      setSchedules(events);
    };
    refetchSchedules();
  }, [events]);

  return (
    <ProfileBanner style={profileBannerStyle}>
      <UserContainer>
        <ProfileCard user={user} client />
      </UserContainer>
      <SchedulesContainer>
        {schedules?.length === 0 ? (
          <EmptyEventCard type="agendados" />
        ) : (
          <EventCard event={schedules[0]} />
        )}
      </SchedulesContainer>
    </ProfileBanner>
  );
}

export default function ClientDashboard() {
  const history = useHistory();
  const { user } = useAuth();
  const {
    backgroundImg,
    events,
    pastEvents,
    firstEvent,
    loadingEvent,
    notifications,
    scheduleInfo,
    setEvents,
    setFirstEvent,
    setLoadingEvent,
    setLoadingPastEvent,
    loadingPastEvent,
    setPastEvents,
  } = useEvent();
  const [showModal, setShowModal] = useState(false);
  const [credits, setCredits] = useState(0);
  const [modalAlert, setModalAlert] = useState(user?.status !== 'registered' && !scheduleInfo?.id);

  useEffect(async () => {
    getCredits();
    fetchSchedules();
    fetchPastSchedules();
  }, []);

  const schedules = useMemo(() => sortedSchedules(events), [events]);

  useEffect(() => {
    const schedulePaymentPending =
      schedules?.filter((schedule) =>
        ['waiting_payment', 'pending', 'refused', 'refunded', 'pending_refund'].includes(
          schedule.attributes.paymentStatus
        )
      )?.length > 0;
    setShowModal(schedulePaymentPending);
  }, [schedules]);

  const getCredits = useCallback(async () => {
    const { data } = await api.get(`${user.type}s/user`);
    if (data.data) {
      setCredits(data.data.attributes.credits);
    }
  }, []);

  const fetchSchedules = useCallback(async () => {
    const controller = new AbortController();
    try {
      const { data } = await api.get(`${user.type}s/schedules`);
      if (data.data) {
        setEvents(data.data);
        return;
      }
      throw new Error('Não foi possível carregar os agendamentos');
    } catch (error) {
      if (!controller.signal.aborted) {
        toast.error('Não foi possível carregar os agendamentos');
      }
    } finally {
      setLoadingEvent(false);
    }

    return () => controller?.abort();
  }, [loadingEvent]);

  const fetchPastSchedules = useCallback(async () => {
    const controller = new AbortController();
    try {
      const { data } = await api.get(`${user.type}s/schedule-history`);
      if (data.data) {
        setPastEvents(data.data);
        return;
      }
      throw new Error('Não foi possível carregar os agendamentos');
    } catch (error) {
      if (!controller.signal.aborted) {
        toast.error('Não foi possível carregar os agendamentos');
      }
    } finally {
      setLoadingPastEvent(false);
    }

    return () => controller?.abort();
  }, [loadingPastEvent]);

  if (scheduleInfo?.publicP || scheduleInfo?.token) {
    history.push(`/client/schedule/${scheduleInfo?.professional?.id}`);
  }

  // async function handleModal() {
  //   try {
  //     await updateOnboarding({ welcome: true }, 'client', user?.id);
  //   } catch (error) {
  //     toast.error('Não foi possível atualizar o seu perfil');
  //   }
  // }

  const pastSchedules = sortedSchedules(
    pastEvents?.filter(
      (schedule) =>
        moment(schedule.attributes?.startDate, 'YYYY-MM-DD').format('MM') === moment().format('MM')
    ),
    true
  );

  const newNotifications = notifications.data?.filter(
    (item) => item.attributes?.readed === null && item.attributes?.notificationType === 'message'
  )?.length;

  const message = newNotifications === 0 ? 'nova mensagem' : 'novas mensagens';

  return (
    <Suspense fallback={<LoaderSpinner />}>
      <Dashboard>
        <WrapperSchedulesCard>
          <WrapperSchedulesButton onClick={() => history.push('/client/professionals')}>
            <ContainerImageSchedule src={backgroundImg} />
            <ButtonSchedule>Agendar Atendimento</ButtonSchedule>
          </WrapperSchedulesButton>
          <WrapperNotification
            message={newNotifications !== 0 ? 'true' : 'false'}
            onClick={() => newNotifications !== 0 && history.push('/client/messages')}
          >
            <TextNotification>
              {newNotifications === 0
                ? `Você não tem nenhuma ${message}`
                : `Você tem ${newNotifications} ${message}`}
            </TextNotification>
            <ContainerImage>
              <Image size="100px" src={ImgBoxMessage} />
            </ContainerImage>
          </WrapperNotification>
          <WrapperNotificationCredit
            onClick={() =>
              history.push({
                pathname: '/client/contents',
                state: { selected: 'Mini Cursos' },
              })
            }
          >
            <TextNotification style={{ fontSize: '1rem', marginBottom: '0' }}>
              {`Você tem ${credits} ponto${
                credits > 1 ? 's' : ''
              } disponíveis para liberar conteúdos`}
            </TextNotification>
            <p style={{ color: theme.white, fontSize: '0.8rem', marginTop: '1rem' }}>
              * Faça consultas para conseguir pontos
            </p>
          </WrapperNotificationCredit>
        </WrapperSchedulesCard>
        <WrapperSchedulesSection>
          <WrapperSchedules>
            <h3 style={{ marginLeft: '1rem', marginBottom: '0.5rem' }}>
              Próximas consultas em {moment().format('MMMM [de] YYYY')}
            </h3>
            <ScrollWrapper>
              {loadingEvent ? (
                <LoaderSpinner />
              ) : schedules?.length > 0 ? (
                <SchedulesContainer>
                  {schedules?.map((schedule) => (
                    <EventCard key={schedule.id} event={schedule} dashboard />
                  ))}
                </SchedulesContainer>
              ) : (
                <EmptyContainer>
                  <EmptyImage />
                  <EmptyFont>Você ainda não tem consultas agendadas neste mês.</EmptyFont>
                </EmptyContainer>
              )}
            </ScrollWrapper>
          </WrapperSchedules>
          <WrapperSchedules>
            <h3 style={{ marginLeft: '1rem', marginBottom: '0.5rem' }}>
              Consultas realizadas em {moment().format('MMMM [de] YYYY')}
            </h3>
            <ScrollWrapper>
              {loadingPastEvent ? (
                <LoaderSpinner />
              ) : pastSchedules?.length > 0 ? (
                <SchedulesContainer>
                  {pastSchedules?.map((schedule) => (
                    <EventCard key={schedule.id} event={schedule} past={true} />
                  ))}
                </SchedulesContainer>
              ) : (
                <EmptyContainer>
                  <EmptyImage />
                  <EmptyFont style={{ marginTop: '0.7rem' }}>
                    Você ainda não tem consultas agendadas neste mês.
                  </EmptyFont>
                  <EmptyFont style={{ marginTop: '0.7rem' }}>
                    Para ver o relatório completo de consultas:
                  </EmptyFont>
                  <PrimaryButton
                    style={{ marginTop: '0.7rem' }}
                    onClick={() => history.push('/client/schedules_report')}
                    iconEnd={<CalendarViewMonth />}
                    width="10rem"
                  >
                    Clique Aqui
                  </PrimaryButton>
                </EmptyContainer>
              )}
            </ScrollWrapper>
          </WrapperSchedules>
        </WrapperSchedulesSection>

        {showModal && firstEvent === 0 && (
          <Modal open>
            <ModalBody>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  position: 'relative',
                  width: '100%',
                }}
              >
                <IconArea style={{ width: 'fit-content' }}>
                  <IconAlert style={{ marginRight: '1rem' }} />
                  <h1 style={{ fontSize: '1.2rem', fontWeight: '700' }}>Atenção!</h1>
                </IconArea>
                <X
                  style={{
                    cursor: 'pointer',
                    width: '2rem',
                    height: '2rem',
                    position: 'absolute',
                    top: '0rem',
                    right: '0rem',
                  }}
                  onClick={() => {
                    setShowModal(false);
                    setFirstEvent(1);
                  }}
                />
              </div>
              <ModalWrapper>
                <ModalTitle>
                  Você tem agendamentos com pagamento pendente. Vá para aba{' '}
                  <Link to="schedules_report" style={{ fontWeight: '700', textDecoration: 'none' }}>
                    "Relatório de Consultas"
                  </Link>{' '}
                  clique no ícone
                  <IconAreaPay>
                    <PayIcon />
                  </IconAreaPay>
                  para realizar o pagamento.
                </ModalTitle>
              </ModalWrapper>
            </ModalBody>
          </Modal>
        )}

        {modalAlert && (
          <ModalAlert
            goBack={() => setModalAlert(false)}
            confirm={() =>
              history.push({
                pathname: '/client/config',
                state: { selected: 'Segurança' },
              })
            }
            title="Atualize sua Senha"
            message="Você está utilizando a senha temporária, por favor atualize-a."
          />
        )}
      </Dashboard>
      {/* {!user?.welcome && <OnboardingClient handleModal={handleModal} />} */}
    </Suspense>
  );
}
