import ReactPlayer from 'react-player';
import styled from 'styled-components';
import {
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
  BigFont,
  ExtrBigFont,
  SmallFont,
} from '../../../../styles/globalStyles';

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  position: relative;
  width: 100%;
`;

export const ExBanner = styled.div`
  align-items: flex-start;
  background-color: #f4f5fa;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 1.5rem;
  width: 100%;
`;

export const ProfileBanner = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;
  padding: 2rem 2.5rem;
  position: relative;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    flex-direction: column;
    padding: 1rem;
  }
`;

export const ProfileWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const EventWrapper = styled(Wrapper)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;

  .--section-icon {
    align-items: flex-end;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    margin-top: 2rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    margin-top: 0;
    grid-template-columns: 1fr;
    justify-items: start;

    .--section-icon {
      align-items: flex-start;
    }
  }
`;

export const WrapperContainer = styled.div`
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    padding-left: 10px;
  }
`;

export const MainCalendar = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  .--title {
    color: black;
    font-size: 1.3rem;
    font-weight: 700;
    padding: 1rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    .--title {
      font-size: 1rem;
    }
  }
`;

export const WrapperCalendar = styled.div`
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 70%;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    width: 80%;
  }
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 100%;
  }
`;

export const EventInfoContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    align-items: flex-start;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
`;

export const ProfileName = styled(ExtrBigFont)`
  color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: row;
  text-align: left;
`;

export const SmallText = styled(SmallFont)`
  align-items: center;
  color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: row;
  font-weight: 400;
  padding-right: 10px;
  text-align: left;
`;

export const ExtraBigText = styled(ExtrBigFont)`
  color: ${({ theme }) => theme.white};
  font-weight: 400;
  padding-bottom: 10px;
  padding-right: 10px;
  text-align: left;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    font-size: 18px;
    font-weight: 700;
  }
`;

export const Body = styled.div`
  border-radius: 1rem;
  column-gap: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
`;

export const BigText = styled(BigFont)`
  color: ${({ theme }) => theme.darkNavy};
`;

export const BlueBigText = styled(BigFont)`
  font-weight: 600;
  color: ${({ theme }) => theme.white};
`;

export const BlueSmallText = styled(SmallText)`
  color: ${({ theme }) => theme.darkNavy};
  font-weight: 500;
  text-align: center;
  z-index: 2;
`;

export const LightSmallText = styled(SmallText)`
  color: ${({ theme }) => theme.white};
`;

export const ProfileVideo = styled(ReactPlayer)`
  display: flex;
  height: 100%;
  padding: 1rem;
  width: 100%;
  z-index: 9;
`;

export const ProfileInformation = styled.div`
  align-items: flex-start;
  background-color: #f4f5fa;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2rem;
  min-height: 400px;
  width: 100%;
`;

export const ProfileAvailability = styled.section`
  align-items: center;
  background-color: #f4f5fa;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2rem;
  padding: 0;
  width: 100%;
`;

export const ContentContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  padding: 20px 30px;
  width: 100%;
`;

export const ClinicContainer = styled.a`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 1rem;
  width: 100%;
  text-decoration: none;
`;

export const ListItem = styled.li`
  text-indent: 1rem;
`;

export const ProfileEditButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.secondary};
  border-radius: 2rem;
  border: 1px solid ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  height: 3rem;
  justify-content: center;
  width: 12rem;

  &:hover {
    background-color: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.secondary};
  }
`;
