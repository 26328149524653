import { Box } from '@material-ui/core';
import IntlCurrencyInput from 'react-intl-currency-input';
import styled from 'styled-components';
import { SmallButton } from '../../../components/Button';
import {
  AnimationWrapper,
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
  MediumBigFont,
  SmallFont,
} from '../../../styles/globalStyles';

export const MainContainer = styled(AnimationWrapper)`
  align-items: flex-start;
  display: grid;
  column-gap: 3rem;
  grid-template-columns: 1.5fr 1fr;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    column-gap: 2rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    row-gap: 1rem;
  }
`;

export const RowItem = styled.section`
  width: 100%;
  display: flex;
  padding-bottom: 30px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const RowLine = styled.section`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerItem = styled.section`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;

  .funds {
    border-radius: 0.625rem;
    border: 1px solid ${({ theme }) => theme.neutralGray};
    margin-top: 1rem;
    padding: 1rem;
    width: 100%;
  }

  .invoice {
    border-radius: 0.625rem;
    border: 1px solid ${({ theme }) => theme.neutralGray};
    margin-top: 1rem;
    padding: 1rem;
    width: 100%;
  }

  .withdraw {
    background-color: ${({ theme }) => theme.neutralGray};
    border-radius: 0.625rem;
    border: 1px solid ${({ theme }) => theme.neutralGray};
    margin-top: 1rem;
    padding: 1rem;
    width: 100%;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerItemLeft = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const BanckAccountContainer = styled.div`
  background-color: ${({ theme }) => theme.primary};
  border-radius: 1rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 1rem 2rem;
  width: 100%;
`;

export const PayOutContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    align-items: center;
    justify-content: center;
  }
`;

export const SMFont = styled(SmallFont)`
  color: ${({ theme }) => theme.neutralGray};

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    font-size: 0.8rem;
  }
`;

export const MDFont = styled(MediumBigFont)`
  font-weight: 400;
  padding: 5px 0;
  color: ${({ theme }) => theme.secondary};
`;

export const MDFontDark = styled(MDFont)`
  color: ${({ theme }) => theme.darkNavy};
  font-weight: 600;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    font-size: 1.2rem;
  }
`;

export const MDFontRed = styled(MDFont)`
  font-weight: 600;
  color: ${({ theme }) => theme.errorRed};
`;

export const Button = styled(SmallButton)`
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.white};
  width: 100%;
  height: 2.3rem;
`;

export const BoxModalWrapper = styled(Box)`
  align-items: center;
  background-color: #f4f5fa;
  border-radius: 1rem;
  border: 1px solid #bcc1e0;
  box-shadow: 24;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  left: 50%;
  max-height: 65%;
  max-width: 70%;
  padding: 2.5rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    max-width: 90%;
    padding: 1rem;
    width: 100%;
  }
`;

export const BoxWrapper = styled.div`
  align-items: center;
  background-color: #f4f5fa;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

export const IconArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 0.5rem 0 0;
  width: 100%;
  cursor: pointer;
`;

export const ButtonWithdraw = styled.button`
  align-items: center;
  background-color: #426aff;
  border-radius: 30px;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-weight: 700;
  height: 41px;
  justify-content: center;
  padding: 10px 20px;
  width: 304px;

  &:disabled {
    opacity: 0.5;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 250px;
  }
`;

export const ButtonWithdrawWrapper = styled.button`
  align-items: center;
  background-color: #f4f5fa;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  padding: 0 2rem 1rem 2rem;
  width: 100%;
  cursor: default;
  border-radius: 1rem;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    top: 32rem;
  }
`;

export const Hr = styled.hr`
  border: 1px solid #bcc1e0;
  margin-bottom: 1.5rem;
  width: 100%;
`;

export const InputWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  position: relative;
  width: 80%;
`;

export const InputWithdraw = styled(IntlCurrencyInput)`
  background-color: #fff;
  border-radius: 0.5rem;
  border: 1px solid #bcc1e0;
  height: 2.5rem;
  padding: 0 1rem;
  width: 100%;
`;

export const ButtonMaxWithdraw = styled.button`
  background-color: #426aff;
  border-radius: 0.7rem;
  border: 1px solid #fff;
  color: #fff;
  font-weight: 600;
  height: 1.7rem;
  padding: 0 1rem;
  position: absolute;
  right: 0.5rem;
  width: fit-content;

  &:disabled {
    opacity: 0.5;
  }
`;
