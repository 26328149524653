import styled from 'styled-components';
import { ReactComponent as LeftArrow } from '../../../images/leftArrow.svg';
import {
  BigFont,
  BREAKPOINT_LG_PX,
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
} from '../../../styles/globalStyles';
import { moveBottomToTop } from '../../../styles/keyframes';

export const Main = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.white};
  column-gap: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  position: relative;
  height: 100vh;
  row-gap: 0;
  width: 100%;

  @media (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: 1fr;
  }
`;

export const LoginScroll = styled.section`
  height: 85vh;
  overflow: auto;
  padding: 2rem 0;
  width: 100%;

  &::-webkit-scrollbar {
    background-color: ${({ primary }) => (primary ? '#eaecf4' : '#ced0db')};
    border-radius: 5px;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ primary }) => (primary ? '#eaecf4' : '#ced0db')};
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #999ebc;
    border-radius: 5px;
    height: 2.5rem;
  }
`;

// export const Input = styled(TextInput)`
//   background-color: transparent;
// `;

export const SendButton = styled.button`
  background-color: ${({ theme, professional, bgColor }) =>
    bgColor ? bgColor : professional ? theme.aquaBlue : theme.secondary};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  font-family: 'Poppins';
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 1rem;
  padding: 0.2rem 0;
  transition: 0.4s;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme, bgColor }) => bgColor || theme.primary};
    color: ${({ theme, bgColor }) => bgColor || theme.primary};
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  }
`;

export const SendLink = styled.a`
  background-color: ${({ theme, professional }) =>
    professional ? theme.aquaBlue : theme.secondary};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  font-family: 'Poppins';
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.2rem 0;
  text-align: center;
  text-decoration: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  transition: 0.4s;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.lightGray};
    border: 1px solid
      ${({ theme, professional }) => (professional ? theme.aquaBlue : theme.secondary)};
    color: ${({ theme, professional }) => (professional ? theme.aquaBlue : theme.secondary)};
  }

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    height: 2.5rem;
  }
`;

export const RegisterButton = styled.button`
  background-color: ${({ theme }) => theme.aquaBlue};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  font-family: 'Poppins';
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.2rem 0;
  transition: 0.4s;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.lightGray};
    border: 1px solid ${({ theme }) => theme.aquaBlue};
    color: ${({ theme }) => theme.aquaBlue};
  }
`;

export const LoginForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
  z-index: 1;
`;

export const LoginWrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 90%;
  }
`;

export const LoginWrapperScreen = styled.section`
  align-items: center;
  animation: ${moveBottomToTop} 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: center;
  width: 100%;
`;

export const LoginWrapperSection = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    width: 70%;
  }

  @media only screen and (max-width: ${BREAKPOINT_LG_PX}) {
    width: 80%;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 90%;
  }
`;

export const LoginButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const InputWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 85%;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    height: 80%;
  }

  @media only screen and (max-width: ${BREAKPOINT_LG_PX}) {
    height: 90%;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const LogoImg = styled.img`
  align-self: center;
  height: 84px;
  margin-bottom: 20px;
  width: 252px;
  z-index: 2;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    height: 70px;
    width: 210px;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    height: 60px;
    width: 180px;
  }
`;

export const LoginTitle = styled(BigFont)`
  font-family: 'Poppins';
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  height: fit-content;
  line-height: 45px;
  margin-bottom: 1rem;
  text-align: left;
  width: 100%;
  z-index: 2;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    font-size: 20px;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    font-size: 18px;
  }
`;

export const LoginParagraph = styled.p`
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 500;
  padding: 0.15rem 0;
  text-align: center;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    border-radius: 0.25rem;
    font-size: 14px;
    margin-top: 0.5rem;
    padding: 0.15rem 0;
  }
`;

export const LoginLink = styled.button`
  background-color: transparent;
  color: ${({ theme }) => theme.primary};
  cursor: pointer;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 700;
  margin-left: 0.5rem;

  &:hover {
    color: ${({ theme }) => theme.secondary};
    text-decoration: underline;
  }
`;

export const LoginMain = styled.section`
  align-items: center;
  animation: ${moveBottomToTop} 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100%;
  z-index: 0;
`;

export const BackgroundImg = styled.figure`
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  z-index: 1;
`;

export const ImgEllipseTop = styled.img`
  left: 45vw;
  position: absolute;
  top: 0;
  z-index: 0;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    left: 85vw;
  }
`;

export const ImgEllipseBottom = styled.img`
  bottom: 0;
  position: absolute;
  left: 0;
  z-index: 0;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    left: 0;
    position: absolute;
    top: 0;
    transform: rotateX(180deg);
  }
`;

export const IconArea = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 3rem;
  width: 100%;
`;

export const LeftArrowIcon = styled(LeftArrow)``;
