import { Tooltip } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import ClientSignUpForm from '../../../components/FormikElements/ClientSignUpForm';
import LoaderSpinner from '../../../components/LoaderSpinner';
import useAuth from '../../../hooks/auth';
import useEvent from '../../../hooks/event';
import EllipseBottom from '../../../images/ellipse-bottom.svg';
import EllipseTop from '../../../images/ellipse-top.svg';
import { BREAKPOINT_MD, InstagramIcon } from '../../../styles/globalStyles';
import theme from '../../../theme';
import useWindowSize from '../../../utils/WindowSize';
import { Main } from '../../Professional/SignUp/styles';
import { BackgroundImg, ImgEllipseBottom, ImgEllipseTop } from '../../Public/Login/styles';
import { IconAreaInstagram, SocialIconsWrapper } from '../../Public/Profile/styles';

export default function ClientSignUp() {
  const location = useLocation();
  const { signUpClient } = useAuth();
  const { backgroundImg } = useEvent();
  const history = useHistory();
  const { width } = useWindowSize();
  const [loading, setLoading] = useState(false);

  const schoolName = new URLSearchParams(location.search).get('schoolName');
  const schoolId = new URLSearchParams(location.search).get('schoolId');

  const submitForm = async (values) => {
    setLoading(true);
    try {
      const success = await signUpClient(values);

      if (success) {
        toast.success('Cadastrado com sucesso!');
        history.push('/cliente/sucesso');
      } else {
        toast.error(
          'Erro no servidor. Reveja se seus dados estão corretos e tente se cadastrar novamente em alguns minutos.'
        );
      }
    } catch (error) {
      toast.error(
        'Erro no servidor. Reveja se seus dados estão corretos e tente se cadastrar novamente em alguns minutos.'
      );
    } finally {
      setLoading(false);
    }
  };

  const schema = Yup.object({
    firstName: Yup.string().required('Nome é obrigatório!'),
    lastName: Yup.string().required('Sobrenome é obrigatório!'),
    email: Yup.string().email().required('Email é obrigatório!'),
    phone: Yup.string().required('Telefone é obrigatório!'),
    password: Yup.string()
      .min(8, 'A senha deve possuir no mínimo 8 caracteres')
      .required('Senha é obrigatório!'),
    password_confirmation: Yup.string()
      .required('Confirmação da senha é obrigatorio!')
      .oneOf([Yup.ref('password'), ''], 'As senhas devem ser iguais.'),
  });

  const form = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
      schoolName: schoolName || null,
      schoolId: schoolId || null,
      schoolType: 'student',
    },
    validationSchema: schema,
    validateOnChange: true,
    onSubmit: (values) => submitForm(values),
  });

  return (
    backgroundImg && (
      <FormikProvider value={form}>
        {loading ? (
          <LoaderSpinner logo />
        ) : (
          <Main>
            <ClientSignUpForm
              form={form}
              handleSubmit={submitForm}
              goBack={() => history.goBack()}
            />
            <ImgEllipseTop src={EllipseTop} alt="imgLogin" />
            <ImgEllipseBottom src={EllipseBottom} alt="imgLogin" />
            {width > BREAKPOINT_MD && <BackgroundImg src={backgroundImg} />}
            <SocialIconsWrapper>
              {/* <Tooltip title="Fale com a gente">
                <IconAreaWhatsApp
                  href="https://wa.me/5571999960614"
                  target="_blank"
                >
                  <WhatsAppIcon fontSize="medium" sx={{ color: theme.white }} />
                </IconAreaWhatsApp>
              </Tooltip> */}
              <Tooltip title="Siga-nos no Instagram">
                <IconAreaInstagram href="https://www.instagram.com/allminds.app/" target="_blank">
                  <InstagramIcon fontSize="medium" sx={{ color: theme.white }} />
                </IconAreaInstagram>
              </Tooltip>
            </SocialIconsWrapper>
          </Main>
        )}
      </FormikProvider>
    )
  );
}
