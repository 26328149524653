import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import EllipseBottom from '../../../../images/ellipse-bottom.svg';
import EllipseTop from '../../../../images/ellipse-top.svg';
import LoginBackGroundImg from '../../../../images/login-background.png';
import Logo from '../../../../images/logoLogin.png';
import { BREAKPOINT_MD } from '../../../../styles/globalStyles';
import useWindowSize from '../../../../utils/WindowSize';
import {
  BackgroundImg,
  ImageContainer,
  ImgEllipseBottom,
  ImgEllipseTop,
  LoginWrapperScreen,
  LoginWrapperSection,
  LogoImg,
  Main,
  SendButton,
} from '../../../Public/Login/styles';
import { Container } from './styles';

export default function Success() {
  const history = useHistory();
  const { width } = useWindowSize();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const BackgroundImg = new Image();
    BackgroundImg.src = LoginBackGroundImg;
    BackgroundImg.onload = () => setLoading(true);
  }, []);

  return (
    loading && (
      <Main>
        <LoginWrapperScreen>
          <LoginWrapperSection>
            <ImageContainer>
              <LogoImg src={Logo} alt="AllMinds" />
            </ImageContainer>
          </LoginWrapperSection>
          <Container>
            <h1 className="--title">Cadastro realizado com sucesso!</h1>
            <SendButton onClick={() => history.push('/cliente/conectar-se')}>
              Fazer login
            </SendButton>
          </Container>
        </LoginWrapperScreen>
        <ImgEllipseTop src={EllipseTop} alt="imgLogin" />
        <ImgEllipseBottom src={EllipseBottom} alt="imgLogin" />
        {width > BREAKPOINT_MD && <BackgroundImg src={LoginBackGroundImg} />}
      </Main>
    )
  );
}
