/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { addLeftZeros } from '../../../screens/Professional/BankAccount';
import { TextError } from '../../../screens/Professional/Config/styles';
import { brasilApi } from '../../../services/api';
import theme from '../../../theme';
import FormikControl from '../../FormikControl';
import { SelectList } from '../../SelectList';
import {
  BankFormContainer,
  BankFormTitle,
  BankInputWrapper,
  BankInputWrapperColumn,
  BankInputWrapperColumnInline,
  BankInputWrapperRow,
} from './styles';

export default function BankAccountForm({ form, clinic }) {
  const { values, errors, touched, setFieldValue, handleBlur, handleChange } = form;
  const [bankList, setBankList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [documentNumberType, setDocumentNumberType] = useState('CPF');

  useEffect(() => {
    const fetchBankCodes = async () => {
      try {
        await brasilApi
          .get()
          .then(({ data, errors }) => {
            if (errors) {
              throw new Error('Erro ao carregar bancos');
            }
            setBankList(
              data?.map((bank) => ({
                label: bank.fullName,
                value: { code: bank.code, fullName: bank.fullName },
              }))
            );
            setFieldValue(
              'bankName',
              data?.filter((bank) => addLeftZeros(bank.code, 3) === values.bankCode)[0]?.fullName
            );
            setLoading(false);
          })
          .catch((error) => {
            toast.error(error);
            setLoading(false);
          });
      } catch (error) {
        toast.error(error);
        setLoading(false);
      }
    };
    fetchBankCodes();
  }, []);

  const types = [
    { label: 'Conta Corrente', value: 'conta_corrente' },
    { label: 'Conta Poupanca', value: 'conta_poupanca' },
  ];

  return (
    <BankFormContainer>
      <BankFormTitle>Informações Bancárias</BankFormTitle>
      {loading || (
        <BankInputWrapper>
          <BankInputWrapperColumn>
            <SelectList
              id="bankCode"
              sign="true"
              name="bankCode"
              value={values.bankCode ? { value: values?.bankCode, label: values?.bankName } : null}
              onChange={({ value }) => {
                setFieldValue('bankCode', addLeftZeros(value.code, 3));
                setFieldValue('bankName', value.fullName);
              }}
              onBlur={handleBlur}
              options={bankList}
              placeholder="Selecione o Banco"
            />
            <TextError>{errors.bankCode && touched.bankCode && errors.bankCode}</TextError>
          </BankInputWrapperColumn>

          <BankInputWrapperColumn>
            <SelectList
              id="accountType"
              sign="true"
              name="accountType"
              value={
                values.accountType
                  ? {
                      value: values.accountType,
                      label: types?.filter((item) => item.value === values.accountType)?.[0]?.label,
                    }
                  : null
              }
              onChange={({ value }) => setFieldValue('accountType', value)}
              onBlur={handleBlur}
              options={types}
              placeholder="Tipo de Conta"
            />
            <TextError>{errors.accountType && touched.accountType && errors.accountType}</TextError>
          </BankInputWrapperColumn>

          <BankInputWrapperRow>
            <BankInputWrapperColumnInline>
              <FormikControl
                bgColor={theme.white}
                control="input"
                errors={errors.bankAg}
                label="Agência"
                labelFamily="Poppins"
                labelSize="0.9rem"
                labelWeight="600"
                name="bankAg"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="9999"
                touched={touched.bankAg}
                upLabel
                value={values.bankAg}
              />
            </BankInputWrapperColumnInline>

            <BankInputWrapperColumnInline>
              <FormikControl
                bgColor={theme.white}
                control="input"
                errors={errors.agenciaDv}
                label="Dígito"
                labelFamily="Poppins"
                labelSize="0.9rem"
                labelWeight="600"
                name="agenciaDv"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="0"
                touched={touched.agenciaDv}
                upLabel
                value={values.agenciaDv}
              />
            </BankInputWrapperColumnInline>
          </BankInputWrapperRow>

          <BankInputWrapperRow>
            <BankInputWrapperColumnInline>
              <FormikControl
                bgColor={theme.white}
                control="input"
                errors={errors.bankCc}
                label="Conta"
                labelFamily="Poppins"
                labelSize="0.9rem"
                labelWeight="600"
                name="bankCc"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="999999"
                touched={touched.bankCc}
                upLabel
                value={values.bankCc}
              />
            </BankInputWrapperColumnInline>
            <BankInputWrapperColumnInline>
              <FormikControl
                bgColor={theme.white}
                control="input"
                errors={errors.contaDv}
                label="Digito"
                labelFamily="Poppins"
                labelSize="0.9rem"
                labelWeight="600"
                name="contaDv"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="0"
                touched={touched.contaDv}
                upLabel
                value={values.contaDv}
              />
            </BankInputWrapperColumnInline>
          </BankInputWrapperRow>

          <BankInputWrapperColumn>
            <FormikControl
              bgColor={theme.white}
              control="input"
              errors={errors?.paymentName}
              label="Nome do Titular"
              labelFamily="Poppins"
              labelSize="0.9rem"
              labelWeight="600"
              name="paymentName"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="OBS: máximo 30 caracteres"
              touched={touched?.paymentName}
              upLabel
              value={values?.paymentName}
            />
          </BankInputWrapperColumn>

          {clinic && (
            <BankInputWrapperColumn>
              <div
                style={{
                  margin: '0.5rem 0',
                  width: '100%',
                }}
              >
                <FormikControl
                  bgColor={theme.white}
                  control="select"
                  label="Tipo de documento"
                  labelFamily="Poppins"
                  labelSize="0.9rem"
                  labelWeight="600"
                  name="documentNumberType"
                  options={['CNPJ', 'CPF']}
                  onChange={({ target }) => {
                    setDocumentNumberType(target.value);
                  }}
                  upLabel
                  value={values.documentNumberType}
                  defaultValue="CPF"
                />
              </div>

              <FormikControl
                bgColor={theme.white}
                control="input"
                errors={errors.paymentDocumentNumber}
                label={documentNumberType === 'CNPJ' ? 'CNPJ' : 'CPF'}
                labelFamily="Poppins"
                labelSize="0.9rem"
                labelWeight="600"
                mask={documentNumberType === 'CNPJ' ? '99.999.999/9999-99' : '999.999.999-99'}
                name="paymentDocumentNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.paymentDocumentNumber}
                upLabel
                value={values.paymentDocumentNumber}
              />
            </BankInputWrapperColumn>
          )}
        </BankInputWrapper>
      )}
    </BankFormContainer>
  );
}
