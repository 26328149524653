import { Check, ThumbsDown, ThumbsUp } from 'react-feather';
import styled from 'styled-components';
import { StaticButton } from '../../../components/Button';
import { BREAKPOINT_SM_PX, MediumBigFont } from '../../../styles/globalStyles';

export const Hr = styled.hr`
  border: 1px solid #bcc1e0;
  height: 1px;
  margin-bottom: 1.5rem;
  width: 90%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 100%;
  }
`;

export const ReviewContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    padding: 0 1em 0 1em;
  }
`;

export const ThumbsContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ThumbsTitle = styled(MediumBigFont)`
  color: ${({ theme }) => theme.darkNavy};
  font-weight: 400;
  text-align: center;
`;

export const ThumbsButtonContainer = styled(MediumBigFont)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ThumbsButton = styled.button`
  align-items: center;
  background-color: ${({ theme, checked }) => (checked ? theme.primary : theme.aquaBlue)};
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  height: 70px;
  justify-content: center;
  margin: 20px 15px;
  width: 70px;
`;

export const ThumbUpIcon = styled(ThumbsUp)`
  color: ${({ theme }) => theme.softBackground};
  height: 40px;
  width: 40px;
`;

export const ThumbDownIcon = styled(ThumbsDown)`
  color: ${({ theme }) => theme.softBackground};
  height: 40px;
  width: 40px;
`;

export const InputContainer = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 90%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 90%;
  width: 300px;
`;

export const ContinueButton = styled(StaticButton)`
  height: 50px;
`;

export const CheckIcon = styled(Check)`
  width: 80px;
  height: 80px;
  padding: 10px;
  color: ${({ theme }) => theme.primary};
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.primary};
  margin: 20px 0;
`;
