import { Calendar, DollarSign } from 'react-feather';
import Modal from 'react-modal';
import styled from 'styled-components';
import { StaticButton } from '../../../components/Button';
import { ProfileImage } from '../../../components/ListItens';
import { ReactComponent as AlertIcon } from '../../../images/alert-triangle-icon.svg';
import { ReactComponent as CardImage } from '../../../images/empty-dashboard.svg';
import {
  AnimationWrapper,
  BREAKPOINT_LG_PX,
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
  BigFont,
  ExtrBigFont,
  MediumFont,
  ModalBoxWrapper,
} from '../../../styles/globalStyles';

export const ExtraBigText = styled(ExtrBigFont)`
  text-align: left;
  padding-bottom: 10px;
  padding-right: 10px;
  font-weight: 400;
  color: ${({ theme }) => theme.secondary};
`;

export const EmptyDashBoard = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
`;

export const EmptyContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const EmptyImage = styled(CardImage)`
  display: flex;
  width: 100%;
  margin: 0 0 15px 0;
`;

export const EmptyFont = styled(BigFont)`
  color: ${({ theme }) => theme.secondary};
  padding-bottom: 10px;
  text-align: center;
`;

export const Dashboard = styled(AnimationWrapper)`
  align-items: flex-start;
  display: grid;
  flex-direction: column;
  gap: 3rem;
  grid-template-columns: max(450px) 1fr;
  justify-content: center;
  justify-items: center;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    gap: 2rem;
    grid-template-columns: max(300px) 1fr;
  }

  @media only screen and (max-width: ${BREAKPOINT_LG_PX}) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    grid-template-columns: 1fr;
  }
`;

// Dashboard component

export const WrapperNotification = styled.section`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 2rem;
  cursor: ${({ message }) => (message === 'true' ? 'pointer' : 'default')};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 2rem;
  width: 100%;
`;

export const WrapperNotificationCredit = styled(WrapperNotification)`
  border-radius: 1rem;
  padding: 0.75rem 1rem;
  cursor: pointer;
`;

export const WrapperSchedulesButton = styled.div`
  align-items: flex-end;
  background-color: ${({ theme }) => theme.secondary};
  border-radius: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  padding: 0;
  transition: 0.4s;

  &:hover {
    background-color: ${({ theme }) => theme.primary};
  }
`;

export const TextNotification = styled(BigFont)`
  color: ${({ theme }) => theme.white};
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 2.5rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    font-size: 1.3rem;
    width: 100%;
  }
`;

export const ContainerImage = styled.div`
  align-items: center;
  border-radius: 0.325rem;
  height: fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  border-radius: 0.325rem;
  height: 100%;
  width: 100%;
`;

export const ProfileBanner = styled.section`
  width: 100%;
  display: flex;
  padding: 0 70px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border-radius: 0% 0% 50% 50%/ 0% 0% 3% 3%;
  background-color: ${({ theme }) => theme.neutralGray};
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    flex-direction: column;
    padding: 0;
  }
`;

export const UserContainer = styled.section`
  flex: 1;
  display: flex;
  padding: 50px 0;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

export const SchedulesContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const WrapperSchedules = styled.div`
  background-color: ${({ theme }) => theme.neutralGray};
  border-radius: 0.7rem;
  border: 1px solid ${({ theme }) => theme.neutralGray};
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
  width: 100%;
`;

export const WrapperSchedulesSection = styled.div`
  border-radius: 0.7rem;
  border: 1px solid ${({ theme }) => theme.neutralGray};
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  max-height: 80%;
  padding: 0;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    gap: 2rem;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
  }
`;

export const WrapperSchedulesCard = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-rows: repeat(2, 1fr);
  max-height: 80%;
  padding: 0;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_LG_PX}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    gap: 0;
    row-gap: 1rem;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto-fit);
  }
`;

// User

export const UserCard = styled.section`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    flex-direction: column;
  }
`;

export const UserInfo = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  padding-left: 15px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`;

export const UserAvatar = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    width: 90px;
    height: 90px;
  }
`;

export const UserImage = styled(ProfileImage)`
  width: 140px;
  height: 140px;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    width: 90px;
    height: 90px;
  }
`;

export const UserName = styled(ExtraBigText)`
  font-weight: 600;
  color: ${({ theme }) => theme.secondary};
`;
export const UserProfession = styled(BigFont)`
  font-weight: 500;
  color: ${({ theme }) => theme.secondary};
`;

// Events

export const SchedulesBoard = styled.section`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`;

export const NextEventsContainer = styled.section`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`;

export const MBFontText = styled(BigFont)`
  text-align: left;
  font-weight: 400;
  color: ${({ theme }) => theme.secondary};
`;

export const CalendarIcon = styled(Calendar)`
  width: 20px;
  padding-right: 10px;
  height: 20px;
  color: ${({ theme }) => theme.secondary};
`;

export const RowItem = styled.section`
  flex: 1;
  width: 90%;
  padding: 15px 0;
  margin-top: 10px;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
`;

export const RowList = styled.section`
  flex: 1;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    width: 100%;
    justify-content: center;
  }
`;

export const ColunmList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ScheduleButton = styled.div`
  flex: 1;
  padding-top: 20px;
  padding-left: 50px;
  width: 300px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  flex-direction: row;
  justify-content: center;
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    padding: 0 1em 0 1em;
    align-self: center;
    margin-top: 1em;
  }
`;

export const Button = styled(StaticButton)``;

export const ButtonSchedule = styled.button`
  background-color: transparent;
  border-end-end-radius: 2rem;
  border-end-start-radius: 2rem;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  min-height: 5rem;
  height: fit-content;
  width: 100%;
  z-index: 1;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    top: 9.2rem;
    font-size: 1.3rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_LG_PX}) {
    top: 12.5rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    top: 13rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    top: 14rem;
  }
`;

export const SchedulesModal = styled(Modal)`
  bottom: auto;
  display: flex;
  height: 80%;
  left: 50%;
  margin-right: -50%;
  position: absolute;
  right: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
`;

export const ModalContainer = styled.div`
  opacity: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 5px solid #ccc;
  background: #fff;
`;

export const ModalHeader = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 43px;
  padding-bottom: 10px;
`;

export const CloseModalButton = styled.button``;

export const ModalBody = styled(ModalBoxWrapper)`
  padding: 1rem;
`;

export const ModalWrapper = styled.div`
  padding: 1rem 2rem 2rem 2rem;
`;

export const ModalTitle = styled(MediumFont)`
  font-family: 'Poppins', sans-serif;
`;

export const IconAlert = styled(AlertIcon)`
  width: 40px;
  height: 40px;
`;

export const PayIcon = styled(DollarSign)`
  color: ${({ theme }) => theme.errorRed};
  height: 20px;
  width: 20px;
`;

export const ContainerImageSchedule = styled.div`
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  height: calc(30vh);
  width: 100%;
  z-index: 0;
`;

export const ImageAddSchedule = styled.div``;

export const CreditPoints = styled.div`
  cursor: pointer;

  &:hover {
    scale: 1.05;
  }
`;
