import Stack from '@mui/material/Stack';
import styled from 'styled-components';
import { SmallFont } from '../../styles/globalStyles';
import { loadingAnimation } from '../../styles/keyframes';

export const LoaderContainer = styled(Stack)`
  align-items: center;
  color: grey.500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 250px;
  width: 100%;
`;

export const LoaderWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  position: relative;
  width: 100%;
`;

export const LoaderSpinnerMain = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
`;

export const LoaderText = styled(SmallFont)`
  color: ${({ theme }) => theme.secondary};
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 500;
  position: absolute;
  top: 2.6rem;
  width: fit-content;
`;

export const LoaderTextMeeting = styled(LoaderText)`
  top: 8rem;
`;

export const LogoImg = styled.img`
  height: 10rem;
  width: 15rem;
`;

export const ZoomImg = styled.img`
  animation: ${loadingAnimation} 1.2s infinite;
  height: auto;
  margin-top: 2rem;
  width: 20rem;
`;
