import valid from 'card-validator';
import moment from 'moment';
import * as Yup from 'yup';
import { PLANSNAMES } from '../../../services/constants';
import validateCPF from '../../../utils/validateCPF';

const formattedDate = (date) => moment(date).format('DD/MM/YYYY');

const getPreferentialPayment = (user) => {
  if (user.acceptPix && !user.acceptCredit) {
    return 'pix';
  }
  if (user.acceptCredit && !user.acceptPix) {
    return 'credit_card';
  }
  return 'all_payment';
};

const psychologistPrice = 80; // Valor mínimo de consulta para psicólogo
const professionalAdvisorPrice = 70; // Valor mínimo de consulta para orientador profissional
const therapistPrice = 70; // Valor mínimo de consulta para terapeuta
const durationAppointment = 30; // Duração mínima de consulta em minutos

export const schemaInfoPersonal = Yup.object({
  avatar: Yup.string().required('Você precisa selecionar uma foto de perfil!').nullable(),
  firstName: Yup.string().required('Nome é obrigatório!').nullable(),
  lastName: Yup.string().required('Sobrenome é obrigatório!').nullable(),
  birthday: Yup.string('Formato de data inválido')
    .required('Data de nascimento é obrigatório')
    .test('maioridade', 'É necessário ter 18 anos ou mais', function (value) {
      const age = moment().diff(moment(value, 'DD/MM/YYYY'), 'years');
      return age >= 18;
    }),
  phone: Yup.string().required('Telefone é obrigatório!').nullable(),
  documentNumber: Yup.string()
    .nullable()
    .test({
      name: 'isValid',
      exclusive: false,
      params: {},
      message: 'CPF inválido',
      test(value) {
        return validateCPF(value || '');
      },
    })
    .required('CPF é obrigatório!'),
});

export const schemaBusinessAddress = Yup.object({
  cep: Yup.string().required('CEP é obrigatório!').nullable(),
  street: Yup.string().required('Endereço é obrigatório!').nullable(),
  street_number: Yup.string().required('Número é obrigatório!').nullable(),
  complement: Yup.string().optional().nullable(),
  neighborhood: Yup.string().required('Bairro é obrigatório!').nullable(),
  city: Yup.string().required('Cidade é obrigatório!').nullable(),
  state: Yup.string().required('Estado é obrigatório!').nullable(),
  country: Yup.string().required('Pais é obrigatório!').nullable(),
});

export const schemaProfessional = Yup.object({
  profession: Yup.string().required('Selecione uma profissão para continuar'),
  professionalDocumentNumber: Yup.string()
    .nullable()
    .when('profession', {
      is: (job) => ['psychologist', 'therapist'].includes(job),
      then: Yup.string().required('Número de Registro é obrigatório').nullable(),
    }),
  professionalDocumentFile: Yup.string()
    .nullable()
    .when('profession', {
      is: (job) => ['professional_advisor'].includes(job),
      then: Yup.string().optional().nullable(),
    }),
  professionalEducationDocumentFile: Yup.string()
    .nullable()
    .when('profession', {
      is: (job) => ['professional_advisor'].includes(job),
      then: Yup.string().optional().nullable(),
    }),
  academicBackground: Yup.array()
    .min(1, 'Apresente ao menos uma formação')
    .required('Formações são obrigatórias'),
  specializations: Yup.array().when('profession', {
    is: (job) => ['psychologist', 'professional_advisor', 'therapist'].includes(job),
    then: Yup.array()
      .min(1, 'Escolha no mínimo uma área de atuação')
      .max(8, 'Escolha no máximo oito áreas de atuação')
      .required('áreas de atuação são obrigatórias'),
  }),
  approaches: Yup.array().when('profession', {
    is: (job) => ['psychologist', 'professional_advisor', 'therapist'].includes(job),
    then: Yup.array()
      .min(1, 'Escolha no mínimo uma abordagem')
      .max(8, 'Escolha no máximo oito abordagens')
      .required('Abordagens são obrigatórias'),
  }),
  bio: Yup.string()
    .max(200, 'Tamanho máximo de 200 caracteres')
    .required('Biografia é obrigatória!')
    .nullable(),
  ageGroups: Yup.array()
    .min(1, 'Escolha no mínimo uma faixa etária')
    .required('Faixa etária é obrigatório')
    .nullable(),
  presentationVideo: Yup.string().nullable(),
  sessionPrice: Yup.number('Valor deve ser numérico')
    .nullable()
    .when('profession', {
      is: (job) => job === 'psychologist',
      then: Yup.number('Valor deve ser numérico')
        .min(psychologistPrice, 'Valor da consulta não pode ser inferior a R$ 80,00')
        .required('Valor da consulta é obrigatório'),
    })
    .when('profession', {
      is: (job) => job === 'professional_advisor',
      then: Yup.number('Valor deve ser numérico')
        .min(professionalAdvisorPrice, 'Valor da consulta não pode ser inferior a R$ 70,00')
        .required('Valor da consulta é obrigatório'),
    })
    .when('profession', {
      is: (job) => job === 'therapist',
      then: Yup.number('Valor deve ser numérico')
        .min(therapistPrice, 'Valor da consulta não pode ser inferior a R$ 70,00')
        .required('Valor da consulta é obrigatório'),
    }),
  newPreferOwnMeetingLink: Yup.boolean().optional(),
  ownMeetingLink: Yup.string()
    .nullable()
    .when('newPreferOwnMeetingLink', {
      is: true,
      then: Yup.string()
        .nullable()
        .url('Link de reunião deve ser uma URL válida')
        .required('Link de reunião é obrigatório'),
    }),
  yearsOfExperience: Yup.number().required('Anos de experiência é obrigatório').nullable(),
});

export const schemaBank = Yup.object({
  bankCode: Yup.string().required('Banco é obrigatório').nullable(),
  bankAg: Yup.string().required('Agência da conta é obrigatório').nullable(),
  agenciaDv: Yup.string().optional().nullable(),
  bankCc: Yup.string().required('Número da conta é obrigatório').nullable(),
  contaDv: Yup.string().optional().nullable(),
  accountType: Yup.string().required('Tipo da conta é obrigatório').nullable(),
  paymentName: Yup.string().required('Nome é obrigatório').nullable(),
});

export const schemaTypesReceipts = Yup.object({
  acceptCredit: Yup.boolean().optional(),
  acceptPix: Yup.boolean().optional(),
  postPaid: Yup.boolean().when('planName', {
    is: (planName) => PLANSNAMES.includes(planName),
    then: Yup.boolean().required('Pós-pago é obrigatório'),
    otherwise: Yup.boolean().optional(),
  }),
  prePaid: Yup.boolean().when('planName', {
    is: (planName) => PLANSNAMES.includes(planName),
    then: Yup.boolean().required('Pré-pago é obrigatório'),
    otherwise: Yup.boolean().optional(),
  }),
  postPaidDay: Yup.number().when(['postPaid', 'planName'], {
    is: (postPaid, planName) => postPaid && PLANSNAMES.includes(planName),
    then: Yup.number()
      .min(1, 'Dia do pagamento deve ser maior que 1')
      .max(31, 'Dia do pagamento deve ser menor que 31')
      .required('Dia do pagamento é obrigatório'),
    otherwise: Yup.number().optional(),
  }),
  externalPayment: Yup.boolean().when('planName', {
    is: (planName) => PLANSNAMES.includes(planName),
    then: Yup.boolean().required('Pagamento externo é obrigatório'),
    otherwise: Yup.boolean().optional(),
  }),
});

export const schemaSecurity = Yup.object({
  password: Yup.string().required('Senha é obrigatório'),
  newPassword: Yup.string().required('Nova senha é obrigatório'),
  confirmPassword: Yup.string()
    .required('Confirmação de senha é obrigatório')
    .oneOf([Yup.ref('newPassword'), null], 'Senhas não conferem'),
});

export const schemaAvailability = Yup.object({
  breakTime: Yup.number()
    .min(0, 'Tempo de descanso não pode ser menor que 0')
    .test(
      'is-multiple-of-5',
      'Tempo de descanso deve conter apenas múltiplos de 5 minutos',
      (value) => value % 5 === 0
    )
    .optional(),
  workdays: Yup.array().min(1, 'Selecione pelo menos um dia da semana').required('Requerido'),
  appointmentTypes: Yup.array()
    .min(1, 'Escolha no mínimo um tipo de agendamento')
    .required('Tipo de agendamento é obrigatório')
    .nullable(),
  appointmentDuration: Yup.number()
    .min(
      durationAppointment,
      `Duração de atendimento não pode ser menor que ${durationAppointment} minutos`
    )
    .test(
      'is-multiple-of-5',
      ({ value }) =>
        value
          ? `${value} não é uma dureção válida. A duração deve conter apenas múltiplos de 5 minutos.`
          : 'A duração deve conter apenas múltiplos de 5 minutos',
      (value) => value % 5 === 0
    )
    .required('Requerido'),
});

export const schemaCreditCard = Yup.object({
  cardNumber: Yup.string().test(
    'test-number',
    'Cartão inválido',
    (value) => valid.number(value).isValid
  ),
  cardHolderName: Yup.string().required('Nome é obrigatório.'),
  cardCvv: Yup.string().test(
    'test-cvv',
    'Código de segurança inválido',
    (value) => valid.cvv(value).isValid
  ),
  cardExpiration_date: Yup.string().test(
    'test-date',
    'Data inválida',
    (value) => valid.expirationDate(value).isValid
  ),
});

export const schemaTerm = Yup.object({
  // accept term
  termId: Yup.string().required('Termo é obrigatório'),
  read: Yup.boolean().required('Ler o termo é obrigatório!'),
  accepted: Yup.boolean().when('read', {
    is: true,
    then: Yup.boolean().oneOf([true], 'Aceitar termo é obrigatório!'),
    otherwise: Yup.boolean().oneOf([false], 'Não pode aceitar sem ler o termo!'),
  }),
});

export const initialValuesProfessional = (user) => {
  const preferentialPayment = getPreferentialPayment(user);

  return {
    // Dados Pessoais
    avatar: user?.avatar || '',
    email: user?.email || '',
    birthday: formattedDate(user?.birthday) || '',
    documentNumber: user?.documentNumber || '',
    firstName: user?.firstName || '',
    hasSocialName: user?.socialName ? true : false,
    lastName: user?.lastName || '',
    phone: user?.phone || '',
    socialName: user?.socialName || '',

    // Dados de Endereço
    cep: user?.address.cep || '',
    city: user?.address.city || '',
    complement: user?.address.complement || 'N/A',
    country: user?.address.country || '',
    neighborhood: user?.address.neighborhood || '',
    state: user?.address.state || '',
    street: user?.address.street || '',
    street_number: user?.address.street_number || '',

    // Dados Profissionais
    ageGroups: user?.ageGroups || [],
    approaches: user?.approaches || [],
    bio: user?.bio || '',
    newPreferOwnMeetingLink: user?.ownMeetingLink ? true : false,
    ownMeetingLink: user?.ownMeetingLink || null,
    presentationVideo: user?.presentationVideo || '',
    profession: user?.profession || '',
    academicBackground: user?.academicBackground || [],
    formations: user?.academicBackground?.map((item) => item).join('\n') || [''],
    professionalDocumentNumber: user?.professionalDocumentNumber || null,
    professionalDocumentFile: user?.professionalDocumentFile || null,
    professionalEducationDocumentFile: user?.professionalEducationEducationEducation || null,
    sessionPrice: user?.sessionPrice || 0,
    specializations: user?.specializations || [],
    yearsOfExperience: user?.yearsOfExperience || null,

    // Dados Bancários e Pagamentos
    accountType: user?.paymentAccount?.accountType || '',
    agenciaDv: user?.paymentAccount?.agenciaDv || null,
    bankAg: user?.paymentAccount?.bankAg || '',
    bankCc: user?.paymentAccount?.bankCc || '',
    bankCode: user?.paymentAccount?.bankCode || '',
    contaDv: user?.paymentAccount?.contaDv || null,
    paymentName: user?.paymentAccount?.name || '',

    // Dados de cartão de crédito
    cardNumber: '',
    cardHolderName: '',
    cardCvv: '',
    cardExpiration_date: '',
    default: false,

    // Formas de recebimento
    preferentialPayment,
    planName: user?.subscription?.pagarmePlan,
    acceptCredit: user?.acceptCredit || true,
    acceptPix: user?.acceptPix || true,
    postPaid: user?.postPaid || false,
    postPaidDay: user?.postPaidDay || 25,
    prePaid: user?.prePaid || true,
    externalPayment: user?.externalPayment || false,

    // Dados de disponibilidade
    appointmentDuration: user?.availabilityRule?.appointmentDuration || durationAppointment,
    appointmentTimeBlocks: user?.availabilityRule?.appointmentTimeBlocks || {},
    appointmentTypes: user?.availabilityRule?.appointmentTypes || [],
    blockedTimes: user?.availabilityRule?.blockedTimes || {},
    breakTime: user?.availabilityRule?.breakTime || 0,
    configTimes: {},
    workdays: [],

    // Notificações
    emailEnabled: user?.notificationSetting?.emailEnabled || true,
    wppEnabled: user?.notificationSetting?.wppEnabled || false,
    smsEnabled: user?.notificationSetting?.smsEnabled || false,

    // Termo de aceite
    termId: user?.term?.id || null,
    accepted: false,
    read: true,

    // Excluir Reason
    reason: '',
  };
};

export const validateInfoPerson = (infos) => {
  const personInfo = ['firstName', 'lastName', 'documentNumber', 'birthday', 'phone', 'avatar'];
  const filterInfos = Object.entries(infos).filter(
    ([key, value]) => personInfo.includes(key) && !value
  );
  return filterInfos.length > 0;
};

export const validateInfoAddress = (infos) => {
  const addressInfo = [
    'cep',
    'city',
    'neighborhood',
    'complement',
    'street',
    'street_number',
    'state',
    'country',
  ];
  const filterInfos = Object.entries(infos).filter(
    ([key, value]) => addressInfo.includes(key) && !value
  );
  return filterInfos.length > 0;
};

export const validateInfoProfessional = (infos) => {
  const professionalInfo = [
    'profession',
    'yearsOfExperience',
    'academicBackground',
    'specializations',
    'approaches',
    'ageGroups',
    'bio',
    'sessionPrice',
  ];
  if (['psychologist', 'therapist'].includes(infos?.profession)) {
    professionalInfo.push('professionalDocumentNumber');
  }
  const filterInfos = Object.entries(infos).filter(
    ([key, value]) => professionalInfo.includes(key) && !value
  );
  return filterInfos.length > 0;
};

export const validateInfoBankAccount = (infos) => {
  const bankAccountInfo = ['bankCode', 'bankAg', 'bankCc', 'accountType', 'name', 'contaDv'];
  const filterInfos = Object.entries(infos).filter(
    ([key, value]) => bankAccountInfo.includes(key) && !value
  );
  return filterInfos.length > 0;
};

export const validateInfoPayment = (infos) => {
  const paymentInfo = [
    'acceptCredit',
    'acceptPix',
    'postPaid',
    'prePaid',
    'externalPayment',
    'preferentialPayment',
  ];

  const filterInfos = Object.entries(infos).filter(
    ([key, value]) => paymentInfo.includes(key) && value === null
  );
  return filterInfos.length > 0;
};

export const validateInfoAvailability = (infos) => {
  const availabilityInfo = [
    'breakTime',
    'workdays',
    'appointmentTypes',
    'appointmentDuration',
    'appointmentTimeBlocks',
    'workdays',
  ];
  const filterInfos = Object.entries(infos).filter(
    ([key, value]) => availabilityInfo.includes(key) && value !== 0 && !value
  );

  return filterInfos.length > 0;
};

export const validateInfoTerm = (infos) => {
  if (!infos) return true;
  const termInfo = ['id'];
  const filterInfos = Object.entries(infos).filter(
    ([key, value]) =>
      (termInfo.includes(key) && value === 1) || (termInfo.includes(key) && value === null)
  );
  return filterInfos.length > 0;
};
