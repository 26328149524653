import styled from 'styled-components';
import {
  BigFont,
  BREAKPOINT_LG_PX,
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
} from '../../../styles/globalStyles';

export const MainContainer = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const MenuHeader = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  justify-content: flex-start;
  margin-bottom: 1rem;
  width: 100%;
`;

export const MenuOption = styled.button`
  background-color: ${({ active, theme }) => (active ? theme.secondary : theme.white)};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.secondary};
  cursor: pointer;
  height: 2.5rem;
  padding: 0.25rem 0.5rem;
  position: relative;
`;

export const OptionText = styled(BigFont)`
  color: ${({ theme, active }) => (active ? theme.white : theme.secondary)};
  font-size: 14px;
  font-size: medium;
  font-weight: 200px;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    font-size: 12px;
  }
`;

export const ScheduleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    width: 60%;
  }

  @media only screen and (max-width: ${BREAKPOINT_LG_PX}) {
    width: 70%;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    width: 90%;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 100%;
  }
`;
