/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { FooterButtons } from '../../../components/FooterButtons';
import FormikControl from '../../../components/FormikControl';
import { InputWrapper } from '../../../components/FormikElements/styles';
import { ImageCropper } from '../../../components/ImageInput';
import { TextError } from '../../../styles/globalStyles';
import theme from '../../../theme';
import handleErrors from '../../../utils/getValuesErrors';
import { LoginTitle } from '../../Public/Login/styles';
import { ImagePickerContainer, ImagePickerWrapper } from '../BasicRegistrationClinic/styles';

const BasicRegistrationValues = ['responsibleName', 'responsibleAbout', 'responsibleAvatar'];

export default function ResponsibleData({ form, next, goBack }) {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } = form;

  const handleContinue = async () => {
    if (!handleErrors(errors, BasicRegistrationValues)) next();
  };

  return (
    <InputWrapper>
      <LoginTitle>Dados Responsável Técnico</LoginTitle>
      <ImagePickerWrapper>
        <ImagePickerContainer>
          <ImageCropper
            src={values?.responsibleAvatar}
            name="responsibleAvatar"
            acceptType=".jpg, .jpeg"
            onCrop={(crop) => setFieldValue('responsibleAvatar', crop)}
          />
          <TextError>{errors.responsibleAvatar}</TextError>
        </ImagePickerContainer>
      </ImagePickerWrapper>

      <FormikControl
        bgColor={theme.white}
        control="input"
        errors={errors.responsibleName}
        label="Nome do responsável"
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="responsibleName"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.responsibleName}
        upLabel
        value={values.responsibleName}
      />

      <FormikControl
        bgColor={theme.white}
        control="input"
        errors={errors.responsibleDocumentNumber}
        label="CRP do responsável"
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="responsibleDocumentNumber"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.responsibleDocumentNumber}
        upLabel
        value={values.responsibleDocumentNumber}
      />

      <FormikControl
        bgColor={theme.white}
        control="textarea"
        errors={errors.responsibleAbout}
        label="Sobre o responsável técnico"
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="responsibleAbout"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.responsibleAbout}
        upLabel
        value={values.responsibleAbout}
      />

      <FooterButtons next={handleContinue} goBack={goBack} />
    </InputWrapper>
  );
}
