import React from 'react';
import { PtBr } from '../../../services/pt_br';
import theme from '../../../theme';
import FormikControl from '../../FormikControl';
import { ButtonInvite, Main } from '../styles';

export default function PreSignUpClient({ form, schedule }) {
  const { values, handleChange, handleBlur, errors, touched } = form;

  return (
    <Main>
      <section className="--input-wrapper-invite">
        <h3 style={{ color: theme.white, marginBottom: '1rem' }}>
          Realize o pré cadastro de um paciente
        </h3>

        <p style={{ color: theme.white, marginBottom: '1rem' }}>
          Preencha os dados do paciente que você quer cadastrar para a plataforma
        </p>

        <FormikControl
          autoFocus
          bgColor={theme.white}
          color={theme.white}
          control="input"
          errorStyle={{ color: theme.white }}
          errors={errors.firstName}
          label={PtBr.register_input_firstname}
          name="firstName"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.firstName}
          upLabel
          value={values.firstName}
        />

        <FormikControl
          bgColor={theme.white}
          color={theme.white}
          control="input"
          errorStyle={{ color: theme.white }}
          errors={errors.lastName}
          label={PtBr.register_input_lastname}
          name="lastName"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.lastName}
          upLabel
          value={values.lastName}
        />

        <FormikControl
          bgColor={theme.white}
          color={theme.white}
          control="input"
          errorStyle={{ color: theme.white }}
          errors={errors.email}
          label={PtBr.register_input_email}
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.email}
          upLabel
          value={values.email}
        />

        <FormikControl
          bgColor={theme.white}
          color={theme.white}
          control="input"
          errorStyle={{ color: theme.white }}
          errors={errors.phone}
          label={PtBr.register_input_phone}
          mask="(99) 99999-9999"
          name="phone"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.phone}
          upLabel
          value={values.phone}
        />

        {!schedule && <ButtonInvite type="submit">Cadastrar paciente</ButtonInvite>}
      </section>
    </Main>
  );
}
