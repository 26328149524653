import React from 'react';
import { Switch } from 'react-router-dom';
import NotificationList from '../../components/NotificationList';
import StatementScreen from '../../components/Statement';
import ContentList from '../../screens/Client/ContentList';
import ContentShow from '../../screens/Client/ContentShow';
import Contents from '../../screens/Contents';
import CalendarScreen from '../../screens/Professional/Calendar';
import { ConfigScreen } from '../../screens/Professional/Config';
import Coupons from '../../screens/Professional/Config/AccountConfig/Coupons';
import { AnamneseConfig } from '../../screens/Professional/Config/Anamnese';
import Dashboard from '../../screens/Professional/Dashboard';
import FinanceScreen from '../../screens/Professional/Finance';
import InvoiceScreen from '../../screens/Professional/Invoice';
import MedicalRecord from '../../screens/Professional/MedicalRecord';
import MessageList from '../../screens/Professional/Messages';
import PackagesPlans from '../../screens/Professional/PackagesPlans';
import PatientList from '../../screens/Professional/PatientList';
import { ScheduleFormProfessional } from '../../screens/Professional/PatientList/Schedule';
import AddBankAccountScreen from '../../screens/Professional/ProfessionalUpdate/BankAccount';
import SubscriptionPlans, {
  NewCardPayment,
  UpdatedPlans,
} from '../../screens/Professional/ProfessionalUpdate/Subscribe';
import SchedulesReportProfessional from '../../screens/Professional/SchedulesReport';
import PublicProfessionalProfile from '../../screens/Public/Profile';
import BankAccount from '../../screens/Register/BankAccount';
import { VideoSDK } from '../../screens/VideoSDK';
import ProfessionalRoutes from './professionalRoutes';

export function PrivateRouteProfessional() {
  return (
    <Switch>
      <ProfessionalRoutes path="/professional/dashboard" component={Dashboard} isPrivate />

      <ProfessionalRoutes
        exact
        path="/professional/profile"
        component={PublicProfessionalProfile}
      />

      <ProfessionalRoutes exact path="/professional/patients" component={PatientList} />

      <ProfessionalRoutes exact path="/professional/patients/form/:id" component={AnamneseConfig} />

      <ProfessionalRoutes
        exact
        path="/professional/schedule/:id"
        component={ScheduleFormProfessional}
      />

      <ProfessionalRoutes exact path="/professional/messages" component={MessageList} />

      <ProfessionalRoutes exact path="/professional/contents" component={Contents} />

      <ProfessionalRoutes exact path="/professional/content_list/:id" component={ContentList} />

      <ProfessionalRoutes exact path="/professional/content/:id" component={ContentShow} />

      <ProfessionalRoutes exact path="/professional/finances" component={FinanceScreen} />

      <ProfessionalRoutes exact path="/professional/invoices" component={InvoiceScreen} />

      <ProfessionalRoutes exact path="/professional/subscription" component={SubscriptionPlans} />

      <ProfessionalRoutes
        exact
        path="/professional/subscription/add_new_payment"
        component={NewCardPayment}
      />

      <ProfessionalRoutes
        exact
        path="/professional/subscription/update_plan"
        component={UpdatedPlans}
      />

      <ProfessionalRoutes
        exact
        path="/professional/add_bank_account"
        component={AddBankAccountScreen}
      />

      <ProfessionalRoutes exact path="/professional/calendar" component={CalendarScreen} />

      <ProfessionalRoutes
        exact
        path="/professional/schedules_report"
        component={SchedulesReportProfessional}
      />

      <ProfessionalRoutes exact path="/professional/payment_account" component={BankAccount} />

      <ProfessionalRoutes exact path="/professional/notifications" component={NotificationList} />

      <ProfessionalRoutes exact path="/professional/config" component={ConfigScreen} />

      <ProfessionalRoutes exact path="/professional/coupon" component={Coupons} />

      <ProfessionalRoutes exact path="/professional/statement" component={StatementScreen} />

      <ProfessionalRoutes
        exact
        path="/professional/packages-assignments"
        component={PackagesPlans}
      />

      <ProfessionalRoutes exact path="/professional/medical_record/:id" component={MedicalRecord} />

      <ProfessionalRoutes exact path="/professional/meeting" component={VideoSDK} />
    </Switch>
  );
}
