import styled from 'styled-components';
import { SmallButton } from '../../../components/Button';
import {
  BigFont,
  BREAKPOINT_MD_PX,
  MediumBigFont,
  MediumFont,
  SmallFont,
} from '../../../styles/globalStyles';

export const MainContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.lightGray};
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
  padding-bottom: 2rem;
  width: 100%;
`;

export const BodyWrapper = styled.div`
  border-radius: 8px;
  box-shadow: 0px 4px 10px ${({ theme }) => theme.secondary};
  height: calc(100vh - 20vh);
  margin-top: 2rem;
  overflow-y: auto;
  width: 80%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.secondary};
  justify-content: center;
  padding: 1.5rem;
  width: 100%;
`;
export const Title = styled(BigFont)`
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
`;

export const ContentGrid = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  padding: 2rem;
`;

export const ContentContainer = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: flex-start;
  padding: 2rem;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: flex-start;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const SMText = styled(SmallFont)``;

export const MDText = styled(MediumFont)``;

export const BGText = styled(BigFont)``;

export const EXText = styled(MediumBigFont)`
  color: ${({ theme }) => theme.primary};
  font-weight: 700;
`;

export const SpanText = styled.span`
  color: ${({ theme }) => theme.secondary};
  font-weight: 700;
`;

export const ButtonConfirm = styled(SmallButton)`
  height: 2.5rem;
  width: fit-content;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    height: 2rem;
    width: 100%;
  }
`;

export const ButtonDelete = styled(ButtonConfirm)``;
