import { Modal } from '@material-ui/core';
import React from 'react';
import IconWarning from '../../../images/alert-triangle-icon.svg';
import theme from '../../../theme';
import { SmallButton } from '../../Button';
import {
  Container,
  ContainerButtons,
  Icon,
  IconArea,
  ModalContainer,
  TextAlert,
  TitleAlert,
  TitleWrapper,
} from './styles';

export function ModalAlert({
  buttonsHeight = '2.5rem',
  confirm,
  goBack,
  message,
  title = 'Atenção',
  type = 'confirmation',
  confirmPlan,
  login,
}) {
  return (
    <Modal open>
      <ModalContainer>
        <Container>
          <TitleWrapper>
            <IconArea>
              <Icon src={IconWarning} alt="Atenção" />
            </IconArea>
            <TitleAlert>{title}</TitleAlert>
          </TitleWrapper>
          <TextAlert>{message}</TextAlert>
          <ContainerButtons>
            {!login && (
              <SmallButton
                width="30%"
                height={buttonsHeight}
                color={type === 'confirmation' ? theme.errorRed : theme.primary}
                type="button"
                title="Voltar"
                onClick={goBack}
              />
            )}
            <SmallButton
              width="30%"
              height={buttonsHeight}
              color={type === 'confirmation' ? theme.primary : theme.errorRed}
              type="button"
              title={confirmPlan ? 'Mais detalhes' : login ? 'Continuar' : 'Confirmar'}
              onClick={(data) => confirm(data)}
            />
          </ContainerButtons>
        </Container>
      </ModalContainer>
    </Modal>
  );
}
