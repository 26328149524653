import styled from 'styled-components';
import {
  BigFont,
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  ModalBoxWrapper,
  SmallFont,
} from '../../../styles/globalStyles';

export const BoxWrapper = styled(ModalBoxWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 40vh;
  min-width: 400px;
  padding: 2rem;
  width: 35%;
`;

export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 50vh;
  max-height: 55vh;
  overflow-y: auto;
  padding: 1rem;
  width: 100%;
`;

export const PaymentWrapper = styled(AddressWrapper)``;

export const Hr = styled.hr`
  border: 1px solid #bcc1e0;
  margin-top: 0.7rem;
  width: 100%;
`;

export const BFontBold = styled(BigFont)`
  color: ${({ theme, active }) => (active ? theme.white : theme.primary)};
  font-size: 1;
`;

export const SFontDark = styled(SmallFont)`
  color: ${({ theme, active }) => (active ? theme.white : theme.primary)};
  font-weight: normal;
  font-size: 0.7rem;
  word-wrap: break-word;
  text-align: center;
`;

export const FontPlanPrice = styled(BigFont)`
  color: ${({ theme, active }) => (active ? theme.white : theme.primary)};
  font-size: 1.2rem;
  font-weight: 700;
`;

export const ContainerPlans = styled.div`
  align-self: center;
  background-color: ${({ theme, active }) => (active ? theme.primary : theme.softBackground)};
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  padding: 1rem 0.25rem;
  width: auto;
`;

export const ColumnItem = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    align-items: center;
  }
`;

export const Button = styled.button`
  background-color: ${({ theme, active }) => (active ? theme.primary : theme.softBackground)};
  border-radius: 2rem;
  border: 1px solid ${({ theme, active }) => (active ? theme.softBackground : theme.primary)};
  color: ${({ theme, active }) => (active ? theme.softBackground : theme.primary)};
  cursor: pointer;
  font-weight: 700;
  height: 2rem;
  width: 80%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    background-color: ${({ theme, active }) => (active ? theme.softBackground : theme.primary)};
    color: ${({ theme, active }) => (active ? theme.primary : theme.softBackground)};
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    padding: 0 1rem;
  }
`;

export const ButtonCancel = styled(Button)`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.errorRed};
  color: ${({ theme }) => theme.errorRed};

  &:hover {
    background-color: ${({ theme }) => theme.errorRed};
    color: ${({ theme }) => theme.white};
  }
`;

export const GridCard = styled.div`
  display: grid;
  grid-template-columns: ${({ subscription }) =>
    `repeat(${subscription ? 2 : 3}, minmax(8rem, ${subscription ? '18rem' : '12rem'}))`};
  gap: 0.5rem;
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: #f5f6fa;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  cursor: pointer;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: repeat(1, minmax(8rem, auto));
  }
`;

export const ContinueButton = styled.button`
  background-color: ${({ theme }) => theme.primary};
  border-radius: 0.25rem;
  box-shadow: 3px 3px 3px ${({ theme }) => theme.neutralGray};
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  font-family: 'Poppins';
  font-weight: 600;
  height: 2.5rem;
  margin-top: 3rem;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primary};
  }
`;
