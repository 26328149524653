/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment/moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoaderSpinner from '../../../components/LoaderSpinner';
import { ModalAlert } from '../../../components/Modals/ModalAlerts';
import useEvent from '../../../hooks/event';
import { apiPublic } from '../../../services/api';
import theme from '../../../theme';
import convertCurrencyPtBr from '../../../utils/convertCurrencyPtBr';
import getProfessionName from '../../../utils/getProfessionName';
import {
  getPaymentMode,
  getRecurrentDuration,
  getRecurrentFrequency,
  handleStatusSchedule,
} from '../../../utils/handleStatusSchedule';
import HeaderPublic from '../Header';
import {
  BGText,
  BodyWrapper,
  ButtonConfirm,
  ButtonDelete,
  ButtonWrapper,
  ContentContainer,
  ContentGrid,
  ContentWrapper,
  EXText,
  MainContainer,
  SpanText,
  Title,
  TitleContainer,
} from './styles';

export default function PreSignUpSchedule() {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const guest = location.pathname.includes('/convidado');
  const token = queryParams.get('token');
  const { scheduleInfo, setScheduleInfo } = useEvent();
  const [loading, setLoading] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalConfirmExternal, setOpenModalConfirmExternal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  useEffect(async () => {
    setLoading(true);
    try {
      const { data } = await apiPublic.get(`/public/pre_schedules/${id}?token=${token}`);

      setScheduleInfo({
        id: data.data.id,
        ...data.data.attributes,
        startTime: moment(data.data.attributes.startTime).format('HH:mm:ss'),
        postPaid: data.data.attributes?.paymentMode === 'post_paid',
        publicP: true,
        token,
      });
    } catch (error) {
      toast.error(
        'Não foi possível carregar esse pré-agendamento, entre em contato com o administrador.'
      );
      history.push('/conectar-se');
    } finally {
      setLoading(false);
    }
  }, []);

  const handleConfirmSchedule = () => {
    setOpenModalConfirm(false);
    history.push(`/cliente/conectar-se`);
  };

  const handleConfirmScheduleExternal = async () => {
    try {
      setLoading(true);
      await apiPublic.post(`/public/pre_schedules/${id}/confirm?token=${token}`, {});
      toast.success('Pré-agendamento confirmado com sucesso');
      history.push(`/publica/agendamento/${scheduleInfo?.professional?.id}`);
    } catch (error) {
      toast.error('Não foi possível confirmar o pré-agendamento');
    } finally {
      setLoading(false);
      setOpenModalConfirm(false);
    }
  };

  const handleDeleteSchedule = async () => {
    try {
      setLoading(true);
      await apiPublic.delete(`/public/pre_schedules/${scheduleInfo.id}?token=${token}`);
      toast.success('Pré-agendamento excluído com sucesso');
      history.push('/conectar-se');
    } catch (error) {
      toast.error('Não foi possível excluir esse pré-agendamento');
    } finally {
      setLoading(false);
      setOpenModalDelete(false);
    }
  };

  return loading ? (
    <LoaderSpinner logo />
  ) : (
    <MainContainer>
      <HeaderPublic />
      <BodyWrapper>
        <TitleContainer>
          <Title>Pré-agendamento</Title>
        </TitleContainer>
        <ContentGrid>
          <ContentContainer>
            {/* Renderizar dados da consulta */}
            <ContentWrapper>
              <EXText>Dados do profissional</EXText>
              <BGText>
                <SpanText>Nome: </SpanText>
                {scheduleInfo?.professional?.socialName ||
                  `${scheduleInfo?.professional?.firstName} ${scheduleInfo?.professional?.lastName}`}
              </BGText>
              <BGText>
                <SpanText>email: </SpanText>
                {scheduleInfo?.professional?.email}
              </BGText>
              <BGText>
                <SpanText>Telefone: </SpanText>
                {scheduleInfo?.professional?.phone}
              </BGText>
              <BGText>
                <SpanText>Especialidade: </SpanText>
                {getProfessionName(scheduleInfo?.professional?.profession, 'toPt')}
              </BGText>
            </ContentWrapper>

            <ContentWrapper>
              <EXText>Dados do paciente</EXText>
              <BGText>
                <SpanText>Nome: </SpanText>
                {`${scheduleInfo?.client?.firstName} ${scheduleInfo?.client?.lastName}`}
              </BGText>
              <BGText>
                <SpanText>email: </SpanText>
                {scheduleInfo?.client?.email}
              </BGText>
              <BGText>
                <SpanText>Telefone: </SpanText>
                {scheduleInfo?.client?.phone}
              </BGText>
              <BGText>
                <SpanText>Status: </SpanText>
                {handleStatusSchedule(scheduleInfo?.client?.status)}
              </BGText>
            </ContentWrapper>
          </ContentContainer>
          <ContentContainer style={{ justifyContent: 'space-between' }}>
            {/* Renderizar botões de confirmação e cancelamento */}
            <ContentWrapper>
              <EXText>Dados do agendamento</EXText>
              <BGText>
                <SpanText>Data do agendamento: </SpanText>
                {moment(scheduleInfo?.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
              </BGText>
              <BGText>
                <SpanText>Hora: </SpanText>
                {moment(scheduleInfo?.startTime, 'HH:mm:ss').format('HH:mm')}
              </BGText>
              <BGText>
                <SpanText>Duração: </SpanText>
                {moment(scheduleInfo?.duration).format('HH:mm')}
              </BGText>
              <BGText>
                <SpanText>Valor: </SpanText>
                {convertCurrencyPtBr(scheduleInfo?.paymentAmount) || 'R$ 0.00'}
              </BGText>
              <BGText>
                <SpanText>Método de recebimento: </SpanText>
                {getPaymentMode(scheduleInfo?.paymentMode)}
              </BGText>

              <BGText>
                <SpanText>Status do agendamento: </SpanText>
                {handleStatusSchedule(scheduleInfo?.status)}
              </BGText>
            </ContentWrapper>

            <ContentWrapper>
              {scheduleInfo?.appointmentFormat === 'recurrent' && (
                <Fragment>
                  <BGText>
                    <SpanText>Agendamento Recorrente</SpanText>
                  </BGText>
                  <BGText>
                    <SpanText>Frequencia: </SpanText>
                    {getRecurrentFrequency(scheduleInfo?.recurrentFrequency)}
                  </BGText>
                  <BGText>
                    <SpanText>Duração: </SpanText>
                    {getRecurrentDuration(scheduleInfo?.recurrentDuration)}
                  </BGText>
                </Fragment>
              )}
            </ContentWrapper>

            <ButtonWrapper>
              <ButtonConfirm
                title="Confirmar agendamento"
                onClick={() => {
                  if (guest) {
                    setOpenModalConfirmExternal(true);
                  } else {
                    setOpenModalConfirm(true);
                  }
                }}
              />
              <ButtonDelete
                color={theme.errorRed}
                title="Cancelar agendamento"
                onClick={() => setOpenModalDelete(true)}
              />
            </ButtonWrapper>
          </ContentContainer>
        </ContentGrid>
      </BodyWrapper>

      {openModalConfirm && (
        <ModalAlert
          message="Para confirmar o agendamento você precisar efetuar o login na plataforma."
          goBack={() => setOpenModalConfirm(false)}
          confirm={handleConfirmSchedule}
        />
      )}

      {openModalConfirmExternal && (
        <ModalAlert
          message="Você irá confirmar o agendamento externamente. Quer continuar?"
          goBack={() => setOpenModalConfirmExternal(false)}
          confirm={handleConfirmScheduleExternal}
        />
      )}

      {openModalDelete && (
        <ModalAlert
          message="Você irá cancelar o agendamento. Quer continuar?"
          goBack={() => setOpenModalDelete(false)}
          confirm={handleDeleteSchedule}
        />
      )}
    </MainContainer>
  );
}
