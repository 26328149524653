/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormControlLabel, Modal, Tooltip, Typography } from '@material-ui/core';
import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { X } from 'react-feather';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import useAuth from '../../hooks/auth';
import api from '../../services/api';
import theme from '../../theme';
import convertCurrencyPtBr from '../../utils/convertCurrencyPtBr';
import { DateRangeSelector } from '../DateRangeSelector';
import FormikControl from '../FormikControl';
import { FlatListStatements } from '../List';
import { StatementListItem } from '../ListItens';
import LoaderSpinner from '../LoaderSpinner';
import {
  AddIcon,
  BoxModalWrapper,
  BoxWrapper,
  Button,
  ButtonWrapper,
  ContainerItem,
  ContainerItemLeft,
  DownloadButton,
  IconArea,
  MDFontDark,
  MDFontRed,
  MainContainer,
  MonthButton,
  PayOutContainer,
  RemoveIcon,
  SMFont,
  StatementButton,
  StatementsWrapper,
  WrapperDownloadButton,
  WrapperMonthly,
} from './styles';

const months = [
  { id: 0, value: 'jan', label: 'Jan', title: 'Janeiro' },
  { id: 1, value: 'fev', label: 'Fev', title: 'Fevereiro' },
  { id: 2, value: 'mar', label: 'Mar', title: 'Março' },
  { id: 3, value: 'abr', label: 'Abr', title: 'Abril' },
  { id: 4, value: 'mai', label: 'Mai', title: 'Maio' },
  { id: 5, value: 'jun', label: 'Jun', title: 'Junho' },
  { id: 6, value: 'jul', label: 'Jul', title: 'Julho' },
  { id: 7, value: 'ago', label: 'Ago', title: 'Agosto' },
  { id: 8, value: 'set', label: 'Set', title: 'Setembro' },
  { id: 9, value: 'out', label: 'Out', title: 'Outubro' },
  { id: 10, value: 'nov', label: 'Nov', title: 'Novembro' },
  { id: 11, value: 'dez', label: 'Dez', title: 'Dezembro' },
];

export default function StatementScreen() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [activeDate, setActiveDate] = useState(false);
  const [activeMonth, setActiveMonth] = useState(
    months.filter((item) => item.value === moment().format('MMM'))
  );
  const [statementType, setStatementType] = useState(null);
  const [statements, setStatements] = useState([]);
  const [statementEdit, setStatementEdit] = useState(null);
  const [recipes, setRecipes] = useState(0);
  const [expenses, setExpenses] = useState(0);
  const [profits, setProfits] = useState(0);
  const [deleteId, setDeleteId] = useState(null);

  const schema = yup.object().shape({
    description: yup.string().required('Nome é obrigatório'),
    amount: yup.number().required('Valor é obrigatório'),
    classification: yup.string().required('Tipo é obrigatório'),
    date: yup
      .string()
      .required('Data é obrigatória')
      .matches(
        /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(20[0-9]{2}|2100)$/,
        'Data deve estar no formato DD/MM/YYYY'
      ),
  });

  const getStatements = useCallback(
    async (
      startDate = moment(activeMonth.at(0)?.value, 'MMM').startOf('month').format('YYYY-MM-DD'),
      endDate = moment(activeMonth.at(-1)?.value, 'MMM').endOf('month').format('YYYY-MM-DD')
    ) => {
      if (!moment(startDate).isValid() && !moment(endDate).isValid()) return;

      setLoading(true);

      if (activeMonth.length === 0) {
        setLoading(false);
        setStatements([]);
        return;
      }

      try {
        const { data } = await api.get(`/${user?.type}s/statements`, {
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        });

        const dataSerialized = data.data.attributes.transactions.map((transaction) => ({
          id: transaction.additionalEntryId,
          ...transaction,
        }));

        const dataSerializedSorted = dataSerialized.sort((a, b) => {
          const dateA = moment(a.paidAt);
          const dateB = moment(b.paidAt);
          return dateA.isBefore(dateB) ? 1 : -1;
        });

        setStatements(dataSerializedSorted);
      } catch (error) {
        toast.error('Erro ao carregar extratos');
      } finally {
        setLoading(false);
      }
    },
    [activeMonth, activeDate]
  );

  const getGeneratePDF = useCallback(async () => {
    setLoading(true);

    if (activeMonth.length === 0) {
      setLoading(false);
      setStatements([]);
      return;
    }

    const startDate = moment(activeMonth.at(0).value, 'MMM').startOf('month').format('YYYY-MM-DD');

    const endDate = moment(activeMonth.at(-1).value, 'MMM').endOf('month').format('YYYY-MM-DD');

    try {
      const { data } = await api.get(`/${user?.type}s/statements/download_pdf`, {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
        responseType: 'blob', // Para garantir que a resposta seja tratada como um blob
      });

      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `extrato_${startDate}-${endDate}.pdf`); // Nome do arquivo
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      window.open(url, '_blank');

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    } catch (error) {
      toast.error('Erro ao carregar extratos');
    } finally {
      setLoading(false);
    }
  }, [activeMonth]);

  const getGenerateCSV = useCallback(async () => {
    setLoading(true);

    if (activeMonth.length === 0) {
      setLoading(false);
      setStatements([]);
      return;
    }

    const startDate = moment(activeMonth.at(0).value, 'MMM').startOf('month').format('YYYY-MM-DD');

    const endDate = moment(activeMonth.at(-1).value, 'MMM').endOf('month').format('YYYY-MM-DD');

    try {
      const { data } = await api.get(`/${user?.type}s/statements/download_csv`, {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
        responseType: 'blob', // Para garantir que a resposta seja tratada como um blob
      });

      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/csv' }));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `extrato_${startDate}-${endDate}.csv`); // Nome do arquivo
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    } catch (error) {
      toast.error('Erro ao carregar extratos');
    } finally {
      setLoading(false);
    }
  }, [activeMonth]);

  useEffect(() => {
    if (activeMonth.every((month) => month.value !== '')) {
      getStatements();
    }
  }, [getStatements]);

  useEffect(() => {
    if (statements.length > 0) {
      const newRecipes = statements
        .filter((transaction) => transaction.classification === 'income')
        .reduce((acc, transaction) => acc + transaction.amount, 0);

      const expenses = statements
        .filter((transaction) => transaction.classification === 'expense')
        .reduce((acc, transaction) => acc + transaction.amount, 0);

      setRecipes(newRecipes);
      setExpenses(expenses);
      setProfits(newRecipes - expenses);
    } else {
      setRecipes(0);
      setExpenses(0);
      setProfits(0);
    }
  }, [statements]);

  const submitStatements = async (values) => {
    setLoading(true);
    try {
      const { data } = await api.post(`/${user?.type}s/additional_entries`, {
        data: {
          attributes: {
            description: values.description,
            amount: values.amount,
            classification: values.classification,
            date: values.date,
            timetable: moment().format('HH:mm'),
          },
        },
      });
      if (data.data) {
        toast.success('Lançamento realizado com sucesso!');
        setModal(false);
      }
    } catch (error) {
      toast.error('Erro ao realizar lançamento');
    } finally {
      setLoading(false);
      getStatements();
    }
  };

  const handleClickMonth = (month) => {
    if (activeMonth.length === 0) {
      setActiveMonth([months.find((item) => item.value === month)]);
      return;
    }
    const clickedMonthIndex = months.findIndex((item) => item.value === month);
    const firstSelectedMonthIndex = months.findIndex(
      (item) => item.value === activeMonth[0]?.value
    );
    const lastSelectedMonthIndex = months.findIndex(
      (item) => item.value === activeMonth[activeMonth.length - 1]?.value
    );

    if (activeMonth.some((item) => item.value === month)) {
      if (
        clickedMonthIndex === firstSelectedMonthIndex ||
        clickedMonthIndex === lastSelectedMonthIndex
      ) {
        setActiveMonth(activeMonth.filter((item) => item.value !== month));
      }
    } else {
      const rangeStart = Math.min(clickedMonthIndex, lastSelectedMonthIndex);
      const rangeEnd = Math.max(clickedMonthIndex, lastSelectedMonthIndex);
      const newSelection = months.slice(rangeStart, rangeEnd + 1);

      setActiveMonth([...new Set([...activeMonth, ...newSelection])].sort((a, b) => a.id - b.id));
    }
  };

  const handleClickType = (type) => {
    setStatementType(type);
    setModal(true);
  };

  const getEdit = (id) => {
    const statement = statements.find((item) => item.id === id);
    setStatementEdit(statement);
  };

  const handleEdit = async (values) => {
    setLoading(true);
    try {
      await api.patch(`/${user?.type}s/additional_entries/${values.id}`, {
        data: {
          attributes: {
            description: values.description,
            amount: values.amount,
            classification: values.classification,
            date: values.date,
            timetable: moment().format('HH:mm'),
          },
        },
      });
    } catch (error) {
      toast.error('Erro ao editar o lançamento');
    } finally {
      setLoading(false);
      setStatementEdit(null);
      setModal(false);
      getStatements();
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await api.delete(`/${user?.type}s/additional_entries/${id}`);
    } catch (error) {
      toast.error('Erro ao deletar o lançamento');
    } finally {
      setLoading(false);
      setModalDelete(false);
      setDeleteId(null);
      getStatements();
    }
  };

  const modalControl = {
    onEdit: (id) => {
      getEdit(id);
      setModal(true);
    },
    onDelete: (id) => {
      setDeleteId(id);
      setModalDelete(true);
    },
  };

  return (
    <MainContainer>
      <ContainerItemLeft>
        {loading ? (
          <LoaderSpinner logo />
        ) : (
          <FlatListStatements
            data={statements}
            dateSearch
            filterObject={(transactions) => transactions.search}
            placeholder="Buscar Lançamentos..."
            renderItem={(transactions, index) => (
              <StatementListItem key={`${transactions.id}${index}`} data={transactions} />
            )}
            type="statement"
            searchType="clinic"
            loading={false}
            modalControl={modalControl}
          />
        )}
      </ContainerItemLeft>

      <ContainerItem>
        <StatementsWrapper>
          <PayOutContainer className="funds">
            <SMFont style={{ color: theme.darkNavy }}>Receita mensal</SMFont>
            <MDFontDark>{convertCurrencyPtBr(recipes)}</MDFontDark>
          </PayOutContainer>
          <PayOutContainer className="invoice">
            <SMFont style={{ color: theme.darkNavy }}>Despesa mensal</SMFont>
            <MDFontRed>{convertCurrencyPtBr(expenses)}</MDFontRed>
          </PayOutContainer>
          <PayOutContainer className="withdraw">
            <SMFont style={{ color: theme.darkNavy }}>Lucro</SMFont>
            <MDFontDark plus={profits}>{convertCurrencyPtBr(profits)}</MDFontDark>
          </PayOutContainer>
        </StatementsWrapper>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '2rem 0',
            width: '100%',
          }}
        >
          <h2
            style={{
              fontSize: '1rem',
              fontWeight: '700',
              paddingBottom: '1rem',
            }}
          >
            Criar lançamento manual
          </h2>
          <ButtonWrapper>
            <StatementButton type="button" name="income" onClick={() => handleClickType('income')}>
              <AddIcon />
              RECEITA
            </StatementButton>
            <StatementButton
              type="button"
              name="expense"
              onClick={() => handleClickType('expense')}
            >
              <RemoveIcon />
              DESPESA
            </StatementButton>
          </ButtonWrapper>
        </div>

        <FormControlLabel
          name="customPeriod"
          control={<Checkbox checked={activeDate} />}
          label="Selecionar período personalizado"
          onChange={({ target }) => {
            setActiveDate(target.checked);
            setActiveMonth(months.filter((item) => item.value === moment().format('MMM')));
          }}
          style={{ marginBottom: '1rem', width: '100%' }}
        />

        {!activeDate && (
          <Fragment>
            <WrapperMonthly>
              {months.slice(0, 6).map(({ value, label, title, id }) => (
                <Tooltip key={id} title={title} arrow>
                  <MonthButton
                    active={activeMonth.some((item) => item?.value?.includes(value))}
                    name={label}
                    value={value}
                    onClick={() => handleClickMonth(value)}
                  >
                    {label}
                  </MonthButton>
                </Tooltip>
              ))}
            </WrapperMonthly>
            <WrapperMonthly>
              {months.slice(6, 12).map(({ value, label, title, id }) => (
                <Tooltip key={id} title={title} arrow>
                  <MonthButton
                    active={activeMonth.some((item) => item?.value?.includes(value))}
                    name={label}
                    value={value}
                    onClick={() => handleClickMonth(value)}
                  >
                    {label}
                  </MonthButton>
                </Tooltip>
              ))}
            </WrapperMonthly>
          </Fragment>
        )}

        {activeDate && <DateRangeSelector handleCustomDate={getStatements} />}

        <WrapperDownloadButton>
          <Tooltip title="Baixar PDF" arrow>
            <DownloadButton onClick={getGeneratePDF}>PDF</DownloadButton>
          </Tooltip>
          <Tooltip title="Baixar CSV" arrow>
            <DownloadButton onClick={getGenerateCSV}>CSV</DownloadButton>
          </Tooltip>
        </WrapperDownloadButton>
      </ContainerItem>

      {modal && (
        <Modal open>
          <BoxModalWrapper
            style={{
              width: '800px',
              height: 'auto',
              maxHeight: '80vh',
              padding: 0,
            }}
          >
            <BoxWrapper>
              <IconArea style={{ cursor: 'default' }}>
                <X
                  style={{ width: '2rem', height: '2rem', cursor: 'pointer' }}
                  onClick={() => setModal(false)}
                />
              </IconArea>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '2rem',
                  textAlign: 'center',
                  width: '80%',
                }}
              >
                {`${statementEdit ? 'Editar' : 'Adicionar'} ${
                  statementType === 'income' ? 'Receita' : 'Despesa'
                }`}
              </Typography>
              <Formik
                initialValues={{
                  id: statementEdit?.id || null,
                  description: statementEdit?.attributes?.description || '',
                  amount: statementEdit?.attributes?.amount || 0,
                  classification: statementEdit?.attributes?.classification || statementType,
                  date: statementEdit?.attributes?.createdAt || moment().format('DD/MM/YYYY'),
                }}
                validationSchema={schema}
                validateOnChange={true}
                validateOnBlur={true}
                validateOnMount={true}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    statementEdit ? handleEdit(values) : submitStatements(values);
                    setSubmitting(false);
                  }, 400);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  touched,
                  values,
                }) => (
                  <Form>
                    <Grid container spacing={5} sx={{ p: 5 }}>
                      <Grid item xs={6}>
                        <FormikControl
                          control="input"
                          errors={errors.description}
                          label="Nome"
                          name="description"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          touched={touched.description}
                          upLabel
                          value={values.description}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikControl
                          control="input-currency"
                          errors={errors.amount}
                          label="Valor"
                          name="amount"
                          onChange={(_event, value) => setFieldValue('amount', value)}
                          touched={touched.amount}
                          upLabel
                          value={Number(values.amount)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikControl
                          disabled
                          control="input"
                          errors={errors.classification}
                          label="Tipo"
                          name="classification"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          touched={touched.classification}
                          upLabel
                          value={values.classification === 'income' ? 'Receita' : 'Despesa'}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikControl
                          control="input"
                          errors={errors.date}
                          label="Data"
                          mask="99/99/9999"
                          name="date"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          touched={touched.date}
                          upLabel
                          value={values.date}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={5} sx={{ p: 5 }}>
                      <Grid item xs={6}>
                        <Button disabled={isSubmitting} onClick={() => handleSubmit()}>
                          {statementEdit ? 'Editar' : 'Adicionar'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </BoxWrapper>
          </BoxModalWrapper>
        </Modal>
      )}

      {modalDelete && (
        <Modal open>
          <BoxModalWrapper
            style={{
              width: '600px',
              height: 'auto',
              maxHeight: '80vh',
              padding: 0,
            }}
          >
            <BoxWrapper>
              <IconArea style={{ cursor: 'default' }}>
                <X
                  style={{ width: '2rem', height: '2rem', cursor: 'pointer' }}
                  onClick={() => setModalDelete(false)}
                />
              </IconArea>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '2rem',
                  textAlign: 'center',
                  width: '80%',
                  mb: 2,
                }}
              >
                Deletar lançamento
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '1.2rem',
                  textAlign: 'center',
                  width: '80%',
                }}
              >
                Deseja realmente deletar este lançamento?
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '40%',
                  m: 5,
                }}
              >
                <Button
                  onClick={() => {
                    handleDelete(deleteId);
                  }}
                  style={{ backgroundColor: 'red' }}
                >
                  Deletar
                </Button>
              </Box>
            </BoxWrapper>
          </BoxModalWrapper>
        </Modal>
      )}
    </MainContainer>
  );
}
