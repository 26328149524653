/* eslint-disable react-hooks/exhaustive-deps */
import { FormControlLabel, Modal, Radio } from '@material-ui/core';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Calendar, CalendarTime } from '../../../components/EventElements';
import InviteForm from '../../../components/FormikElements/InviteForm';
import PreSignUpClient from '../../../components/FormikElements/PreSignUpClient';
import { Flatlist } from '../../../components/List';
import { PatientListCalendar, PatientListItem } from '../../../components/ListItens';
import LoaderSpinner from '../../../components/LoaderSpinner';
import useAuth from '../../../hooks/auth';
import useEvent from '../../../hooks/event';
import api from '../../../services/api';
import { BoxScroll } from '../../../styles/globalStyles';
import theme from '../../../theme';
import handleErrors from '../../../utils/getValuesErrors';
import useWindowSize from '../../../utils/WindowSize';
import {
  BlueBigText,
  BlueSmallText,
  BoxWrapperSchedule,
  ButtonContainer,
  ButtonContainerCalendar,
  CloseIcon,
  Hr,
  IconTouchableArea,
  RowContainer,
  ScheduleButton,
  Square,
} from '../../Client/Professionals/styles';
import { Schedule, ScheduleStatus } from './Schedule';
import {
  BigFontModal,
  BoxWrapper,
  ButtonTop,
  ButtonWrapper,
  CalendarWrapper,
  FormWrapper,
  IconArea,
  Main,
} from './styles';

export default function PatientList() {
  const [patients, setPatients] = useState([]);
  const { width } = useWindowSize();
  const [loading, setLoading] = useState(true);
  const [formType, setFormType] = useState('invite');

  const submitFormInvite = async (values) => {
    setLoading(true);
    try {
      const request = {
        data: {
          attributes: {
            name: values.name,
            email: values.email,
            phone: values.phone,
            city: values.city,
            message: values.message,
          },
        },
      };
      const { data } = await api.post(`/professionals/invites`, request);
      if (data) {
        toast.success('Convite enviado com sucesso!');
      }
    } catch (error) {
      toast.error('Não foi possível enviar o convite!');
    } finally {
      form.resetForm();
      setLoading(false);
    }
  };

  const submitFormPreSignUp = async (values) => {
    setLoading(true);
    try {
      const request = {
        data: {
          type: 'client',
          attributes: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
          },
        },
      };
      const { data } = await api.post(`/professionals/clients`, request);
      if (data) {
        toast.success('Pré cadastro realizado com sucesso!');
      }
    } catch (error) {
      toast.error('Não foi possível realizar o pré cadastro!');
    } finally {
      form.resetForm();
      setLoading(false);
    }
  };

  const schemaInvite = Yup.object({
    name: Yup.string()
      .min(3, 'O nome deve ter pelo menos 3 caracteres')
      .required('Nome é obrigatório!'),
    email: Yup.string().email('Email deve ser um Email válido').required('Email é obrigatório!'),
    phone: Yup.string().optional(),
    city: Yup.string().optional(),
    message: Yup.string().optional(),
  });

  const schemaPreSignUp = Yup.object({
    firstName: Yup.string().nullable().required('Nome é obrigatório!'),
    lastName: Yup.string().nullable().required('Sobrenome é obrigatório!'),
    email: Yup.string().nullable().email('Email inválido').required('Email é obrigatório!'),
    phone: Yup.string().nullable().required('Telefone é obrigatório!'),
  });

  const form = useFormik({
    initialValues: {
      name: '',
      lastName: '',
      email: '',
      phone: '',
      city: '',
      message: '',
    },
    validationSchema: formType === 'invite' ? schemaInvite : schemaPreSignUp,
    onSubmit: async (values) => {
      try {
        if (formType === 'invite') {
          submitFormInvite(values);
        } else {
          submitFormPreSignUp(values);
        }
      } catch (error) {
        toast.error('Email do paciente já se encontra em uso! Favor fornecer um novo email!');
      }
    },
    validateOnChange: true,
  });

  useEffect(() => {
    const controller = new AbortController();
    const fetchPatients = async () => {
      try {
        const { data } = await api.get('/professionals/my_patients', {
          signal: controller.signal,
        });

        const patientsSerialized = data.data.map((patient) => ({
          ...patient,
          attributes: {
            client: {
              ...patient.attributes,
              id: patient.id,
            },
          },
        }));

        setPatients(patientsSerialized);
      } catch (error) {
        if (!controller.signal.aborted) toast.error('Não foi possível carregar lista de pacientes');
      } finally {
        setLoading(false);
      }
    };
    fetchPatients();

    return () => controller.abort();
  }, []);

  return (
    <Main>
      <Flatlist
        type="patient"
        data={patients}
        filterObject={(item) => item.attributes.client.firstName}
        placeholder="Pesquise um Pacientes"
        renderItem={(item, index) => <PatientListItem key={index} data={item} width={width} />}
        loading={loading}
      />

      <FormWrapper>
        <ButtonWrapper>
          <ButtonTop
            active={formType === 'invite'}
            onClick={() => setFormType('invite')}
            type="button"
          >
            Convidar Paciente
          </ButtonTop>
          <ButtonTop
            active={formType !== 'invite'}
            onClick={() => setFormType('pre-signup')}
            type="button"
          >
            Cadastrar Paciente
          </ButtonTop>
        </ButtonWrapper>
        <form onSubmit={form.handleSubmit} style={{ width: '100%' }}>
          {formType === 'invite' ? <InviteForm form={form} /> : <PreSignUpClient form={form} />}
        </form>
      </FormWrapper>
    </Main>
  );
}

export function CalendarComponentProfessional({ form }) {
  const { errors, values, setValues, setFieldValue, handleSubmit } = form;
  const { user } = useAuth();
  const { setScheduleInfo } = useEvent();
  const { width } = useWindowSize();
  const [workableDays, setWorkableDays] = useState([]);
  const [fullTimes, setFullTimes] = useState(false);
  const [date, setDate] = useState(new Date());
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timeBlocks, setTimeBlocks] = useState([]);
  const [modalTime, setModalTime] = useState(false);
  const [modalSchedule, setModalSchedule] = useState(false);
  const [modalScheduleStatus, setModalScheduleStatus] = useState(false);
  const [modalPatient, setModalPatient] = useState(false);

  const FieldValues = ['firstName', 'lastName', 'email', 'phone'];

  useEffect(() => {
    const controller = new AbortController();
    const fetchPatients = async () => {
      try {
        const { data } = await api.get(
          `/${user?.type}s/${user?.type === 'professional' ? 'my_' : ''}patients`,
          {
            signal: controller.signal,
          }
        );

        if (user?.type === 'professional') {
          const patientsSerialized = data.data.map((patient) => ({
            ...patient,
            attributes: {
              client: {
                ...patient.attributes,
                id: patient.id,
              },
            },
          }));
          setPatients(patientsSerialized);
        } else setPatients(data.data);
      } catch (error) {
        if (!controller.signal.aborted) toast.error('Não foi possível carregar lista de pacientes');
      } finally {
        setLoading(false);
      }
    };
    fetchPatients();

    return () => controller.abort();
  }, []);

  const handleContinue = async () => {
    if (!handleErrors(errors, FieldValues)) {
      setModalTime(true);
      setModalPatient(false);
    }
  };

  const getAvaiableTimeBlocks = (data) => {
    const ADD_HOURS = 21600; //Add 6 hours in seconds
    const avaiable = data?.attributes?.appointmentTimeBlocks?.map((tb) => ({
      time: moment(tb, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'),
      type: 'available',
    }));

    const checkHour = (hour1, hour2) => {
      const parseHour1 = moment(hour1, 'YYYY-MM-DD HH:mm');
      const parseHour2 = moment(hour2, 'YYYY-MM-DD HH:mm');
      return parseHour1.isBefore(parseHour2);
    };

    const todayDate = moment().format('YYYY-MM-DD');
    const parseDate = moment(date).format('YYYY-MM-DD');

    if (todayDate === parseDate) {
      const parseReatroactiveSchedules = avaiable?.map((tm) => {
        const nowAddSixHours = moment().add(ADD_HOURS, 'seconds').format('YYYY-MM-DD HH:mm');
        const time = moment(tm.time, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
        if (tm.type === 'available') {
          if (checkHour(time, nowAddSixHours)) {
            return { ...tm, type: 'unavailable' };
          } else {
            return tm;
          }
        } else {
          return tm;
        }
      });

      return parseReatroactiveSchedules
        .sort((a, b) => Number(a.time.split(':').join('')) - Number(b.time.split(':').join('')))
        ?.map((tm) => ({
          ...tm,
          time: moment(tm.time, 'YYYY-MM-DD HH:mm').format('HH:mm'),
        }));
    } else {
      return avaiable
        .sort((a, b) => Number(a.time.split(':').join('')) - Number(b.time.split(':').join('')))
        ?.map((tm) => ({
          ...tm,
          time: moment(tm.time, 'YYYY-MM-DD HH:mm').format('HH:mm'),
        }));
    }
  };

  const fetchTimeBlocks = async () => {
    const currentDate = moment(date).format('YYYY-MM-DD');

    try {
      setLoading(true);
      const { data } = await api.get(`/professionals/availabilities?date=${currentDate}`);
      const availableTimeBlocks = getAvaiableTimeBlocks(data.data);
      if (availableTimeBlocks?.length !== 0) {
        setTimeBlocks(availableTimeBlocks);
        setFullTimes(availableTimeBlocks?.every((tb) => tb.type === 'unavailable'));
      } else setFullTimes(true);
    } catch (error) {
      toast.error('Erro ao buscar horários disponíveis');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const updateCalendar = async () => {
      const startOfMonth = moment(date).startOf('month');
      const endOfMonth = moment(date).endOf('month');

      const getDiasMes = async () => {
        const daysWeek = [];
        while (startOfMonth.isBefore(endOfMonth)) {
          daysWeek.push(startOfMonth.format('D'));
          startOfMonth.add(1, 'days');
        }

        const days = daysWeek?.filter(
          (day) =>
            user?.availabilityRule?.workdays?.filter(
              (item) =>
                new Date(date.getFullYear(), date.getMonth(), day).getDay().toString() === item
            )?.length === 0
        );

        const working = days?.map((item) => new Date(date.getFullYear(), date.getMonth(), item));

        return working;
      };

      const result = await getDiasMes();
      setWorkableDays(result);
    };

    updateCalendar();
  }, [date]);

  useEffect(() => {
    fetchTimeBlocks();
  }, [date]);

  const handleSelectClientDay = (client) => {
    setFieldValue('clientId', client.id);
    setFieldValue('clientName', client?.socialName || `${client.firstName} ${client?.lastName}`);
    setFieldValue('firstName', null);
    setFieldValue('lastName', null);
    setFieldValue('email', null);
    setFieldValue('phone', null);

    values?.appointmentType === null
      ? toast.info('Não há horários disponíveis para agendamento.')
      : setModalTime(true);
  };

  return loading ? (
    <LoaderSpinner />
  ) : (
    <Main>
      <Flatlist
        type="patient"
        data={patients}
        filterObject={(item) => item.attributes.client.firstName}
        placeholder="Pesquise um Pacientes"
        renderItem={(item, index) => (
          <PatientListCalendar
            key={index}
            data={item}
            width={width}
            handleClick={handleSelectClientDay}
            disabled={fullTimes}
          />
        )}
        loading={loading}
      />
      <CalendarWrapper>
        <BlueBigText>Selecione um dia para agendar</BlueBigText>
        <Calendar value={date} dates={workableDays} setDate={setDate} />

        <ButtonContainerCalendar>
          <BlueSmallText>
            {fullTimes
              ? 'O dia selecionado não possui horários disponíveis'
              : '\u2B05\ufe0f Clique em "Criar Agendamento" no cartão do cliente desejado para seguir com o agendamento ou clique em "Cadastrar Paciente" para cadastrar um novo paciente e concluir o agendamento \u27A1\ufe0f'}
          </BlueSmallText>

          {!fullTimes && (
            <ScheduleButton
              onClick={() => {
                setFieldValue('clientId', null);
                setFieldValue('clientName', null);
                setModalPatient(true);
              }}
              style={{ padding: '0 1rem', minWidth: 'fit-content' }}
              type="button"
            >
              Cadastrar Paciente
            </ScheduleButton>
          )}
        </ButtonContainerCalendar>
      </CalendarWrapper>

      {modalPatient && (
        <Modal open>
          <BoxWrapperSchedule>
            <IconArea style={{ padding: '1rem 1rem 0 0' }}>
              <CloseIcon
                onClick={() => {
                  setModalPatient(false);
                }}
              />
            </IconArea>
            <BigFontModal>
              Cadastre um novo paciente para agendar a consulta para este dia.
            </BigFontModal>
            <BoxScroll>
              <PreSignUpClient form={form} schedule />
              <Hr style={{ width: '100%' }} />
              <ButtonContainerCalendar
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  padding: 0,
                }}
              >
                <ScheduleButton
                  onClick={handleContinue}
                  style={{ padding: '0 1rem', width: '15rem' }}
                  type="button"
                >
                  Continuar agendamento
                </ScheduleButton>
              </ButtonContainerCalendar>
            </BoxScroll>
          </BoxWrapperSchedule>
        </Modal>
      )}

      {modalTime && (
        <Modal open>
          <BoxWrapper>
            <IconArea>
              <CloseIcon
                onClick={() => {
                  setModalTime(false);
                  setScheduleInfo({});
                }}
              />
            </IconArea>
            <BoxScroll>
              <CalendarTime
                date={date}
                title={moment(date, 'pt-br').format('DD [de] MMMM [de] YYYY')}
                time={timeBlocks}
                values={values}
                setValues={setValues}
                setDate={setDate}
              />
              <RowContainer style={{ justifyContent: 'flex-start' }}>
                <IconTouchableArea>
                  <Square background={theme.white} />
                  <p style={{ marginLeft: '0.25rem' }}>Horários livres</p>
                </IconTouchableArea>
                <IconTouchableArea>
                  <Square background={theme.softBackground} />
                  <p style={{ marginLeft: '0.25rem' }}>Horários indisponíveis</p>
                </IconTouchableArea>
              </RowContainer>
              <Hr style={{ width: '100%' }} />
              <RowContainer>
                {user?.availabilityRule?.appointmentTypes?.includes('in_person') && (
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{ color: theme.neutralGray }}
                        checked={values?.appointmentType === 'in_person'}
                        name="radio-buttons"
                        value="in_person"
                        onChange={({ target }) => {
                          setFieldValue('appointmentType', target.value);
                        }}
                      />
                    }
                    label="Presencial"
                  />
                )}
                {user?.availabilityRule?.appointmentTypes?.includes('online') && (
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{ color: '#BCC1E0' }}
                        checked={values?.appointmentType === 'online'}
                        name="radio-buttons"
                        value="online"
                        onChange={({ target }) => {
                          setFieldValue('appointmentType', target.value);
                        }}
                      />
                    }
                    label="Online"
                  />
                )}
              </RowContainer>
            </BoxScroll>
            <Hr style={{ marginTop: '1rem' }} />
            <ButtonContainer style={{ width: '80%', marginTop: '0.5rem' }}>
              <ScheduleButton
                onClick={() => {
                  setModalPatient(true);
                  setModalTime(false);
                }}
                style={{ width: '6rem' }}
              >
                Voltar
              </ScheduleButton>
              <ScheduleButton
                onClick={() => {
                  setValues({
                    ...values,
                    startDate: moment(date).format('YYYY-MM-DD'),
                    startTime: values.startTime,
                    appointmentType: values?.appointmentType,
                  });
                  setModalTime(false);
                  setModalSchedule(true);
                }}
                disabled={!values?.startTime || !values?.appointmentType}
                style={{ width: '15rem' }}
              >
                Continuar agendamento
              </ScheduleButton>
            </ButtonContainer>
          </BoxWrapper>
        </Modal>
      )}

      {modalSchedule && (
        <Modal open>
          <BoxWrapperSchedule>
            <IconArea style={{ padding: '1rem 1rem 0 0' }}>
              <CloseIcon
                onClick={() => {
                  setModalSchedule(false);
                }}
              />
            </IconArea>
            <BoxScroll>
              <Schedule values={values} setValues={setValues} setFieldValue={setFieldValue} />
            </BoxScroll>
            <Hr style={{ marginTop: '1rem' }} />
            <ButtonContainer style={{ width: '80%', marginTop: '0.5rem' }}>
              <ScheduleButton
                onClick={() => {
                  setModalPatient(true);
                  setModalTime(false);
                }}
                style={{ width: '6rem' }}
              >
                Voltar
              </ScheduleButton>
              <ScheduleButton
                onClick={() => {
                  setModalSchedule(false);
                  setModalScheduleStatus(true);
                }}
                disabled={!values?.startTime || !values?.appointmentType}
                style={{ width: '15rem' }}
              >
                Continuar agendamento
              </ScheduleButton>
            </ButtonContainer>
          </BoxWrapperSchedule>
        </Modal>
      )}

      {modalScheduleStatus && (
        <Modal open>
          <BoxWrapperSchedule>
            <IconArea style={{ padding: '1rem 1rem 0 0' }}>
              <CloseIcon
                onClick={() => {
                  setModalScheduleStatus(false);
                }}
              />
            </IconArea>
            <BoxScroll>
              <ScheduleStatus form={form} />
            </BoxScroll>
            <Hr style={{ marginTop: '1rem' }} />
            <ButtonContainer style={{ width: '80%', marginTop: '0.5rem' }}>
              <ScheduleButton
                onClick={() => {
                  setModalSchedule(true);
                  setModalScheduleStatus(false);
                }}
                style={{ width: '6rem' }}
              >
                Voltar
              </ScheduleButton>
              <ScheduleButton
                onClick={handleSubmit}
                disabled={!values?.startTime || !values?.appointmentType}
                style={{ width: '15rem' }}
              >
                Finalizar agendamento
              </ScheduleButton>
            </ButtonContainer>
          </BoxWrapperSchedule>
        </Modal>
      )}
    </Main>
  );
}
