/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from '@mui/material';
import valid from 'card-validator';
import { FormikProvider, useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { FooterButtons } from '../../../components/FooterButtons';
import PaymentForm from '../../../components/FormikElements/PaymentForm';
import { ClinicForm } from '../../../components/FormikElements/styles';
import LoaderSpinner from '../../../components/LoaderSpinner';
import useAuth from '../../../hooks/auth';
import useEvent from '../../../hooks/event';
import EllipseBottom from '../../../images/ellipse-bottom.svg';
import EllipseTop from '../../../images/ellipse-top.svg';
import LogoDefault from '../../../images/logo-clinic-default.png';
import Logo from '../../../images/logoLogin.png';
import { BREAKPOINT_MD, InstagramIcon } from '../../../styles/globalStyles';
import theme from '../../../theme';
import useWindowSize from '../../../utils/WindowSize';
import handleErrors from '../../../utils/getValuesErrors';
import validateCNPJ from '../../../utils/validateCNPJ';
import validateCPF from '../../../utils/validateCPF';
import validateInfoBank from '../../../utils/validateInfoBank';
import {
  BackgroundImg,
  ImageContainer,
  ImgEllipseBottom,
  ImgEllipseTop,
  LogoImg,
} from '../../Public/Login/styles';
import { IconAreaInstagram, SocialIconsWrapper } from '../../Public/Profile/styles';
import AcceptanceTerm from '../../Register/AcceptanceTerm';
import BankAccount from '../../Register/BankAccount';
import BasicRegistration, { TypesReceipts } from '../../Register/BasicRegistrationClinic';
import ComercialAddress from '../../Register/ComercialAddress';
import ResponsibleData from '../../Register/ResponsibleData/index.';
import { CardContainer, LoginWrapper, Main } from './styles';

export default function ClinicSignUp() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const { backgroundImg } = useEvent();
  const { signUpClinic } = useAuth();
  const { width } = useWindowSize();

  const FieldValues = ['cardNumber', 'cardHolderName', 'cardCvv', 'cardExpiration_date'];

  async function submitForm(values) {
    if (!values.avatar) values.avatar = LogoDefault;
    setLoading(true);
    try {
      const success = await signUpClinic(values);
      if (success) {
        history.push('/clinica/sucesso');
      } else {
        toast.error(
          'Erro no servidor. Reveja se seus dados estão corretos e tente se cadastrar novamente em alguns minutos.'
        );
      }
    } catch (error) {
      toast.error(
        'Erro no servidor. Reveja se seus dados estão corretos e tente se cadastrar novamente em alguns minutos.'
      );
    } finally {
      setLoading(false);
    }
  }

  function handleContinue(values) {
    values.cardNumber = values.cardNumber.replace(/\s+/g, '');
    values.cardNumber = values.cardNumber.replace(/_/g, '');
    values.cardExpiration_date = values.cardExpiration_date.replace(/[/]/g, '');
    values.cardExpiration_date = values.cardExpiration_date.replace(/_/g, '');
    submitForm(values);
  }

  const schema = Yup.object().shape({
    // basic registration
    avatar: Yup.string().optional(),
    banner: Yup.string().optional(),
    name: Yup.string().required('Nome é obrigatório!'),
    description: Yup.string().optional(),
    email: Yup.string().email('Email invalido').required('Email é obrigatório!'),
    phone: Yup.string().required('Telefone é obrigatório!'),
    documentNumber: Yup.string().test({
      name: 'isValid',
      exclusive: false,
      params: {},
      message: 'CPF/CNPJ inválido',
      test(value) {
        if (value?.replace(/[^\d]+/g, '').length === 11) return validateCPF(value || '');
        if (value?.replace(/[^\d]+/g, '').length === 14) return validateCNPJ(value || '');
      },
    }),
    password: Yup.string()
      .required('Senha é Requerida')
      .min(8, 'Senha deve conter no mínimo 8 caracteres'),
    confirm_password: Yup.string()
      .required('Repita a senha')
      .oneOf([Yup.ref('password'), null], 'Senhas devem ser iguais.'),

    // responsible
    responsibleName: Yup.string().required('Nome é obrigatório'),
    responsibleDocumentNumber: Yup.string().required('CRP é obrigatório'),
    responsibleAbout: Yup.string().required('Descrição é obrigatória'),
    responsibleAvatar: Yup.string().optional(),

    // payment method
    acceptCredit: Yup.boolean().optional(),
    acceptPix: Yup.boolean().optional(),
    prePaid: Yup.boolean().optional(),
    postPaid: Yup.boolean().optional(),
    postPaidDay: Yup.number().when('postPaid', {
      is: true,
      then: Yup.number()
        .min(1, 'Dia do pagamento deve ser maior que 1')
        .max(31, 'Dia do pagamento deve ser menor que 31')
        .required('Dia do pagamento é obrigatório'),
    }),
    externalPayment: Yup.boolean().optional(),
    allmindsBenefit: Yup.boolean().optional(),

    // comercial address
    cep: Yup.string()
      .required('CEP é obrigatorio')
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'CEP inválido',
        test(value) {
          const maskDefaultValue = '_';
          const str = value;
          const result = str?.search(maskDefaultValue);
          return result > -1 ? false : true;
        },
      }),
    street: Yup.string().required('Endereço é obrigatório!'),
    street_number: Yup.string().required('Número é obrigatório!'),
    complement: Yup.string().optional(),
    neighborhood: Yup.string().required('Bairro é obrigatório!'),
    city: Yup.string().required('Cidade é obrigatório!'),
    state: Yup.string().required('Estado é obrigatório!'),
    country: Yup.string().required('Pais é obrigatório!'),

    // bank account
    paymentDocumentNumber: Yup.string().test({
      name: 'isValid',
      exclusive: false,
      params: {},
      message: 'CPF/CNPJ inválido',
      test(value) {
        if (value?.replace(/[^\d]+/g, '').length === 11) return validateCPF(value || '');
        if (value?.replace(/[^\d]+/g, '').length === 14) return validateCNPJ(value || '');
      },
    }),
    bankCode: Yup.string().required('Banco é obrigatório'),
    bankAg: Yup.string()
      .required('Agência da conta é obrigatório')
      .nullable()
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'Informe apenas números',
        test(value) {
          if (!value) return true;
          return validateInfoBank(value);
        },
      }),
    agenciaDv: Yup.string()
      .optional()
      .nullable()
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'Informe apenas números',
        test(value) {
          if (!value) return true;
          return validateInfoBank(value);
        },
      }),
    bankCc: Yup.string()
      .required('Número da conta é obrigatório')
      .nullable()
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'Informe apenas números',
        test(value) {
          if (!value) return true;
          return validateInfoBank(value);
        },
      }),
    contaDv: Yup.string()
      .required('Dígito da conta é obrigatório')
      .nullable()
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'Informe apenas números',
        test(value) {
          if (!value) return true;
          return validateInfoBank(value);
        },
      }),
    accountType: Yup.string().required('Tipo da conta é obrigatório'),
    paymentName: Yup.string().required('Nome é obrigatório'),

    // payment
    cardNumber: Yup.string().test(
      'test-number',
      'Cartão inválido',
      (value) => valid.number(value).isValid
    ),
    cardHolderName: Yup.string().required('Nome é obrigatório.'),
    cardCvv: Yup.string().test(
      'test-cvv',
      'Código de segurança inválido',
      (value) => valid.cvv(value).isValid
    ),
    cardExpiration_date: Yup.string().test(
      'test-date',
      'Data inválida',
      (value) => valid.expirationDate(value).isValid
    ),
  });

  const form = useFormik({
    initialValues: {
      // Basic registration
      avatar: '',
      banner: '',
      name: '',
      description: '',
      email: '',
      phone: '',
      documentNumber: '',
      password: '',
      confirm_password: '',

      // Responsible
      responsibleName: '',
      responsibleDocumentNumber: '',
      responsibleAbout: '',
      responsibleAvatar: '',

      // Comercial address
      cep: '',
      street: '',
      street_number: '',
      neighborhood: '',
      city: '',
      state: '',
      country: '',
      complement: '',

      // Bank account
      bankAg: '',
      agenciaDv: null,
      accountType: '',
      bankCc: '',
      bankCode: '',
      contaDv: null,
      paymentDocumentNumber: '',
      paymentName: '',

      // Payment Method
      acceptCredit: false,
      acceptPix: false,
      prePaid: true,
      postPaid: false,
      postPaidDay: 10,
      externalPayment: false,
      allmindsBenefit: false,
      preferentialPayment: '',

      // Payment
      cardNumber: '',
      cardHolderName: '',
      cardCvv: '',
      cardExpiration_date: '',

      // terms and conditions
      read: true,
      accepted: false,
    },
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: (values) => handleContinue(values),
  });

  useEffect(() => {
    const toggleModal = () => {
      setLoading(false);
    };
    toggleModal();
  }, [step]);

  function next() {
    setStep(step + 1);
  }

  function goBack() {
    setStep(step - 1);
  }

  function validCardInfo() {
    if (!handleErrors(form.errors, FieldValues)) {
      next();
    }
  }

  return (
    backgroundImg && (
      <FormikProvider value={form}>
        {loading ? (
          <LoaderSpinner logo />
        ) : (
          <Main step={step}>
            <ClinicForm>
              <LoginWrapper step={step}>
                <ImageContainer>
                  <LogoImg src={Logo} alt="Logo" />
                </ImageContainer>
                {step === 0 && !loading && <BasicRegistration next={next} form={form} />}
                {step === 1 && !loading && (
                  <ResponsibleData next={next} goBack={goBack} form={form} />
                )}
                {step === 2 && !loading && (
                  <ComercialAddress next={next} goBack={goBack} form={form} />
                )}
                {step === 3 && !loading && (
                  <BankAccount next={next} goBack={goBack} form={form} clinic />
                )}
                {step === 4 && !loading && (
                  <TypesReceipts next={next} goBack={goBack} form={form} />
                )}
                {step === 5 && !loading && (
                  <CardContainer>
                    <p
                      style={{
                        fontSize: '1rem',
                        fontWeight: '700',
                        marginTop: '1rem',
                        textAlign: 'center',
                        width: '100%',
                      }}
                    >
                      Informações do cartão de crédito
                    </p>
                    <p
                      style={{
                        fontSize: '1rem',
                        fontWeight: '700',
                        textAlign: 'center',
                        width: '100%',
                      }}
                    >
                      (Para cobrança da assinatura Allminds)
                    </p>
                    <PaymentForm form={form} loading={loading} subscription clinic />
                    <FooterButtons goBack={goBack} next={validCardInfo} />
                  </CardContainer>
                )}
                {step === 6 && !loading && <AcceptanceTerm goBack={goBack} form={form} />}
              </LoginWrapper>
            </ClinicForm>
            <ImgEllipseBottom src={EllipseBottom} alt="imgLogin" />
            {width > BREAKPOINT_MD && ![6].includes(step) && (
              <Fragment>
                <ImgEllipseTop src={EllipseTop} alt="imgLogin" />
                <BackgroundImg style={{ zIndex: 0 }} src={backgroundImg} />
              </Fragment>
            )}
            <SocialIconsWrapper align={[6].includes(step)}>
              {/* <Tooltip title="Fale com a gente">
                <IconAreaWhatsApp
                  href="https://wa.me/5571999960614"
                  target="_blank"
                >
                  <WhatsAppIcon fontSize="medium" sx={{ color: theme.white }} />
                </IconAreaWhatsApp>
              </Tooltip> */}
              <Tooltip title="Siga-nos no Instagram">
                <IconAreaInstagram href="https://www.instagram.com/allminds.app/" target="_blank">
                  <InstagramIcon fontSize="medium" sx={{ color: theme.white }} />
                </IconAreaInstagram>
              </Tooltip>
            </SocialIconsWrapper>
          </Main>
        )}
      </FormikProvider>
    )
  );
}
