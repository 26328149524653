import TimeField from 'react-simple-timefield';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid ${({ theme }) => theme.neutralGray};
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: flex-start;
  margin: 5px 0;
  max-width: 500px;
  position: relative;

  label {
    color: ${({ theme }) => theme.darkNavy};
    font-size: 14px;
    line-height: 21px;
    padding-right: 10px;
  }
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: flex-start;
  width: 100%;
`;

export const BigContainer = styled.div`
  display: flex;
  flex: 1;
  min-height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledInput = styled.input`
  background-color: transparent;
  border: none;
  flex: 1;
  font-size: 14px;
  line-height: 21px;
  padding: 0 0.5rem;
  width: 100%;
`;

export const EmojiPickerContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.iceBackground};
  border-top: 1px solid ${({ theme }) => theme.neutralGray};
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

export const EmojiInput = styled.input`
  background-color: transparent;
  border-radius: 0.5rem;
  border: none;
  font-size: 16px;
  padding: 10px;
  width: 90%;
`;

export const EmojiPickerButton = styled.button`
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.white};
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  font-size: 18px;
`;

export const PickerContainer = styled.div`
  position: absolute;
  bottom: -330px;
  right: 0;
  z-index: 1;
`;

export const StyledTextArea = styled.textarea`
  background-color: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.neutralGray)};
  border-radius: 0.5rem;
  border: ${({ theme }) => `1px solid ${theme.neutralGray}`};
  color: ${({ theme, color }) => (color ? color : theme.darkNavy)};
  display: flex;
  flex-grow: 1;
  font-size: 14px;
  line-height: 21px;
  overflow-y: scroll;
  padding: 0.325rem;
  resize: none;
  width: ${({ width }) => (width ? width : '100%')};

  &::-webkit-scrollbar {
    background-color: ${({ theme }) => theme.iceBackground};
    border-radius: 5px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.iceBackground};
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.neutralGray};
    border-radius: 5px;
    height: 2rem;
  }
`;

export const StyledTimeInput = styled.input`
  height: 90%;
  width: 10%;
  padding-left: 2%;
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 21px;
`;

export const CheckInput = styled.input`
  height: 20px;
  width: 20px;
  margin-right: 10px;
  margin-left: ${({ left }) => (left ? '8px' : '0px')};
`;

export const Content = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 1px solid ${({ theme }) => theme.neutralGray};
  .--time {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
  }
  .--signal {
    font-size: 14px;
    line-height: 21px;
    color: ${({ theme }) => theme.neutralGray};
  }
`;

export const StyledTimeField = styled(TimeField)`
  border: 0;
  display: flex;
  width: 50%;
  background-color: ${({ theme }) => theme.lightGray};
`;
