/* eslint-disable react-hooks/exhaustive-deps */
import { FormControlLabel, Radio } from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Calendar, CalendarCard, CalendarTime } from '../../../../components/EventElements';
import LoaderSpinner from '../../../../components/LoaderSpinner';
import useAuth from '../../../../hooks/auth';
import EmptyEvent from '../../../../images/empty-dashboard.png';
import api from '../../../../services/api';
import { PtBr } from '../../../../services/pt_br';
import { BigFont, BoxScroll } from '../../../../styles/globalStyles';
import theme from '../../../../theme';
import {
  ConsultingPackages,
  ConsultingPlans,
  Schedule,
} from '../../../Client/Professionals/Schedule';
import {
  BoxWrapper,
  BoxWrapperSchedule,
  ButtonContainer,
  CloseIcon,
  Hr,
  IconArea,
  IconTouchableArea,
  RowContainer,
  ScheduleButton,
  Square,
  WarningIcon,
} from '../../../Client/Professionals/styles';
import {
  CalendarContainer,
  ContainerImg,
  TimeItemList,
  TimeText,
  WeekItemList,
  WeekList,
  WeekText,
} from '../styles';

export function CalendarItemList({ ...props }) {
  const { item, schedules, date } = props;
  const handleDate = (date) => {
    return moment(date).format('HH:mm');
  };

  const handleSchedule = () => {
    return (
      schedules?.filter(
        (dt) =>
          dt.attributes.startDate === date &&
          dt.attributes.startTime === moment(item).format('HH:mm')
      )[0] || null
    );
  };

  return (
    <TimeItemList>
      <TimeText>{handleDate(item)}</TimeText>
      {handleSchedule() && <CalendarCard event={handleSchedule()} />}
    </TimeItemList>
  );
}

function WeekItem({ ...props }) {
  const { date, schedules, daysTimes, setLoading } = props;
  const [timeBlocks, setTimeBlocks] = useState([]);
  const formatedDate = moment(date).format('YYYY-MM-DD');
  const currentDate = moment().format('YYYY-MM-DD');
  const currentDay = moment(date).format('ddd').toLowerCase();

  useEffect(() => {
    const blockTimes = daysTimes?.filter((dt) => dt.day === currentDay);

    const schedulesTimes = schedules?.filter(
      (schedule) => schedule.attributes.startDate === formatedDate
    );

    const mergedTimes = blockTimes.map((block) => {
      const matchingSchedule = schedulesTimes.find(
        (schedule) => schedule.attributes.startTime === block.attributes.startTime
      );
      return matchingSchedule || block;
    });

    setTimeBlocks(
      mergedTimes.sort((a, b) => {
        const timeA = moment(a.attributes.startTime, 'HH:mm');
        const timeB = moment(b.attributes.startTime, 'HH:mm');
        return timeA.isBefore(timeB) ? -1 : 1;
      })
    );
  }, [date]);

  return (
    <WeekItemList active={'true'} current={formatedDate === currentDate ? 'true' : 'false'}>
      <WeekItemList>
        <WeekText active={'true'} current={formatedDate === currentDate ? 'true' : 'false'}>
          {date.getDate()} de {PtBr.months_name[date.getMonth()].toLowerCase()}
        </WeekText>
        <WeekText active={'true'} current={formatedDate === currentDate ? 'true' : 'false'}>
          {PtBr.days_name[date.getDay()]}
        </WeekText>
      </WeekItemList>
      {timeBlocks?.length > 0 ? (
        <WeekList>
          {timeBlocks?.map((event) => (
            <CalendarCard
              key={event.attributes.startTime}
              event={event}
              calendarProfessional={true}
              setLoading={setLoading}
            />
          ))}
        </WeekList>
      ) : (
        <WeekList>
          <ContainerImg>
            <img src={EmptyEvent} alt="empty" style={{ width: '70%', alignSelf: 'center' }} />
            <p
              style={{
                color: '#999EBC',
                fontSize: '0.8rem',
                marginTop: '0.7rem',
                textAlign: 'center',
              }}
            >
              Sem agendamento para o dia.
            </p>
          </ContainerImg>
        </WeekList>
      )}
    </WeekItemList>
  );
}

export function CalendarComponentSchedule({ ...props }) {
  const { user } = useAuth();
  const { date, dayColumn } = props;
  const [schedules, setSchedules] = useState([]);
  const [blockTimes, setBlockTimes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [days, setDays] = useState([]);
  const [daysTimes, setDaysTimes] = useState([]);
  const defaultType = localStorage.getItem('type');
  const type = user.type || defaultType;

  useEffect(() => {
    const controller = new AbortController();
    const fetchTimeBlocks = async () => {
      try {
        const attributes = {
          appointmentTimeBlocks: {},
          blockedTimes: {},
          workdays: [],
          appointmentDuration: 60,
          appointmentTypes: [],
          breakTime: 0,
          startHour: 8,
          startMinute: 0,
          endHour: 18,
          endMinute: 0,
        };

        let { data } = await api.get(`/professionals/availabilities/rule`, {
          signal: controller.signal,
        });

        if (!data?.data?.attributes) {
          data = { data: { attributes } };
          setLoading(false);
        }

        const duration = data?.data?.attributes?.appointmentDuration;

        const appointmentTimeBlocks = Object.entries(data?.data?.attributes?.appointmentTimeBlocks);

        const blockedTimes = Object.entries(data?.data?.attributes?.blockedTimes);

        const timesBlock = [];

        if (appointmentTimeBlocks) {
          appointmentTimeBlocks.forEach(([key, value]) =>
            value.forEach((date) => {
              timesBlock.push({
                type: 'timeFree',
                day: PtBr.days_abs[key].toLowerCase(),
                attributes: {
                  startTime: moment(date).format('HH:mm'),
                  duration: duration,
                },
              });
            })
          );
        }

        if (blockedTimes) {
          blockedTimes.forEach(([key, value]) =>
            value.forEach((date) => {
              timesBlock.push({
                type: 'blockedTime',
                day: PtBr.days_abs[key].toLowerCase(),
                attributes: {
                  startTime: moment(date).format('HH:mm'),
                  duration: duration,
                },
              });
            })
          );
        }

        setBlockTimes(
          timesBlock.sort(
            (a, b) =>
              Number(a.attributes.startTime.split(':').join('')) -
              Number(b.attributes.startTime.split(':').join(''))
          )
        );
      } catch (error) {
        if (!controller.signal.aborted) toast.error('Erro ao buscar horários disponíveis');
      }
    };
    fetchTimeBlocks();

    return () => controller.abort();
  }, [date]);

  useEffect(() => {
    const controller = new AbortController();
    const getEvents = async () => {
      try {
        const { data } = await api.get(`/${type}s/schedules_locked_times`, {
          signal: controller.signal,
        });
        if (data.data) {
          setSchedules(data.data);
          return true;
        }
        throw new Error('Não foi possível listar os agendamentos');
      } catch (error) {
        if (!controller.signal.aborted) toast.error('Não foi possível listar os agendamentos');
        return false;
      }
    };
    getEvents();

    return () => controller.abort();
  }, [date]);

  useEffect(() => {
    const getDates = () => {
      const numberWeek = [];
      for (let i = 0; i < dayColumn; i++) {
        numberWeek.push(moment(date).add(i, 'days').toDate());
      }
      return numberWeek;
    };
    const getDays = getDates();
    const daysFilter = getDays?.map((item) => moment(item).format('ddd'));
    const filterTimes = blockTimes?.filter((item) => daysFilter.includes(item.day) && item);

    setDaysTimes(
      filterTimes.map((item) => ({
        ...item,
        attributes: {
          ...item.attributes,
          startTime: moment(item.attributes.startTime, 'HH:mm').format('HH:mm:ss'),
        },
      }))
    );
    setDays(getDays);
  }, [blockTimes, schedules]);

  useEffect(() => {
    if (days?.length > 0 && daysTimes?.length > 0) {
      setLoading(false);
    }
  }, [days, daysTimes]);

  return loading ? (
    <LoaderSpinner logo />
  ) : (
    <CalendarContainer>
      {days?.map((item) => (
        <WeekItem
          key={item}
          date={item}
          schedules={schedules}
          daysTimes={daysTimes}
          setLoading={setLoading}
        />
      ))}
    </CalendarContainer>
  );
}

export function CalendarComponent() {
  const { user } = useAuth();
  const [workableDays, setWorkableDays] = useState([]);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [timeBlocks, setTimeBlocks] = useState([]);
  const [fullTimes, setFullTimes] = useState(false);
  const [modalTime, setModalTime] = useState(false);
  const [modalSchedule, setModalSchedule] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);

  const form = useFormik({
    initialValues: {
      startDate: '',
      startTime: '',
      appointmentType: '',
      client: '',
    },
    validationSchema: Yup.object({
      startDate: Yup.date().required('Data é obrigatória'),
      startTime: Yup.string().required('Hora é obrigatória'),
      appointmentType: Yup.string().required('Tipo de consulta é obrigatório'),
      client: Yup.string().required('Profissional é obrigatório'),
    }),
  });

  const { values, handleSubmit, setValues, setFieldValue } = form;

  const handleSchedule = () => {
    setValues({
      ...values,
      startDate: date,
      startTime: values.startTime,
      appointmentType: values?.appointmentType,
    });
    setModalTime(false);
    setModalSchedule(true);
  };

  const getAvaiableTimeBlocks = (data) => {
    const ADD_HOURS = 21600; //Add 6 hours in seconds
    const avaiable = data?.attributes?.appointmentTimeBlocks?.map((tb) => ({
      time: moment(tb, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'),
      type: 'available',
    }));

    const checkHour = (hour1, hour2) => {
      const parseHour1 = moment(hour1, 'YYYY-MM-DD HH:mm');
      const parseHour2 = moment(hour2, 'YYYY-MM-DD HH:mm');
      return parseHour1.isBefore(parseHour2);
    };

    const todayDate = moment().format('YYYY-MM-DD');
    const parseDate = moment(date).format('YYYY-MM-DD');

    if (todayDate === parseDate) {
      const parseReatroactiveSchedules = avaiable?.map((tm) => {
        const nowAddSixHours = moment().add(ADD_HOURS, 'seconds').format('YYYY-MM-DD HH:mm');
        const time = moment(tm.time, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
        if (tm.type === 'available') {
          if (checkHour(time, nowAddSixHours)) {
            return { ...tm, type: 'unavailable' };
          } else {
            return tm;
          }
        } else {
          return tm;
        }
      });

      return parseReatroactiveSchedules
        .sort((a, b) => Number(a.time.split(':').join('')) - Number(b.time.split(':').join('')))
        ?.map((tm) => ({
          ...tm,
          time: moment(tm.time, 'YYYY-MM-DD HH:mm').format('HH:mm'),
        }));
    } else {
      return avaiable
        .sort((a, b) => Number(a.time.split(':').join('')) - Number(b.time.split(':').join('')))
        ?.map((tm) => ({
          ...tm,
          time: moment(tm.time, 'YYYY-MM-DD HH:mm').format('HH:mm'),
        }));
    }
  };

  useEffect(() => {
    const updateCalendar = async () => {
      const startOfMonth = moment(date).startOf('month');
      const endOfMonth = moment(date).endOf('month');

      const getDiasMes = async () => {
        const daysWeek = [];
        while (startOfMonth.isBefore(endOfMonth)) {
          daysWeek.push(startOfMonth.format('D'));
          startOfMonth.add(1, 'days');
        }

        const days = daysWeek?.filter(
          (day) =>
            user?.availabilityRule?.workdays?.filter(
              (item) =>
                new Date(date.getFullYear(), date.getMonth(), day).getDay().toString() === item
            )?.length === 0
        );

        const working = days?.map((item) => new Date(date.getFullYear(), date.getMonth(), item));

        return working;
      };

      const result = await getDiasMes();

      setWorkableDays(result);
    };

    updateCalendar();
  }, [date]);

  useEffect(() => {
    const fetchTimeBlocks = async () => {
      const currentDate = moment(date).format('YYYY-MM-DD');

      try {
        setLoading(true);
        const { data } = await api.get(`/professionals/availabilities?date=${currentDate}`);

        const availableTimeBlocks = getAvaiableTimeBlocks(data.data);
        if (availableTimeBlocks?.length !== 0) {
          setTimeBlocks(availableTimeBlocks);
          setFullTimes(availableTimeBlocks?.every((tb) => tb.type === 'unavailable'));
        } else setFullTimes(true);
      } catch (error) {
        toast.error('Erro ao buscar horários disponíveis');
      } finally {
        setLoading(false);
      }
    };

    fetchTimeBlocks();
  }, [date]);

  return (
    <Fragment>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <Calendar value={date} dates={workableDays} setDate={setDate} />
          <ButtonContainer>
            <ScheduleButton
              disabled={fullTimes}
              onClick={() => {
                values?.appointmentType === null
                  ? toast.info('Não há horários disponíveis para agendamento.')
                  : setModalTime(true);
              }}
            >
              {fullTimes
                ? 'O dia selecionado não possui horários disponíveis'
                : 'Selecionar dia da consulta'}
            </ScheduleButton>
          </ButtonContainer>
        </div>
      )}

      {modalTime && (
        <Modal open>
          <BoxWrapper>
            <IconArea>
              <CloseIcon
                onClick={() => {
                  setModalTime(false);
                }}
              />
            </IconArea>
            <BoxScroll>
              <CalendarTime
                date={date}
                title={moment(date, 'pt-br').format('DD [de] MMMM [de] YYYY')}
                time={timeBlocks}
                values={values}
                setValues={setValues}
                setDate={setDate}
              />
              <RowContainer style={{ justifyContent: 'flex-start' }}>
                <IconTouchableArea>
                  <Square background={theme.white} />
                  <p style={{ marginLeft: '0.25rem' }}>Horários livres</p>
                </IconTouchableArea>
                <IconTouchableArea>
                  <Square background={theme.softBackground} />
                  <p style={{ marginLeft: '0.25rem' }}>Horários indisponíveis</p>
                </IconTouchableArea>
              </RowContainer>
              <Hr style={{ width: '100%' }} />
              <RowContainer>
                {user?.availabilityRule?.appointmentTypes?.includes('in_person') && (
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{ color: theme.neutralGray }}
                        checked={values?.appointmentType === 'in_person'}
                        name="radio-buttons"
                        value="in_person"
                        onChange={({ target }) => {
                          setFieldValue('appointmentType', target.value);
                        }}
                      />
                    }
                    label="Presencial"
                  />
                )}
                {user?.availabilityRule?.appointmentTypes?.includes('online') && (
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{ color: '#BCC1E0' }}
                        checked={values?.appointmentType === 'online'}
                        name="radio-buttons"
                        value="online"
                        onChange={({ target }) => {
                          setFieldValue('appointmentType', target.value);
                        }}
                      />
                    }
                    label="Online"
                  />
                )}
              </RowContainer>
            </BoxScroll>
            <Hr style={{ marginTop: '1rem' }} />
            <ButtonContainer style={{ width: '80%', marginTop: '0.5rem' }}>
              <ScheduleButton
                onClick={() => {
                  setModalTime(false);
                }}
                style={{ width: '6rem' }}
              >
                Voltar
              </ScheduleButton>
              <ScheduleButton
                onClick={handleSchedule}
                disabled={!values?.startTime || !values?.appointmentType}
                style={{ width: '15rem' }}
              >
                Continuar agendamento
              </ScheduleButton>
            </ButtonContainer>
          </BoxWrapper>
        </Modal>
      )}

      {modalSchedule && (
        <Modal open>
          <BoxWrapperSchedule>
            <IconArea style={{ padding: '1rem 1rem 0 0' }}>
              <CloseIcon
                onClick={() => {
                  setModalSchedule(false);
                }}
              />
            </IconArea>

            <BoxScroll>
              <Schedule
                professional={user}
                values={values}
                setValues={setValues}
                setFieldValue={setFieldValue}
              />

              {!values?.monthlySubscription && (
                <Fragment>
                  <Hr style={{ width: '100%' }} />
                  <ButtonContainer
                    style={{
                      width: '100%',
                      marginTop: '0.5rem',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <ScheduleButton
                      onClick={() => {
                        setModalSchedule(false);
                        setModalTime(true);
                      }}
                      style={{ width: '6rem' }}
                    >
                      Voltar
                    </ScheduleButton>

                    <ScheduleButton
                      disabled={!values?.startTime || !values?.appointmentType}
                      onClick={handleSubmit}
                      style={{ width: '12rem' }}
                    >
                      Confirmar Consulta
                    </ScheduleButton>
                  </ButtonContainer>
                </Fragment>
              )}
            </BoxScroll>
          </BoxWrapperSchedule>
        </Modal>
      )}

      {values.consultingPackageAdd && (
        <Modal open>
          <BoxWrapperSchedule>
            <IconArea style={{ padding: '1rem 1rem 0 0' }}>
              <CloseIcon
                onClick={() => {
                  setFieldValue('consultingPackageAdd', false);
                }}
              />
            </IconArea>
            <BoxScroll>
              <ConsultingPackages professional={user} setFieldValue={setFieldValue} />
            </BoxScroll>
          </BoxWrapperSchedule>
        </Modal>
      )}

      {values.monthlySubscriptionAdd && (
        <Modal open>
          <BoxWrapperSchedule>
            <IconArea style={{ padding: '1rem 1rem 0 0' }}>
              <CloseIcon
                onClick={() => {
                  setFieldValue('monthlySubscriptionAdd', false);
                }}
              />
            </IconArea>
            <BoxScroll>
              <ConsultingPlans professional={user} setFieldValue={setFieldValue} values={values} />
            </BoxScroll>
          </BoxWrapperSchedule>
        </Modal>
      )}

      {values?.confirmBuyCredit && (
        <Modal open>
          <BoxWrapperSchedule>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                marginTop: '2rem',
              }}
            >
              <WarningIcon style={{ marginRight: '0.5rem' }} />
              <BigFont
                style={{
                  fontWeight: '700',
                  fontSize: '1.5rem',
                }}
              >
                Aviso!
              </BigFont>
            </div>

            <BigFont style={{ fontWeight: '700', margin: '1.5rem 5rem' }}>
              Você já possui créditos disponíveis. Quer continuar para a compra de novos créditos?
            </BigFont>

            <ButtonContainer
              style={{
                width: '70%',
                marginTop: '0.5rem',
                marginBottom: '2rem',
                justifyContent: 'space-between',
              }}
            >
              <ScheduleButton
                type="button"
                height="3rem"
                bgColor={theme.errorRed}
                onClick={() => setFieldValue('confirmBuyCredit', false)}
                style={{ width: '15rem' }}
              >
                Cancelar
              </ScheduleButton>
              <ScheduleButton
                type="button"
                height="3rem"
                onClick={() => {
                  setFieldValue('confirmBuyCredit', false);
                  setFieldValue('consultingPackageAdd', true);
                }}
                style={{ width: '15rem' }}
              >
                Continuar
              </ScheduleButton>
            </ButtonContainer>
          </BoxWrapperSchedule>
        </Modal>
      )}

      {modalAlert && (
        <Modal open>
          <BoxWrapperSchedule>
            <IconArea style={{ padding: '1rem 1rem 0 0' }}>
              <CloseIcon onClick={() => setModalAlert(false)} />
            </IconArea>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <WarningIcon style={{ marginRight: '0.5rem' }} />
              <BigFont
                style={{
                  fontWeight: '700',
                  fontSize: '1.5rem',
                }}
              >
                Aviso!
              </BigFont>
            </div>
            <Hr />
            <ButtonContainer
              style={{
                width: '100%',
                marginTop: '0.5rem',
                marginBottom: '2rem',
                justifyContent: 'center',
              }}
            >
              <ScheduleButton style={{ width: '15rem' }} onClick={() => {}}>
                Teste
              </ScheduleButton>
            </ButtonContainer>
          </BoxWrapperSchedule>
        </Modal>
      )}
    </Fragment>
  );
}
