import styled from 'styled-components';
import {
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
  BigFont,
  SmallFont,
} from '../../styles/globalStyles';

export const Main = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ContainerPlans = styled.div`
  align-self: center;
  background-color: ${({ theme, active }) => (active ? theme.sunYellow : theme.softBackground)};
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  height: 20rem;
  padding: 1rem 0;
  width: 100%;
`;

export const UserImage = styled.div`
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${({ search }) => (search ? '1rem' : '50%')};
  cursor: pointer;
  height: ${({ size }) => (size === 'large' ? '150px' : '100px')};
  overflow: hidden;
  width: ${({ size }) => (size === 'large' ? '150px' : '100px')};
`;

export const ColumnItem = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    align-items: center;
  }
`;

export const BFontBold = styled(BigFont)`
  color: ${({ theme, active, secondary }) =>
    !secondary ? theme.primary : active ? theme.white : theme.sunYellow};
  font-size: 1.5rem;
  font-weight: 700;
  max-width: 300px;
`;

export const FontPlanPrice = styled(BigFont)`
  color: ${({ theme, active, secondary }) =>
    !secondary ? theme.primary : active ? theme.white : theme.sunYellow};
  font-size: 2.5rem;
  font-weight: 700;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    font-size: 2rem;
  }
`;

export const SFontActive = styled.p`
  font-size: 1.2rem;
  font-weight: 700;
  color: ${({ theme, active, secondary }) =>
    !secondary ? theme.primary : active ? theme.white : theme.sunYellow};
`;

export const MdFontBold = styled(BFontBold)`
  font-size: 1.2rem;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    font-size: 1rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    font-size: 0.8rem;
  }
`;

export const SFontDark = styled(SmallFont)`
  color: ${({ theme, active }) => (active ? theme.white : theme.darkNavy)};
  font-weight: ${({ date }) => (date ? '900' : 'normal')};
  font-size: ${({ date }) => date && '0.7rem'};
  max-width: 25rem;
  overflow-y: hidden;
  white-space: pre-line;
  word-wrap: break-word;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    font-size: 1rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    font-size: 0.8rem;
    padding: 0 1rem;
  }
`;

export const Button = styled.button`
  background-color: ${({ theme, active }) => (active ? theme.primary : theme.white)};
  border-radius: 2rem;
  border: 1px solid ${({ theme }) => theme.primary};
  color: ${({ theme, active }) => (active ? theme.white : theme.primary)};
  cursor: pointer;
  font-weight: 700;
  height: 2.5rem;
  width: 80%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    background-color: ${({ theme, active }) => (active ? theme.primary : theme.neutralGray)};
    color: ${({ theme, active }) => (active ? theme.white : theme.secondary)};
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    padding: 0 1rem;
  }
`;

export const ButtonParceled = styled(Button)`
  background-color: ${({ theme, active }) => (active ? theme.neutralGray : theme.sunYellow)};
  border: 1px solid ${({ theme }) => theme.sunYellow};
  color: ${({ theme, active }) => (active ? theme.darkNavy : theme.white)};

  &:hover {
    background-color: ${({ theme, active }) => (active ? theme.lightGray : theme.sunYellow)};
    color: ${({ theme, active }) => (active ? theme.darkNavy : theme.white)};
  }
`;

export const ButtonCancel = styled(Button)`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.errorRed};
  color: ${({ theme }) => theme.errorRed};

  &:hover {
    background-color: ${({ theme }) => theme.errorRed};
    color: ${({ theme }) => theme.white};
  }
`;

export const ButtonActiveDiv = styled.div`
  align-items: center;
  background-color: ${({ theme, active }) => (active ? theme.primary : theme.white)};
  border-radius: 2rem;
  border: 1px solid ${({ theme }) => theme.primary};
  color: ${({ theme, active }) => (active ? theme.white : theme.primary)};
  display: flex;
  font-weight: 700;
  height: 2.5rem;
  justify-content: center;
  width: 80%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    padding: 0 1rem;
  }
`;

export const PlanDetails = styled.div`
  align-items: center;
  background-color: ${({ theme, active }) =>
    active === 'true' ? theme.primary : theme.softBackground};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  height: 12rem;
  justify-content: center;
  margin-left: 1rem;
  padding: 1rem 0;
  width: 40%;
`;

export const PlanSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem 0;
`;

export const PlansParceledContainer = styled.button`
  align-items: center;
  background-color: ${({ theme, selected }) => (selected ? theme.secondary : 'transparent')};
  border: 1px solid ${({ theme }) => theme.secondary};
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 0.25rem 0.5rem;
  width: 100%;

  &:hover {
    background-color: ${({ theme, selected }) => (selected ? theme.secondary : theme.neutralGray)};
  }
`;

export const PlansParceledWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const PlansParceledDescription = styled(SFontDark)`
  color: ${({ theme, selected }) => (selected ? theme.white : theme.secondary)};
  font-size: 0.9rem;
`;

export const PlansParceledSpan1 = styled.span`
  color: ${({ theme, selected }) => (selected ? theme.white : theme.secondary)};
  font-size: 0.9rem;
  font-weight: 700;
  margin-left: 1rem;
`;

export const PlansParceledSpan2 = styled(PlansParceledSpan1)`
  color: ${({ theme, selected }) => (selected ? theme.white : theme.primary)};
  font-size: 1rem;
`;
