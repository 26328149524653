import { Box } from '@mui/material';
import { AlertTriangle, X } from 'react-feather';
import styled from 'styled-components';
import { SmallButton } from '../../../components/Button';
import { AnimationWrapper, BREAKPOINT_MD_PX, BREAKPOINT_SM_PX } from '../../../styles/globalStyles';

export const Main = styled(AnimationWrapper)`
  align-items: flex-start;
  display: grid;
  gap: 3rem;
  grid-template-columns: 1.5fr 1fr;
  justify-items: center;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    justify-content: center;
    row-gap: 1rem;
  }
`;

export const ListContainer = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 90%;
  }
`;

export const BoxWrapper = styled(Box)`
  align-items: center;
  background-color: #f4f5fa;
  border-radius: 1rem;
  border: 1px solid #bcc1e0;
  box-shadow: 24;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  max-height: 70%;
  max-width: 60%;
  padding: 1rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 960px;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    max-width: 90%;
  }
`;

export const BoxWrapperSchedule = styled(BoxWrapper)`
  padding: 0;
  padding-bottom: 1rem;
`;

export const IconArea = styled.div`
  display: flex;
  width: 95%;
  justify-content: flex-end;
`;

export const CloseIcon = styled(X)`
  cursor: pointer;
  height: 2rem;
  width: 2rem;
`;

export const WarningIcon = styled(AlertTriangle)`
  height: 2rem;
  width: 2rem;
  color: ${({ theme }) => theme.primary};
`;

export const ButtonWrapper = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.neutralGray};
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.neutralGray};
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
  color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 1rem;
  padding: 0.5rem;
  width: 100%;
`;

export const ButtonMenu = styled(SmallButton)`
  background-color: ${({ theme, active }) => (active ? theme.primary : theme.neutralGray)};
  color: ${({ theme, active }) => (active ? theme.white : theme.primary)};
  height: 2rem;
  width: 15rem;

  &:disabled {
    cursor: not-allowed;
  }
`;
