import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`;

export const CheckboxWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const CheckboxInput = styled.input`
  cursor: pointer;
  height: 20px;
  width: 20px;
`;

export const CheckboxLabel = styled.label`
  cursor: pointer;
  font-size: 1rem;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.darkNav};
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;
