/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@material-ui/core';
import { Box, Modal } from '@mui/material';
import React, { Fragment, useCallback, useMemo } from 'react';
import useAuth from '../../hooks/auth';
import useEvent from '../../hooks/event';
import defaultAvatar from '../../images/noAvatar.png';
import { PLANSNAMES } from '../../services/constants';
import { BoxScroll, ModalBoxWrapper } from '../../styles/globalStyles';
import theme from '../../theme';
import convertCurrencyPtBr from '../../utils/convertCurrencyPtBr';
import getPlanName, {
  PLAN_GOLD_12X,
  PLAN_GOLD_1X,
  PLAN_GOLD_3X,
  PLAN_GOLD_6X,
  PLAN_SILVER_12X,
  PLAN_SILVER_1X,
  PLAN_SILVER_3X,
  PLAN_SILVER_6X,
} from '../../utils/getPlanName';
import {
  BFontBold,
  Button,
  ButtonActiveDiv,
  ButtonCancel,
  ButtonParceled,
  ColumnItem,
  ContainerPlans,
  FontPlanPrice,
  Main,
  MdFontBold,
  PlanDetails,
  PlanSelectContainer,
  PlansParceledContainer,
  PlansParceledDescription,
  PlansParceledSpan1,
  PlansParceledSpan2,
  PlansParceledWrapper,
  SFontDark,
  UserImage,
} from './styles';

export function handleUserNames(user) {
  const social = user?.socialName || user?.social_name;
  const firstName = user?.firstName || user?.first_name;
  const lastName = user?.lastName || user?.last_name;
  const fullName = `${firstName} ${lastName?.split(' ')[lastName?.split(' ')?.length - 1]}`;
  return social || fullName;
}

export function CardsPlans({ ...props }) {
  const { user } = useAuth();
  const { infoCardCredit } = useEvent();
  const {
    active,
    isSubscription,
    message,
    handleModal,
    planName,
    planPrice,
    setFieldValue,
    submitPlan,
    tPlanName,
    values,
  } = props;
  const setActive = values === planName && active;

  return (
    <ContainerPlans>
      <ColumnItem>
        <BFontBold>
          {tPlanName === 'Grátis' ? 'Plano gratuito' : `Individual ${tPlanName}`}
        </BFontBold>
        <SFontDark>{message}</SFontDark>

        <FontPlanPrice>{convertCurrencyPtBr(planPrice)}</FontPlanPrice>

        {!active ? (
          <Button
            disabled={isSubscription ? true : setActive}
            active={active}
            name={tPlanName}
            value={planName}
            onClick={({ target }) => {
              submitPlan && setFieldValue(planName, infoCardCredit);
              setFieldValue('planName', target.value);
              handleModal(user?.subscription?.pagarmePlan);
            }}
          >
            Assinar Plano
          </Button>
        ) : (
          <ButtonActiveDiv active={active}>Plano Ativo</ButtonActiveDiv>
        )}
      </ColumnItem>
    </ContainerPlans>
  );
}

export function UserSubscribe({ ...props }) {
  const { form, isSubscription, handleModal, plans, submitPlan } = props;
  const { user } = useAuth();

  return (
    <Main>
      <CardsPlans
        active={user?.subscription?.id && user?.subscription?.pagarmePlan === plans?.planName}
        isSubscription={isSubscription}
        message={plans?.message}
        handleModal={handleModal}
        planName={plans?.planName}
        planPrice={plans?.planPrice}
        setFieldValue={form?.setFieldValue}
        submitPlan={submitPlan}
        tPlanName={getPlanName(plans?.planName)}
        values={form?.values}
      />
      {plans?.detail && (
        <PlanDetails>
          <MdFontBold>Valor por profissional</MdFontBold>
          <MdFontBold>{convertCurrencyPtBr(plans?.detail)}</MdFontBold>
        </PlanDetails>
      )}
    </Main>
  );
}

function PlansParceled({ form }) {
  const { values, setFieldValue } = form;

  function PlanDescription({ value, label, total }) {
    return (
      <PlansParceledContainer
        key={label}
        onClick={() => {
          setFieldValue('planName', label);
          setFieldValue('planPrice', value);
          setFieldValue('planTotalPrice', total);
          setFieldValue('planParceled', label.split('_').pop());
        }}
        selected={label === values?.planName}
      >
        <PlansParceledWrapper>
          <PlansParceledDescription selected={label === values?.planName}>
            {getPlanName(label)}
            <PlansParceledSpan1 selected={label === values?.planName}>
              {convertCurrencyPtBr(value)}
            </PlansParceledSpan1>
          </PlansParceledDescription>

          {total && (
            <PlansParceledSpan2 selected={label === values?.planName}>
              Total {convertCurrencyPtBr(total)}
            </PlansParceledSpan2>
          )}
        </PlansParceledWrapper>
      </PlansParceledContainer>
    );
  }

  const options = useMemo(() => {
    const optionsFull = [
      {
        value: 'silver_annual_1x',
        label: PlanDescription({
          label: 'silver_annual_1x',
          value: PLAN_SILVER_1X,
          total: PLAN_SILVER_1X,
        }),
      },
      {
        value: 'silver_annual_3x',
        label: PlanDescription({
          label: 'silver_annual_3x',
          value: PLAN_SILVER_3X,
          total: PLAN_SILVER_1X,
        }),
      },
      {
        value: 'silver_annual_6x',
        label: PlanDescription({
          label: 'silver_annual_6x',
          value: PLAN_SILVER_6X,
          total: PLAN_SILVER_1X,
        }),
      },
      {
        value: 'silver_annual_12x',
        label: PlanDescription({
          label: 'silver_annual_12x',
          value: PLAN_SILVER_12X,
          total: PLAN_SILVER_1X,
        }),
      },
      {
        value: 'gold_annual_1x',
        label: PlanDescription({
          label: 'gold_annual_1x',
          value: PLAN_GOLD_1X,
          total: PLAN_GOLD_1X,
        }),
      },
      {
        value: 'gold_annual_3x',
        label: PlanDescription({
          label: 'gold_annual_3x',
          value: PLAN_GOLD_3X,
          total: PLAN_GOLD_1X,
        }),
      },
      {
        value: 'gold_annual_6x',
        label: PlanDescription({
          label: 'gold_annual_6x',
          value: PLAN_GOLD_6X,
          total: PLAN_GOLD_1X,
        }),
      },
      {
        value: 'gold_annual_12x',
        label: PlanDescription({
          label: 'gold_annual_12x',
          value: PLAN_GOLD_12X,
          total: PLAN_GOLD_1X,
        }),
      },
    ];
    if (values?.selectedPlanType?.includes('silver')) {
      return optionsFull?.filter((option) => option.value.includes('silver'));
    } else if (values?.selectedPlanType?.includes('gold')) {
      return optionsFull?.filter((option) => option.value.includes('gold'));
    }
  }, [values]);

  return <PlanSelectContainer>{options.map((option) => option.label)}</PlanSelectContainer>;
}

export function CardPlansParceled({ ...props }) {
  const { form, plans, resetForm, handleModal } = props;
  const { setFieldValue, values } = form;
  const { user } = useAuth();
  const { creditCards } = useEvent();

  const creditCardId = useCallback(() => {
    const creditCardDefault = creditCards?.find((item) => item.attributes.default === true);
    setFieldValue('creditCardId', creditCardDefault?.id);
  }, [creditCards]);

  const active =
    user?.subscription?.id &&
    PLANSNAMES.includes(user?.subscription?.pagarmePlan) &&
    user?.subscription?.pagarmePlan?.includes(plans?.planName);

  return (
    <ContainerPlans active={active}>
      <ColumnItem>
        <BFontBold active={active} secondary>
          Parcelado {getPlanName(plans?.planName)}
          {active && ' - Ativo'}
        </BFontBold>
        <SFontDark active={active} secondary>
          {plans?.message}
        </SFontDark>

        <ButtonParceled
          active={active}
          onClick={() => {
            setFieldValue('viewPlans', true);
            setFieldValue(
              'planTitle',
              values?.planName?.includes('annual') ? values?.planName : plans?.planName
            );
            setFieldValue('selectedPlanType', plans?.planName);
            creditCardId();
          }}
        >
          Ver todos os métodos de parcelamento
        </ButtonParceled>

        {values?.planName?.includes(plans?.planName) && (
          <Fragment>
            {values?.planPrice && values?.planParceled && (
              <FontPlanPrice active={active} secondary>
                {`${values?.planParceled} ${convertCurrencyPtBr(values?.planPrice)}`}
              </FontPlanPrice>
            )}
            {values?.planTotalPrice && (
              <BFontBold active={active} secondary>
                {`Total ${convertCurrencyPtBr(values?.planTotalPrice)}`}
              </BFontBold>
            )}
          </Fragment>
        )}
      </ColumnItem>

      {values?.viewPlans && values?.selectedPlanType === plans?.planName && (
        <Modal open>
          <ModalBoxWrapper>
            <BoxScroll>
              <Typography
                variant="p"
                sx={{
                  color: theme.secondary,
                  fontWeight: 700,
                  fontSize: '1.7rem',
                  textAlign: 'center',
                }}
              >
                Parcelado {getPlanName(plans?.planName)}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  color: theme.secondary,
                  fontWeight: 700,
                  fontSize: '1.1rem',
                }}
              >
                Para seguir com a assinatura, selecione o plano desejado:
              </Typography>

              <PlansParceled form={form} />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '1rem',
                }}
              >
                <ButtonCancel
                  type="button"
                  onClick={() => {
                    setFieldValue('viewPlans', false);
                    resetForm();
                  }}
                  style={{
                    width: '8rem',
                  }}
                >
                  Voltar
                </ButtonCancel>
                <Button
                  onClick={() => handleModal(user?.subscription?.pagarmePlan)}
                  type="button"
                  style={{
                    width: '8rem',
                  }}
                >
                  Assinar
                </Button>
              </Box>
            </BoxScroll>
          </ModalBoxWrapper>
        </Modal>
      )}
    </ContainerPlans>
  );
}

export function UserAvatar({ src, size, search }) {
  return <UserImage src={src || defaultAvatar} size={size} search={search} />;
}
