import styled from 'styled-components';
import { SmallButton } from '../../../components/Button';
import { BREAKPOINT_SM_PX, MediumBigFont, SmallFont } from '../../../styles/globalStyles';

export const MainContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
  width: 70%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
`;

export const RowItem = styled.section`
  width: 100%;
  display: flex;
  padding-bottom: 30px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const RowLine = styled.section`
  width: 100%;
  display: flex;
  padding-bottom: 10px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerItem = styled.section`
  flex: 1;
  display: flex;

  align-self: flex-end;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerItemLeft = styled.section`
  flex: 1;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const PayOutContainer = styled.div`
  display: flex;
  padding: 0 5px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    align-items: center;
    justify-content: center;
  }
`;

export const SMFont = styled(SmallFont)`
  color: ${({ theme }) => theme.neutralGray};
`;

export const MDFont = styled(MediumBigFont)`
  font-weight: 400;
  padding: 5px 0;
  color: ${({ theme }) => theme.secondary};
`;

export const MDFontBlue = styled(MDFont)`
  color: ${({ theme }) => theme.aquaBlue};
`;

export const Button = styled(SmallButton)`
  width: 100%;
  height: 35px;
`;
