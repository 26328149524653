import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Certifique-se de importar o CSS padrão do DatePicker
import styled from 'styled-components';

export const MainContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
  width: 100%;

  .react-datepicker__input-container input {
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.neutralGray};
    box-sizing: border-box; /* Inclui padding e border na largura total */
    font-size: 16px;
    outline: none;
    padding: 8px;
    width: 100%; /* Defina uma largura fixa */
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.white};
  }

  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};
  }
`;

export const CustomDatePicker = styled(DatePicker)`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.neutralGray};
  color: ${({ theme }) => theme.darkNavy};
  display: flex;
  flex-direction: column;
  font-size: 16px;
  justify-content: center;
  padding: 5px 0;
`;
