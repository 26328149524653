import { Box } from '@material-ui/core';
import { AlertTriangle, X } from 'react-feather';
import styled from 'styled-components';
import {
  AnimationWrapper,
  BigFont,
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
} from '../../../styles/globalStyles';

export const Main = styled(AnimationWrapper)`
  align-items: flex-start;
  display: grid;
  gap: 3rem;
  grid-template-columns: 1.5fr 1fr;
  justify-items: center;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    justify-content: center;
    row-gap: 1rem;
  }
`;

export const ListContainer = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 90%;
  }
`;

export const FormWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.neutralGray};
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
  color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 1rem;
  width: 100%;
`;

export const ButtonWrapperTop = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.neutralGray};
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
  color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 1rem;
  width: 100%;
`;

export const ButtonTop = styled.button`
  align-items: center;
  background-color: ${({ theme, active }) => (active ? theme.darkNavy : theme.neutralGray)};
  border-radius: 2.5rem;
  border: none;
  color: ${({ theme, active }) => (active ? theme.white : theme.darkNavy)};
  display: flex;
  font-weight: 600;
  height: 2rem;
  justify-content: center;
  padding: 0.625rem 1rem;
  width: 12rem;

  &:hover {
    background-color: ${({ theme }) => theme.darkNavy};
    color: ${({ theme }) => theme.white};
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    padding: 0.625rem 0.5rem;
    max-width: 10rem;
  }
`;

export const BoxWrapper = styled(Box)`
  align-items: center;
  background-color: #f4f5fa;
  border-radius: 1rem;
  border: 1px solid #bcc1e0;
  box-shadow: 24;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  max-height: 70%;
  max-width: 60%;
  padding: 1rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 960px;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    max-width: 90%;
  }
`;

export const BoxWrapperSchedule = styled(BoxWrapper)`
  padding: 0;
  padding-bottom: 1rem;
`;

export const ButtonContainer = styled.section`
  flex: 1;
  width: 90%;
  display: flex;
  padding-top: 15px;
`;

export const ScheduleButton = styled.button`
  align-items: center;
  background-color: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.primary)};
  border-radius: 2rem;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  height: 3rem;
  justify-content: center;
  width: 100%;

  &:disabled {
    opacity: 0.5;
    cursor: default;
    &:hover {
      background-color: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.primary)};
      color: ${({ theme }) => theme.white};
      border: none;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.white};
    color: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.primary)};
    border: 1px solid ${({ theme, bgColor }) => (bgColor ? bgColor : theme.primary)};
  }
`;

export const IconArea = styled.div`
  display: flex;
  width: 95%;
  justify-content: flex-end;
`;

export const CloseIcon = styled(X)`
  cursor: pointer;
  height: 2rem;
  width: 2rem;
`;

export const WarningIcon = styled(AlertTriangle)`
  height: 2rem;
  width: 2rem;
  color: ${({ theme }) => theme.primary};
`;

export const CalendarWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  width: 100%;
`;

export const BigFontModal = styled(BigFont)`
  width: 80%;
`;
