/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoaderSpinner from '../components/LoaderSpinner';
import BannerClincDefault from '../images/banner-clinic.png';
import LogoClinicDefault from '../images/logo-default-clinic.png';
import ProfilePersonDefault from '../images/noAvatar.png';
import api from '../services/api';
import theme from '../theme';
export const AuthContext = createContext();

// Checking if the user is sending a new base64 or it was just the amazon url.
const isBase64 = (avatar) => {
  const arr = avatar.split('data:image/');
  return arr?.length > 1 ? true : false;
};

const isValidImage = (image) => {
  if (image) {
    if (isBase64(image)) {
      return { data: image };
    }
    return undefined;
  }
  return undefined;
};

function convertImportedImageToBase64(imagePath, callback) {
  fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.onerror = function (error) {
        console.error('Error converting imported image to Base64:', error);
      };
      reader.readAsDataURL(blob);
    })
    .catch((error) => console.error('Error fetching the image:', error));
}

export function AuthProvider({ children }) {
  const history = useHistory();
  const [authData, setAuthData] = useState({});
  const [publicProfile, setPublicProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [LogoClinicDefaultBase64, setLogoClinicDefaultBase64] = useState('');
  const [BannerClinicDefaultBase64, setBannerClinicDefaultBase64] = useState('');
  const [ProfilePersonDefaultBase64, setProfilePersonDefaultBase64] = useState('');

  convertImportedImageToBase64(LogoClinicDefault, function (base64Image) {
    setLogoClinicDefaultBase64(base64Image);
  });

  convertImportedImageToBase64(BannerClincDefault, function (base64Image) {
    setBannerClinicDefaultBase64(base64Image);
  });

  convertImportedImageToBase64(ProfilePersonDefault, function (base64Image) {
    setProfilePersonDefaultBase64(base64Image);
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userStorage = localStorage.getItem('user');
    const publicUserStorage = localStorage.getItem('publicUser');
    if (publicUserStorage) {
      setPublicProfile(JSON.parse(publicUserStorage));
    }

    if (userStorage) {
      setAuthData(JSON.parse(userStorage));
    }
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    setLoading(false);
  }, []);

  function setGlobalUser(data) {
    const authToken = localStorage.getItem('token');
    const user = {
      ...data.attributes,
      id: data.id,
      type: data.type,
      firstName: data.type === 'clinic' ? data.attributes?.name : data.attributes?.firstName,
    };

    localStorage.setItem('type', user.type);
    localStorage.setItem('token', authToken || user.authToken);
    localStorage.setItem('user', JSON.stringify(user));
    setAuthData(user);
  }

  async function signIn({ email, password, type }) {
    try {
      const { data } = await api.post(`/authenticate-${type}`, {
        email: email.toLowerCase(),
        password: password,
      });
      const user = { ...data.data.attributes, id: data.data.id, type: type };
      if (user.authToken) {
        // Setting the token for the api requests
        api.defaults.headers.Authorization = `Bearer ${user.authToken}`;
        setGlobalUser(data.data);
        if (user?.type === 'professional') {
          if (!user?.subscription?.id && user?.trialing) {
            toast.warning(
              `Atenção! Você tem até ${moment(user?.expirationDate).format('D [de] MMMM [de] YYYY')} para utilizar nossa plataforma de forma gratuita. Após essa data será necessário realizar a assinatura de um de nossos planos.`,
              { autoClose: false }
            );
          }
          if (
            !user?.trialing &&
            user?.trialNoticeSeen === false &&
            user?.trialDaysLeft &&
            !user?.subscription
          ) {
            toast.warning(`Seu período de teste expira em ${user?.trialDaysLeft} dias.`, {
              autoClose: false,
            });
          }
          if (user?.status === 'pending') {
            toast.warning(
              <div>
                Atenção! Para utilizar a plataforma e aparecer nas buscas de clientes, você precisa
                completar seu perfil.
                <button
                  onClick={() => history.push('/professional/config')}
                  style={{
                    background: theme.primary,
                    border: 'none',
                    borderRadius: '0.5rem',
                    color: theme.white,
                    cursor: 'pointer',
                    marginLeft: '1rem',
                    padding: '0.25rem 1rem',
                  }}
                >
                  Completar perfil
                </button>
              </div>,
              { autoClose: false }
            );
          }
        }
      } else {
        throw new Error('Token not found');
      }
      return user;
    } catch (error) {
      if (error.response) {
        if (error.response.data.error.user_authentication === 'User approval pending') {
          toast.error('Usuário ainda está pendente de aprovação.');
        } else if (error.response.data.error.user_authentication === 'invalid credentials') {
          toast.error('Credenciais inválidas');
        } else {
          toast.error('Um erro inesperado ocorreu');
        }
      } else {
        toast.error('Um erro inesperado ocorreu');
      }
      return false;
    }
  }

  async function signOut() {
    api.defaults.headers.Authorization = null;

    setAuthData('');
    localStorage.clear();
  }

  async function signUpProfessional(formValues) {
    try {
      const request = {
        data: {
          type: 'professional',
          attributes: {
            birthday: formValues.birthday,
            clinicId: formValues.clinicId,
            documentNumber: formValues.documentNumber,
            email: formValues.email,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            password: formValues.password,
            phone: formValues.phone,
            socialName: formValues.socialName,
            termId: formValues.termId,
            addressAttributes: {
              cep: formValues.cep,
              street: formValues.street,
              neighborhood: formValues.neighborhood,
              country: formValues.country,
              city: formValues.city,
              complement: formValues.complement,
              street_number: formValues.street_number,
              state: formValues.state,
            },
            subscriptionAttributes: {
              planName: formValues.planName,
              demoAccessCode: formValues.demoAccessCode,
              creditCardAttributes: {
                cardNumber: formValues.cardNumber,
                cardExpiration_date: formValues.cardExpiration_date?.replace('/', ''),
                cardHolderName: formValues.cardHolderName,
                cardCvv: formValues.cardCvv,
                default: true,
              },
            },
          },
        },
      };

      if (formValues.planName && formValues.planName !== 'trial') {
        delete request.data.attributes.subscriptionAttributes.demoAccessCode;
      } else if (!formValues.demoAccessCode) {
        delete request.data.attributes.subscriptionAttributes;
        delete request.data.attributes.addressAttributes;
        delete request.data.attributes.documentNumber;
      } else if (formValues.demoAccessCode) {
        delete request.data.attributes.subscriptionAttributes.creditCardAttributes;
        delete request.data.attributes.addressAttributes;
      }
      if (!formValues.clinicId) {
        delete request.data.attributes.clinicId;
      }
      const { data } = await api.post('/professionals/sign_up', request);
      if (data.data) {
        const user = {
          ...data.data.attributes,
          id: data.data.id,
          type: data.data.type,
        };
        setPublicProfile(user);
        return true;
      } else {
        throw new Error(JSON.stringify(data.error));
      }
    } catch (error) {
      if (error.response) {
        error.response.data.errors.forEach((er) => {
          if (er.title === 'Email já está em uso') {
            toast.error(er.title);
          }
        });
      }

      return false;
    }
  }

  async function updateProfessional(formValues, professionalId) {
    try {
      const request = {
        data: {
          type: 'professional',
          attributes: {
            termId: formValues.termId || authData?.term?.id,
            clinicId: formValues.clinicId || null,
            trialNoticeSeen: formValues.trialNoticeSeen,
            welcome: formValues.welcome,
          },
        },
      };
      if (!formValues.clinicId) {
        delete request.data.attributes.clinicId;
        delete request.data.attributes.acceptPix;
        delete request.data.attributes.acceptPix;
      }
      if (authData?.documentNumber) {
        delete request.data.attributes.documentNumber;
      }
      if (!authData?.address?.id) {
        delete request.data.attributes.addressAttributes;
      }
      if (!formValues?.subscriptionAttributes?.creditCardAttributes?.cardNumber) {
        delete request.data.attributes.subscriptionAttributes;
      }
      const { data } = await api.put(`/professionals/${professionalId}`, request);
      if (data.data) {
        setGlobalUser(data.data);
      } else {
        throw new Error(JSON.stringify(data.error));
      }

      return true;
    } catch (error) {
      toast.error('Não foi possível completar seu registro');
      return false;
    }
  }

  async function professionalUpdateBasicData(formValues, professionalId) {
    try {
      const request = {
        data: {
          type: 'professional',
          attributes: {
            avatar: isValidImage(formValues.avatar),
            documentNumber: formValues.documentNumber,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            socialName: formValues.hasSocialName ? formValues.socialName : null,
            phone: formValues.phone,
            birthday: formValues.birthday,
            termId: authData?.term?.id,
          },
        },
      };
      const { data } = await api.put(`/professionals/${professionalId}`, request);
      if (data.data) {
        setGlobalUser(data.data);
      } else {
        throw new Error(JSON.stringify(data.error));
      }
      return true;
    } catch (error) {
      if (error.response) {
        error.response.data.errors.forEach((er) => {
          if (er.title === 'Document number já está em uso') {
            toast.error('Este CPF já está em uso! Forneça outro CPF.');
            return false;
          } else {
            toast.error(er.title);
            return false;
          }
        });
      } else {
        toast.error('Não foi possível completar seu registro');
        return false;
      }
    }
  }

  async function professionalUpdateAddress(formValues, professionalId) {
    try {
      const request = {
        data: {
          type: 'professional',
          attributes: {
            documentNumber: formValues.documentNumber,
            addressAttributes: {
              id: authData.address.id || null,
              cep: formValues.cep,
              street: formValues.street,
              neighborhood: formValues.neighborhood,
              country: formValues.country,
              city: formValues.city,
              complement: formValues.complement,
              street_number: formValues.street_number,
              state: formValues.state,
            },
            termId: authData?.term?.id,
          },
        },
      };
      if (authData?.documentNumber) {
        delete request.data.attributes.documentNumber;
      }
      if (!authData?.address?.id) {
        delete request.data.attributes.addressAttributes.id;
      }
      const { data } = await api.put(`/professionals/${professionalId}`, request);
      if (data.data) {
        setGlobalUser(data.data);
      } else {
        throw new Error(JSON.stringify(data.error));
      }

      return true;
    } catch (error) {
      if (error.response) {
        error.response.data.errors.forEach((er) => {
          if (er.title === 'CEP inválido') {
            toast.error('CEP inválido');
            return false;
          }
        });
      } else {
        toast.error('Não foi possível completar seu registro');
        return false;
      }
    }
  }

  async function professionalUpdateProfessionalInfos(formValues, professionalId) {
    try {
      const request = {
        data: {
          type: 'professional',
          attributes: {
            profession: formValues.profession,
            professionalDocumentNumber: formValues.professionalDocumentNumber,
            academicBackground: formValues.academicBackground,
            specializations: formValues.specializations,
            approaches: formValues.approaches,
            bio: formValues.bio,
            ageGroups: formValues.ageGroups,
            yearsOfExperience: formValues.yearsOfExperience,
            presentationVideo: formValues.presentationVideo,
            sessionPrice: formValues.sessionPrice,
            termId: authData?.term?.id,
            ownMeetingLink: formValues.newPreferOwnMeetingLink ? formValues.ownMeetingLink : null,
            preferOwnMeetingLink: formValues.newPreferOwnMeetingLink,
            professionalDocumentFile: formValues.professionalDocumentFile,
            professionalEducationDocumentFile: formValues.professionalEducationDocument,
          },
        },
      };
      const { data } = await api.put(`/professionals/${professionalId}`, request);

      if (data.data) {
        setGlobalUser(data.data);
      } else {
        throw new Error(JSON.stringify(data.error));
      }

      return true;
    } catch (error) {
      if (error.response) {
        error.response.data.errors.forEach((er) => {
          toast.error(er.title);
          return false;
        });
      } else {
        toast.error('Não foi possível completar seu registro');
        return false;
      }
    }
  }

  async function professionalUpdateBankAccount(formValues) {
    try {
      const request = {
        data: {
          type: 'payment_accounts',
          attributes: {
            documentNumber: formValues?.paymentDocumentNumber,
            name: formValues?.paymentName,
            bankAg: formValues?.bankAg,
            accountType: formValues?.accountType,
            bankCc: formValues?.bankCc,
            bankCode: formValues?.bankCode,
            agenciaDv: formValues?.agenciaDv,
            contaDv: formValues?.contaDv,
          },
        },
      };
      let data = {};
      if (authData?.paymentAccount?.id) {
        const { data: response } = await api.put(
          `/professionals/payment_accounts/${authData?.paymentAccount?.id}`,
          request
        );
        data = response;
      } else {
        const { data: response } = await api.post('/professionals/payment_accounts', request);
        data = response;
      }
      if (data.data) {
        const user = {
          id: authData.id,
          type: authData.type,
          attributes: {
            ...authData,
            paymentAccount: data.data.attributes,
          },
        };
        delete user.attributes.id;
        delete user.attributes.type;
        setGlobalUser(user);
      } else {
        throw new Error(JSON.stringify(data.error));
      }

      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error);
      } else {
        toast.error('Falha ao atualizar conta bancária.');
      }
    }
  }

  async function professionalUpdateAvailability(formValues, professionalId) {
    try {
      const request = {
        data: {
          type: 'professional',
          attributes: {
            availabilityRuleAttributes: {
              ...authData.availabilityRule,
              appointmentDuration: formValues.appointmentDuration,
              appointmentTimeBlocks: formValues.appointmentTimeBlocks,
              blockedTimes: formValues.blockedTimes,
              appointmentTypes: formValues.appointmentTypes,
              workdays: formValues.workdays,
              breakTime: formValues.breakTime,
            },
          },
        },
      };
      const { data } = await api.put(`/professionals/${professionalId}`, request);
      if (data.data) {
        setGlobalUser(data.data);
      } else {
        throw new Error(JSON.stringify(data.error));
      }
      return true;
    } catch (error) {
      if (error.response) {
        error.response.data.errors.forEach((er) => {
          toast.error(er.title);
          return false;
        });
      } else {
        toast.error('Não foi possível completar seu registro');
        return false;
      }
    }
  }

  async function professionalUpdateTypesReceipts(formValues, professionalId) {
    try {
      const request = {
        data: {
          type: 'professional',
          attributes: {
            acceptCredit: formValues.acceptCredit,
            acceptPix: formValues.acceptPix,
            preferentialPayment: formValues.preferentialPayment,
            externalPayment: formValues.externalPayment,
            postPaid: formValues.postPaid,
            postPaidDay: formValues.postPaidDay,
            prePaid: formValues.prePaid,
          },
        },
      };
      const { data } = await api.put(`/professionals/${professionalId}`, request);
      if (data.data) {
        setGlobalUser(data.data);
      } else {
        throw new Error(JSON.stringify(data.error));
      }
      return true;
    } catch (error) {
      if (error.response) {
        error.response.data.errors.forEach((er) => {
          toast.error(er.title);
          return false;
        });
      } else {
        toast.error('Não foi possível completar seu registro');
        return false;
      }
    }
  }

  async function deleteProfessional(id) {
    try {
      setLoading(true);
      await api.delete(`/professionals/${id}`);
      toast.success(
        `Sua conta foi excluída com sucesso. Se quiser criar uma nova conta, sinta-se
        à vontade. Para reativar sua conta, entre em contato com nossa equipe.
        Agradecemos pela compreensão. Atenciosamente, Allminds.`,
        { autoClose: false }
      );
    } catch (error) {
      toast.error('Não foi possível excluir sua conta! Entre em contato com a nossa equipe.', {
        autoClose: false,
      });
    } finally {
      setLoading(false);
      signOut();
    }
  }

  async function signUpClient(formValues) {
    try {
      const request = {
        data: {
          type: 'client',
          attributes: {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            email: formValues.email,
            phone: formValues.phone,
            password: formValues.password,
            password_confirmation: formValues.password_confirmation,
            schoolId: formValues.schoolId || null,
            schoolType: formValues.schoolType || null,
          },
        },
      };
      const { data } = await api.post('clients/sign_up', request);
      if (data.data) {
        setGlobalUser(data.data);
        return true;
      } else {
        throw new Error(JSON.stringify(data.error));
      }
    } catch (error) {
      toast.error('Não foi possível completar seu registro');

      if (error.response) {
        error.response.data.errors.forEach((er) => {
          if (er.title === 'Email já está em uso') {
            toast.error(er.title);
          }
        });
      }

      return false;
    }
  }

  async function updateClient(formValues, id) {
    try {
      const request = {
        data: {
          type: 'client',
          attributes: {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            email: formValues.email,
            avatar: isValidImage(formValues.avatar),
            birthday: formValues.birthday,
            phone: formValues.phone,
            documentNumber: formValues.documentNumber,
            password: formValues.password,
            password_confirmation: formValues.password_confirmation,
            needLegalGuardian: formValues.needLegalGuardian,
            legalGuardianFullName: formValues.legalGuardianFullName,
            legalGuardianEmail: formValues.legalGuardianEmail,
            legalGuardianDocumentNumber: formValues.legalGuardianDocumentNumber,
            legalGuardianBirthday: formValues.legalGuardianBirthday,
            legalGuardianPhone: formValues.legalGuardianPhone,
            addressAttributes: {
              id: authData.address.id,
              cep: formValues.cep,
              street: formValues.street,
              neighborhood: formValues.neighborhood,
              country: formValues.country,
              city: formValues.city,
              state: formValues.state,
              street_number: formValues.street_number,
              complement: formValues.complement,
            },
            schoolId: formValues.schoolId || null,
            welcome: formValues.welcome,
          },
        },
      };
      if (authData.documentNumber === formValues.documentNumber)
        delete request.data.attributes.documentNumber;
      if (authData.address.id === null || !authData.address.id)
        delete request.data.attributes.addressAttributes.id;
      const { data } = await api.put(`/clients/sign_up/${id}`, request);
      if (data.data) {
        setGlobalUser(data.data);
        return true;
      } else {
        throw new Error(JSON.stringify(data.error));
      }
    } catch (error) {
      if (error.response) {
        error.response.data.errors.forEach((er) => {
          if (er.title === 'Email já está em uso') {
            toast.error(er.title);
            return false;
          }
          if (er.id.includes('document_number')) {
            toast.error('Este CPF já está em uso! Forneça outro CPF.');
            return false;
          }
        });
      } else {
        toast.error('Não foi possível completar seu registro');
        return false;
      }
    }
  }

  async function updateOnboarding(formValues, type, id) {
    try {
      const request = {
        data: {
          type: type,
          attributes: { welcome: formValues.welcome },
        },
      };
      const url = type === 'client' ? '/clients/sign_up' : '/professionals';

      const { data } = await api.put(`${url}/${id}`, request);
      if (data.data) {
        setGlobalUser(data.data);
        return true;
      } else {
        throw new Error(JSON.stringify(data.error));
      }
    } catch (error) {
      if (error.response) {
        error.response.data.errors.forEach((er) => {
          if (er.title === 'Email já está em uso') {
            toast.error(er.title);
            return false;
          }
          if (er.title === 'Document number já está em uso') {
            toast.error('Este CPF já está em uso! Forneça outro CPF.');
            return false;
          }
        });
      } else {
        toast.error('Não foi possível completar seu registro');
        return false;
      }
    }
  }

  async function updateTrialNotice(formValues, id) {
    try {
      const request = {
        data: {
          type: 'professional',
          attributes: { trialNoticeSeen: formValues.trialNoticeSeen },
        },
      };
      const { data } = await api.put(`/professionals/${id}`, request);
      if (data.data) {
        setGlobalUser(data.data);
        return true;
      } else {
        throw new Error(JSON.stringify(data.error));
      }
    } catch (error) {
      if (error.response) {
        error.response.data.errors.forEach((er) => {
          if (er.title === 'Email já está em uso') {
            toast.error(er.title);
            return false;
          }
          if (er.title === 'Document number já está em uso') {
            toast.error('Este CPF já está em uso! Forneça outro CPF.');
            return false;
          }
        });
      } else {
        toast.error('Não foi possível completar seu registro');
        return false;
      }
    }
  }

  async function signUpClinic(formValues) {
    try {
      const request = {
        data: {
          type: 'clinic',
          attributes: {
            avatar: formValues.avatar
              ? { data: formValues.avatar }
              : { data: LogoClinicDefaultBase64 },
            banner: formValues.banner
              ? { data: formValues.banner }
              : { data: BannerClinicDefaultBase64 },
            name: formValues.name,
            description: formValues.description,
            email: formValues.email,
            phone: formValues.phone,
            password: formValues.password,
            password_confirmation: formValues.confirm_password,
            documentNumber: formValues.documentNumber,
            paymentAccountAttributes: {
              documentNumber: formValues.paymentDocumentNumber,
              name: formValues.paymentName,
              bankAg: formValues.bankAg,
              accountType: formValues.accountType,
              bankCc: formValues.bankCc,
              bankCode: formValues.bankCode,
              agenciaDv: formValues.agenciaDv,
              contaDv: formValues.contaDv,
            },
            addressAttributes: {
              cep: formValues.cep,
              street: formValues.street,
              neighborhood: formValues.neighborhood,
              country: formValues.country,
              city: formValues.city,
              state: formValues.state,
              street_number: formValues.street_number,
              complement: formValues.complement,
            },
            subscriptionAttributes: {
              creditCardAttributes: {
                cardNumber: formValues.cardNumber,
                cardExpiration_date: formValues.cardExpiration_date,
                cardHolderName: formValues.cardHolderName,
                cardCvv: formValues.cardCvv,
              },
            },
            responsibleName: formValues.responsibleName,
            responsibleDocumentNumber: formValues.responsibleDocumentNumber,
            responsibleAbout: formValues.responsibleAbout,
            responsibleAvatar: formValues.responsibleAvatar
              ? { data: formValues.responsibleAvatar }
              : { data: ProfilePersonDefaultBase64 },
            acceptCredit: formValues.acceptCredit,
            acceptPix: formValues.acceptPix,
            postPaid: formValues.postPaid,
            prePaid: formValues.prePaid,
            postPaidDay: formValues.postPaidDay,
            externalPayment: formValues.externalPayment,
            allmindsBenefit: false,
          },
        },
      };
      const { data } = await api.post('clinics/sign_up', request);
      if (data.data) {
        setGlobalUser(data.data);
        return true;
      } else {
        throw new Error(JSON.stringify(data.error));
      }
    } catch (error) {
      toast.error('Não foi possível completar seu registro');

      if (error.response) {
        error.response.data.errors.forEach((er) => {
          if (er.title === 'Email já está em uso') {
            toast.error(er.title);
          }
        });
      }

      return false;
    }
  }

  async function updateClinic(formValues, id) {
    try {
      const request = {
        data: {
          type: 'clinic',
          attributes: {
            avatar: isValidImage(formValues.avatar),
            banner: formValues.bannerColor ? null : isValidImage(formValues.banner),
            responsibleAvatar: isValidImage(formValues.responsibleAvatar),
            secondaryBanner: isValidImage(formValues.secondaryBanner),
            bannerColor: formValues.bannerColor,
            name: formValues.name,
            description: formValues.description,
            email: formValues.email,
            phone: formValues.phone,
            password: formValues.password,
            password_confirmation: formValues.password_confirmation,
            documentNumber: formValues.documentNumber,
            hiddenFields: formValues.hiddenFields,
            paymentAccountAttributes: {
              id: authData.paymentAccount.id,
              documentNumber: formValues.paymentDocumentNumber,
              name: formValues.paymentName,
              bankAg: formValues.bankAg,
              accountType: formValues.accountType,
              bankCc: formValues.bankCc,
              bankCode: formValues.bankCode,
              agenciaDv: formValues.agenciaDv,
              contaDv: formValues.contaDv,
            },
            addressAttributes: {
              id: authData.address.id,
              cep: formValues.cep,
              street: formValues.street,
              neighborhood: formValues.neighborhood,
              country: formValues.country,
              city: formValues.city,
              state: formValues.state,
              street_number: formValues.street_number,
              complement: formValues.complement,
            },
            responsibleName: formValues.responsibleName,
            responsibleDocumentNumber: formValues.responsibleDocumentNumber,
            responsibleAbout: formValues.responsibleAbout,
            acceptCredit: formValues.acceptCredit,
            acceptPix: formValues.acceptPix,
            postPaid: formValues.postPaid,
            prePaid: formValues.prePaid,
            postPaidDay: formValues.postPaidDay,
            externalPayment: formValues.externalPayment,
            trialNoticeSeen: formValues.trialNoticeSeen,
            allmindsBenefit: false,
          },
        },
      };
      if (authData.documentNumber === formValues.documentNumber)
        delete request.data.attributes.documentNumber;
      if (authData.paymentAccount.documentNumber === formValues.paymentDocumentNumber)
        delete request.data.attributes.paymentAccountAttributes.documentNumber;

      const { data } = await api.put(`/clinics/${id}`, request);
      if (data.data) {
        setGlobalUser(data.data);
        return true;
      } else {
        throw new Error(JSON.stringify(data.error));
      }
    } catch (error) {
      if (error.response.data.errors) {
        error.response.data.errors.forEach((er) => {
          if (er.title === 'Email já está em uso') {
            toast.error(er.title);
            return false;
          }
          if (er.id.includes('document_number')) {
            toast.error('Este CPF já está em uso! Forneça outro CPF.');
            return false;
          }
        });
      } else {
        toast.error('Não foi possível atualizar seu registro');
        return false;
      }
    }
  }

  async function forgetPassword(email, type) {
    try {
      const data = await api.post(`/${type}/auth/password`, {
        email: email,
        app: true,
      });
      if (data.data) {
        return data.data;
      }
    } catch (error) {
      return { success: false, message: 'Email não encontrado!' };
    }
  }

  async function updatePassword(formValues, type, userId) {
    const request = {
      data: {
        type: type,
        attributes: {
          password: formValues.password,
          new_password: formValues.newPassword,
        },
      },
    };

    try {
      const { data } = await api.patch(`/${type}s/${userId}/update_password`, request);
      return data;
    } catch (error) {
      return { error };
    }
  }

  if (loading) {
    return <LoaderSpinner />;
  }

  return (
    <AuthContext.Provider
      value={{
        deleteProfessional,
        forgetPassword,
        loading,
        professionalUpdateAddress,
        professionalUpdateAvailability,
        professionalUpdateBankAccount,
        professionalUpdateBasicData,
        professionalUpdateProfessionalInfos,
        professionalUpdateTypesReceipts,
        publicProfile,
        setAuthData,
        setLoading,
        signIn,
        signOut,
        signUpClient,
        signUpClinic,
        signUpProfessional,
        updateClient,
        updateClinic,
        updateOnboarding,
        updatePassword,
        updateProfessional,
        updateTrialNotice,
        user: authData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
