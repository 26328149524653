import styled from 'styled-components';
import { AnimationWrapper, BREAKPOINT_MD_PX, BREAKPOINT_SM_PX } from '../../../styles/globalStyles';

import { ReactComponent as Empty } from '../../../images/msgEmpty.svg';

export const Main = styled(AnimationWrapper)`
  align-items: flex-start;
  display: grid;
  column-gap: 3rem;
  grid-template-columns: 1.5fr 2fr;
  justify-items: center;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    justify-items: center;
    row-gap: 2rem;
  }
`;

export const ListContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const MessageContainer = styled.section`
  align-items: center;
  align-self: flex-start;
  border-radius: 0.7rem;
  border: 1px solid ${({ theme }) => theme.neutralGray};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-bottom: 2rem;
  min-height: 70vh;
  width: 100%;
`;

export const RowContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-around;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const ButtonWrapper = styled.section`
  display: flex;
  width: 200px;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
`;

export const EmptyImage = styled(Empty)`
  height: 250px;
  padding: 30px 0;
`;
