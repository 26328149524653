/* eslint-disable react-hooks/exhaustive-deps */
import { Field, FieldArray, Form, Formik } from 'formik';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { X } from 'react-feather';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { QUESTIONS } from '../../services/constants';
import theme from '../../theme';
import FormikControl from '../FormikControl';
import LoaderSpinner from '../LoaderSpinner';
import {
  AddField,
  AnamneseTitle,
  ButtonWrapper,
  CheckBoxContainer,
  FieldMainGrid,
  FormMain,
  FormsWrapper,
  Hr,
  IconArea,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
  ResetField,
  StyledModal,
} from './styles';

const FormAnamnese = ({
  clientId,
  config,
  editForm,
  formInfos,
  loading,
  setEditForm,
  setFormInfos,
  setForms,
  setLoading,
  setUpdate,
}) => {
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalSave, setOpenModalSave] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  const serializeQuestions = formInfos?.questions?.map(({ question }) => ({
    question,
    checked: true,
  }));

  const serializeAnswers = formInfos?.answers?.reduce(
    (acc, { answer, question }) => ({
      ...acc,
      [question]: answer,
    }),
    {}
  );

  const selectedFields = useMemo(() => {
    if (formInfos?.questions && Number(formInfos?.id) !== 0 && !config) {
      return serializeQuestions;
    }
    if (serializeQuestions?.length > QUESTIONS?.length && config) {
      return serializeQuestions;
    }
    if (serializeQuestions) {
      return QUESTIONS.map(({ question }) => {
        const questionFound = serializeQuestions.find((q) => q.question === question);
        if (questionFound) return questionFound;
        return { question, checked: false };
      });
    }
    if (serializeAnswers) {
      return Object.keys(serializeAnswers).map((question) => ({
        question,
        checked: true,
      }));
    }
    return QUESTIONS.map(({ question }) => ({
      question,
      checked: true,
    }));
  }, [formInfos]);

  const dynamicFields = useMemo(() => {
    return (
      serializeAnswers ||
      selectedFields.reduce((acc, { question }) => {
        acc[question] = '';
        return acc;
      }, {})
    );
  }, [formInfos]);

  useEffect(() => {
    setInitialValues({
      selectedFields,
      dynamicFields,
      newField: '',
      title: formInfos?.title || '',
    });
  }, [formInfos, selectedFields, loading, openModalAdd, openModalSave]);

  const serializeSelectedFields = useCallback((values) => {
    return values.selectedFields
      .filter((field) => field.checked)
      .map((field, index) => ({
        id: index,
        question: field.question,
      }));
  }, []);

  const serializeSelectedFieldsAnswers = useCallback((values) => {
    return Object.entries(values.dynamicFields).map(([field, value], index) => ({
      id: index + 1,
      question: field,
      answer: value,
    }));
  }, []);

  const handleSubmitForm = async (values) => {
    setLoading(true);
    try {
      const request = {
        data: {
          attributes: {
            title: values.title,
            questions: serializeSelectedFields(values),
          },
        },
      };
      let data;
      if (editForm) {
        const { data: response } = await api.put(`/professionals/forms/${formInfos.id}`, request);
        data = response;
      } else {
        const { data: response } = await api.post('/professionals/forms', request);
        data = response;
      }

      if (data.data) {
        toast.success(`Formulário ${editForm ? 'atualizado' : 'cadastrado'} com sucesso!`);
      }
    } catch (error) {
      toast.error('Erro ao cadastrar formulário');
    } finally {
      setOpenModalAdd(false);
      setLoading(false);
      setUpdate((prevUpdate) => !prevUpdate);
      setForms(null);
      setFormInfos(null);
    }
  };

  const handleSubmitFormAnswers = async (values) => {
    setLoading(true);
    try {
      const request = {
        data: {
          attributes: {
            clientId: Number(clientId),
            title: values.title,
            answers: serializeSelectedFieldsAnswers(values),
          },
        },
      };
      let data;
      if (editForm) {
        const { data: response } = await api.put(
          `/professionals/form_answers/${formInfos.id}`,
          request
        );
        data = response;
      } else {
        const { data: response } = await api.post('/professionals/form_answers', request);
        data = response;
      }

      if (data.data) {
        toast.success(`Formulário ${editForm ? 'atualizado' : 'cadastrado'} com sucesso!`);

        setFormInfos({
          ...data.data.attributes,
        });
      }
    } catch (error) {
      toast.error('Erro ao cadastrar formulário');
    } finally {
      setOpenModalAdd(false);
      setLoading(false);
      setUpdate((prevUpdate) => !prevUpdate);
    }
  };

  return !initialValues ? (
    <LoaderSpinner />
  ) : (
    <Formik initialValues={initialValues} enableReinitialize={true}>
      {({ values, setFieldValue, handleChange }) => (
        <Form>
          <FormsWrapper>
            <AnamneseTitle>
              {config & !editForm
                ? 'Selecione os campos'
                : editForm
                  ? `Formulário ${formInfos ? formInfos?.title : 'de Anamnese Padrão'}`
                  : 'Responda o formulário'}
            </AnamneseTitle>
          </FormsWrapper>

          <Hr />

          {config && (Number(formInfos?.id) === 0 || editForm) && (
            <Fragment>
              <ButtonWrapper>
                <AddField type="button" onClick={() => setOpenModalAdd(true)}>
                  Adicionar campos
                </AddField>

                <ResetField
                  type="button"
                  onClick={() =>
                    setFieldValue(
                      'selectedFields',
                      QUESTIONS.map(({ question }) => ({
                        question,
                        checked: true,
                      }))
                    )
                  }
                >
                  Resetar campos
                </ResetField>
              </ButtonWrapper>

              <Hr />
            </Fragment>
          )}

          <FieldMainGrid config={config}>
            {values.selectedFields.map((field, index) => (
              <FormMain key={`${field.question.id} ${index}`}>
                {config && (
                  <CheckBoxContainer>
                    <label>
                      <Field
                        type="checkbox"
                        name="selectedFields"
                        value={field.question}
                        checked={field.checked}
                        onChange={({ target }) => {
                          const { checked, value } = target;
                          const newDynamicFields = { ...values.dynamicFields };

                          const newSelectedFields = values.selectedFields.map((field) => {
                            if (field.question === value) {
                              field.checked = !field.checked;
                              return field;
                            }
                            return field;
                          });

                          if (checked) {
                            newDynamicFields[value] = '';
                            setFieldValue('dynamicFields', newDynamicFields);
                            setFieldValue('selectedFields', newSelectedFields);
                          } else {
                            delete newDynamicFields[value];
                            setFieldValue('dynamicFields', newDynamicFields);
                            setFieldValue('selectedFields', newSelectedFields);
                          }
                        }}
                      />
                      {field.question}
                    </label>
                  </CheckBoxContainer>
                )}
                {!config && (
                  <FieldArray name="dynamicFields">
                    {values.selectedFields.some((field) =>
                      field.question.includes(field.question)
                    ) && (
                      <FormikControl
                        bgColor={theme.white}
                        control="input"
                        key={field.question}
                        label={field.question}
                        labelFamily="Poppins"
                        name={`dynamicFields.${field.question}`}
                        onChange={({ target }) =>
                          setFieldValue(`dynamicFields.${field.question}`, target.value)
                        }
                        upLabel
                        value={values.dynamicFields[field.question]}
                      />
                    )}
                  </FieldArray>
                )}
              </FormMain>
            ))}
          </FieldMainGrid>

          <Fragment>
            <Hr />
            {config ? (
              <ButtonWrapper>
                <AddField
                  type="button"
                  onClick={() => {
                    if ((config && !editForm) || clientId) {
                      setOpenModalSave(true);
                    } else handleSubmitForm(values);
                  }}
                >
                  {Number(formInfos?.id) === 0 ? 'Criar formulário' : 'Salvar formulário'}
                </AddField>

                {editForm && (
                  <ResetField type="button" onClick={() => setEditForm(false)}>
                    Cancelar edição
                  </ResetField>
                )}
              </ButtonWrapper>
            ) : (
              <ButtonWrapper>
                <AddField
                  type="button"
                  onClick={() => {
                    if (clientId && !editForm) {
                      setOpenModalSave(true);
                    } else handleSubmitFormAnswers(values);
                  }}
                  style={{ width: 'auto' }}
                >
                  {!editForm && !formInfos ? 'Criar formulário de respostas' : 'Salvar formulário'}
                </AddField>

                {editForm && (
                  <ResetField type="button" onClick={() => setEditForm(false)}>
                    Cancelar edição
                  </ResetField>
                )}
              </ButtonWrapper>
            )}
          </Fragment>

          <StyledModal
            ariaHideApp={false}
            isOpen={openModalAdd}
            onRequestClose={() => setOpenModalAdd(false)}
            onAfterOpen={null}
          >
            <ModalContainer>
              <ModalWrapper style={{ padding: '2rem' }}>
                <IconArea>
                  <X
                    style={{ cursor: 'pointer', width: '2rem', height: '2rem' }}
                    onClick={() => setOpenModalAdd(false)}
                  />
                </IconArea>
                <ModalContent>
                  <ModalHeader>
                    <ModalTitle>Informe o nome do novo campo</ModalTitle>
                  </ModalHeader>

                  <FormikControl
                    bgColor={theme.white}
                    control="input"
                    label="Nome do Campo"
                    labelFamily="Poppins"
                    name="fieldName"
                    onChange={({ target }) => setFieldValue('newField', target.value)}
                    type="text"
                    upLabel
                  />

                  <AddField
                    disabled={values.newField === ''}
                    type="button"
                    onClick={() => {
                      setOpenModalAdd(false);
                      setFieldValue('newField', '');
                      setFieldValue('selectedFields', [
                        ...values.selectedFields,
                        { question: values.newField, checked: true },
                      ]);
                      setFieldValue('dynamicFields', {
                        ...values.dynamicFields,
                        [values.newField]: '',
                      });
                    }}
                  >
                    Salvar
                  </AddField>
                </ModalContent>
              </ModalWrapper>
            </ModalContainer>
          </StyledModal>

          <StyledModal
            ariaHideApp={false}
            isOpen={openModalSave}
            onRequestClose={() => setOpenModalSave(false)}
            onAfterOpen={null}
          >
            <ModalContainer>
              <ModalWrapper style={{ padding: '2rem' }}>
                <IconArea>
                  <X
                    style={{ cursor: 'pointer', width: '2rem', height: '2rem' }}
                    onClick={() => setOpenModalSave(false)}
                  />
                </IconArea>
                <ModalContent>
                  <ModalHeader>
                    <ModalTitle>Informe o nome do Formulário</ModalTitle>
                  </ModalHeader>

                  <FormikControl
                    bgColor={theme.white}
                    control="input"
                    label="Título do formulário"
                    labelFamily="Poppins"
                    name="title"
                    onChange={handleChange}
                    type="text"
                    upLabel
                  />

                  <AddField
                    disabled={values.title === ''}
                    type="button"
                    onClick={() => {
                      if (config) {
                        handleSubmitForm(values);
                      } else {
                        handleSubmitFormAnswers(values);
                      }
                    }}
                  >
                    Salvar
                  </AddField>
                </ModalContent>
              </ModalWrapper>
            </ModalContainer>
          </StyledModal>
        </Form>
      )}
    </Formik>
  );
};

export default FormAnamnese;
