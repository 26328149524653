import { Formik } from 'formik';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { FooterButtons } from '../../../components/FooterButtons';
import FormikControl from '../../../components/FormikControl';
import useAuth from '../../../hooks/auth';
import EllipseBottom from '../../../images/ellipse-bottom.svg';
import EllipseTop from '../../../images/ellipse-top.svg';
import LoginBackGroundImg from '../../../images/login-background.png';
import Logo from '../../../images/logoLogin.png';
import { PtBr } from '../../../services/pt_br';
import { BREAKPOINT_MD } from '../../../styles/globalStyles';
import theme from '../../../theme';
import useWindowSize from '../../../utils/WindowSize';
import {
  BackgroundImg,
  ImageContainer,
  ImgEllipseBottom,
  ImgEllipseTop,
  InputWrapper,
  LoginMain,
  LoginTitle,
  LoginWrapper,
  LogoImg,
  Main,
  SendButton,
} from '../../Public/Login/styles';
import { FormContainer } from './styles';

export default function ForgetPassword() {
  const { forgetPassword } = useAuth();
  const { width } = useWindowSize();
  const location = useLocation();
  const history = useHistory();
  const schema = Yup.object({
    email: Yup.string()
      .email('Esse campo deve conter um email!')
      .required('Esse campo é requerido!'),
  });

  const params = location?.pathname.split('/')[1];

  const type =
    params === 'cliente' ? 'client' : params === 'profissional' ? 'professional' : 'clinic';

  const handleValues = async (values) => {
    try {
      const response = await forgetPassword(values.email, type);
      if (response.success) {
        history.push('/conectar-se');
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Main>
      <LoginMain>
        <LoginWrapper>
          <Formik
            validationSchema={schema}
            initialValues={{
              email: '',
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                handleValues(values);
              }, 400);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <FormContainer onSubmit={handleSubmit}>
                <ImageContainer>
                  <LogoImg src={Logo} alt="Logo" />
                </ImageContainer>
                <InputWrapper>
                  <LoginTitle>Esqueceu a senha</LoginTitle>

                  <FormikControl
                    bgColor={theme.white}
                    name="email"
                    type="email"
                    control="input"
                    onBlue={handleBlur}
                    value={values.email}
                    onChange={handleChange}
                    label={PtBr.input_email}
                    placeholder="nome@email.com"
                    upLabel
                  />
                  {errors.email && touched.email && errors.email}

                  <SendButton onClick={handleSubmit}>Enviar</SendButton>
                </InputWrapper>
              </FormContainer>
            )}
          </Formik>
          <FooterButtons
            forgetPassword
            goBack={() => history.push('/conectar-se')}
            disableNext={true}
          />
        </LoginWrapper>
      </LoginMain>
      <ImgEllipseTop src={EllipseTop} alt="imgLogin" />
      <ImgEllipseBottom src={EllipseBottom} alt="imgLogin" />
      {width > BREAKPOINT_MD && <BackgroundImg src={LoginBackGroundImg} />}
    </Main>
  );
}
