/* eslint-disable react-hooks/exhaustive-deps */
import { FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Flatlist } from '../../../components/List';
import { PackageCard, PlansCardSubscription } from '../../../components/ListItens';
import api from '../../../services/api';
import theme from '../../../theme';
import { CustomPackages, CustomPlans } from '../../Clinic/Profile/components';
import { WrapperCustom } from '../../Clinic/Profile/styles';
import { Hr } from '../../Public/Professionals/components/styles';
import { ConfigMain, RowLine, TitleH1 } from '../Config/styles';
import { MenuHeader, MenuOption, OptionText, ScheduleWrapper } from './styles';
const options = ['Pacotes', 'Planos de Assinaturas', '|', 'Gestão de pacotes', 'Gestão de planos'];
export function TopMenuBar({ ...props }) {
  const { options, setSelected, selected } = props;
  return (
    <MenuHeader>
      {options?.map((item) =>
        item === '|' ? (
          <div
            style={{
              margin: '0 1rem',
              border: `1px solid ${theme.secondary}`,
              height: '2.5rem',
            }}
          />
        ) : (
          <MenuOption
            active={selected === item}
            key={item}
            onClick={() => setSelected(item)}
            type="button"
          >
            <OptionText active={selected === item}>{item}</OptionText>
          </MenuOption>
        )
      )}
    </MenuHeader>
  );
}

export default function PackagesPlans() {
  const [selected, setSelected] = useState('Pacotes');
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [packages, setPackages] = useState([]);

  const getPackagesPlans = useCallback(async () => {
    try {
      const { data } = await api.get('professionals/payments/misc_history');
      if (data?.data) {
        const packages = data.data.filter((item) => item.attributes.appointmentPackage);
        setPackages(packages);
        const plans = data.data.filter((item) => !item.attributes.appointmentPackage);
        setPlans(plans);
      }
    } catch (error) {
      toast.error('Não foi possível carregar os planos de assinaturas ativos');
    } finally {
      setLoading(false);
    }
  }, []);

  const schemaPackages = Yup.object({
    packageId: Yup.string().optional().nullable(),
    editNamePackage: Yup.string().when(['packageId', 'isDeletePackage'], {
      is: (packageId, isDeletePackage) => packageId && !isDeletePackage,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().optional(),
    }),
    editNumberOfAppointments: Yup.string().when(['packageId', 'isDeletePackage'], {
      is: (packageId, isDeletePackage) => packageId && !isDeletePackage,
      then: Yup.string()
        .matches(/^\d+$/, 'Deve conter apenas números')
        .required('Campo obrigatório'),
      otherwise: Yup.string().optional(),
    }),
    editPricePackage: Yup.number().when(['packageId', 'isDeletePackage'], {
      is: (packageId, isDeletePackage) => packageId && !isDeletePackage,
      then: Yup.number().typeError('Deve ser um número').required('Campo obrigatório'),
      otherwise: Yup.number().optional(),
    }),
    namePackage: Yup.string().when('packageId', {
      is: (value) => !value,
      then: Yup.string().required('Campo obrigatório'),
    }),
    numberOfAppointments: Yup.string().when('packageId', {
      is: (value) => !value,
      then: Yup.string()
        .matches(/^\d+$/, 'Deve conter apenas números')
        .required('Campo obrigatório'),
    }),
    pricePackage: Yup.number().when('packageId', {
      is: (value) => !value,
      then: Yup.number().typeError('Deve ser um número').required('Campo obrigatório'),
    }),
  });

  const schemaPlans = Yup.object({
    planId: Yup.string().optional().nullable(),
    editNamePlan: Yup.string().when(['planId', 'isDeletePlan'], {
      is: (planId, isDeletePlan) => planId && !isDeletePlan,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().optional(),
    }),
    editAppointmentsPerWeek: Yup.string().when(['planId', 'isDeletePlan'], {
      is: (planId, isDeletePlan) => planId && !isDeletePlan,
      then: Yup.string()
        .matches(/^\d+$/, 'Deve conter apenas números')
        .required('Campo obrigatório'),
      otherwise: Yup.string().optional(),
    }),
    editPricePlan: Yup.number().when(['planId', 'isDeletePlan'], {
      is: (planId, isDeletePlan) => planId && !isDeletePlan,
      then: Yup.number().typeError('Deve ser um número').required('Campo obrigatório'),
      otherwise: Yup.number().optional(),
    }),
    namePlan: Yup.string().when('planId', {
      is: (value) => !value,
      then: Yup.string().required('Campo obrigatório'),
    }),
    frequency: Yup.string().when('planId', {
      is: (value) => !value,
      then: Yup.string().required('Campo obrigatório'),
    }),
    appointmentsPerWeek: Yup.string().when('planId', {
      is: (value) => !value,
      then: Yup.string()
        .matches(/^\d+$/, 'Deve conter apenas números')
        .required('Campo obrigatório'),
    }),
    pricePlan: Yup.number().when('planId', {
      is: (value) => !value,
      then: Yup.number().typeError('Deve ser um número').required('Campo obrigatório'),
    }),
  });

  const schemas = useMemo(() => {
    switch (selected) {
      case 'Gestão de pacotes':
        return schemaPackages;
      case 'Gestão de planos':
        return schemaPlans;
      default:
        return schemaPackages;
    }
  }, [selected]);

  useEffect(() => {
    getPackagesPlans();
  }, [getPackagesPlans]);

  const form = useFormik({
    initialValues: {
      // Gestão de pacotes
      editNamePackage: '',
      editNumberOfAppointments: '',
      editPricePackage: '',
      isDeletePackage: false,
      namePackage: '',
      numberOfAppointments: '',
      packageId: null,
      pricePackage: '',

      // Gestão de planos
      appointmentsPerWeek: 1,
      frequency: '',
      isDeletePlan: false,
      namePlan: '',
      pricePlan: '',
      userId: '',
      userType: '',
    },
    validationSchema: schemas,
    onSubmit: (values) => {
      submitFormSelected(values);
      form.resetForm();
    },
  });

  const handleCreateCustomPackages = async (values) => {
    try {
      setLoading(true);
      const request = {
        data: {
          attributes: {
            name: values?.namePackage,
            price: values?.pricePackage,
            numberOfAppointments: Number(values?.numberOfAppointments),
          },
        },
      };
      const { data } = await api.post('/professionals/appointment_packages', request);
      if (data) {
        toast.success('Plano personalizado criado com sucesso');
      }
    } catch (error) {
      toast.error('Erro ao criar plano personalizado');
    } finally {
      form.setFieldValue('namePackage', '');
      form.setFieldValue('numberOfAppointments', '');
      form.setFieldValue('pricePackage', '');
      setLoading(false);
    }
  };

  const handleUpdateCustomPackages = async (values) => {
    try {
      setLoading(true);
      const request = {
        data: {
          attributes: {
            name: values?.editNamePackage,
            price: values?.editPricePackage,
            numberOfAppointments: Number(values?.editNumberOfAppointments),
          },
        },
      };
      const { data } = await api.patch(
        `/professionals/appointment_packages/${values?.packageId}`,
        request
      );
      if (data) {
        toast.success('Plano personalizado atualizado com sucesso');
      }
    } catch (error) {
      toast.error('Erro ao atualizar plano personalizado');
    } finally {
      form.setFieldValue('packageId', null);
      form.setFieldValue('editNamePackage', '');
      form.setFieldValue('editNumberOfAppointments', '');
      form.setFieldValue('editPricePackage', '');
      setLoading(false);
    }
  };

  const handleDeleteCustomPackages = async (id) => {
    try {
      setLoading(true);
      const { data } = await api.delete(`/professionals/appointment_packages/${id}`);
      if (data) {
        toast.success('Plano personalizado deletado com sucesso');
      }
    } catch (error) {
      toast.error('Erro ao deletar plano personalizado');
    } finally {
      form.setFieldValue('packageId', null);
      form.setFieldValue('isDeletePackage', false);
      setLoading(false);
    }
  };

  const handleCreateCustomPlans = async (values) => {
    try {
      setLoading(true);
      const request = {
        data: {
          attributes: {
            name: values?.namePlan,
            price: values?.pricePlan,
            frequency: values?.frequency,
            appointmentsPerWeek: 1,
            userType: values?.userType,
            userId: values?.userId,
          },
        },
      };

      const { data } = await api.post('/professionals/appointment_plans', request);
      if (data) {
        toast.success('Plano criado com sucesso');
      }
    } catch (error) {
      toast.error('Erro ao criar plano');
    } finally {
      form.setFieldValue('namePlan', '');
      form.setFieldValue('pricePlan', '');
      form.setFieldValue('frequency', '');
      form.setFieldValue('userType', '');
      form.setFieldValue('userId', '');
      setLoading(false);
    }
  };

  const handleUpdateCustomPlans = async (values) => {
    try {
      setLoading(true);
      const request = {
        data: {
          attributes: {
            name: values?.editNamePlan,
            price: values?.editPricePlan,
            frequency: values?.editFrequency,
            appointmentsPerWeek: 1,
            userType: values?.editUserType,
            userId: values?.editUserId,
          },
        },
      };
      const { data } = await api.patch(
        `/professionals/appointment_plans/${values?.planId}`,
        request
      );
      if (data) {
        toast.success('Plano atualizado com sucesso');
      }
    } catch (error) {
      toast.error('Erro ao atualizar plano');
    } finally {
      form.setFieldValue('planId', null);
      form.setFieldValue('editNamePlan', '');
      form.setFieldValue('editPricePlan', '');
      form.setFieldValue('editFrequency', '');
      form.setFieldValue('editUserType', '');
      form.setFieldValue('editUserId', '');
      setLoading(false);
    }
  };

  const handleDeleteCustomPlans = async (id) => {
    try {
      setLoading(true);
      const { data } = await api.delete(`/professionals/appointment_plans/${id}`);
      if (data) {
        toast.success('Plano deletado com sucesso');
      }
    } catch (error) {
      toast.error('Erro ao deletar plano');
    } finally {
      form.setFieldValue('planId', null);
      form.setFieldValue('isDeletePlan', false);
      setLoading(false);
    }
  };

  const submitFormPackages = async (values) => {
    if (values?.isDeletePackage && values?.packageId) {
      handleDeleteCustomPackages(values?.packageId);
    } else if (values?.packageId) {
      handleUpdateCustomPackages(values);
    } else {
      handleCreateCustomPackages(values);
    }
  };

  const submitFormPlans = async (values) => {
    if (values?.isDeletePlan && values?.planId) {
      handleDeleteCustomPlans(values?.planId);
    } else if (values?.planId) {
      handleUpdateCustomPlans(values);
    } else {
      handleCreateCustomPlans(values);
    }
  };

  const submitFormSelected = useCallback(
    async (values) => {
      if (selected === 'Gestão de pacotes') submitFormPackages(values);
      else if (selected === 'Gestão de planos') submitFormPlans(values);
    },
    [selected]
  );

  return (
    <FormikProvider value={form}>
      <ConfigMain onSubmit={form.handleSubmit}>
        <TopMenuBar options={options} setSelected={setSelected} selected={selected} />

        <Hr />

        {selected === 'Pacotes' && (
          <ScheduleWrapper>
            <WrapperCustom>
              <TitleH1>Pacotes adiquirido por clientes</TitleH1>
              <RowLine />
            </WrapperCustom>
            <Flatlist
              data={packages}
              dateSearch
              filterObject={(packageData) => packageData.attributes.search}
              placeholder="Buscar Pacotes"
              renderItem={(packageData, index) => (
                <PackageCard
                  key={`${packageData.id} ${index}`}
                  packageData={packageData.attributes}
                />
              )}
              loading={loading}
            />
          </ScheduleWrapper>
        )}

        {selected === 'Planos de Assinaturas' && (
          <ScheduleWrapper>
            <WrapperCustom>
              <TitleH1>Planos adiquirido por clientes</TitleH1>
              <RowLine />
            </WrapperCustom>
            <Flatlist
              data={plans}
              dateSearch
              filterObject={(plan) => plan.attributes.search}
              placeholder="Buscar Planos de Assinaturas"
              renderItem={(plan, index) => (
                <PlansCardSubscription key={`${plan.id} ${index}`} plan={plan.attributes} />
              )}
              loading={loading}
            />
          </ScheduleWrapper>
        )}

        {selected === 'Gestão de pacotes' && <CustomPackages form={form} isRender={loading} />}
        {selected === 'Gestão de planos' && <CustomPlans form={form} isRender={loading} />}
      </ConfigMain>
    </FormikProvider>
  );
}
