/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { viaCepApi } from '../../../services/api';
import { PtBr } from '../../../services/pt_br';
import theme from '../../../theme';
import FormikControl from '../../FormikControl';

export function AddressFormik({ form, subscription }) {
  const { values, errors, touched, setFieldValue, handleChange, handleBlur } = form;
  const [cep, setCep] = useState('');
  const onlyNumbers = (str) => str.replace(/[_]/g, '');

  useEffect(() => {
    const controller = new AbortController();
    const handleCEP = async () => {
      if (onlyNumbers(cep)?.length === 9) {
        try {
          const { data } = await viaCepApi.get(`${values?.cep}/json/`, {
            signal: controller.signal,
          });
          if (data) {
            setFieldValue('street', data.logradouro);
            setFieldValue('neighborhood', data.bairro);
            setFieldValue('city', data.localidade);
            setFieldValue('state', data.uf);
            setFieldValue('cep', onlyNumbers(cep));
          }
        } catch (error) {
          if (!controller.signal.aborted) toast.error('CEP inválido');
        }
      }
    };
    handleCEP();

    return () => controller.abort();
  }, [cep]);

  useEffect(() => setFieldValue('country', 'Brasil'), []);

  const validateComplement = () => {
    if (values.complement === '') {
      setFieldValue('complement', 'N/A');
    }
  };

  return (
    <Fragment>
      {subscription && (
        <FormikControl
          bgColor={theme.white}
          control="input"
          errors={errors.documentNumber}
          label={PtBr.register_input_cpf_rg}
          labelFamily="Poppins"
          labelSize="0.9rem"
          labelWeight="600"
          mask="999.999.999-99"
          name="documentNumber"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.documentNumber}
          type="text"
          upLabel
          value={values.documentNumber}
        />
      )}

      <FormikControl
        bgColor={theme.white}
        control="input"
        errors={errors.cep}
        label={PtBr.register_input_cep}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        mask="99999-999"
        name="cep"
        onBlur={handleBlur}
        onChange={handleChange}
        onInput={({ target }) => setCep(target?.value)}
        touched={touched.cep}
        upLabel
        value={values.cep}
      />

      <FormikControl
        bgColor={theme.white}
        control="input"
        errors={errors.street}
        label={PtBr.register_input_street}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="street"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.street}
        upLabel
        value={values.street}
      />

      <FormikControl
        bgColor={theme.white}
        control="input"
        errors={errors.street_number}
        label={PtBr.register_input_street_number}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="street_number"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.street_number}
        upLabel
        value={values.street_number}
      />

      <FormikControl
        bgColor={theme.white}
        control="input"
        errors={errors.complement}
        label={PtBr.register_input_complement}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="complement"
        onBlur={(e) => {
          handleBlur(e);
          validateComplement();
        }}
        onChange={handleChange}
        touched={touched.complement}
        upLabel
        value={values.complement}
      />

      <FormikControl
        bgColor={theme.white}
        control="input"
        errors={errors.neighborhood}
        label={PtBr.register_input_neighborhood}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="neighborhood"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.neighborhood}
        upLabel
        value={values.neighborhood}
      />

      <FormikControl
        bgColor={theme.white}
        control="input"
        errors={errors.city}
        label={PtBr.register_input_city}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="city"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.city}
        upLabel
        value={values.city}
      />

      <FormikControl
        bgColor={theme.white}
        control="input"
        errors={errors.state}
        label={'Estado'}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="state"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.state}
        upLabel
        value={values.state}
      />

      <FormikControl
        bgColor={theme.white}
        control="input"
        errors={errors.country}
        label={PtBr.register_input_country}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="country"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.country}
        upLabel
        value={values.country}
      />
    </Fragment>
  );
}
