import styled from 'styled-components';
import { BREAKPOINT_MD_PX, BREAKPOINT_SM_PX, SmallFont } from '../../../../../styles/globalStyles';
import { moveLeftToRight } from '../../../../../styles/keyframes';

// Coupons dashboard components
export const TesteContainer = styled.section`
  flex: 1;
  width: 100%;
  display: flex;
  background-color: violet;
`;

export const CouponMain = styled.div`
  animation: ${moveLeftToRight} 0.5s ease-in-out;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 2rem;
  width: 60%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 100%;
  }
`;

export const CouponDashboard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const CouponFormContainer = styled.form`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

export const ActiveCouponsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerLabel = styled(SmallFont)`
  color: ${({ theme }) => theme.neutralGray};
  align-self: flex-start;
  padding-top: 10px;
`;

export const ActiveCoupons = styled.div`
  width: 100%;
  padding: 0.7rem 0;
`;

export const CouponsInformationContainer = styled.div`
  width: 100%;
`;

export const CouponsActionsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  width: 100%;
`;
export const EmptyContainerLabel = styled(SmallFont)`
  padding: 10px;
  color: ${({ theme }) => theme.primary};
`;

export const NewCouponButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 1rem;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 600;
  height: 3rem;
  justify-content: center;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primary};
  }
`;

export const CouponButtonAdd = styled(NewCouponButton)`
  margin-top: 1rem;
  background-color: ${({ theme, create }) => (create === 'true' ? theme.primary : theme.errorRed)};
  width: 40%;

  &:hover {
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme, create }) => (create === 'true' ? theme.primary : theme.errorRed)};
    color: ${({ theme, create }) => (create === 'true' ? theme.primary : theme.errorRed)};
  }
`;

export const CouponButtonCancel = styled(CouponButtonAdd)`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme, create }) => (create === 'true' ? theme.errorRed : theme.primary)};
  color: ${({ theme, create }) => (create === 'true' ? theme.errorRed : theme.primary)};
  width: 40%;

  &:hover {
    background-color: ${({ theme, create }) =>
      create === 'true' ? theme.errorRed : theme.primary};
    border: 1px solid ${({ theme, create }) => (create === 'true' ? theme.errorRed : theme.primary)};
    color: ${({ theme }) => theme.white};
  }
`;

export const CouponButton = styled.button`
  flex: 1;
  width: 100%;
  height: 80px;
  display: flex;
  border: none;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.primary};
`;

export const CouponCard = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.lightGray};
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;

  &:hover {
    margin-left: 1rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    flex-direction: column;
  }
`;

export const CouponTypeButton = styled(CouponCard)`
  background-color: ${({ theme, selected }) => (selected ? theme.primary : theme.softBackground)};
  label,
  h2 {
    color: ${({ theme, selected }) => (selected ? theme.lightGray : theme.secondary)};
  }
`;

export const CouponNameContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
export const CouponName = styled.h2`
  color: black;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
`;

export const CouponStatus = styled.div`
  width: 30%;
  display: flex;
  padding: 5px 0;
  align-items: center;
  justify-content: center;
  background-color: #999ebc;
  border-radius: 10px;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    width: 100%;
  }
`;

export const CouponInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const CouponStatusText = styled(SmallFont)`
  color: ${({ theme }) => theme.lightGray};
  cursor: pointer;
`;

export const WrapperSelect = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`;
