/* eslint-disable react-hooks/exhaustive-deps */
import {
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@material-ui/core';
import { FormikProvider, useFormik } from 'formik';
import React, { Fragment, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import FormikControl from '../../../../components/FormikControl';
import LoaderSpinner from '../../../../components/LoaderSpinner';
import useAuth from '../../../../hooks/auth';
import { BigFont } from '../../../../styles/globalStyles';
import { PLAN_LEVEL_FULL_ACCESS } from '../../../../utils/constantsLevelAccess';

import { CalendarComponentProfessional } from '..';
import {
  AddressInfo,
  BFont,
  RowItem,
  SMFRegular,
  SMFontL,
  ScheduleBody,
  StyledButton,
  TypeContainer,
} from '../../../Client/Professionals/Schedules/styles';

import moment from 'moment';
import api from '../../../../services/api';
import convertCurrencyPtBr from '../../../../utils/convertCurrencyPtBr';
import { PaymentContainer } from '../../../Client/Professionals/Payment/styles';
import { BoxWrapperSchedule, CloseIcon, IconArea, WarningIcon } from '../styles';
import {
  FlexStartContainer,
  FlexStartText,
  FlexWrapper,
  HeaderWrapperResum,
  Hr,
  Main,
  SMFontBlack,
  WrapperTitleResum,
} from './styles';

export function ScheduleRecurrence({ ...props }) {
  const { values, handleChangeFrequency, handleChangeDuration } = props;

  return (
    <TypeContainer style={{ marginBottom: '1rem' }}>
      <FormControl style={{ width: '250px' }}>
        <InputLabel>{<SMFontL>Frequência</SMFontL>}</InputLabel>
        <Select
          value={values?.recurrentFrequency}
          label="Frequência"
          onChange={handleChangeFrequency}
        >
          <MenuItem value={'weekly'}>
            <SMFRegular>Semanal</SMFRegular>
          </MenuItem>
          <MenuItem value={'biweekly'}>
            <SMFRegular>Quinzenal</SMFRegular>
          </MenuItem>
          <MenuItem value={'monthly'}>
            <SMFRegular>Mensal</SMFRegular>
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl style={{ width: '250px', marginTop: '10px' }}>
        <InputLabel>{<SMFontL>Duração da recorrência</SMFontL>}</InputLabel>
        <Select
          value={values?.recurrentDuration}
          label="Duração da recorrência"
          onChange={handleChangeDuration}
        >
          <MenuItem value={'one_month'}>
            <SMFRegular>1 mês</SMFRegular>
          </MenuItem>
          <MenuItem value={'two_month'}>
            <SMFRegular>2 meses</SMFRegular>
          </MenuItem>
          <MenuItem value={'three_month'}>
            <SMFRegular>3 meses</SMFRegular>
          </MenuItem>
        </Select>
      </FormControl>

      <SMFontL style={{ padding: '0 2rem 0.5rem 2rem', marginTop: '2rem' }}>
        ¹ Agendamentos recorrentes ocorrem sempre no mesmo dia da semana e na mesma hora marcados.
      </SMFontL>
      <SMFontL style={{ padding: '0.5rem 2rem 0 2rem' }}>
        ² Agendamentos recorrentes precisam ser renovados após a duração indicada.
      </SMFontL>
    </TypeContainer>
  );
}

export function Schedule({ ...props }) {
  const { user } = useAuth();
  const { values, setFieldValue } = props;
  const [alertPackages, setAlertPackages] = useState(false);
  const [alertPlans, setAlertPlans] = useState(false);

  const options = [
    {
      label: 'Agendamento Individual (Padrão)',
      value: 'single_appointment',
    },
    {
      label: 'Agendamento recorrente',
      value: 'recurrent',
    },
    // {
    //   label: 'Pacote de consultas',
    //   value: 'consulting_package',
    // },
    // {
    //   label: 'Assinatura mensal',
    //   value: 'monthly_subscription',
    // },
  ].filter((option) =>
    user?.clinic?.id || PLAN_LEVEL_FULL_ACCESS.includes(user?.planLevel)
      ? option.value
      : !['monthly_subscription', 'consulting_package'].includes(option.value)
  );

  const handleChangePaymentMethod = (event) => {
    setFieldValue('paymentMode', event.target.value);
    if (event.target.value === 'post_paid') {
      setFieldValue('postPaid', true);
      setFieldValue('postPaidDay', user?.postPaidDay);
    }
  };

  const handleChangeScheduleType = ({ target }) => {
    switch (target.value) {
      case 'single_appointment': {
        setFieldValue('appointmentFormat', 'single_appointment');
        setFieldValue('recurrentFrequency', null);
        setFieldValue('recurrentDuration', null);
        return setFieldValue('recurrent', false);
      }
      case 'recurrent': {
        setFieldValue('appointmentFormat', 'recurrent');
        return setFieldValue('recurrent', true);
      }

      // case 'monthly_subscription':
      //   return setFieldValue('monthlySubscription', true);
      // case 'consulting_package':
      //   setFieldValue('paymentMethod', 'client_credit');
      //   return setFieldValue('consultingPackage', true);
      default:
        return;
    }
  };

  const handleChangeFrequency = (event) => {
    setFieldValue('recurrentFrequency', event.target.value);
  };

  const handleChangeDuration = (event) => {
    setFieldValue('recurrentDuration', event.target.value);
  };

  return (
    <ScheduleBody style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
      <TypeContainer>
        <BFont>Agendar Atendimento</BFont>
        <SMFontL>Modalidade</SMFontL>
        <RowItem style={{ justifyContent: 'center' }}>
          <StyledButton style={{ cursor: 'default' }} selected={values?.appointmentType}>
            {values?.appointmentType === 'online' ? 'Online' : 'Presencial'}
          </StyledButton>
        </RowItem>
      </TypeContainer>

      <Hr />

      {user?.availabilityRule?.appointmentTypes === 'in_person' && (
        <AddressInfo>
          <SMFRegular style={{ fontSize: '1.3rem', fontWeight: '700' }}>Endereço:</SMFRegular>
          <SMFRegular>{user?.address?.neighborhood}</SMFRegular>
          <SMFRegular>{user?.address?.street}</SMFRegular>
          <SMFRegular>
            {user?.address?.city} CEP:{user?.address?.cep}
          </SMFRegular>
        </AddressInfo>
      )}

      <TypeContainer>
        <RowItem style={{ paddingTop: 'inherit' }}>
          <FormikControl
            control="selectMultLang"
            name="typeSchedule"
            label="Tipo de agendamento"
            onChange={handleChangeScheduleType}
            options={options}
            upLabel
            defaultValue="single_appointment"
          />
        </RowItem>

        {values?.recurrent && (
          <Fragment>
            <RowItem>
              <BFont>Agendamento recorrente</BFont>
            </RowItem>
            <ScheduleRecurrence
              values={values}
              handleChangeFrequency={handleChangeFrequency}
              handleChangeDuration={handleChangeDuration}
            />
          </Fragment>
        )}
      </TypeContainer>

      {(user?.clinic?.id || PLAN_LEVEL_FULL_ACCESS.includes(user?.planLevel)) &&
        [values.monthlySubscription, values.consultingPackage].every((item) => !item) && (
          <Fragment>
            <Hr />
            <TypeContainer>
              {user?.clinic?.name && (
                <RowItem>
                  <BFont>Clínica - {user?.clinic?.name}</BFont>
                </RowItem>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  padding: '0 10px 20px 0',
                }}
              >
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={values?.paymentMode}
                    onChange={handleChangePaymentMethod}
                    style={{ fontFamily: 'Open Sans' }}
                  >
                    {(user?.clinic.prePaid || user?.prePaid) && (
                      <FormControlLabel
                        value="pre_paid"
                        control={<Radio />}
                        label={<Typography>Agendar com pagamento PRÉ-PAGO (Padrão)</Typography>}
                        defaultChecked
                      />
                    )}
                    {(user?.clinic.postPaid || user?.postPaid) && (
                      <FormControlLabel
                        label={<Typography>Agendar com agendamento PÓS-PAGO</Typography>}
                        value="post_paid"
                        control={<Radio />}
                      />
                    )}
                    {(user?.clinic.externalPayment || user?.externalPayment) && (
                      <FormControlLabel
                        value="external"
                        control={<Radio />}
                        label={<Typography>Agendar com pagamento EXTERNO</Typography>}
                      />
                    )}
                    <FormControlLabel
                      value="client_selects"
                      control={<Radio />}
                      label={<Typography>Deixar paciente definir na hora do pagamento</Typography>}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </TypeContainer>
          </Fragment>
        )}

      {(alertPackages || alertPlans) && (
        <Modal open>
          <BoxWrapperSchedule
            style={{
              width: '40rem',
            }}
          >
            <IconArea style={{ padding: '1rem 1rem 0 0' }}>
              <CloseIcon
                onClick={() => {
                  setAlertPackages(false);
                  setAlertPlans(false);
                }}
              />
            </IconArea>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <WarningIcon style={{ marginRight: '0.5rem' }} />
              <BigFont
                style={{
                  fontWeight: '700',
                  fontSize: '1.5rem',
                }}
              >
                Aviso!
              </BigFont>
            </div>

            <BigFont style={{ fontWeight: '700', margin: '2rem 1rem' }}>
              {`Ainda não há ${alertPackages ? 'pacotes' : 'planos'} disponíveis para compra.`}
            </BigFont>
          </BoxWrapperSchedule>
        </Modal>
      )}
    </ScheduleBody>
  );
}

export function ScheduleStatus({ form }) {
  const { user } = useAuth();
  const { values } = form;

  const recurrentFrequency = useMemo(() => {
    switch (values?.recurrentFrequency) {
      case 'weekly':
        return 'Semanalmente';
      case 'biweekly':
        return 'Quinzenalmente';
      case 'monthly':
        return 'Mensalmente';
      default:
        return '';
    }
  }, values.recurrentFrequency);

  const recurrentDuration = useMemo(() => {
    switch (values?.recurrentDuration) {
      case 'one_month':
        return '1 mês';
      case 'two_month':
        return '2 meses';
      case 'three_month':
        return '3 meses';
      default:
        return '';
    }
  }, [values.recurrentDuration]);

  return (
    <PaymentContainer>
      <HeaderWrapperResum>
        <WrapperTitleResum>Resumo do Agendamento</WrapperTitleResum>
        <FlexWrapper>
          <SMFontBlack
            style={{
              marginBottom: '0.5rem',
            }}
          >
            Cliente:{' '}
            <SMFontBlack style={{ fontSize: '1.15rem' }} bold>
              {values?.clientName || `${values?.firstName} ${values?.lastName}`}
            </SMFontBlack>
          </SMFontBlack>
          <SMFontBlack bold>
            <span style={{ fontWeight: '400' }}>Tipo da sessão: </span>
            {`${['in_person'].includes(values?.appointmentType) ? 'Presencial' : 'Online'}`}
          </SMFontBlack>
          <SMFontBlack bold>
            <span style={{ fontWeight: '400' }}>Duração: </span>
            {`${values?.duration} min`}
          </SMFontBlack>
          <SMFontBlack bold>
            <span style={{ fontWeight: '400' }}>Data: </span>
            {`${moment(values.startDate).format('DD [de] MMMM [de] YYYY')}
                às ${moment(values?.startTime, 'HH:mm:ss').format('HH:mm')}`}
          </SMFontBlack>
          <FlexStartContainer>
            <FlexStartText style={{ marginRight: '0.325rem' }}>Valor da consulta:</FlexStartText>
            <FlexStartText bold>{` ${convertCurrencyPtBr(user?.sessionPrice)}`}</FlexStartText>
          </FlexStartContainer>

          {values?.recurrent && (
            <Fragment>
              <FlexStartContainer>
                <FlexStartText bold>Consulta Recorrente:</FlexStartText>
              </FlexStartContainer>

              <FlexStartContainer>
                <FlexStartText bold>Frequencia:</FlexStartText>
                <FlexStartText>{recurrentFrequency}</FlexStartText>
              </FlexStartContainer>

              <FlexStartContainer>
                <FlexStartText bold>Duração:</FlexStartText>
                <FlexStartText>{recurrentDuration}</FlexStartText>
              </FlexStartContainer>
            </Fragment>
          )}

          {values?.postPaid && values?.postPaidDay && (
            <FlexStartContainer style={{ marginBottom: '0.5rem' }}>
              <FlexStartText>Consulta pós-paga: </FlexStartText>
              <FlexStartText bold style={{ marginLeft: '0.5rem' }}>
                {`Pagamento será realizado no dia ${values.postPaidDay}!`}
              </FlexStartText>
            </FlexStartContainer>
          )}
        </FlexWrapper>

        <Hr />
        <Container style={{ padding: '0px' }}>
          {['in_person'].includes(values?.appointmentType) && (
            <FlexWrapper style={{ marginBottom: '1rem' }}>
              <WrapperTitleResum style={{ fontSize: '1rem', paddingBottom: '0.2rem' }}>
                Endereço:
              </WrapperTitleResum>
              <SMFontBlack>
                {`${user?.address?.street} -
                    ${user?.address?.street_number} -
                    ${user?.address?.neighborhood},
                    ${user?.address?.city} -
                    ${user?.address?.state}`}
              </SMFontBlack>
            </FlexWrapper>
          )}
        </Container>
      </HeaderWrapperResum>
    </PaymentContainer>
  );
}

export function ScheduleFormProfessional() {
  const { user } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const schema = Yup.object({
    startDate: Yup.string().required('Data do agendamento é obrigatório!'),
    startTime: Yup.string().required('Horário o agendamento é obrigatório!'),
    appointmentType: Yup.string().required('Tipo de agendamento é obrigatório!'),
    paymentMode: Yup.string().required('Método de pagamento é obrigatório!'),
    clientId: Yup.string().optional().nullable(),
    firstName: Yup.string()
      .nullable()
      .when('clientId', {
        is: (clientId) => !clientId,
        then: Yup.string().nullable().required('Nome é obrigatório!'),
      }),
    lastName: Yup.string()
      .nullable()
      .when('clientId', {
        is: (clientId) => !clientId,
        then: Yup.string().nullable().required('Sobrenome é obrigatório!'),
      }),
    email: Yup.string()
      .nullable()
      .when('clientId', {
        is: (clientId) => !clientId,
        then: Yup.string().nullable().email('E-mail inválido!').required('E-mail é obrigatório!'),
      }),
    phone: Yup.string()
      .nullable()
      .when('clientId', {
        is: (clientId) => !clientId,
        then: Yup.string().nullable().required('Número de telefone é obrigatório!'),
      }),
  });

  const submitForm = async (formValues) => {
    setLoading(true);
    try {
      const request = {
        data: {
          attributes: {
            startDate: formValues.startDate,
            startTime: formValues.startTime,
            appointmentFormat: formValues.appointmentFormat,
            appointmentType: formValues.appointmentType,
            paymentMode: formValues.paymentMode,
            clientId: formValues.clientId,
            isFirstSession: formValues.isFirstSession,
            recurrentDuration: formValues.recurrentDuration,
            recurrentFrequency: formValues.recurrentFrequency,
            clientAttributes: {
              firstName: formValues.firstName,
              lastName: formValues.lastName,
              email: formValues.email,
              phone: formValues.phone,
            },
          },
        },
      };

      if (formValues.appointmentFormat !== 'recurrent') {
        delete request.data.attributes.recurrentDuration;
        delete request.data.attributes.recurrentFrequency;
      }

      if (formValues.clientId) {
        delete request.data.attributes.clientAttributes;
      } else {
        delete request.data.attributes.clientId;
      }

      const { data } = await api.post('/professionals/pre_schedules', request);

      if (data.data) {
        toast.success('Agendamento criado com sucesso!');
        history.push('/professional/dashboard');
        form.resetForm();
      } else {
        throw new Error(data.errors);
      }
    } catch (error) {
      toast.error('Não foi possível criar o agendamento!');
    } finally {
      setLoading(false);
    }
  };

  const form = useFormik({
    initialValues: {
      startDate: '',
      startTime: '',
      duration: user?.availabilityRule?.appointmentDuration || '30',
      appointmentFormat: 'single_appointment',
      appointmentType: '',
      paymentMode: 'pre_paid',
      postPaidDay: null,
      postPaid: false,
      clientId: null,
      clientName: null,
      isFirstSession: false,
      recurrent: false,
      recurrentDuration: null,
      recurrentFrequency: null,

      // Not Client
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: (values) => {
      submitForm(values);
    },
  });

  return (
    <Main>
      {loading ? (
        <LoaderSpinner logo />
      ) : (
        <FormikProvider value={form}>
          <CalendarComponentProfessional row form={form} />
        </FormikProvider>
      )}
    </Main>
  );
}
