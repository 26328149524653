import { QRCodeSVG } from 'qrcode.react';
import styled from 'styled-components';
import { ReactComponent as MedalPlanFree } from '../../../../images/iconMedalWhite.svg';
import {
  AnimationWrapper,
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
  BigFont,
  ScrollCardStyle,
  SmallFont,
} from '../../../../styles/globalStyles';

export const Main = styled(AnimationWrapper)`
  display: block;
  width: 100%;
`;

export const MainCreateEvent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const PaymentWrapper = styled(AnimationWrapper)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    grid-template-columns: 1fr;
    row-gap: 2rem;
    height: auto;
  }
`;

export const PaymentCreditCard = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.iceBackground};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem 0;
  width: 100%;
  height: 100%;
`;

export const HeaderWrapperResum = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.iceBackground};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 2rem;
  min-height: 25rem;
  max-height: 25rem;
  width: 100%;
`;

export const WrapperTitleResum = styled(BigFont)`
  color: ${({ theme }) => theme.darkNavy};
  font-size: 1.3rem;
  font-weight: 700;
  padding-bottom: 10px;
  text-align: left;
`;

export const SMFontBlack = styled(SmallFont)`
  text-align: left;
  color: ${({ theme }) => theme.darkNavy};
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
`;

export const FlexStartText = styled(SmallFont)`
  color: ${({ theme }) => theme.darkNavy};
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
`;

export const FlexStartContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 5px 0;
  width: 100%;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: flex-start;
`;

export const Hr = styled.hr`
  border: 1px solid ${({ theme }) => theme.neutralGray};
  margin: 1.5rem 0;
  width: 100%;
`;

export const ButtonCoupon = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-radius: 2rem;
  border: 1px solid ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.secondary};
  cursor: pointer;
  display: flex;
  font-size: 0.8rem;
  font-weight: 700;
  height: fit-content;
  justify-content: center;
  padding: 0.7rem 0.5rem;
  width: 40%;
`;

export const ContainerCardPayment = styled(ScrollCardStyle)`
  width: 100%;
  height: 40vh;
  padding: 0 2rem;
  overflow-y: auto;
`;

export const ButtonAddCreditCard = styled.button`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme, secondary }) => secondary && theme.primary};
  border-radius: 2rem;
  color: ${({ theme }) => theme.primary};
  cursor: pointer;
  font-weight: 700;
  height: 2.3rem;
  margin: ${({ secondary }) => (secondary ? '2rem 0 0 0' : '0 2.5rem')};
  width: 60%;

  &:hover {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};
  }

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    width: 70%;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    margin: ${({ secondary }) => (secondary ? '2rem 0 0 0' : '0 1.5rem')};
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 100%;
  }
`;

export const WrapperPayment = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const WrapperPaymentPix = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`;

export const CardButtonMethod = styled.button`
  align-items: center;
  background-color: ${({ theme, checked }) => (checked ? theme.secondary : theme.white)};
  border-radius: 2rem;
  color: ${({ theme, checked }) => (checked ? theme.white : theme.secondary)};
  cursor: pointer;
  display: flex;
  font-size: 0.8rem;
  height: 2.5rem;
  font-weight: 700;
  justify-content: center;
  width: 45%;
  margin: 0 1rem;
`;

export const PaymentButton = styled.button`
  align-items: center;
  background-color: ${({ theme, checked }) => (checked ? theme.primary : theme.white)};
  border-radius: 2rem;
  color: ${({ theme, checked }) => (checked ? theme.white : theme.primary)};
  cursor: pointer;
  display: flex;
  font-size: 0.8rem;
  font-weight: 700;
  height: 2.5rem;
  justify-content: center;
  padding: 0 0.5rem;
  width: 45%;

  &:hover {
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primary};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      background-color: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.white};
      border-color: ${({ theme }) => theme.primary};
    }
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    margin-bottom: 3rem;
    width: 100%;
  }
`;

export const CancelButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-radius: 2rem;
  border: 1px solid ${({ theme }) => theme.errorRed};
  color: ${({ theme }) => theme.errorRed};
  cursor: pointer;
  display: flex;
  font-size: 0.8rem;
  font-weight: 700;
  height: 2.5rem;
  justify-content: center;
  padding: 0 0.5rem;
  width: 45%;

  &:hover {
    background-color: ${({ theme }) => theme.errorRed};
    border: 1px solid ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.white};
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 100%;
  }
`;

export const MedalIcon = styled(MedalPlanFree)`
  height: 20px;
  margin-left: 0.5rem;
  width: 20px;
`;

export const RowButtons = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    flex-direction: column;
  }
`;

export const PixQrCode = styled(QRCodeSVG)`
  height: 60%;
  padding: 1rem 0;
  width: 60%;
`;

export const RowLine = styled.section`
  display: flex;
  width: 100%;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonDayWeek = styled.button`
  align-items: center;
  background-color: ${({ theme, checked }) => (checked ? theme.primary : theme.white)};
  border-radius: 2rem;
  color: ${({ theme, checked }) => (checked ? theme.white : theme.primary)};
  cursor: pointer;
  display: flex;
  font-size: 0.8rem;
  font-weight: 700;
  height: 2.5rem;
  justify-content: center;
  padding: 0 0.5rem;
  width: 7rem;

  &:hover {
    background-color: ${({ theme, checked }) => (checked ? theme.primary : theme.white)};
    border: 1px solid ${({ theme }) => theme.primary};
    color: ${({ theme, checked }) => (checked ? theme.white : theme.primary)};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    margin-bottom: 3rem;
    width: 100%;
  }
`;

export const ButtonTime = styled(ButtonDayWeek)`
  border-radius: 0.5rem;
  width: 3.5rem;
`;

export const ButtonAddPlans = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 2rem;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  display: flex;
  font-size: 0.8rem;
  font-weight: 700;
  height: 2.5rem;
  justify-content: center;
  padding: 0 0.5rem;
  width: 40%;

  &:hover {
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primary};
    opacity: 0.8;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    width: 80%;
  }
`;
