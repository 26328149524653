import styled from 'styled-components';
import {
  BREAKPOINT_LG_PX,
  BREAKPOINT_SM_PX,
  ModalBoxWrapper,
} from '../../../../styles/globalStyles';

export const BoxWrapperLesson = styled(ModalBoxWrapper)`
  min-width: 60%;
  min-height: 60%;
  max-height: 95%;
`;

export const Main = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2rem;
  width: 100%;
`;

export const Title = styled.h1`
  font-size: ${({ webinar }) => (webinar ? '1.5rem' : '1.7rem')};
  font-weight: 700;
  margin-bottom: ${({ webinar }) => (webinar ? '1rem' : '2rem')};
  text-align: center;
  width: 100%;
  color: ${({ theme, webinar }) => (webinar ? theme.primary : theme.darkNavy)};

  @media only screen and (max-width: ${BREAKPOINT_LG_PX}) {
    font-size: ${({ webinar }) => (webinar ? '1rem' : '1.2rem')};
  }
`;

export const Text = styled.p`
  color: ${({ theme, webinar, error }) =>
    error ? theme.errorRed : webinar ? theme.primary : theme.darkNavy};
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: ${({ webinar }) => (webinar ? '1rem' : '2rem')};
  text-align: ${({ webinar }) => (webinar ? 'center' : 'justify')};
  width: 70%;

  @media only screen and (max-width: ${BREAKPOINT_LG_PX}) {
    font-size: 1.2;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    font-size: 1rem;
  }
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.primary};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  font-size: 1.2rem;
  height: 3rem;
  margin-top: 3rem;
  width: 25rem;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    height: 2.5rem;
    width: 15rem;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
