export default function getPlanName(plan) {
  switch (plan) {
    case 'free':
      return 'Gratuito';
    case 'silver':
      return 'Prata';
    case 'silver_annual_1x':
      return 'Prata Anual 1x';
    case 'silver_annual_3x':
      return 'Prata Anual 3x';
    case 'silver_annual_6x':
      return 'Prata Anual 6x';
    case 'silver_annual_12x':
      return 'Prata Anual 12x';
    case 'gold':
      return 'Ouro';
    case 'gold_annual_1x':
      return 'Ouro Anual 1x';
    case 'gold_annual_3x':
      return 'Ouro Anual 3x';
    case 'gold_annual_6x':
      return 'Ouro Anual 6x';
    case 'gold_annual_12x':
      return 'Ouro Anual 12x';
    case 'platinum':
      return 'Gestão';
    default:
      return 'Cancelado';
  }
}

export const PLAN_SILVER_MONTH = 59.9;
export const PLAN_SILVER_1X = 565;
export const PLAN_SILVER_3X = 188.33;
export const PLAN_SILVER_6X = 94.17;
export const PLAN_SILVER_12X = 47.08;

export const PLAN_GOLD_MONTH = 89.9;
export const PLAN_GOLD_1X = 855;
export const PLAN_GOLD_3X = 285;
export const PLAN_GOLD_6X = 142.5;
export const PLAN_GOLD_12X = 71.25;
