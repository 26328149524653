/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Logo from '../../../images/logoLogin.png';
import {
  ImageContainer,
  LoginLink,
  LoginParagraph,
  LoginTitle,
  LogoImg,
} from '../../../screens/Public/Login/styles';
import api from '../../../services/api';
import { PtBr } from '../../../services/pt_br';
import theme from '../../../theme';
import handleErrors from '../../../utils/getValuesErrors';
import FormikControl from '../../FormikControl';
import { BoxWrapper, ClientForm, InputWrapper, LoginWrapper, SendButton } from '../styles';

function getSchoolTypeName(name, type) {
  if (type === 'toPt') {
    switch (name) {
      case 'student':
        return 'Estudante';
      case 'professor':
        return 'Professor(a) / Colaborador(a)';
      case 'parent':
        return 'Pai / Mãe / Responsável';
      case 'noon_app':
        return 'Usuário NoonApp';
      default:
        return 'Estudante';
    }
  }
  if (type === 'toEn') {
    switch (name) {
      case 'Estudante':
        return 'student';
      case 'Professor(a) / Colaborador(a)':
        return 'professor';
      case 'Pai / Mãe / Responsável':
        return 'parent';
      case 'Usuário NoonApp':
        return 'noon_app';
      default:
        return 'student';
    }
  }
}

export default function ClientSignUpForm({ form, handleSubmit, updating = false, userId }) {
  const { values, setFieldValue, handleChange, handleBlur, errors, touched } = form;
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const history = useHistory();

  const handleDeleteClient = async () => {
    try {
      const { data } = await api.delete(`/clients/${userId}`);
      if (data) {
        toast.info('A conta foi excluída.');
        history.push('/conectar-se');
      }
    } catch (error) {
      toast.error('Falha ao tentar excluir conta.');
    }
  };

  useEffect(() => {
    if (values.schoolName !== null && values.schoolId !== null) {
      setFieldValue({
        schoolName: values.schoolName,
        schoolId: values.schoolId,
      });
    }
  }, []);

  useEffect(() => {
    if (moment.duration(moment(new Date()).diff(moment(values.birthday))).asYears() >= 18) {
      setFieldValue('legalGuardianFullName', '');
      setFieldValue('legalGuardianEmail', '');
      setFieldValue('legalGuardianDocumentNumber', '');
      setFieldValue('legalGuardianPhone', '');
    }
  }, [values.birthday]);

  function onSubmit() {
    if (!handleErrors(errors, [])) {
      if (moment.duration(moment(new Date()).diff(moment(values.birthday))).asYears() < 18) {
        if (values.legalGuardianDocumentNumber === '') {
          toast.error('CPF do responsável é obrigatório!');
        }
        if (values.legalGuardianEmail === '') {
          toast.error('Email do responsável é obrigatório!');
        }
        if (values.legalGuardianFullName === '') {
          toast.error('Nome completo do responsável é obrigatório!');
        }
        if (values.legalGuardianPhone === '') {
          toast.error('Telefone do responsável é obrigatório!');
        }

        if (
          values.legalGuardianDocumentNumber !== '' &&
          values.legalGuardianEmail !== '' &&
          values.legalGuardianFullName !== '' &&
          values.legalGuardianPhone !== ''
        ) {
          handleSubmit({ ...values });
        }
      } else {
        handleSubmit({ ...values });
      }
    }
  }

  return (
    <ClientForm>
      <LoginWrapper>
        <ImageContainer>
          <LogoImg src={Logo} alt="Logo" />
        </ImageContainer>
        <InputWrapper>
          <LoginTitle>Crie uma conta agora</LoginTitle>
          <FormikControl
            bgColor={theme.white}
            control="input"
            errors={errors.firstName}
            label={PtBr.register_input_firstname}
            labelFamily="Poppins"
            labelSize="0.9rem"
            labelWeight="600"
            name="firstName"
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.firstName}
            upLabel
            value={values.firstName}
          />

          <FormikControl
            bgColor={theme.white}
            control="input"
            errors={errors.lastName}
            label={PtBr.register_input_lastname}
            labelFamily="Poppins"
            labelSize="0.9rem"
            labelWeight="600"
            name="lastName"
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.lastName}
            upLabel
            value={values.lastName}
          />

          {values.schoolId && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '1rem',
                width: '100%',
              }}
            >
              <FormikControl
                bgColor={theme.white}
                control="input"
                disabled
                errors={errors.schoolName}
                label={PtBr.register_input_member}
                labelFamily="Poppins"
                labelSize="0.9rem"
                labelWeight="600"
                name="member"
                touched={touched.schoolName}
                upLabel
                value={values?.schoolName}
              />
              <div style={{ marginTop: '1rem' }}>
                <FormikControl
                  bgColor={theme.white}
                  control="select"
                  defaultValue={getSchoolTypeName(values.schoolType, 'toPt')}
                  label={PtBr.register_input_schoolType}
                  name="schoolType"
                  upLabel
                  value={getSchoolTypeName(values.schoolType, 'toPt')}
                  onChange={({ target }) =>
                    setFieldValue('schoolType', getSchoolTypeName(target.value, 'toEn'))
                  }
                  onBlur={handleBlur}
                  options={[
                    'Estudante',
                    'Professor(a) / Colaborador(a)',
                    'Pai / Mãe / Responsável',
                    'Usuário NoonApp',
                  ]}
                />
              </div>
            </div>
          )}

          <FormikControl
            bgColor={theme.white}
            control="input"
            errors={errors.email}
            label={PtBr.register_input_email}
            labelFamily="Poppins"
            labelSize="0.9rem"
            labelWeight="600"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.email}
            upLabel
            value={values.email}
          />

          <FormikControl
            bgColor={theme.white}
            control="input"
            errors={errors.phone}
            label={PtBr.register_input_phone}
            labelFamily="Poppins"
            labelSize="0.9rem"
            labelWeight="600"
            mask="(99) 99999-9999"
            name="phone"
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.phone}
            upLabel
            value={values.phone}
          />

          <FormikControl
            bgColor={theme.white}
            control="input"
            errors={errors.password}
            label={PtBr.register_input_password}
            labelFamily="Poppins"
            labelSize="0.9rem"
            labelWeight="600"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.password}
            type="password"
            upLabel
            value={values.password}
          />

          <FormikControl
            bgColor={theme.white}
            control="input"
            errors={errors.password_confirmation}
            label={PtBr.register_input_confirm_password}
            labelFamily="Poppins"
            labelSize="0.9rem"
            labelWeight="600"
            name="password_confirmation"
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.password_confirmation}
            type="password"
            upLabel
            value={values.password_confirmation}
          />

          {updating && (
            <Button
              variant="outlined"
              color="error"
              onClick={() => setModal1(true)}
              style={{
                marginTop: '25px',
                textAlign: 'center',
              }}
            >
              Excluir Conta
            </Button>
          )}
          <SendButton
            type="button"
            bgColor={theme.secondary}
            onClick={onSubmit}
            style={{ marginTop: '2rem' }}
            disabled={Object.keys(errors)?.length !== 0}
          >
            Cadastrar
          </SendButton>
          <LoginParagraph style={{ marginTop: '1rem' }}>
            Já possui uma conta?{' '}
            <LoginLink type="button" onClick={() => history.push('/cliente/conectar-se')}>
              Entrar
            </LoginLink>
          </LoginParagraph>
        </InputWrapper>
      </LoginWrapper>

      {modal1 && (
        <Modal open onClose={() => setModal1(false)}>
          <BoxWrapper>
            <Typography variant="h6">Deseja excluir sua conta?</Typography>
            <Typography variant="h6" sx={{ fontSize: '14px' }}>
              Essa ação é irreversível! Você não terá mais acesso a nossa plataforma.
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '10px',
              }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setModal2(true);
                  setModal1(false);
                }}
                style={{ textAlign: 'center' }}
              >
                Sim
              </Button>
              <Button
                variant="outlined"
                onClick={() => setModal1(false)}
                style={{ textAlign: 'center' }}
              >
                Não
              </Button>
            </div>
          </BoxWrapper>
        </Modal>
      )}

      {modal2 && (
        <Modal open onClose={() => setModal2(false)}>
          <BoxWrapper>
            <Typography variant="h6">Tem certeza?</Typography>
            <Typography variant="h6" sx={{ fontSize: '14px' }}>
              Todas as suas informações serão perdidas.
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '10px',
              }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={handleDeleteClient}
                style={{ textAlign: 'center' }}
              >
                Confirmar
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setModal2(false);
                }}
                style={{ textAlign: 'center' }}
              >
                Cancelar
              </Button>
            </div>
          </BoxWrapper>
        </Modal>
      )}
    </ClientForm>
  );
}
