/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { EventCard } from '../../../components/EventElements';
import { Flatlist } from '../../../components/List';
import useAuth from '../../../hooks/auth';
import useAxiosGet from '../../../hooks/useAxiosGet';
import sortedSchedules from '../../../utils/sortedSchedules';
import { Main, ScheduleWrapper } from './styles';

export default function SchedulesReport() {
  const { user } = useAuth();
  const [schedules, setSchedules] = useState([]);
  const [pastSchedules, setPastSchedules] = useState([]);

  const getSchedules = useAxiosGet();
  const getPastSchedules = useAxiosGet();

  useEffect(() => {
    const { controller, data, errors, fetchData } = getSchedules;

    if (data?.length === 0) fetchData(`${user.type}s/schedules`);

    if (data?.length > 0) {
      const parsedData = parsedGetSchedules(data);
      setSchedules(parsedData);
    }

    if (errors) toast.error('Não foi possível carregar os agendamentos');

    return () => controller?.abort();
  }, [getSchedules.loading]);

  useEffect(() => {
    const { controller, data, errors, fetchData } = getPastSchedules;

    if (data?.length === 0) fetchData(`${user.type}s/schedule-history`);

    if (data?.length > 0) {
      const parsedData = parsedGetSchedules(data, 'past');
      setPastSchedules(parsedData);
    }

    if (errors) toast.error('Não foi possível carregar os agendamentos concluidos');

    return () => controller?.abort();
  }, [getPastSchedules.loading]);

  const parsedGetSchedules = (data, type) =>
    data?.map((schedule) => ({
      ...schedule,
      attributes: {
        ...schedule.attributes,
        past: type === 'past',
      },
    }));

  const allSchedules = [...schedules, ...pastSchedules];

  return (
    <Main>
      <ScheduleWrapper>
        <Flatlist
          data={sortedSchedules(allSchedules, true)}
          dateSearch
          filterObject={(schedule) => schedule.attributes.search}
          placeholder="Buscar Consultas"
          renderItem={(schedule, index) => (
            <EventCard
              key={`${schedule.id} ${index}`}
              event={schedule}
              past={schedule.attributes.past}
              reportClient
            />
          )}
          type="schedule"
          searchType="professional"
          loading={getSchedules.loading || getPastSchedules.loading}
        />
      </ScheduleWrapper>
    </Main>
  );
}
