import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import Logo from '../../images/logo-mind-white.svg';
import { Reticence } from '../../styles/globalStyles';
import theme from '../../theme';
import {
  LoaderContainer,
  LoaderSpinnerMain,
  LoaderTextMeeting,
  LoaderWrapper,
  LogoImg,
} from './styles';

function Container({ children }) {
  return <LoaderContainer>{children}</LoaderContainer>;
}

export default function LoaderSpinner({ ...props }) {
  const { size, logo, meeting, ...rest } = props;
  return (
    <Container>
      {logo && <LogoImg src={Logo} alt="Logo" />}
      <LoaderWrapper>
        <LoaderSpinnerMain>
          <CircularProgress sx={{ color: theme.darkBlue }} size={size || '5rem'} {...rest} />
        </LoaderSpinnerMain>
        {meeting && (
          <LoaderTextMeeting>
            Aguarde para iniciar a sessão
            <Reticence delay={0.3}>.</Reticence>
            <Reticence delay={0.6}>.</Reticence>
            <Reticence delay={0.9}>.</Reticence>
          </LoaderTextMeeting>
        )}
      </LoaderWrapper>
    </Container>
  );
}
