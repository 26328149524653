/* eslint-disable react-hooks/exhaustive-deps */
import { FormControlLabel, Modal, Radio } from '@material-ui/core';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Calendar, CalendarTime } from '../../../components/EventElements';
import InviteForm from '../../../components/FormikElements/InviteForm';
import PreSignUpClient from '../../../components/FormikElements/PreSignUpClient';
import { Flatlist } from '../../../components/List';
import {
  PatientListCalendar,
  PatientListItem,
  ProfessionalListCalendar,
} from '../../../components/ListItens';
import LoaderSpinner from '../../../components/LoaderSpinner';
import useAuth from '../../../hooks/auth';
import useEvent from '../../../hooks/event';
import api from '../../../services/api';
import { BoxScroll } from '../../../styles/globalStyles';
import theme from '../../../theme';
import handleErrors from '../../../utils/getValuesErrors';
import useWindowSize from '../../../utils/WindowSize';
import {
  BlueBigText,
  BlueSmallText,
  ButtonContainer,
  ButtonContainerCalendar,
  Hr,
  IconTouchableArea,
  RowContainer,
  ScheduleButton,
  Square,
} from '../../Client/Professionals/styles';
import {
  BigFontModal,
  ButtonTop,
  ButtonWrapperTop,
  CalendarWrapper,
  FormWrapper,
} from '../../Professional/PatientList/styles';
import { Schedule, ScheduleStatus } from './Schedule';
import { CancelButton } from './Schedule/styles';
import {
  BoxWrapper,
  BoxWrapperSchedule,
  ButtonMenu,
  ButtonWrapper,
  CloseIcon,
  IconArea,
  Main,
} from './styles';

export default function ClientList() {
  const { user } = useAuth();
  const [patients, setPatients] = useState([]);
  const { width } = useWindowSize();
  const [loading, setLoading] = useState(true);
  const [formType, setFormType] = useState('invite');

  useEffect(() => {
    const controller = new AbortController();
    const fetchPatients = async () => {
      try {
        const { data } = await api.get('/clinics/patients', {
          signal: controller.signal,
        });
        setPatients(data.data);
      } catch (error) {
        if (!controller.signal.aborted) toast.error('Não foi possível carregar lista de pacientes');
      } finally {
        setLoading(false);
      }
    };
    fetchPatients();

    return () => controller.abort();
  }, []);

  const submitFormInvite = async (values) => {
    setLoading(true);
    try {
      const request = {
        data: {
          attributes: {
            name: values.name,
            email: values.email,
            phone: values.phone,
            city: values.city,
            message: values.message,
            receiver: 'client',
          },
        },
      };
      const { data } = await api.post(`/${user?.type}s/invites`, request);
      if (data) {
        toast.success('Convite enviado com sucesso!');
      }
    } catch (error) {
      toast.error('Não foi possível enviar o convite!');
    } finally {
      form.resetForm();
      setLoading(false);
    }
  };

  const submitFormPreSignUp = async (values) => {
    setLoading(true);
    try {
      const request = {
        data: {
          type: 'client',
          attributes: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
          },
        },
      };
      const { data } = await api.post(`/${user?.type}s/clients`, request);
      if (data) {
        toast.success('Pré cadastro realizado com sucesso!');
      }
    } catch (error) {
      toast.error('Não foi possível realizar o pré cadastro!');
    } finally {
      form.resetForm();
      setLoading(false);
    }
  };

  const schemaInvite = Yup.object({
    name: Yup.string()
      .min(3, 'O nome deve ter pelo menos 3 caracteres')
      .required('Nome é obrigatório!'),
    email: Yup.string().email('Email deve ser um Email válido').required('Email é obrigatório!'),
    phone: Yup.string().optional(),
    city: Yup.string().optional(),
    message: Yup.string().optional(),
  });

  const schemaPreSignUp = Yup.object({
    firstName: Yup.string().nullable().required('Nome é obrigatório!'),
    lastName: Yup.string().nullable().required('Sobrenome é obrigatório!'),
    email: Yup.string().nullable().email('Email inválido').required('Email é obrigatório!'),
    phone: Yup.string().nullable().required('Telefone é obrigatório!'),
  });

  const form = useFormik({
    initialValues: {
      name: '',
      lastName: '',
      email: '',
      phone: '',
      city: '',
      message: '',
    },
    validationSchema: formType === 'invite' ? schemaInvite : schemaPreSignUp,
    onSubmit: async (values) => {
      try {
        if (formType === 'invite') {
          submitFormInvite(values);
        } else {
          submitFormPreSignUp(values);
        }
      } catch (error) {
        toast.error('Email do paciente já se encontra em uso! Favor fornecer um novo email!');
      }
    },
    validateOnChange: true,
  });

  return (
    <Main>
      <Flatlist
        type="patient"
        data={patients}
        filterObject={(item) => item.attributes.client.firstName}
        placeholder="Pesquise um Cliente"
        renderItem={(item, index) => (
          <PatientListItem key={index} data={item} width={width} clinic />
        )}
        loading={loading}
      />

      <FormWrapper>
        <ButtonWrapperTop>
          <ButtonTop
            active={formType === 'invite'}
            onClick={() => {
              setFormType('invite');
              form.resetForm();
            }}
            type="button"
          >
            Convidar Paciente
          </ButtonTop>
          <ButtonTop
            active={formType !== 'invite'}
            onClick={() => {
              setFormType('pre-signup');
              form.resetForm();
            }}
            type="button"
          >
            Cadastrar Paciente
          </ButtonTop>
        </ButtonWrapperTop>
        <form onSubmit={form.handleSubmit} style={{ width: '100%' }}>
          {formType === 'invite' ? (
            <InviteForm form={form} type="client" />
          ) : (
            <PreSignUpClient form={form} />
          )}
        </form>
      </FormWrapper>
    </Main>
  );
}

export function CalendarComponentClinic({ form }) {
  const history = useHistory();
  const { errors, values, setValues, setFieldValue, handleSubmit } = form;
  const { user } = useAuth();
  const { setScheduleInfo } = useEvent();
  const { width } = useWindowSize();
  const [workableDays, setWorkableDays] = useState([]);
  const [unavailableTimes, setUnavailableTimes] = useState(false);
  const [date, setDate] = useState(new Date());
  const [patients, setPatients] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timeBlocks, setTimeBlocks] = useState([]);
  const [modalTime, setModalTime] = useState(false);
  const [modalSchedule, setModalSchedule] = useState(false);
  const [modalScheduleStatus, setModalScheduleStatus] = useState(false);
  const [modalPatient, setModalPatient] = useState(false);

  const FieldValues = ['firstName', 'lastName', 'email', 'phone'];

  useEffect(() => {
    const controllerProfessional = new AbortController();
    const controllerPatients = new AbortController();

    fetchProfessionals(controllerProfessional);
    fetchPatients(controllerPatients);

    return () => {
      controllerProfessional.abort();
      controllerPatients.abort();
    };
  }, []);

  useEffect(() => {
    const updateCalendar = async () => {
      const startOfMonth = moment(date).startOf('month');
      const endOfMonth = moment(date).endOf('month');

      const getDiasMes = async () => {
        const daysWeek = [];
        while (startOfMonth.isBefore(endOfMonth)) {
          daysWeek.push(startOfMonth.format('D'));
          startOfMonth.add(1, 'days');
        }

        const days = daysWeek?.filter(
          (day) =>
            values?.professional?.availabilityRule?.workdays?.filter(
              (item) =>
                new Date(date.getFullYear(), date.getMonth(), day).getDay().toString() === item
            )?.length === 0
        );

        const working = days?.map((item) => new Date(date.getFullYear(), date.getMonth(), item));

        return working;
      };

      const result = await getDiasMes();
      setWorkableDays(result);
    };

    updateCalendar();
  }, [date]);

  const handleContinue = async () => {
    try {
      if (!handleErrors(errors, FieldValues)) {
        setModalTime(true);
        setModalPatient(false);
      }
    } catch (error) {
      toast.error('Email do paciente já se encontra em uso! Favor fornecer um novo email!');
    }
  };

  const fetchProfessionals = useCallback(async (controller) => {
    try {
      const { data } = await api.get('/clinics/my_professionals', {
        signal: controller.signal,
      });
      const serializerProfessionals = data.data.map((item) => ({
        id: item.id,
        attributes: {
          ...item.attributes,
        },
      }));
      setProfessionals(serializerProfessionals);
    } catch (error) {
      toast.error('Não foi possível carregar lista de profissionais');
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchPatients = useCallback(async (controller) => {
    try {
      const { data } = await api.get(
        `/${user?.type}s/${user?.type === 'profesional' ? 'my_' : ''}patients`,
        {
          signal: controller.signal,
        }
      );
      setPatients(data.data);
    } catch (error) {
      if (!controller.signal.aborted) toast.error('Não foi possível carregar lista de pacientes');
    } finally {
      setLoading(false);
    }
  }, []);

  const getAvaiableTimeBlocks = (data) => {
    const ADD_HOURS = 21600; //Add 6 hours in seconds
    const avaiable = data?.attributes?.appointmentTimeBlocks?.map((tb) => ({
      time: moment(tb, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'),
      type: 'available',
    }));

    const checkHour = (hour1, hour2) => {
      const parseHour1 = moment(hour1, 'YYYY-MM-DD HH:mm');
      const parseHour2 = moment(hour2, 'YYYY-MM-DD HH:mm');
      return parseHour1.isBefore(parseHour2);
    };

    const todayDate = moment().format('YYYY-MM-DD');
    const parseDate = moment(date).format('YYYY-MM-DD');

    if (todayDate === parseDate) {
      const parseReatroactiveSchedules = avaiable?.map((tm) => {
        const nowAddSixHours = moment().add(ADD_HOURS, 'seconds').format('YYYY-MM-DD HH:mm');
        const time = moment(tm.time, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
        if (tm.type === 'available') {
          if (checkHour(time, nowAddSixHours)) {
            return { ...tm, type: 'unavailable' };
          } else {
            return tm;
          }
        } else {
          return tm;
        }
      });

      return parseReatroactiveSchedules
        .sort((a, b) => Number(a.time.split(':').join('')) - Number(b.time.split(':').join('')))
        ?.map((tm) => ({
          ...tm,
          time: moment(tm.time, 'YYYY-MM-DD HH:mm').format('HH:mm'),
        }));
    } else {
      return avaiable
        .sort((a, b) => Number(a.time.split(':').join('')) - Number(b.time.split(':').join('')))
        ?.map((tm) => ({
          ...tm,
          time: moment(tm.time, 'YYYY-MM-DD HH:mm').format('HH:mm'),
        }));
    }
  };

  const fetchTimeBlocks = useCallback(
    async (date, id) => {
      if (!id) {
        return toast.info(
          'Você precisa selecionar um profissional antes de escolher o dia do agendamento.'
        );
      }

      const professionalId = id || values?.professionalId;
      const currentDate = moment(date).format('YYYY-MM-DD');

      try {
        const { data } = await api.get(
          `/clinics/professionals/${professionalId}/availabilities?date=${currentDate}`
        );
        const availableTimeBlocks = getAvaiableTimeBlocks(data.data);

        if (availableTimeBlocks?.length !== 0) {
          setTimeBlocks(availableTimeBlocks);
          setUnavailableTimes(availableTimeBlocks?.every((tb) => tb.type === 'unavailable'));
        } else setUnavailableTimes(true);
      } catch (error) {
        toast.error('Erro ao buscar horários disponíveis');
      }
    },
    [date]
  );

  const handleSelectClientDay = (client) => {
    if (unavailableTimes)
      return toast.info('Todos os horários estão indisponíveis. Selecione outro dia.');
    if (timeBlocks.length === 0) return toast.info('Selecione um dia para continuar.');
    setFieldValue('clientId', client.id);
    setFieldValue('clientName', `${client.firstName} ${client?.lastName}`);
    setModalTime(true);
  };

  const handleSelectProfessionalDay = (professional) => {
    setFieldValue('professionalId', professional.id);
    fetchTimeBlocks(date, professional.id);
    setFieldValue(
      'professionalName',
      professional?.socialName || `${professional.firstName} ${professional?.lastName}`
    );
    setFieldValue('professional', professional);
    setFieldValue('step', 1);
  };

  return loading ? (
    <LoaderSpinner />
  ) : (
    <Main>
      <CalendarWrapper>
        <ButtonWrapper>
          <ButtonMenu
            active={values?.step === 0}
            title="Profissional"
            onClick={() => setFieldValue('step', 0)}
          />

          <ButtonMenu
            disabled={!values?.professionalId}
            active={values?.step === 1}
            title="Paciente"
            onClick={() => setFieldValue('step', 1)}
          />
        </ButtonWrapper>
        {values?.step === 0 && (
          <Flatlist
            type="professional"
            data={professionals}
            filterObject={(item) => `${item.attributes.firstName} ${item.attributes.lastName}`}
            placeholder="Pesquise um Profissional"
            renderItem={(item) => (
              <ProfessionalListCalendar
                active={Number(item.id) === values?.professionalId}
                key={item.id}
                data={item}
                width={width}
                handleClick={handleSelectProfessionalDay}
                clinic
              />
            )}
            loading={loading}
          />
        )}
        {values?.step === 1 && (
          <Flatlist
            type="patient"
            data={patients}
            filterObject={(item) => `${item.attributes.firstName} ${item.attributes.lastName}`}
            placeholder="Pesquise um Pacientes"
            renderItem={(item) => (
              <PatientListCalendar
                active={Number(item.id) === values?.clientId}
                key={item.id}
                data={item}
                width={width}
                handleClick={handleSelectClientDay}
                clinic
              />
            )}
            loading={loading}
          />
        )}
      </CalendarWrapper>
      <CalendarWrapper>
        {values?.professionalId && (
          <Fragment>
            <BlueBigText style={{ textAlign: 'center', width: '100%' }}>
              Selecione um dia para agendar
            </BlueBigText>
            <Calendar
              value={date}
              dates={workableDays}
              setDate={(selectDate) => {
                setDate(selectDate);
                fetchTimeBlocks(selectDate, values?.professionalId);
                setFieldValue('clientId', null);
                setFieldValue('clientName', null);
              }}
            />
            <ButtonContainerCalendar>
              <BlueSmallText>
                {unavailableTimes
                  ? 'O dia selecionado não possui horários disponíveis'
                  : '\u2B05\ufe0f Selecione um Profissional e um Paciente para seguir com o agendamento ou após selecionar o Profissional clique em "Cadastrar Paciente" para cadastrar um novo paciente e concluir o agendamento \u27A1\ufe0f'}
              </BlueSmallText>

              {!unavailableTimes && values?.professionalId && (
                <ScheduleButton
                  onClick={() => {
                    setFieldValue('clientId', null);
                    setFieldValue('clientName', null);
                    setModalPatient(true);
                  }}
                  style={{ padding: '0 1rem', minWidth: 'fit-content' }}
                  type="button"
                >
                  Cadastrar Paciente
                </ScheduleButton>
              )}
            </ButtonContainerCalendar>

            <CancelButton
              style={{ fontSize: '1rem', marginTop: '1.5rem' }}
              onClick={() => {
                form.resetForm();
                history.push('/clinic/calendar');
              }}
            >
              Cancelar Agendamento
            </CancelButton>
          </Fragment>
        )}
      </CalendarWrapper>

      {modalPatient && (
        <Modal open>
          <BoxWrapperSchedule>
            <IconArea style={{ padding: '1rem 1rem 0 0' }}>
              <CloseIcon
                onClick={() => {
                  setModalPatient(false);
                }}
              />
            </IconArea>
            <BigFontModal>
              Cadastre um novo paciente para agendar a consulta para este dia.
            </BigFontModal>
            <BoxScroll>
              <PreSignUpClient form={form} schedule />
              <Hr style={{ width: '100%' }} />
              <ButtonContainerCalendar
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  padding: 0,
                }}
              >
                <ScheduleButton
                  onClick={handleContinue}
                  style={{ padding: '0 1rem', width: '15rem' }}
                  type="button"
                >
                  Continuar agendamento
                </ScheduleButton>
              </ButtonContainerCalendar>
            </BoxScroll>
          </BoxWrapperSchedule>
        </Modal>
      )}

      {modalTime && (
        <Modal open>
          <BoxWrapper>
            <IconArea>
              <CloseIcon
                onClick={() => {
                  setModalTime(false);
                  setScheduleInfo({});
                }}
              />
            </IconArea>
            <BoxScroll>
              <CalendarTime
                date={date}
                title={moment(date, 'pt-br').format('DD [de] MMMM [de] YYYY')}
                time={timeBlocks}
                values={values}
                setValues={setValues}
                setDate={setDate}
              />
              <RowContainer style={{ justifyContent: 'flex-start' }}>
                <IconTouchableArea>
                  <Square background={theme.white} />
                  <p style={{ marginLeft: '0.25rem' }}>Horários livres</p>
                </IconTouchableArea>
                <IconTouchableArea>
                  <Square background={theme.softBackground} />
                  <p style={{ marginLeft: '0.25rem' }}>Horários indisponíveis</p>
                </IconTouchableArea>
              </RowContainer>
              <Hr style={{ width: '100%' }} />
              <RowContainer>
                {values?.professional?.availabilityRule?.appointmentTypes?.includes(
                  'in_person'
                ) && (
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{ color: theme.neutralGray }}
                        checked={values?.appointmentType === 'in_person'}
                        name="radio-buttons"
                        value="in_person"
                        onChange={({ target }) => {
                          setFieldValue('appointmentType', target.value);
                        }}
                      />
                    }
                    label="Presencial"
                  />
                )}
                {values?.professional?.availabilityRule?.appointmentTypes?.includes('online') && (
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{ color: '#BCC1E0' }}
                        checked={values?.appointmentType === 'online'}
                        name="radio-buttons"
                        value="online"
                        onChange={({ target }) => {
                          setFieldValue('appointmentType', target.value);
                        }}
                      />
                    }
                    label="Online"
                  />
                )}
              </RowContainer>
            </BoxScroll>
            <Hr style={{ marginTop: '1rem' }} />
            <ButtonContainer style={{ width: '80%', marginTop: '0.5rem' }}>
              <ScheduleButton
                onClick={() => {
                  if (!values?.firstName) {
                    setModalTime(false);
                  } else {
                    setModalPatient(true);
                  }
                }}
                style={{ width: '6rem' }}
              >
                Voltar
              </ScheduleButton>
              <ScheduleButton
                onClick={() => {
                  setValues({
                    ...values,
                    startDate: moment(date).format('YYYY-MM-DD'),
                    startTime: values.startTime,
                    appointmentType: values?.appointmentType,
                  });
                  setModalTime(false);
                  setModalSchedule(true);
                }}
                disabled={!values?.startTime || !values?.appointmentType}
                style={{ width: '15rem' }}
              >
                Continuar agendamento
              </ScheduleButton>
            </ButtonContainer>
          </BoxWrapper>
        </Modal>
      )}

      {modalSchedule && (
        <Modal open>
          <BoxWrapperSchedule>
            <IconArea style={{ padding: '1rem 1rem 0 0' }}>
              <CloseIcon
                onClick={() => {
                  setModalSchedule(false);
                }}
              />
            </IconArea>
            <BoxScroll>
              <Schedule values={values} setValues={setValues} setFieldValue={setFieldValue} />
            </BoxScroll>
            <Hr style={{ marginTop: '1rem' }} />
            <ButtonContainer style={{ width: '80%', marginTop: '0.5rem' }}>
              <ScheduleButton
                onClick={() => {
                  setModalTime(true);
                  setModalSchedule(false);
                }}
                style={{ width: '6rem' }}
              >
                Voltar
              </ScheduleButton>
              <ScheduleButton
                onClick={() => {
                  setModalSchedule(false);
                  setModalScheduleStatus(true);
                }}
                disabled={!values?.startTime || !values?.appointmentType}
                style={{ width: '15rem' }}
              >
                Continuar agendamento
              </ScheduleButton>
            </ButtonContainer>
          </BoxWrapperSchedule>
        </Modal>
      )}

      {modalScheduleStatus && (
        <Modal open>
          <BoxWrapperSchedule>
            <IconArea style={{ padding: '1rem 1rem 0 0' }}>
              <CloseIcon
                onClick={() => {
                  setModalScheduleStatus(false);
                }}
              />
            </IconArea>
            <BoxScroll>
              <ScheduleStatus form={form} />
            </BoxScroll>
            <Hr style={{ marginTop: '1rem' }} />
            <ButtonContainer style={{ width: '80%', marginTop: '0.5rem' }}>
              <ScheduleButton
                onClick={() => {
                  setModalSchedule(true);
                  setModalScheduleStatus(false);
                }}
                style={{ width: '6rem' }}
              >
                Voltar
              </ScheduleButton>
              <ScheduleButton
                onClick={handleSubmit}
                disabled={!values?.startTime || !values?.appointmentType}
                style={{ width: '15rem' }}
              >
                Finalizar agendamento
              </ScheduleButton>
            </ButtonContainer>
          </BoxWrapperSchedule>
        </Modal>
      )}
    </Main>
  );
}
