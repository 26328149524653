/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from '@mui/material';
import valid from 'card-validator';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { X } from 'react-feather';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { SubscribeFormik } from '../../../../components/FormikElements/SubscribeForm';
import { Defaultlist } from '../../../../components/List';
import { CreditCardListItem, PaymentHistoryItemList } from '../../../../components/ListItens';
import LoaderSpinner from '../../../../components/LoaderSpinner';
import {
  ModalSubscription,
  ModalSubscriptionPlan,
} from '../../../../components/Modals/ModalSubscription';
import useAuth from '../../../../hooks/auth';
import useEvent from '../../../../hooks/event';
import LogoSignature from '../../../../images/LOGO_ALLMINDS_AZUL.png';
import api from '../../../../services/api';
import { PLANSNAMES } from '../../../../services/constants';
import { BoxScroll } from '../../../../styles/globalStyles';
import { PLAN_DEMO, PLAN_LEVEL_FULL_ACCESS } from '../../../../utils/constantsLevelAccess';
import getPlanName from '../../../../utils/getPlanName';
import validateCPF from '../../../../utils/validateCPF';
import { EmptyList } from '../../../Public/Professionals';
import { PlansSubscription } from '../../../Register/BasicRegistrationProfessional';
import {
  ButtonCancelPlan,
  ButtonPlan,
  ButtonPlanWrapper,
  Container,
  ContainerCardPayment,
  ContainerLeft,
  ContainerPayment,
  ContainerRight,
  ContainerSignature,
  IconArea,
  ListContainer,
  LogoSignatureImg,
  Main,
  MainPayment,
  MainPlans,
  ModalContainer,
  WrapperSignature,
} from './styles';

export function ListCardPayment({ ...props }) {
  const { data, renderItem } = props;
  const [result, setResult] = useState(data);

  useEffect(() => {
    if (data) {
      data.sort((a, b) => b?.attributes?.default - a?.attributes?.default);
      setResult(data);
    }
  }, [data]);

  return (
    <Container>
      <ListContainer>{result?.map(renderItem)}</ListContainer>
    </Container>
  );
}

export function NewCardPayment() {
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth();
  const { infoCardCredit } = useEvent();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    let defaultCard;
    if (!infoCardCredit) {
      defaultCard = true;
    }
    try {
      setLoading(true);
      if (
        values?.cardNumber?.length < 16 ||
        values?.cardCvv?.length < 3 ||
        values?.cardExpiration_date?.length < 4
      )
        return toast.error('Dados do cartão são requeridos.');

      const request = {
        data: {
          attributes: {
            cardNumber: values?.cardNumber,
            cardExpiration_date: values?.cardExpiration_date,
            cardHolderName: values?.cardHolderName,
            cardCvv: values?.cardCvv,
            default: defaultCard,
          },
        },
      };
      await api.post(`${user.type}s/credit_cards`, request);
      toast.success('Cartão cadastrado com sucesso.');
      if (location?.state?.id) {
        return history.push(`/client/schedule/${location?.state?.id}`);
      }
      if (user.type === 'client') return history.push(`/client/finances`);
      return history.push('/professional/subscription');
    } catch (error) {
      toast.error('Não foi possível adicionar o cartão');
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainPayment>
      <SubscribeFormik handleContinue={handleSubmit} loading={loading} />
    </MainPayment>
  );
}

export function UpdatedPlans() {
  const { setAuthData } = useAuth();
  const [loading, setLoading] = useState(false);

  return (
    <MainPlans>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <SubscribeFormik plan={true} setAuthData={setAuthData} setLoading={setLoading} />
      )}
    </MainPlans>
  );
}

export function PaymentHistory() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [paymentHistory, setPaymentHistory] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const getTransactions = async () => {
      try {
        setLoading(true);
        const url =
          user?.type === 'professional'
            ? `${user?.type}s/subscription/transactions`
            : `${user?.type}s/payments`;
        const { data } = await api.get(url, {
          signal: controller.signal,
        });
        if (user?.type === 'professional') {
          setPaymentHistory(data?.data?.attributes);
        } else setPaymentHistory(data?.data);
      } catch (error) {
        if (!controller.signal.aborted) toast.error('Erro ao buscar histórico de transações');
      } finally {
        setLoading(false);
      }
    };
    getTransactions();

    return () => controller.abort();
  }, []);

  return (
    <BoxScroll>
      <ContainerPayment>
        <h3
          style={{
            fontSize: '1.3rem',
            fontWeight: '700',
            marginBottom: '1.2rem',
            width: '100%',
            textAlign: 'center',
          }}
        >
          Histórico de pagamentos
        </h3>
        {loading ? (
          <LoaderSpinner />
        ) : paymentHistory?.length > 0 ? (
          <Defaultlist
            data={paymentHistory}
            renderItem={(item, index) => <PaymentHistoryItemList key={index} data={item} />}
          />
        ) : (
          <EmptyList text="Parece que você ainda não tem transações!" />
        )}
      </ContainerPayment>
    </BoxScroll>
  );
}

export function CardsList() {
  const { user } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [creditCards, setCreditCards] = useState([]);
  const [filterDefaults, setFilterDefaults] = useState([]);
  const [patchCreditCard, setPatchCreditCard] = useState({});
  const [selected, setSelected] = useState(null);

  const handleDeleteCard = async (id) => {
    try {
      await api.delete(`${user.type}s/credit_cards/${id}`);
      toast.success('Cartão deletado com sucesso.');
      const newCreditCards = creditCards?.filter((item) => item.id !== id);
      setCreditCards(newCreditCards);
    } catch (error) {
      toast.error('Não foi possivel deletar o cartão');
    }
  };

  const handleClickDefault = async (id) => {
    try {
      const { data } = await api.patch(`${user?.type}s/credit_cards/${id}/set_default`);
      setPatchCreditCard(data.data);
      toast.success('Cartão de crédito definido como padrão');
      setLoading(false);
    } catch (error) {
      toast.error('Erro ao definir cartão como padrão');
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const getCreditCards = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`${user.type}s/credit_cards`, {
          signal: controller.signal,
        });
        setCreditCards(data.data);
      } catch (error) {
        if (!controller.signal.aborted) toast.error('Erro ao buscar histórico de cartões');
      } finally {
        setLoading(false);
      }
    };
    getCreditCards();

    return () => controller.abort();
  }, [patchCreditCard]);

  useEffect(() => {
    setFilterDefaults(creditCards?.filter((item) => item.attributes.default === true));
  }, [creditCards]);

  return (
    <ContainerCardPayment>
      <h3
        style={{
          fontSize: '1.3rem',
          fontWeight: '700',
          marginBottom: '0.7rem',
          width: '100%',
          textAlign: 'center',
        }}
      >
        Formas de pagamento
      </h3>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <Fragment>
          <ListCardPayment
            data={creditCards}
            renderItem={(item, index) => (
              <CreditCardListItem
                key={index}
                data={item}
                handleClickDefault={handleClickDefault}
                handleDeleteCard={handleDeleteCard}
                filterDefaults={filterDefaults}
                selected={selected}
                setSelected={setSelected}
              />
            )}
          />
          <ButtonPlan
            secondary={'true'}
            onClick={() => history.push(`/${user?.type}/subscription/add_new_payment`)}
          >
            Adicionar forma de pagamento
          </ButtonPlan>
        </Fragment>
      )}
    </ContainerCardPayment>
  );
}

export default function SubscriptionPlans() {
  const history = useHistory();
  const { user } = useAuth();
  const { currentPlan, creditCards, getCreditCards } = useEvent();
  const [loading, setLoading] = useState(false);
  const [modalCancelPlan, setModalCancelPlan] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    getCreditCards(controller);

    return () => controller.abort();
  }, [getCreditCards]);

  const schema = Yup.object().shape({
    // address
    cep: Yup.string().when('planName', {
      is: (planName) => PLANSNAMES.includes(planName),
      then: Yup.string().nullable().required('CEP é obrigatório.'),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    street: Yup.string().when('planName', {
      is: (planName) => PLANSNAMES.includes(planName),
      then: Yup.string().nullable().required('Endereço é obrigatório!'),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    street_number: Yup.string().when('planName', {
      is: (planName) => PLANSNAMES.includes(planName),
      then: Yup.string().nullable().required('Endereço é obrigatório!'),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    complement: Yup.string().optional().nullable(),
    neighborhood: Yup.string().when('planName', {
      is: (planName) => PLANSNAMES.includes(planName),
      then: Yup.string().nullable().required('Bairro é obrigatório!'),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    city: Yup.string().when('planName', {
      is: (planName) => PLANSNAMES.includes(planName),
      then: Yup.string().nullable().required('Cidade é obrigatório!'),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    state: Yup.string().when('planName', {
      is: (planName) => PLANSNAMES.includes(planName),
      then: Yup.string().nullable().required('Estado é obrigatório!'),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    country: Yup.string().when('planName', {
      is: (planName) => PLANSNAMES.includes(planName),
      then: Yup.string().nullable().required('Pais é obrigatório!'),
      otherwise: Yup.string().nullable().notRequired(),
    }),

    // subscription
    planName: Yup.string().nullable().optional(),
    documentNumber: Yup.string()
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'CPF inválido',
        test(value) {
          if (!value) return true;
          return validateCPF(value || '') || null;
        },
      })
      .when('planName', {
        is: (planName) => PLANSNAMES.includes(planName),
        then: Yup.string().nullable().required('CPF é obrigatório.'),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    cardNumber: Yup.string()
      .nullable()
      .test('test-number', 'Cartão inválido', (value) => !value || valid.number(value).isValid)
      .when('planName', {
        is: (planName) => PLANSNAMES.includes(planName),
        then: Yup.string().nullable().required('Número do cartão é obrigatório.'),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    cardHolderName: Yup.string()
      .nullable()
      .when('planName', {
        is: (planName) => PLANSNAMES.includes(planName),
        then: Yup.string().nullable().required('Nome é obrigatório.'),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    cardCvv: Yup.string()
      .nullable()
      .test(
        'test-cvv',
        'Código de segurança inválido',
        (value) => !value || valid.cvv(value).isValid
      )
      .when('planName', {
        is: (planName) => PLANSNAMES.includes(planName),
        then: Yup.string().nullable().required('CVV é obrigatório.'),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    cardExpiration_date: Yup.string()
      .nullable()
      .test('test-date', 'Data inválida', (value) => !value || valid.expirationDate(value).isValid)
      .when('planName', {
        is: (planName) => PLANSNAMES.includes(planName),
        then: Yup.string().nullable().required('Data de expiração é obrigatória.'),
        otherwise: Yup.string().nullable().notRequired(),
      }),
  });

  const form = useFormik({
    initialValues: {
      open: false,
      openPlan: false,
      step: 0,

      // address
      cep: user?.address?.cep || null,
      street: user?.address?.street || '',
      street_number: user?.address?.street_number || '',
      complement: user?.address?.complement || '',
      neighborhood: user?.address?.neighborhood || '',
      city: user?.address?.city || '',
      state: user?.address?.state || '',
      country: user?.address?.country || 'Brasil',
      documentNumber: user?.documentNumber || '',

      // subscription
      planName: user?.subscription?.planName || 'gold',
      cardNumber: null,
      cardHolderName: null,
      cardCvv: null,
      cardExpiration_date: null,
    },
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: (values) => submitForm(values),
  });

  const submitForm = async (values) => {
    setLoading(true);
    try {
      const request = {
        data: {
          type: 'subscription',
          attributes: {
            planName: values.planName,
            creditCardAttributes: {
              cardNumber: values.cardNumber,
              cardExpiration_date: values.cardExpiration_date?.replace('/', ''),
              cardHolderName: values?.cardHolderName,
              cardCvv: values?.cardCvv,
              default: true,
            },
          },
        },
      };
      await api.post('/professionals/subscriptions', request);
      toast.success(
        `Plano ${getPlanName(values?.planName)} assinado com sucesso! Por favor autentique-se novamente.`
      );
    } catch (error) {
      toast.error('Não foi possível atualizar o plano');
    } finally {
      setLoading(false);
      form.resetForm();
    }
  };

  const { values, setFieldValue } = form;

  const handleClickPlans = () => {
    const creditCard = creditCards?.find((creditCard) => creditCard.attributes.default === true);

    if (!creditCard || creditCards?.length === 0) {
      setFieldValue('openPlan', true);
    } else history.push('/professional/subscription/update_plan');
  };

  const handleCancelPlans = async () => {
    try {
      setLoading(true);
      setModalCancelPlan(false);
      await api.post('professionals/subscription/cancel');
      toast.success('Plano cancelado com sucesso.');
    } catch (error) {
      toast.error('Não foi possível cancelar o plano');
    } finally {
      setLoading(false);
    }
  };

  const currentPlanName = getPlanName(currentPlan?.attributes?.planName).includes('Anual')
    ? getPlanName(currentPlan?.attributes?.planName).split(' ').slice(0, 2).join(' ')
    : getPlanName(currentPlan?.attributes?.planName);

  return (
    <Main style={{ alignItems: 'flex-start' }}>
      <ContainerLeft>
        {loading ? (
          <LoaderSpinner />
        ) : (
          <WrapperSignature>
            <ContainerSignature>
              <p className="--signature-title">Meu plano de assinatura:</p>
              <p className="--signature-type">
                {user?.planLevel === PLAN_DEMO
                  ? 'Demonstração'
                  : user?.trialing && !user?.subscription?.id
                    ? 'Período de teste'
                    : currentPlan?.attributes?.planName
                      ? currentPlanName
                      : 'Sem assinatura'}
                {user?.trialing && user?.expirationDate && !user?.subscription?.id && (
                  <p style={{ fontSize: '0.8rem' }}>
                    {`Expira em: ${moment(user?.expirationDate).format('DD/MM/YYYY')}`}
                  </p>
                )}
              </p>
              <p className="--signature-text">{`De: ${
                currentPlan?.attributes?.currentPeriodStart ||
                moment(currentPlan?.attributes?.createdAt).format('DD/MM/YYYY')
              }`}</p>
              {user?.subscription?.status === 'canceled' && (
                <p className="--signature-text">{`Até: ${
                  currentPlan?.attributes?.currentPeriodEnd
                }`}</p>
              )}

              <ButtonPlanWrapper>
                {PLAN_DEMO !== user?.planLevel && (
                  <ButtonPlan onClick={() => handleClickPlans()}>Mais Planos</ButtonPlan>
                )}
                {PLAN_LEVEL_FULL_ACCESS.includes(user?.planLevel) &&
                  PLAN_DEMO !== user?.planLevel &&
                  user?.subscription?.id &&
                  user?.subscription?.status === 'paid' && (
                    <ButtonCancelPlan onClick={() => setModalCancelPlan(true)}>
                      Cancelar Plano
                    </ButtonCancelPlan>
                  )}
              </ButtonPlanWrapper>
            </ContainerSignature>

            <LogoSignatureImg src={LogoSignature} />
          </WrapperSignature>
        )}
      </ContainerLeft>

      <ContainerRight>
        <PaymentHistory />
      </ContainerRight>

      {modalCancelPlan && (
        <ModalSubscription
          goBack={() => setModalCancelPlan(false)}
          message="Ao cancelar seu plano você não ira aparecer nas buscas para o cliente e seu acesso a plataforma ficará limitado. Deseja continuar?"
          confirm={handleCancelPlans}
        />
      )}

      {values?.openPlan && (
        <Modal open={values?.openPlan}>
          <ModalContainer>
            <IconArea>
              <X
                style={{ cursor: 'pointer', width: '2rem', height: '2rem' }}
                onClick={() => setFieldValue('openPlan', false)}
              />
            </IconArea>
            <Container>
              <PlansSubscription form={form} disableSubmit={true} subscription />
            </Container>
          </ModalContainer>
        </Modal>
      )}

      {values?.open && (
        <form onSubmit={form.handleSubmit}>
          <ModalSubscriptionPlan form={form} loading={loading} setLoading={setLoading} signUp />
        </form>
      )}
    </Main>
  );
}
