import { Box } from '@mui/material';
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'react-feather';
import styled from 'styled-components';
import {
  AnimationWrapper,
  BREAKPOINT_LG_PX,
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BigFont,
  CALENDAR_COLUMN_LG,
  CALENDAR_COLUMN_MD,
  CALENDAR_COLUMN_SM,
  CALENDAR_COLUMN_XL,
  MediumBigFont,
  MediumFont,
  SmallFont,
} from '../../../styles/globalStyles';

export const BodyContainer = styled(AnimationWrapper)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
`;

export const MenuHeader = styled.section`
  align-items: center;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    margin-top: 0.5rem;
  }
`;

export const CalendarContainer = styled.section`
  align-items: flex-start;
  column-gap: 0.7rem;
  display: grid;
  grid-template-columns: repeat(${CALENDAR_COLUMN_XL}, 1fr);
  justify-items: center;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_LG_PX}) {
    grid-template-columns: repeat(${CALENDAR_COLUMN_LG}, 1fr);
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: repeat(${CALENDAR_COLUMN_MD}, 1fr);
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    grid-template-columns: repeat(${CALENDAR_COLUMN_SM}, 1fr);
  }
`;

export const ButtonsContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    max-width: 350px;
    width: 100%;
  }
`;

export const TypeButtonsContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 80%;
`;

export const CalendarButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.softBackground};
  border-radius: 5px;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 5px;
  padding: 5px;
`;

export const TypeButton = styled.button`
  align-items: center;
  background-color: ${({ theme, selected }) => (selected ? theme.primary : theme.neutralGray)};
  border-radius: 5px;
  border: none;
  color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 2px;
  padding: 5px 10px;
`;

export const ArrowLeft = styled(ChevronLeft)`
  color: ${({ theme }) => theme.darkNavy};
`;

export const ArrowsLeft = styled(ChevronsLeft)`
  color: ${({ theme }) => theme.darkNavy};
`;

export const ArrowRight = styled(ChevronRight)`
  color: ${({ theme }) => theme.darkNavy};
`;

export const ArrowsRight = styled(ChevronsRight)`
  color: ${({ theme }) => theme.darkNavy};
`;

export const DateInfo = styled.section`
  display: flex;
  padding: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const InfoWeekDay = styled(MediumBigFont)`
  font-weight: 500;
  color: ${({ theme }) => theme.secondary};
`;

export const InfoDay = styled(MediumBigFont)`
  font-weight: 400;
  color: ${({ theme }) => theme.secondary};
`;

export const InfoMonth = styled(BigFont)`
  font-weight: 900;
  color: ${({ theme }) => theme.darkNavy};
`;

export const TimeItemList = styled.section`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.neutralGray};
  display: flex;
  flex-direction: row;
  height: 80px;
  justify-content: flex-start;
  width: 80%;

  @media only screen and(max-width: ${BREAKPOINT_SM_PX}) {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const TimeText = styled(SmallFont)`
  color: ${({ theme }) => theme.secondary};
  font-weight: 400;
  padding: 0 25px;
`;

export const WeekItemList = styled.section`
  background-color: ${({ theme, current }) =>
    current === 'true' ? theme.neutralGray : theme.white};
  border-radius: ${({ current }) => (current === 'true' ? '0.325rem' : '0')};
  align-items: ${({ active }) => (active === 'true' ? 'stretch' : 'center')};
  display: flex;
  flex-direction: column;
  justify-content: ${({ active }) => (active === 'true' ? 'center' : 'flex-start')};
  max-width: 350px;
  padding: ${({ current }) => (current === 'true' ? '0 0.5rem 0.5rem 0.5rem' : '0')};
  width: 100%;
`;

export const WeekList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    margin-top: 1rem;
  }
`;

export const WeekText = styled(MediumFont)`
  background-color: ${({ theme, current }) =>
    current === 'true' ? theme.neutralGray : 'transparent'};
  font-weight: ${({ active }) => (active === 'true' ? '700' : '400')};
  padding: ${({ active }) => (active === 'true' ? '0' : '25px 25px 0 10px')};
  text-align: center;
  width: 100%;
`;

export const ContainerImg = styled.div`
  align-items: center;
  border-radius: 0.325rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 0.5rem;
  width: 100%;
`;

export const ExportCalendar = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.primary};
  box-shadow: 0 0 5px ${({ theme }) => theme.primary};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.2rem 0.5rem;
  width: fit-content;
`;

export const AddSchedule = styled(ExportCalendar)`
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.white};
  padding: 0.35rem 0.7rem;
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  position: fixed;
  right: 1rem;
  top: 5.5rem;
  width: auto;
`;

export const BoxModalWrapper = styled(Box)`
  align-items: center;
  background-color: #f4f5fa;
  border-radius: 1rem;
  border: 1px solid #bcc1e0;
  box-shadow: 24;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  left: 50%;
  max-height: 65%;
  max-width: 40%;
  padding: 2.5rem;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    max-width: 60%;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    height: 550px;
    max-height: 90%;
    max-width: 90%;
    padding: 1rem;
  }
`;
