export function handleStatusSchedule(status) {
  switch (status) {
    case 'pending':
      return 'Aguardando confirmação';
    case 'approved':
      return 'Aprovado';
    case 'pre_registered':
      return 'Pre-registrado';
    default:
      return 'N/A';
  }
}

export function getPaymentMode(paymentMode) {
  switch (paymentMode) {
    case 'pre_paid':
      return 'Pré-pago';
    case 'post_paid':
      return 'Pós-pago';
    case 'external':
      return 'Externo';
    default:
      return 'A definir pelo paciente';
  }
}

export function getRecurrentFrequency(value) {
  switch (value) {
    case 'weekly':
      return 'Semanalmente';
    case 'biweekly':
      return 'Quinzenalmente';
    case 'monthly':
      return 'Mensalmente';
    default:
      return '';
  }
}

export function getRecurrentDuration(value) {
  switch (value) {
    case 'one_month':
      return '1 mês';
    case 'two_month':
      return '2 meses';
    case 'three_month':
      return '3 meses';
    default:
      return '';
  }
}
