import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import styled from 'styled-components';

export const DownloadButton = styled.button`
  align-items: center;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.primary};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 20px;
  justify-content: center;
  padding: 0.9rem 0.8rem;
  width: 20px;
`;

export const Icon = styled(SimCardDownloadIcon)``;
