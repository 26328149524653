import { Box } from '@material-ui/core';
import { AlertTriangle, Bell, MessageSquare, Send, X } from 'react-feather';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { SmallButton } from '../../../components/Button';
import { ReactComponent as Triangle } from '../../../images/alert-triangle-icon.svg';
import { ReactComponent as Help } from '../../../images/helpIcon.svg';
import { ReactComponent as ThumbsUp } from '../../../images/thumbs-uo-icon.svg';
import {
  AnimationWrapper,
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BigFont,
  ExtrBigFont,
  MediumBigFont,
  SmallFont,
} from '../../../styles/globalStyles';

export const Hr = styled.hr`
  border: 1px solid #bcc1e0;
  width: 95%;
`;

export const ColumnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
`;

export const Container = styled.section`
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
`;

export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
`;

export const ExBanner = styled.div`
  display: flex;
  z-index: 1;
  position: relative;
  padding: 0 20%;
  min-height: 280px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.softBackground};
  border-radius: 0px 0px 50% 50% / 0px 0px 2% 2%;
`;

export const Main = styled.div`
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.lightGray};
`;
export const ProfileBanner = styled.div`
  flex: 1;
  z-index: 10;
  display: flex;
  padding: 80px 50px;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  background-color: ${({ theme }) => theme.neutralGray};

  border-radius: 0px 0px 50% 50% / 0px 0px 5% 5%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    flex-direction: column;
  }
`;

export const VectorProfile = styled.img`
  position: absolute;
  bottom: 10px;
`;

export const ProfileWrapper = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
`;

export const EventWrapper = styled(Wrapper)`
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const ProfileAvatar = styled.img`
  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  border-radius: 180px;
  justify-content: center;
  border: 6px solid ${({ theme }) => theme.primary};
  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    width: 120px;
    height: 120px;
  }
`;

export const WrapperContainer = styled.div`
  padding-left: 40px;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 20px;
`;

export const EventContainer = styled.div`
  padding-left: 20px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`;

export const ProfileName = styled(ExtrBigFont)`
  text-align: left;
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.secondary};
`;

export const SeeMyProfile = styled(SmallFont)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  font-weight: 600;
  color: ${({ theme }) => theme.secondary};
`;

export const SmallText = styled(SmallFont)`
  font-weight: 400;
  text-align: left;

  color: ${({ theme }) => theme.secondary};
`;

export const ExtraBigText = styled(BigFont)`
  text-align: left;
  padding-right: 10px;
  font-weight: 400;
  color: ${({ theme }) => theme.secondary};
`;

export const IconTouchableArea = styled.button`
  border: none;
  display: flex;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
`;

export const BellIcon = styled(Bell)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.lightGray};
`;

export const MessageSquareIcon = styled(MessageSquare)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.lightGray};
`;

// body

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 50px 50px;
  align-self: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.lightGray};
`;

export const SearchWrapper = styled.section`
  flex: 1;
  width: 50%;
  height: 100%;
  display: flex;
  padding: 50px 0;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: center;
`;

export const PostListWrapper = styled.section`
  flex: 2;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;

  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const BigText = styled(BigFont)`
  color: ${({ theme }) => theme.secondary};
`;

export const WhiteBigText = styled(BigFont)`
  text-align: center;
  color: ${({ theme }) => theme.lightGray};
`;
export const SecondaryBigText = styled(BigFont)`
  text-align: center;
  color: ${({ theme }) => theme.secondary};
`;

export const WhiteSmallText = styled(SmallText)`
  text-align: center;
  color: ${({ theme }) => theme.lightGray};

  z-index: 2;
`;
export const SecondarySmallText = styled(SmallText)`
  text-align: center;
  color: ${({ theme }) => theme.secondary};

  z-index: 2;
`;

export const LightBigText = styled(BigFont)`
  opacity: 0.7;
  font-weight: 400;
  color: ${({ theme }) => theme.secondary};
`;
export const LightSmallText = styled(SmallText)`
  opacity: 0.7;
  color: ${({ theme }) => theme.secondary};
`;

export const RowIten = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
`;

export const ProfileVideo = styled(ReactPlayer)`
  width: 100%;
  z-index: 0;
  margin-top: -1.5%;
  height: 100%;
  display: flex;
`;

export const AbsoluteCircle = styled.div`
  display: flex;
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 120px;
  height: 120px;
  border-radius: 60px;
  background-color: ${({ theme }) => theme.secondary};
`;

export const TriangleAlert = styled(Triangle)`
  width: 24px;
  height: 24px;
  padding-left: 5px;
`;
export const HelpIcon = styled(Help)`
  width: 15px;
  height: 18px;
  padding-left: 5px;
`;
export const ThumbsUpIcon = styled(ThumbsUp)`
  width: 15px;
  height: 18px;
  padding-right: 5px;
`;

export const ThumbsUpIconPrimary = styled(ThumbsUp)`
  width: 18px;
  height: 18px;
  margin: 0 5px 0 10px;
  align-self: center;
  color: ${({ theme }) => theme.primary};
`;

export const ButtonsContainer = styled.section`
  width: 90%;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ScheduleButtonProfile = styled(SmallButton)`
  height: 40px;
  width: 150px;
  margin-right: 5px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.softBackground};
  padding: 0 10px;
  border: none;
`;

export const MessageButton = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: fit-content;
  border-radius: 10px;
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.secondary};
  background-color: ${({ theme }) => theme.white};

  .-icon {
    color: ${({ theme }) => theme.secondary};
    width: 20px;
    height: 20px;
  }
`;

export const SendIcon = styled(Send)``;

// body

export const ProfileInformation = styled.div`
  flex: 1.1;
  margin-right: 20px;
  min-height: 400px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.softBackground};
`;

export const ProfileAvailability = styled.section`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`;

export const RowContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem 0;
  width: 100%;
`;

export const ContentContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  padding: 20px 30px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ListItem = styled.li``;

export const Recomendations = styled(AnimationWrapper)`
  flex: 1;
  display: flex;
  padding-top: 50px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
`;

export const RecomendationsTitle = styled(MediumBigFont)`
  text-align: left;
  font-weight: 300;
  color: ${({ theme }) => theme.secondary};
`;

export const RecomendationsNumber = styled(MediumBigFont)`
  text-align: left;
  font-weight: 200;
  padding: 0 5px;
  color: ${({ theme }) => theme.secondary};
`;

export const EmptyRecomendations = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  padding: 30px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EmptyRecomendationsText = styled(BigFont)`
  text-align: center;
  color: ${({ theme }) => theme.primary};
`;

export const ButtonContainer = styled.section`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-around;
  padding-top: 15px;
  width: 90%;
`;

export const ButtonContainerCalendar = styled(ButtonContainer)`
  align-items: flex-end;
  background-color: ${({ theme }) => theme.lightGray};
  gap: 1rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.lightGray};
  border-radius: 0.5rem;
  padding: 0.5rem;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    flex-direction: column;
  }
`;

export const BlueBigText = styled(BigFont)`
  color: ${({ theme }) => theme.primary};
  font-size: 1.7rem;
  font-weight: 700;
`;

export const BlueSmallText = styled(SmallFont)`
  color: ${({ theme }) => theme.primary};
  font-size: 1.2em;
  font-weight: 600;
  text-align: justify;
`;

export const ScheduleButton = styled.button`
  align-items: center;
  background-color: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.primary)};
  border-radius: 2rem;
  border: 1px solid ${({ theme, bgColor }) => (bgColor ? bgColor : theme.primary)};
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  height: 3rem;
  justify-content: center;
  width: 100%;

  &:disabled {
    cursor: default;
    opacity: 0.5;
    &:hover {
      background-color: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.primary)};
      border: none;
      color: ${({ theme }) => theme.white};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.white};
    color: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.primary)};
  }
`;

export const Square = styled.div`
  background-color: ${({ background }) => (background ? background : 'transparent')};
  border: 1px solid #bcc1e0;
  border-radius: 2px;
  height: ${({ size }) => (size ? size : '1rem')};
  width: ${({ size }) => (size ? size : '1rem')};
`;

export const BoxWrapper = styled(Box)`
  align-items: center;
  background-color: #f4f5fa;
  border-radius: 1rem;
  border: 1px solid #bcc1e0;
  box-shadow: 24;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  max-height: 70%;
  max-width: 60%;
  padding: 1rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 960px;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    max-width: 90%;
  }
`;

export const BoxWrapperSchedule = styled(BoxWrapper)`
  padding: 0;
  padding-bottom: 1rem;
`;

export const IconArea = styled.div`
  display: flex;
  width: 95%;
  justify-content: flex-end;
`;

export const CloseIcon = styled(X)`
  cursor: pointer;
  height: 2rem;
  width: 2rem;
`;

export const WarningIcon = styled(AlertTriangle)`
  height: 2rem;
  width: 2rem;
  color: ${({ theme }) => theme.primary};
`;
