import styled from 'styled-components';

export const HeaderMain = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.secondary};
  display: flex;
  flex-direction: column;
  grid-area: header;
  height: 12vh;
  justify-content: center;
  padding: 1rem;
  width: 100%;
`;

export const ContainerLogo = styled.a`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  width: 10rem;
`;

export const HeaderLogoImg = styled.img`
  height: auto;
  width: auto;
`;

export const HeaderNav = styled.button`
  align-items: center;
  background-color: transparent;
  color: ${({ theme }) => theme.white};
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  left: 1rem;
  opacity: 1;
  position: absolute;
  text-decoration: underline;
  top: 1rem;

  &:disabled {
    opacity: 0;
  }
`;
