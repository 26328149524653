/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from '@material-ui/core';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { AddressFormik } from '../../../components/FormikElements/AddressForm';
import PaymentForm from '../../../components/FormikElements/PaymentForm';
import useAuth from '../../../hooks/auth';
import IconWarning from '../../../images/alert-triangle-icon.svg';
import { LoginTitle } from '../../../screens/Public/Login/styles';
import { PLANSNAMES } from '../../../services/constants';
import theme from '../../../theme';
import convertCurrencyPtBr from '../../../utils/convertCurrencyPtBr';
import getPlanName, {
  PLAN_GOLD_12X,
  PLAN_GOLD_1X,
  PLAN_GOLD_3X,
  PLAN_GOLD_6X,
  PLAN_GOLD_MONTH,
  PLAN_SILVER_12X,
  PLAN_SILVER_1X,
  PLAN_SILVER_3X,
  PLAN_SILVER_6X,
  PLAN_SILVER_MONTH,
} from '../../../utils/getPlanName';
import handleErrors from '../../../utils/getValuesErrors';
import { SmallButton } from '../../Button';
import LoaderSpinner from '../../LoaderSpinner';
import { NewSelect } from '../../SelectList';
import {
  AddressWrapper,
  BoxWrapper,
  Button,
  ButtonCancel,
  Container,
  ContainerButtons,
  Hr,
  Icon,
  IconArea,
  ModalContainer,
  PaymentWrapper,
  PlanSelectContainer,
  PlansParceledContainer,
  PlansParceledDescription,
  PlansParceledSpan1,
  PlansParceledSpan2,
  PlansParceledWrapper,
  TextAlert,
  TitleAlert,
  TitleWrapper,
} from './styles';

const CardRegistrationValues = [
  'planName',
  'cardNumber',
  'cardHolderName',
  'cardCvv',
  'cardExpiration_date',
  'documentNumber',
];

const AddressRegistrationValues = [
  'cep',
  'street',
  'street_number',
  'complement',
  'neighborhood',
  'city',
  'state',
  'country',
];

export function ModalSubscription({
  buttonsHeight = '2.5rem',
  confirm,
  goBack,
  message,
  title = 'Atenção',
  type = 'confirm',
}) {
  return (
    <Modal open>
      <ModalContainer>
        <Container>
          <TitleWrapper>
            <IconArea>
              <Icon src={IconWarning} alt="Atenção" />
            </IconArea>
            <TitleAlert>{title}</TitleAlert>
          </TitleWrapper>
          <TextAlert>{message}</TextAlert>
          <ContainerButtons>
            <SmallButton
              width="30%"
              height={buttonsHeight}
              color={type === 'confirm' ? theme.errorRed : theme.errorRed}
              type="button"
              title="Voltar"
              onClick={goBack}
            />
            <SmallButton
              width="30%"
              height={buttonsHeight}
              color={type === 'confirm' ? theme.primary : theme.errorRed}
              type="button"
              title="Confirmar"
              onClick={(data) => confirm(data)}
            />
          </ContainerButtons>
        </Container>
      </ModalContainer>
    </Modal>
  );
}

function PlansParceled({ form }) {
  const { errors, values, setFieldValue, touched } = form;

  function PlanDescription({ value, label, total }) {
    return (
      <PlansParceledContainer>
        <PlansParceledWrapper>
          <PlansParceledDescription>
            {getPlanName(label)}
            {PLANSNAMES.includes(label) ? ' Mensal' : ''}
            <PlansParceledSpan1>{convertCurrencyPtBr(value)}</PlansParceledSpan1>
          </PlansParceledDescription>

          {total && <PlansParceledSpan2>Total {convertCurrencyPtBr(total)}</PlansParceledSpan2>}
        </PlansParceledWrapper>
      </PlansParceledContainer>
    );
  }

  const options = useMemo(() => {
    const optionsfull = [
      {
        value: 'silver',
        label: PlanDescription({ label: 'silver', value: PLAN_SILVER_MONTH }),
      },
      {
        value: 'silver_annual_1x',
        label: PlanDescription({ label: 'silver_annual_1x', value: PLAN_SILVER_1X }),
      },
      {
        value: 'silver_annual_3x',
        label: PlanDescription({
          label: 'silver_annual_3x',
          value: PLAN_SILVER_3X,
          total: PLAN_SILVER_1X,
        }),
      },
      {
        value: 'silver_annual_6x',
        label: PlanDescription({
          label: 'silver_annual_6x',
          value: PLAN_SILVER_6X,
          total: PLAN_SILVER_1X,
        }),
      },
      {
        value: 'silver_annual_12x',
        label: PlanDescription({
          label: 'silver_annual_12x',
          value: PLAN_SILVER_12X,
          total: PLAN_SILVER_1X,
        }),
      },
      {
        value: 'gold',
        label: PlanDescription({
          label: 'gold',
          value: PLAN_GOLD_MONTH,
        }),
      },
      {
        value: 'gold_annual_1x',
        label: PlanDescription({
          label: 'gold_annual_1x',
          value: PLAN_GOLD_1X,
        }),
      },
      {
        value: 'gold_annual_3x',
        label: PlanDescription({
          label: 'gold_annual_3x',
          value: PLAN_GOLD_3X,
          total: PLAN_GOLD_1X,
        }),
      },
      {
        value: 'gold_annual_6x',
        label: PlanDescription({
          label: 'gold_annual_6x',
          value: PLAN_GOLD_6X,
          total: PLAN_GOLD_1X,
        }),
      },
      {
        value: 'gold_annual_12x',
        label: PlanDescription({
          label: 'gold_annual_12x',
          value: PLAN_GOLD_12X,
          total: PLAN_GOLD_1X,
        }),
      },
    ];

    if (values.planName.includes('silver')) {
      return optionsfull.filter((option) => option.value.includes('silver'));
    } else if (values.planName.includes('gold')) {
      return optionsfull.filter((option) => option.value.includes('gold'));
    }
  }, [values]);

  return (
    <PlanSelectContainer>
      <NewSelect
        bgColor={theme.white}
        defaultValue={values.planName}
        errors={errors.planName}
        label="Formas de pagamento..."
        name="planName"
        onChange={({ value }) => {
          setFieldValue('planName', value);
        }}
        options={options}
        placeholder="Selecione a forma de recebimento"
        touched={touched.planName}
      />
    </PlanSelectContainer>
  );
}

export function ModalSubscriptionPlan({ form, loading, setLoading, signUp }) {
  const { user, professionalUpdateAddress } = useAuth();
  const { errors, values, setFieldValue, handleSubmit } = form;

  useEffect(() => {
    if (user?.address?.id) {
      setFieldValue('step', 1);
    }
  }, []);

  const handleUpdateAddress = async (values) => {
    try {
      setLoading(true);
      await professionalUpdateAddress(values);
      toast.success('Endereço atualizado com sucesso.');
    } catch (error) {
      toast.error('Não foi possível atualizar o endereço');
    } finally {
      setLoading(false);
    }
  };

  const handleContinue = async () => {
    if (!handleErrors(errors, AddressRegistrationValues) && values?.step === 0) {
      if (signUp) {
        await handleUpdateAddress(values);
      }
      setFieldValue('step', 1);
    } else if (!handleErrors(errors, CardRegistrationValues) && values?.step === 1) {
      setFieldValue('open', false);
      setFieldValue('openPlan', true);
      setFieldValue('step', 0);
      if (signUp) {
        setFieldValue('openPlan', false);
        handleSubmit();
      }
    }
  };

  return loading ? (
    <LoaderSpinner />
  ) : (
    <Modal open>
      <BoxWrapper>
        {values?.step === 0 && (
          <AddressWrapper>
            <Typography
              variant="p"
              sx={{
                color: theme.secondary,
                fontWeight: 700,
                fontSize: '1.1rem',
              }}
            >
              Para seguir com a assinatura do plano, informe os dados de endereço
            </Typography>
            <Hr />
            <LoginTitle>Endereço Comercial</LoginTitle>
            <AddressFormik form={form} subscription />
          </AddressWrapper>
        )}
        {values?.step === 1 && (
          <PaymentWrapper>
            <Typography
              variant="p"
              sx={{
                color: theme.secondary,
                fontWeight: 700,
                fontSize: '1.1rem',
              }}
            >
              Para seguir com a assinatura do plano, selecione a forma de pagamento e adicione um
              cartão para pagamento
            </Typography>
            <Hr />
            <PlansParceled form={form} loading={loading} />
            <Hr />
            <PaymentForm form={form} loading={loading} subscription clinic />
          </PaymentWrapper>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1rem',
          }}
        >
          <ButtonCancel
            type="button"
            onClick={() => {
              if (values?.step === 1) {
                setFieldValue('step', 0);
              } else {
                setFieldValue('open', false);
                setFieldValue('openPlan', true);
                setFieldValue('step', 0);
              }
            }}
            style={{
              width: '8rem',
            }}
          >
            Voltar
          </ButtonCancel>
          <Button
            onClick={handleContinue}
            type="button"
            style={{
              width: '8rem',
            }}
          >
            Continuar
          </Button>
        </Box>
      </BoxWrapper>
    </Modal>
  );
}
