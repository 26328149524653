import React from 'react';
import { PtBr } from '../../../services/pt_br';
import theme from '../../../theme';
import FormikControl from '../../FormikControl';
import { ButtonInvite, Main } from '../styles';

export default function InviteForm({ form, type }) {
  const { values, handleChange, handleBlur, errors, touched } = form;

  return (
    <Main>
      <section className="--input-wrapper-invite">
        <h3 style={{ color: theme.white, marginBottom: '1rem' }}>
          {`Convide um novo ${type === 'professional' ? 'profissional' : 'paciente'}`}
        </h3>

        <p style={{ color: theme.white, marginBottom: '1rem' }}>
          {`Preencha os dados do ${
            type === 'professional' ? 'profissional' : 'paciente'
          } que você quer convidar para a plataforma`}
        </p>

        <FormikControl
          autoFocus
          bgColor={theme.white}
          color={theme.white}
          control="input"
          errorStyle={{ color: theme.white }}
          errors={errors.name}
          label={PtBr.register_input_firstname}
          name="name"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.name}
          upLabel
          value={values.name}
        />

        <FormikControl
          bgColor={theme.white}
          color={theme.white}
          control="input"
          errorStyle={{ color: theme.white }}
          errors={errors.email}
          label={PtBr.register_input_email}
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.email}
          upLabel
          value={values.email}
        />

        <FormikControl
          bgColor={theme.white}
          color={theme.white}
          control="input"
          errorStyle={{ color: theme.white }}
          errors={errors.phone}
          label={PtBr.register_input_phone}
          mask="(99) 99999-9999"
          name="phone"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.phone}
          upLabel
          value={values.phone}
        />

        <FormikControl
          bgColor={theme.white}
          color={theme.white}
          control="input"
          errorStyle={{ color: theme.white }}
          errors={errors.city}
          label={PtBr.register_input_city}
          name="city"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.city}
          upLabel
          value={values.city}
        />

        <FormikControl
          bgColor={theme.white}
          color={theme.white}
          control="textarea"
          errorStyle={{ color: theme.white }}
          errors={errors.message}
          label="Mensagem"
          name="message"
          onBlur={handleBlur}
          onChange={handleChange}
          rows={5}
          touched={touched.message}
          upLabel
          value={values.message}
        />

        <ButtonInvite type="submit">{`Convidar ${
          type === 'professional' ? 'profissional' : 'paciente'
        }`}</ButtonInvite>
      </section>
    </Main>
  );
}
