import Modal from 'react-modal';
import styled from 'styled-components';
import {
  BigFont,
  BoxScroll,
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  SmallFont,
} from '../../styles/globalStyles';

export const AnamneseTitle = styled.p`
  color: ${({ theme }) => theme.primary};
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
`;

export const Hr = styled.hr`
  border: 1px solid ${({ theme }) => theme.neutralGray};
  height: 1px;
  margin: 1rem 0;
  width: 100%;
`;

export const FieldMainGrid = styled.div`
  align-items: ${({ config }) => (config ? 'start' : 'end')};
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const FormMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
  width: 100%;
`;

export const FormsWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const CheckBoxContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  width: 100%;

  label {
    font-size: 0.8rem;
    font-weight: 500;
  }
  input[type='checkbox'] {
    margin-right: 0.5rem;
  }
  input[type='checkbox']:checked + label {
    font-weight: 700;
  }
  input[type='checkbox']:disabled + label {
    color: rgba(0, 0, 0, 0.3);
  }
  input[type='checkbox']:focus-visible + label {
    border-bottom: 2px solid blue;
  }
  input[type='checkbox']:focus-visible + label:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const AddField = styled.button`
  align-items: center;
  background-color: transparent;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.primary};
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  padding: 0.5rem 1rem;
  width: 12rem;

  &:hover {
    background-color: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.white};
  }

  &:disabled {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.3);
    cursor: not-allowed;
  }
`;

export const ResetField = styled(AddField)`
  border: 1px solid ${({ theme }) => theme.errorRed};
  color: ${({ theme }) => theme.errorRed};

  &:hover {
    background-color: ${({ theme }) => theme.errorRed};
    color: ${({ theme }) => theme.white};
  }
`;

export const StyledModal = styled(Modal)`
  bottom: auto;
  display: flex;
  height: 100%;
  left: 50%;
  margin-right: -50%;
  position: absolute;
  right: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export const ModalContainer = styled.section`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const ModalWrapper = styled.section`
  align-items: center;
  background-color: ${({ theme }) => theme.lightGray};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.neutralGray};
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  max-height: 75%;
`;

export const ModalContent = styled.section`
  align-items: center;
  background-color: ${({ theme }) => theme.lightGray};
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem 1rem 1.5rem;
  width: 100%;
`;

export const ModalWrapperScroll = styled(BoxScroll)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  padding: 0 2rem 2rem 2rem;
  width: 40vw;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 90%;
  }
`;

export const ModalHeader = styled.section`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`;

export const ModalTitle = styled(BigFont)`
  font-size: 1.5rem;
  font-weight: 400;
  color: ${({ theme }) => theme.primary};
`;

export const ModalSubTitle = styled(SmallFont)`
  color: ${({ theme }) => theme.secondary};
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const IconArea = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 0.7rem;
  top: 0.7rem;
  z-index: 1;
`;

export const IconAreaEdit = styled(IconArea)`
  gap: 0.5rem;
  left: 2rem;
  right: auto;
  top: 0;
`;

export const SelectForm = styled(AddField)`
  width: 100%;
`;
