/* eslint-disable react-hooks/exhaustive-deps */
import { Person } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as Feather from 'react-feather';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/auth';
import useEvent from '../../hooks/event';
import useAxiosGet from '../../hooks/useAxiosGet';
import LogoCli from '../../images/logo-navbar.png';
import LogoProf from '../../images/logoLogin.png';
import theme from '../../theme';
import {
  ContainerLogo,
  ContainerRow,
  ContainerUser,
  HeaderButtons,
  HeaderConfigButtons,
  HeaderConfigWrapper,
  HeaderItemText,
  HeaderLogoImg,
  HeaderLogoWrapper,
  HeaderMain,
  HeaderNotificationWrapper,
  IconArea,
  MenuSideBar,
  UserAvatar,
  UserImage,
} from './styles';

export default function Header({ ...props }) {
  const location = useLocation();
  const { sidebar, setSidebar } = props;
  const history = useHistory();
  const { user } = useAuth();
  const { notifications, setNotifications } = useEvent();
  const [newNotifications, setNewNotifications] = useState(0);
  const [menuVisible, setMenuVisible] = useState(false);
  const getNotifications = useAxiosGet();

  useEffect(() => {
    const { controller, data, errors, fetchData, loading } = getNotifications;

    if (data?.length === 0) fetchData(`/${user.type}s/notifications`);

    if (data.length > 0) {
      setNewNotifications(
        data?.filter(
          (item) =>
            item.attributes?.notificationType !== 'message' && item.attributes?.readed !== true
        )?.length
      );

      setNotifications({
        data: [...notifications.data, ...data],
        loading,
      });
    }

    if (errors) toast.error('Não foi possível carregar as notificações');

    return () => {
      controller.abort();
      setNotifications({ data: [], loading: true });
    };
  }, [getNotifications.loading]);

  useEffect(() => {
    if (location.pathname.includes('meeting')) setMenuVisible(true);
  }, [location.pathname]);

  const notificationsMessage =
    newNotifications === 0 ? 'Notificações' : `Você tem ${newNotifications} novas notificações`;

  const selectedButton =
    location.pathname.includes('clinic') && location.pathname.includes('all-professionals')
      ? 'all-professionals'
      : 'config';

  const isMeeting = location.pathname.includes('meeting');

  return (
    <HeaderMain user={user?.type}>
      <HeaderLogoWrapper>
        <ContainerLogo onClick={() => history.push(`/${user.type}/dashboard`)} logo={'true'}>
          <HeaderLogoImg
            src={['professional', 'clinic'].includes(user?.type) ? LogoProf : LogoCli}
          />
        </ContainerLogo>
        <ContainerUser user={user?.type}>
          <UserAvatar>
            <UserImage size="100%" src={user?.avatar} />
          </UserAvatar>
          <HeaderItemText className="header-text">
            Boas vindas {`${user?.firstName}`}
          </HeaderItemText>
        </ContainerUser>
      </HeaderLogoWrapper>
      <HeaderConfigWrapper>
        {!isMeeting ? (
          <>
            <HeaderNotificationWrapper>
              <HeaderConfigButtons
                setting={selectedButton === 'config' ? 'true' : 'false'}
                to={{
                  pathname: `/${user.type}/${
                    !location.pathname.includes('clinic') ? 'config' : 'all-professionals'
                  }`,
                }}
              >
                <ContainerRow>
                  <HeaderItemText className="item-text">
                    {!location.pathname.includes('clinic')
                      ? 'Configurações'
                      : 'Profissionais cadastrados'}
                  </HeaderItemText>
                  <IconArea className="item-text">
                    {user.type !== 'clinic' ? (
                      <Feather.Settings style={{ marginLeft: '0.5rem' }} />
                    ) : (
                      <Person sx={{ ml: 1 }} />
                    )}
                  </IconArea>
                </ContainerRow>
              </HeaderConfigButtons>
              {user?.type === 'professional' && (
                <HeaderConfigButtons
                  style={{ marginLeft: '1rem' }}
                  public={'true'}
                  to={{ pathname: '/professional/profile' }}
                >
                  <ContainerRow>
                    <HeaderItemText className="item-text">Perfil público</HeaderItemText>
                    <IconArea className="item-text">
                      <Feather.User />
                    </IconArea>
                  </ContainerRow>
                </HeaderConfigButtons>
              )}
            </HeaderNotificationWrapper>

            <HeaderButtons
              user={user?.type}
              setting={selectedButton === 'config' ? 'true' : 'false'}
              notify={'true'}
              to={{ pathname: `/${user.type}/notifications` }}
            >
              <Tooltip title={notificationsMessage} placement="bottom">
                <IconArea className="icon-notify" style={{ position: 'relative' }}>
                  <Feather.Bell />
                  {newNotifications > 0 && (
                    <span
                      style={{
                        width: '0.7rem',
                        height: '0.7rem',
                        backgroundColor: '#EB5757',
                        borderRadius: '50%',
                        position: 'absolute',
                        top: '0.2rem',
                        left: '0.7rem',
                      }}
                    ></span>
                  )}
                </IconArea>
              </Tooltip>
            </HeaderButtons>
          </>
        ) : (
          <>
            <div />
            <div />
          </>
        )}

        <MenuSideBar
          onClick={() => setSidebar(!sidebar)}
          isvisible={menuVisible ? 'visible' : 'hidden'}
        >
          {sidebar ? (
            <MenuOpenIcon
              fontSize="large"
              style={{
                color: ['professional', 'clinic'].includes(user?.type)
                  ? theme.darkNavy
                  : theme.white,
              }}
            />
          ) : (
            <MenuIcon
              fontSize="large"
              style={{
                color: ['professional', 'clinic'].includes(user?.type)
                  ? theme.darkNavy
                  : theme.white,
              }}
            />
          )}
        </MenuSideBar>
      </HeaderConfigWrapper>
    </HeaderMain>
  );
}
