import styled from 'styled-components';
import {
  BREAKPOINT_MD_PX,
  ScrollPageStyle,
} from '../../../styles/globalStyles';
import { moveBottomToTop } from '../../../styles/keyframes';

export const ScreenScroll = styled(ScrollPageStyle)`
  animation: ${moveBottomToTop} 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${({ height }) => height});
  justify-content: flex-start;
  width: 100%;
`;

export const SocialIconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  right: 51%;
  top: auto;
  bottom: 0.7rem;
  z-index: 1000;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    right: 3%;
  }
`;

export const IconAreaWhatsApp = styled.a`
  align-items: center;
  background: #25d366;
  cursor: pointer;
  border-radius: 1rem;
  display: flex;
  height: 2rem;
  justify-content: center;
  width: 2rem;
  margin-bottom: 0.7rem;
`;

export const IconAreaInstagram = styled(IconAreaWhatsApp)`
  background: radial-gradient(
      circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%
    ),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(
      #6559ca,
      #bc318f 30%,
      #e33f5f 50%,
      #f77638 70%,
      #fec66d 100%
    );
  margin-bottom: 0;
`;
