/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { X } from 'react-feather';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormAnamnese from '../../../../components/FormAnamnese';
import {
  IconArea,
  ModalContainer,
  ModalContent,
  ModalWrapper,
  ModalWrapperScroll,
  SelectForm,
  StyledModal,
} from '../../../../components/FormAnamnese/styles';
import LoaderSpinner from '../../../../components/LoaderSpinner';
import api from '../../../../services/api';
import { formDefault } from '../../../../services/constants';
import {
  CardTitle,
  DeleteButton,
  DeleteIcon,
  FormInfoTitle,
  FormsWrapper,
  FormTitle,
  MainAnamnese,
  MainAnamneseForms,
  MainCard,
} from './styles';

function FormCard({ form, handleConfirm, handleDelete, selected }) {
  return (
    <MainCard
      selected={selected}
      onClick={() => {
        if (!selected) {
          handleConfirm();
        }
      }}
      type="button"
    >
      <CardTitle selected={selected}>{form.attributes.title}</CardTitle>

      {Number(form.id) !== 0 && (
        <Tooltip title="Excluir formulário">
          <DeleteButton type="button" onClick={handleDelete}>
            <DeleteIcon />
          </DeleteButton>
        </Tooltip>
      )}
    </MainCard>
  );
}

export function AnamneseConfig() {
  const { id: paramsId } = useParams();
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const [forms, setForms] = useState(null);
  const [formsAnswers, setFormsAnswers] = useState(null);
  const [formInfos, setFormInfos] = useState({
    id: formDefault.id,
    ...formDefault.attributes,
  });
  const [update, setUpdate] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [openModalSelect, setOpenModalSelect] = useState(false);

  useEffect(() => {
    if (paramsId) {
      getFormsAnswers();
    }

    getForms();
  }, [update, paramsId]);

  const getForms = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/professionals/forms');

      if (data.data) {
        setForms([formDefault, ...data.data]);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [update]);

  const getFormsAnswers = useCallback(async () => {
    try {
      const { data } = await api.get(`/professionals/form_answers?client_id=${paramsId}`);
      if (data.data) {
        setFormsAnswers(data.data);
        if (state.active) {
          setFormInfos({
            id: data.data,
            ...data.data[0].attributes,
          });
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [update]);

  const getFormId = useCallback(async (id, type) => {
    try {
      let data;
      if (paramsId && !type) {
        const { data: response } = await api.get(`/professionals/form_answers/${id}`);
        data = response;
      } else {
        const { data: response } = await api.get(`/professionals/forms/${id}`);
        data = response;
      }
      if (data.data) {
        setFormInfos({
          id: data.data.id,
          ...data.data.attributes,
        });
      } else {
        throw new Error('Formulário não encontrado');
      }
    } catch (error) {
      toast.error('Formulário não encontrado');
    } finally {
      setLoading(false);
    }
  }, []);

  const handleEditForm = (type) => {
    setEditForm(type);
    if (type === false) {
      setFormInfos({
        id: formDefault.id,
        ...formDefault.attributes,
      });
    }
  };

  const handleDelete = useCallback(
    async (id) => {
      setLoading(true);
      try {
        if (paramsId) {
          await api.delete(`/professionals/form_answers/${id}`);
        } else await api.delete(`/professionals/forms/${id}`);
        toast.success('Formulário excluído com sucesso');
      } catch (error) {
        toast.error('Não foi possível excluir o formulário');
      } finally {
        setLoading(false);
        setFormInfos({
          id: formDefault.id,
          ...formDefault.attributes,
        });
        setEditForm(false);
        setUpdate((prevUpdate) => !prevUpdate);
      }
    },
    [paramsId]
  );

  const formsRender = useMemo(() => {
    if (!forms && !formsAnswers) return [];
    if (paramsId) {
      return formsAnswers;
    }
    return forms;
  }, [forms, formsAnswers, update]);

  return loading ? (
    <LoaderSpinner loading={loading} />
  ) : (
    <MainAnamnese>
      <FormAnamnese
        clientId={paramsId}
        config={!paramsId}
        editForm={editForm}
        formInfos={formInfos}
        setFormInfos={setFormInfos}
        loading={loading}
        setEditForm={handleEditForm}
        setLoading={setLoading}
        setUpdate={setUpdate}
        setForms={setForms}
      />

      <MainAnamneseForms>
        {paramsId && (
          <Tooltip title="Selecionar formulário">
            <SelectForm onClick={() => setOpenModalSelect(true)}>
              Escolher modelo de formulário
            </SelectForm>
          </Tooltip>
        )}
        <FormTitle>{paramsId ? 'Formulários preenchidos' : 'Formulários Salvos'}</FormTitle>
        <FormsWrapper>
          {formsRender?.length === 0 ? (
            <p>Nenhum formulário</p>
          ) : (
            formsRender?.map((form, index) => (
              <FormCard
                key={`${form.id} ${index}`}
                form={form}
                handleConfirm={() => {
                  if (Number(form.id) !== 0) {
                    getFormId(form.id);
                    handleEditForm(true);
                  } else {
                    handleEditForm(false);
                  }
                }}
                handleDelete={() => handleDelete(form.id)}
                selected={
                  Number(formInfos?.id) === Number(form?.id) ||
                  (Number(form?.id) === 0 && !formInfos)
                }
                config={paramsId}
              />
            ))
          )}
        </FormsWrapper>
      </MainAnamneseForms>

      <StyledModal
        ariaHideApp={false}
        isOpen={openModalSelect}
        onRequestClose={() => setOpenModalSelect(false)}
        onAfterOpen={null}
      >
        <ModalContainer>
          <ModalWrapper>
            <ModalContent>
              <Tooltip title="Fechar">
                <IconArea>
                  <X
                    style={{ cursor: 'pointer', width: '2rem', height: '2rem' }}
                    onClick={() => setOpenModalSelect(false)}
                  />
                </IconArea>
              </Tooltip>

              <FormInfoTitle>Fomulários</FormInfoTitle>
            </ModalContent>
            <ModalWrapperScroll>
              <FormsWrapper>
                {forms?.length === 0 ? (
                  <p>Nenhum formulário</p>
                ) : (
                  forms?.map((form, index) => (
                    <FormCard
                      key={`${form.id} ${index}`}
                      form={form}
                      handleConfirm={() => {
                        setOpenModalSelect(false);
                        if (index === 0) {
                          setFormInfos({
                            id: formDefault.id,
                            ...formDefault.attributes,
                          });
                        } else getFormId(form.id, 'client');
                      }}
                      selected={Number(formInfos?.id) === Number(form.id)}
                      config={paramsId}
                    />
                  ))
                )}
              </FormsWrapper>
            </ModalWrapperScroll>
          </ModalWrapper>
        </ModalContainer>
      </StyledModal>
    </MainAnamnese>
  );
}
