import { Info, Trash2 } from 'react-feather';
import styled from 'styled-components';
import { BigFont, BoxScroll, BREAKPOINT_MD_PX, SmallFont } from '../../../../styles/globalStyles';

export const MainAnamnese = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 2fr 1fr;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: 1fr;
  }
`;

export const MainAnamneseForms = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  padding: 1rem;
  width: 100%;
`;

export const FormTitle = styled(BigFont)`
  color: ${({ theme }) => theme.primary};
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
`;

export const FormsWrapper = styled(BoxScroll)`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  width: 100%;
`;

export const MainCard = styled.button`
  align-items: center;
  background-color: ${({ theme, selected }) => (selected ? theme.secondary : theme.lightGray)};
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.secondary};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.7rem;
  width: 100%;
  z-index: 0;
`;

export const CardTitle = styled(SmallFont)`
  color: ${({ theme, selected }) => (selected ? theme.white : theme.primary)};
  cursor: pointer;
`;

export const InfoButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
`;

export const InfoIcon = styled(Info)`
  color: ${({ theme, selected }) => (selected ? theme.white : theme.primary)};
  width: 18px;
  height: 18px;
`;

export const DeleteIcon = styled(Trash2)`
  color: ${({ theme }) => theme.errorRed};
  width: 18px;
  height: 18px;

  &:hover {
    color: ${({ theme }) => theme.white};
  }
`;

export const DeleteButton = styled(InfoButton)`
  background-color: ${({ theme }) => theme.lightGray};
  border: 1px solid ${({ theme }) => theme.errorRed};
  border-radius: 0.25rem;
  z-index: 1;

  &:hover {
    background-color: ${({ theme }) => theme.errorRed};
    border: 1px solid ${({ theme }) => theme.lightGray};

    ${DeleteIcon} {
      color: ${({ theme }) => theme.white};
    }
  }
`;

export const FieldMainGrid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const FormMainGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
  width: 100%;
`;

export const CheckBoxContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  width: 100%;

  label {
    font-size: 0.8rem;
    font-weight: 500;
  }
  input[type='checkbox'] {
    margin-right: 0.5rem;
  }
  input[type='checkbox']:checked + label {
    font-weight: 700;
  }
  input[type='checkbox']:disabled + label {
    color: rgba(0, 0, 0, 0.3);
  }
  input[type='checkbox']:focus-visible + label {
    border-bottom: 2px solid blue;
  }
  input[type='checkbox']:focus-visible + label:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const FormInfoTitle = styled(BigFont)`
  color: ${({ theme }) => theme.primary};
  font-size: 1.8rem;
  font-weight: 700;
`;
