export default {
  primary: '#6C8CBF',
  secondary: '#608C99',

  aquaBlue: '#02BCC8',
  darkBlue: '#315896',
  darkNavy: '#040817',
  errorRed: '#B05656',
  iceBackground: '#F4F5FA',
  lightGray: '#F5F5F5',
  neutralGray: '#EAECF4',
  softBackground: '#F7F7FF',
  white: '#FFF',
  sunYellow: '#E2D46B',
  warning: 'orange',
};
