import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import SidebarPublic from '../../components/SideNavBarPublic';
import PublicProfileList from '../../screens/Public/Professionals';
import ProfessionalsClinic from '../../screens/Public/ProfessionalsClinic';
import PublicProfessionalProfile from '../../screens/Public/Profile';
import PreSignUpSchedule from '../../screens/Public/SignUpSchedule';

function PublicRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <SidebarPublic>
          <Component {...props} />
        </SidebarPublic>
      )}
    />
  );
}

export function PublicRoutes() {
  const clinicName = window.location.pathname;

  return (
    <Switch>
      <Redirect exact from="/public/professionals" to="/publica/profissionais" />
      <Redirect exact from="/publica" to="/publica/profissionais" />

      <PublicRoute exact path="/publica/profissionais" component={PublicProfileList} />

      <PublicRoute exact path="/publica/profissionais/:profession" component={PublicProfileList} />

      <PublicRoute exact path="/publica/agendamento/:id" component={PublicProfessionalProfile} />

      <Route exact path="/publica/clinica/agendamento/:id" component={PublicProfessionalProfile} />

      <Route exact path="/cliente/confirmar-agendamento/:id" component={PreSignUpSchedule} />

      <Route exact path="/convidado/confirmar-agendamento/:id" component={PreSignUpSchedule} />

      <Route path={`${clinicName}`} component={ProfessionalsClinic} />
    </Switch>
  );
}
