import styled from 'styled-components';
import { SFontDark } from '../../../screens/Register/BasicRegistrationProfessional/styles';
import { BREAKPOINT_MD_PX, ModalBoxWrapper } from '../../../styles/globalStyles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const TextAlert = styled.p`
  font-size: 1rem;
  font-weight: 500;
`;

export const TitleAlert = styled.h1`
  font-size: 1.2rem;
  font-weight: 700;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const IconArea = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 1rem;
`;

export const Icon = styled.img`
  height: 2.5rem;
  width: 2.5rem;
`;

export const ModalContainer = styled(ModalBoxWrapper)`
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

export const PlanSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
`;

export const PlansParceledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const PlansParceledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

export const PlansParceledDescription = styled(SFontDark)`
  color: ${({ theme }) => theme.secondary};
  font-size: 0.9rem;
`;

export const PlansParceledSpan1 = styled.span`
  color: ${({ theme }) => theme.secondary};
  font-size: 0.9rem;
  font-weight: 700;
  margin-left: 1rem;
`;

export const PlansParceledSpan2 = styled(PlansParceledSpan1)`
  color: ${({ theme }) => theme.primary};
  font-size: 1rem;
`;

export const BoxWrapper = styled(ModalBoxWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 40vh;
  min-width: 400px;
  padding: 2rem;
  width: 35%;
`;

export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 50vh;
  max-height: 55vh;
  overflow-y: auto;
  padding: 1rem;
  width: 100%;
`;

export const PaymentWrapper = styled(AddressWrapper)``;

export const Hr = styled.hr`
  border: 1px solid #bcc1e0;
  margin-top: 0.7rem;
  width: 100%;
`;

export const Button = styled.button`
  background-color: ${({ theme, active }) => (active ? theme.primary : theme.softBackground)};
  border-radius: 2rem;
  border: 1px solid ${({ theme, active }) => (active ? theme.softBackground : theme.primary)};
  color: ${({ theme, active }) => (active ? theme.softBackground : theme.primary)};
  cursor: pointer;
  font-weight: 700;
  height: 2rem;
  width: 80%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    background-color: ${({ theme, active }) => (active ? theme.softBackground : theme.primary)};
    color: ${({ theme, active }) => (active ? theme.primary : theme.softBackground)};
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    padding: 0 1rem;
  }
`;

export const ButtonCancel = styled(Button)`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.errorRed};
  color: ${({ theme }) => theme.errorRed};

  &:hover {
    background-color: ${({ theme }) => theme.errorRed};
    color: ${({ theme }) => theme.white};
  }
`;
