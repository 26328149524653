/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useAuth from '../../../../hooks/auth';
import { CheckboxInput, CheckboxLabel, CheckboxWrapper, Container, Title } from './styles';

const NotificationConfig = ({ form, validateInfos }) => {
  const { values, setFieldValue } = form;
  const { user } = useAuth();

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setFieldValue(name, checked);
  };

  useEffect(() => {
    const validateInfo =
      values.emailEnabled === user?.notificationSetting?.emailEnabled &&
      values.wppEnabled === user?.notificationSetting?.wppEnabled &&
      values.smsEnabled === user?.notificationSetting?.smsEnabled;

    validateInfos(validateInfo);
  }, [values]);

  return (
    <Container>
      <Title>Configurações de Notificação</Title>
      <CheckboxWrapper>
        <CheckboxInput
          type="checkbox"
          name="emailEnabled"
          checked={values.emailEnabled}
          onChange={handleChange}
          disabled
        />
        <CheckboxLabel>Receber notificações por E-mail</CheckboxLabel>
      </CheckboxWrapper>

      <CheckboxWrapper>
        <CheckboxInput
          type="checkbox"
          name="wppEnabled"
          checked={values.wppEnabled}
          onChange={handleChange}
        />
        <CheckboxLabel>Receber notificações por Whatsapp</CheckboxLabel>
      </CheckboxWrapper>

      <CheckboxWrapper>
        <CheckboxInput
          type="checkbox"
          name="smsEnabled"
          checked={values.smsEnabled}
          onChange={handleChange}
        />
        <CheckboxLabel>Receber notificações por SMS</CheckboxLabel>
      </CheckboxWrapper>
    </Container>
  );
};

export default NotificationConfig;
