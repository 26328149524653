import {
  PLAN_GOLD_12X,
  PLAN_GOLD_1X,
  PLAN_GOLD_3X,
  PLAN_GOLD_6X,
  PLAN_SILVER_12X,
  PLAN_SILVER_1X,
  PLAN_SILVER_3X,
  PLAN_SILVER_6X,
} from '../utils/getPlanName';

export const QUESTIONS = [
  { id: 1, question: 'Nome Completo' },
  { id: 2, question: 'Idade' },
  { id: 3, question: 'Data de nascimento' },
  { id: 4, question: 'Estado Civil' },
  { id: 5, question: 'Gênero' },
  { id: 6, question: 'Profissão' },
  { id: 7, question: 'Escolaridade' },
  { id: 8, question: 'Endereço' },
  { id: 9, question: 'Contato' },
  { id: 10, question: 'Encaminhamento (se houver)' },
  { id: 11, question: 'Data da entrevista' },
  { id: 12, question: 'O que fez o paciente buscar atendimento psicológico' },
  {
    id: 13,
    question: 'Infância e adolescência (eventos significativos, traumas e experiências marcantes)',
  },
  { id: 14, question: 'Sinais e sintomas atuais' },
  { id: 15, question: 'Histórico de transtornos mentais, eventos traumáticos e/ou doenças' },
  { id: 16, question: 'Com quem reside' },
  { id: 17, question: 'Dinâmica familiar' },
  { id: 18, question: 'Histórico de transtornos mentais ou doenças na família' },
  { id: 19, question: 'Uso de medicamentos' },
  { id: 20, question: 'Uso de substâncias' },
  { id: 21, question: 'Experiência de automutilação e tentativa de suicídio' },
  { id: 22, question: 'Rotina de Trabalho/Estudo' },
  { id: 23, question: 'Sono' },
  { id: 24, question: 'Alimentação' },
  { id: 25, question: 'Exercícios físicos' },
  { id: 26, question: 'Lazer e Autocuidado' },
  { id: 27, question: 'Sexualidade e relacionamento' },
  { id: 28, question: 'Vida social' },
  { id: 29, question: 'Rede de apoio' },
  { id: 30, question: 'Crenças e práticas religiosas' },
  { id: 31, question: 'Aspectos culturais importantes' },
  { id: 32, question: 'Objetivos terapêuticos' },
  { id: 33, question: 'Observações adicionais' },
];

export const formDefault = {
  id: '0',
  type: 'form',
  attributes: {
    id: 0,
    title: 'Anamnese Padrão',
    questions: [
      {
        id: 0,
        question: 'Nome Completo',
      },
      {
        id: 1,
        question: 'Idade',
      },
      {
        id: 2,
        question: 'Data de nascimento',
      },
      {
        id: 3,
        question: 'Estado Civil',
      },
      {
        id: 4,
        question: 'Gênero',
      },
      {
        id: 5,
        question: 'Profissão',
      },
      {
        id: 6,
        question: 'Escolaridade',
      },
      {
        id: 7,
        question: 'Endereço',
      },
      {
        id: 8,
        question: 'Contato',
      },
      {
        id: 9,
        question: 'Encaminhamento (se houver)',
      },
      {
        id: 10,
        question: 'Data da entrevista',
      },
      {
        id: 11,
        question: 'O que fez o paciente buscar atendimento psicológico',
      },
      {
        id: 12,
        question:
          'Infância e adolescência (eventos significativos, traumas e experiências marcantes)',
      },
      {
        id: 13,
        question: 'Sinais e sintomas atuais',
      },
      {
        id: 14,
        question: 'Histórico de transtornos mentais, eventos traumáticos e/ou doenças',
      },
      {
        id: 15,
        question: 'Com quem reside',
      },
      {
        id: 16,
        question: 'Dinâmica familiar',
      },
      {
        id: 17,
        question: 'Histórico de transtornos mentais ou doenças na família',
      },
      {
        id: 18,
        question: 'Uso de medicamentos',
      },
      {
        id: 19,
        question: 'Uso de substâncias',
      },
      {
        id: 20,
        question: 'Experiência de automutilação e tentativa de suicídio',
      },
      {
        id: 21,
        question: 'Rotina de Trabalho/Estudo',
      },
      {
        id: 22,
        question: 'Sono',
      },
      {
        id: 23,
        question: 'Alimentação',
      },
      {
        id: 24,
        question: 'Exercícios físicos',
      },
      {
        id: 25,
        question: 'Lazer e Autocuidado',
      },
      {
        id: 26,
        question: 'Sexualidade e relacionamento',
      },
      {
        id: 27,
        question: 'Vida social',
      },
      {
        id: 28,
        question: 'Rede de apoio',
      },
      {
        id: 29,
        question: 'Crenças e práticas religiosas',
      },
      {
        id: 30,
        question: 'Aspectos culturais importantes',
      },
      {
        id: 31,
        question: 'Objetivos terapêuticos',
      },
      {
        id: 32,
        question: 'Observações adicionais',
      },
    ],
    createdAt: '2025-02-05T20:16:42.988-03:00',
    updatedAt: '2025-02-05T20:16:42.988-03:00',
  },
};

export const PLANSNAMES = [
  'silver',
  'silver_annual_1x',
  'silver_annual_3x',
  'silver_annual_6x',
  'silver_annual_12x',
  'gold',
  'gold_annual_1x',
  'gold_annual_3x',
  'gold_annual_6x',
  'gold_annual_12x',
  'platinum',
];

export const OPTIONSPLANS = [
  {
    label: 'silver_annual_1x',
    value: PLAN_SILVER_1X,
    total: PLAN_SILVER_1X,
  },
  {
    label: 'silver_annual_3x',
    value: PLAN_SILVER_3X,
    total: PLAN_SILVER_1X,
  },
  {
    label: 'silver_annual_6x',
    value: PLAN_SILVER_6X,
    total: PLAN_SILVER_1X,
  },
  {
    label: 'silver_annual_12x',
    value: PLAN_SILVER_12X,
    total: PLAN_SILVER_1X,
  },
  {
    label: 'gold_annual_1x',
    value: PLAN_GOLD_1X,
    total: PLAN_GOLD_1X,
  },
  {
    label: 'gold_annual_3x',
    value: PLAN_GOLD_3X,
    total: PLAN_GOLD_1X,
  },
  {
    label: 'gold_annual_6x',
    value: PLAN_GOLD_6X,
    total: PLAN_GOLD_1X,
  },
  {
    label: 'gold_annual_12x',
    value: PLAN_GOLD_12X,
    total: PLAN_GOLD_1X,
  },
];
