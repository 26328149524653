import { Modal } from '@material-ui/core';
import 'cropperjs/dist/cropper.css';
import React, { useRef, useState } from 'react';
import Cropper from 'react-cropper';
import { X } from 'react-feather';
import DefaultImage from '../../images/noAvatar.png';
import DefaultBanner from '../../images/noBanner.png';
import { ModalBoxWrapper } from '../../styles/globalStyles';
import theme from '../../theme';
import { UserImage } from '../UserElements/styles';
import {
  CameraIcon,
  CheckCircleIcon,
  ContainerBannerIcon,
  ContainerIcon,
  CropButton,
  CropperWrapper,
  ImagePickerContainer,
  NewImageBannerContainer,
  NewImagePickerContainer,
} from './styles';

export default function ImagePicker({ ...props }) {
  const { src, size, ...rest } = props;
  return (
    <ImagePickerContainer>
      <label>
        <UserImage src={src || DefaultImage} alt="" size={size} />
        <input type="file" accept=".png" {...rest} style={{ cursor: 'pointer' }} />
      </label>
    </ImagePickerContainer>
  );
}

export function NewImagePicker({ ...props }) {
  const { src, size, acceptType, ...rest } = props;
  return (
    <NewImagePickerContainer>
      <label style={{ cursor: 'pointer' }}>
        <UserImage src={src || DefaultImage} alt="" size={size}></UserImage>
        <input type="file" accept={`.png, ${acceptType}`} {...rest} />
        <ContainerIcon>
          <CameraIcon />
        </ContainerIcon>
      </label>
    </NewImagePickerContainer>
  );
}

export function NewImageBanner({ ...props }) {
  const { src, size, onClick, onChange, ...rest } = props;
  return (
    <NewImageBannerContainer>
      <label style={{ cursor: `${src ? 'default' : 'pointer'}` }}>
        <img src={src || DefaultBanner} alt="" size={size} />
        <input
          disabled={src}
          type="file"
          accept=".png"
          onChange={(event) => {
            if (onChange && !src) {
              onChange(event);
            }
          }}
          {...rest}
        />
        {!src && (
          <ContainerBannerIcon>
            <CameraIcon />
          </ContainerBannerIcon>
        )}
      </label>
      {src && (
        <ContainerBannerIcon
          active={src}
          onClick={(event) => {
            if (onClick && src) {
              onClick(event);
            }
          }}
        >
          <X color="white" />
        </ContainerBannerIcon>
      )}
    </NewImageBannerContainer>
  );
}

export const ImageCropper = ({ onCrop, src, acceptType, name }) => {
  const cropperRef = useRef(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);

  // Adiciona um parâmetro `format` com um valor padrão de 'image/jpeg'
  const handleCrop = (format = 'image/jpeg') => {
    const cropper = cropperRef.current.cropper;
    const canvas = cropper.getCroppedCanvas({
      width: 400, // Ajuste conforme necessário
      height: 400, // A altura é ajustada automaticamente para manter a proporção
    });

    // Determina a qualidade com base no formato
    const quality = format === 'image/jpeg' ? 0.75 : 1.0;

    // Chama a função onCrop com a URL da imagem no formato especificado
    onCrop(canvas.toDataURL(format, quality));
    setCroppedImage(canvas.toDataURL(format, quality));
    setIsModalOpen(false);
  };

  return (
    <CropperWrapper>
      <NewImagePicker
        size="large"
        src={croppedImage || src || DefaultImage}
        name={name}
        acceptType={acceptType}
        onChange={({ target }) => {
          const fileInput = target;
          const file = fileInput.files[0];
          if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
              setUploadedImage(e.target.result);
            };
            reader.readAsDataURL(file);
            setIsModalOpen(true);
            fileInput.value = '';
          }
        }}
      />
      {uploadedImage && (
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <ModalBoxWrapper
            style={{
              maxWhidth: '90vw',
            }}
          >
            <Cropper
              aspectRatio={1} // Garante que a seleção seja quadrada
              guides={false}
              initialAspectRatio={1}
              ref={cropperRef}
              src={uploadedImage}
              style={{
                height: '100%',
                width: '100%',
                background: theme.darkNavy,
                maxWidth: '500px',
                maxHeight: '400px',
              }}
              viewMode={1} // Restringe a área de corte ao contêiner
            />
            <CropButton type="button" onClick={handleCrop}>
              <CheckCircleIcon />
            </CropButton>
          </ModalBoxWrapper>
        </Modal>
      )}
    </CropperWrapper>
  );
};
