import styled from 'styled-components';

export const FieldLabel = styled.label`
  color: ${({ theme, color }) => (color ? color : theme.darkNavy)};
  cursor: pointer;
  font-size: 15px;
  font-weight: 900;
  font-family: 'Poppins';
  line-height: 150%;
  margin-bottom: 1rem;
`;

export const ImagePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
`;

export const ImagePickerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;
`;
