import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
  ScrollPageStyle,
} from '../../styles/globalStyles';
import { moveLeftToRight } from '../../styles/keyframes';

export const Main = styled.section`
  display: grid;
  grid-template-columns: ${({ meeting }) =>
    meeting ? 'auto' : '18vw auto'}; /* Coluna do conteúdo principal e do menu lateral */
  grid-template-rows: min-content auto; /* Ajusta automaticamente a altura conforme o conteúdo */
  grid-template-areas: ${({ meeting }) =>
    meeting ? `"header" "content"` : `"header header" "navbar content"`};
  height: 100vh;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      'header'
      'content';
  }
`;

export const Container = styled(ScrollPageStyle)`
  grid-area: content;
  overflow-y: auto;
  transition: 0.4s;
  padding: ${({ meeting }) => (meeting ? '0' : '2rem 2rem 1.5rem 2rem')};
  height: calc(100vh - 9vh);

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    padding: 2rem 1rem;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const NavMenuWeb = styled.nav`
  align-items: center;
  background-color: ${({ theme }) => theme.softBackground};
  box-sizing: border-box;
  display: flex;
  grid-area: navbar;
  height: calc(100vh - 9vh);
  flex-direction: column;
  justify-content: flex-start;
  width: 18vw;
`;

export const NavMenuMobile = styled(NavMenuWeb)`
  animation: ${moveLeftToRight} 0.5s ease-in-out;
  background-color: ${({ theme, typepage }) =>
    typepage === 'true' ? theme.softBackground : theme.primary};
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 20vw;
  z-index: 9999;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 90vw;
  }
`;

export const NavMenuItems = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 1rem;
  padding: 1rem 0;
  text-decoration: none;
  width: auto;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    padding: 0.3rem 0;
  }
`;

export const MenuSideBarWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
`;
export const MenuSideBar = styled(IconButton)``;

export const NavBarItemList = styled(Link)`
  align-items: center;
  background-color: ${({ active, theme, title, typepage }) => {
    if (active === 'true') return theme.primary;
    if (title === 'Sair') return theme.white;
    if (typepage === 'true') return theme.neutralGray;
    return theme.neutralGray;
  }};
  border: 1px solid ${({ theme }) => theme.white};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  height: ${({ logo }) => (logo ? '70px' : 'fit-content')};
  justify-content: 'flex-start';
  margin-bottom: ${({ logo }) => (logo ? '3rem' : '0.7rem')};
  margin-top: ${({ title }) => (title === 'Sair' ? '8rem' : '0.325rem')};
  padding: ${({ logo }) => (logo ? '0.5rem 0' : '0.4rem 0.25rem')};
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  width: 85%;

  .nav-text {
    color: ${({ active, theme, title, typepage }) => {
      if (active === 'true') return theme.white;
      if (title === 'Sair') return theme.primary;
      if (typepage === 'true') return theme.primary;
      return theme.secondary;
    }};
    cursor: pointer;
    margin-left: 0.5rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.primary};
    .nav-text {
      color: ${({ theme }) => theme.white};
      cursor: pointer;
    }
    transition: all 0.3s ease-in-out;
  }

  &:active {
    .nav-text {
      background-color: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.white};
      cursor: pointer;
    }
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    background-color: ${({ active, theme, title, typepage }) => {
      if (active === 'true') return theme.secondary;
      if (title === 'Sair') return theme.white;
      if (typepage === 'true') return theme.neutralGray;
      return theme.primary;
    }};

    .nav-text {
      color: ${({ active, theme, title, typepage }) => {
        if (active === 'true') return theme.white;
        if (title === 'Sair') return theme.primary;
        if (typepage === 'true') return theme.secondary;
        return theme.white;
      }};
    }

    &:hover {
      background-color: ${({ theme }) => theme.secondary};
    }

    &:active {
      .nav-text {
        background-color: ${({ theme }) => theme.secondary};
        color: ${({ theme }) => theme.white};
        cursor: pointer;
      }
    }
  }
`;

export const NavHelpButton = styled.a`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-color: #fff;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: 'flex-start';
  margin-top: 8rem;
  padding: 0.4rem 0.25rem;
  text-decoration: none;
  width: 85%;

  .nav-text {
    color: ${({ theme }) => theme.primary};
    cursor: pointer;
    margin-left: 0.5rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.primary};
    .nav-text {
      color: ${({ theme }) => theme.white};
      cursor: pointer;
    }
    transition: all 0.3s ease-in-out;
  }
`;

export const ClosedNavBarItemList = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.7rem 0;
  text-decoration: none;
  width: 85%;

  margin: ${({ title }) => (title === 'Ajuda' ? '5rem 0 0 0' : '0')};
  .nav-text {
    color: ${({ theme }) => theme.secondary};
    cursor: pointer;
    margin-left: 0.5rem;
  }

  &:active {
    .nav-text {
      color: ${({ theme }) => theme.white};
      cursor: pointer;
    }
  }
`;

export const ClosedItemButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1rem 0;
  text-decoration: none;
  width: 85%;

  .nav-text {
    color: ${({ theme }) => theme.secondary};
    margin-left: 0.5rem;
  }
  &:hover {
    .nav-text {
      background-color: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.white};
    }
  }
  &:active {
    .nav-text {
      color: ${({ theme }) => theme.white};
    }
  }
`;

export const ButtonSecondary = styled.button`
  align-self: center;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: ${({ web }) => (web ? '10rem' : '0')};
  text-decoration: none;
  width: 85%;
  .nav-text {
    color: ${({ theme }) => theme.secondary};
    margin-left: 0.5rem;
  }
  &:active {
    .nav-text {
      color: ${({ theme }) => theme.white};
    }
  }
`;

export const ContainerRow = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-color: #fff;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: flex-start;
  margin: 0.7rem 0;
  padding: 0.5rem 0.5rem;
  text-decoration: none;
  width: ${({ mobile }) => (mobile ? '70%' : '100%')};

  .nav-text {
    color: ${({ theme }) => theme.primary};
    cursor: pointer;
    margin-left: 0.5rem;
  }

  &:hover {
    background-color: ${({ theme, notify }) => (notify ? 'transparent' : theme.primary)};
    .nav-text {
      color: ${({ theme }) => theme.white};
      cursor: pointer;
    }
  }

  &:active {
    .nav-text {
      background-color: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.white};
      cursor: pointer;
    }
  }
`;

export const ToogleButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  justify-content: center;
  margin-left: 15rem;
  width: 15%;

  .top-icon {
    color: ${({ theme }) => theme.secondary};
    width: 24px;
  }
`;

export const IconArea = styled.div`
  align-items: center;
  color: ${({ active, theme }) => (active === 'true' ? theme.white : theme.secondary)};
  display: flex;
  justify-content: center;
  text-decoration: none;
  width: 24px;
`;

export const NavBarItemText = styled.label`
  color: ${({ active, theme }) => (active === 'true' ? theme.white : theme.secondary)};
  font-size: 14px;
  line-height: 150%;
`;

export const NavBarTitle = styled.h1`
  align-self: center;
  color: ${({ theme }) => theme.secondary};
  font-size: 24px;
  line-height: 150%;
`;

export const NavBarLogoImg = styled.img`
  margin-left: 1.8em;
`;

export const ProfileImage = styled.li`
  align-items: center;
  background-color: ${({ theme, typepage }) =>
    typepage === 'true' ? theme.primary : theme.secondary};
  border-radius: 0.325rem;
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: ${({ meeting }) => (meeting ? '0' : '3rem 0 2rem 0')};
  padding: 1rem 0;
  visibility: ${({ meeting }) => (meeting ? 'hidden' : 'visible')};
  width: 85%;
`;

export const IconCloneNavBar = styled(MenuOpenIcon)`
  color: ${({ theme, typepage }) => (typepage === 'true' ? theme.secondary : theme.white)};
  cursor: pointer;
  left: auto;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;
