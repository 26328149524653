import styled from 'styled-components';
import { ReactComponent as Triangle } from '../../../../images/alert-triangle-icon.svg';
import { ReactComponent as Help } from '../../../../images/helpIcon.svg';
import { ReactComponent as ThumbsUp } from '../../../../images/thumbs-uo-icon.svg';
import {
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BigFont,
  ExtrBigFont,
  SmallFont,
} from '../../../../styles/globalStyles';

export const MainContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ProfileBanner = styled.section`
  align-items: center;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 80px;
  padding: 1.5rem 2.5rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    flex-direction: column;
  }
`;

export const UserContainer = styled.section`
  flex: 1;
  display: flex;
  padding: 50px 0;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

export const EventContainer = styled.section`
  flex: 1;
  display: flex;
  padding: 50px 0;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

export const ProfessionalInfo = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 100%;
  }
`;

export const AddressInfo = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const SMFStrong = styled(SmallFont)`
  font-weight: 700;
  color: ${({ theme }) => theme.secondary};
`;

export const SMFMedium = styled(SmallFont)`
  font-weight: 500;
  color: ${({ theme }) => theme.secondary};
`;

export const SMFRegular = styled(SmallFont)`
  font-weight: 400;
  color: ${({ theme }) => theme.secondary};
`;

export const BGFRegular = styled(BigFont)`
  font-weight: 400;
  color: ${({ theme }) => theme.secondary};
`;

export const ESFRegular = styled.label`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  padding-left: 5px;
  color: ${({ theme }) => theme.secondary};
`;

export const ScheduleBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    flex-direction: column;
  }
`;

export const TypeContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CalendarContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BFont = styled(BigFont)`
  color: ${({ theme }) => theme.secondary};
`;

export const SMFontL = styled(SmallFont)`
  padding: 20px 0;
  color: ${({ theme }) => theme.darkNavy};
`;

export const RowLine = styled.section`
  display: flex;
  width: 100%;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
`;

export const CupomWrapper = styled(RowLine)`
  align-items: flex-start;
  flex-direction: column;
  margin-top: 1rem;
`;

export const ColumnLine = styled.section`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const RowItem = styled.section`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
  width: 400px;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 250px;
  }
`;

export const CreditWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    flex-direction: column;
    align-items: ce;
  }
`;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 45%;
  height: 50px;
  border-radius: 10px;
  background-color: ${({ theme, selected }) => (selected ? theme.primary : theme.softBackground)};
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme, selected }) => (selected ? theme.softBackground : theme.secondary)};
`;

export const ProfileWrapper = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
`;

export const WrapperContainer = styled.div`
  padding-left: 40px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    padding-left: 10px;
  }
`;

export const ProfileName = styled(ExtrBigFont)`
  text-align: left;
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.white};
`;

export const SmallText = styled(SmallFont)`
  display: flex;
  font-weight: 400;
  text-align: left;
  padding-right: 10px;
  align-items: center;
  flex-direction: row;

  color: ${({ theme }) => theme.white};
`;

export const LightBigText = styled(BigFont)`
  color: ${({ theme }) => theme.white};
`;

export const LightSmallText = styled(SmallText)`
  color: ${({ theme }) => theme.white};
`;

export const BigText = styled(BigFont)`
  color: ${({ theme }) => theme.darkNavy};
`;

export const TriangleAlert = styled(Triangle)`
  width: 24px;
  height: 24px;
  padding-left: 5px;
`;

export const HelpIcon = styled(Help)`
  width: 15px;
  height: 18px;
  padding-left: 5px;
`;

export const ThumbsUpIcon = styled(ThumbsUp)`
  width: 15px;
  height: 18px;
  padding-right: 5px;
`;

export const RowIten = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
`;
