import styled from 'styled-components';
import { ReactComponent as Empty } from '../../../images/h-image.svg';
import { BigFont, BREAKPOINT_MD_PX, BREAKPOINT_SM_PX } from '../../../styles/globalStyles';

export const ConfigMain = styled.form`
  width: 100%;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.neutralGray} inset !important;
  }
`;

export const ConfigBody = styled.section`
  padding-bottom: 3rem;
  width: 100%;
`;

export const RowLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.neutralGray};
  margin-top: 10px;
  margin-bottom: 17px;
`;

export const MenuHeader = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  justify-content: flex-start;
  width: 100%;
`;

export const MenuOption = styled.button`
  background-color: ${({ active, theme }) => (active ? theme.darkNavy : theme.white)};
  border-radius: 20px;
  border: 1px solid black;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  position: relative;
`;

export const OptionText = styled(BigFont)`
  color: ${({ theme, active }) => (active ? theme.white : theme.darkNavy)};
  font-size: 14px;
  font-size: medium;
  font-weight: 200px;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    font-size: 12px;
  }
`;

export const GridItem = styled.div`
  column-gap: 5rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    column-gap: 0;
    grid-template-columns: 1fr;
  }
`;

export const RowItem = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: fit-content;
`;

export const BackgroundBanner = styled.div`
  background-color: ${({ color }) => color || '#ffffff'};
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  height: 10rem;
  justify-content: center;
  padding: 0.5rem 1rem;
  width: 100%;
`;

export const MainCustom = styled.div`
  display: grid;
  gap: 2.5rem;
  grid-template-columns: 1.5fr 1fr;
  width: 100%;
`;

export const WrapperCustom = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CustonButton = styled.button`
  background-color: ${({ theme }) => theme.primary};
  border-radius: 1rem;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 2rem;
  padding: 0.5rem 1rem;
  width: 50%;
`;

export const EmptyImage = styled(Empty)`
  height: 250px;
  padding: 30px 0;
`;
