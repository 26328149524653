import styled from 'styled-components';
import { SmallFont } from '../../styles/globalStyles';

export const Container = styled.button`
  align-items: center;
  background-color: ${({ color, theme, reverse }) =>
    color || (reverse ? theme.secondary : theme.primary)};
  border-radius: 2rem;
  color: ${({ theme }) => theme.lightGray};
  cursor: pointer;
  display: flex;
  font-size: 0.9rem;
  font-weight: 600;
  height: ${({ height }) => height || '3rem'};
  justify-content: center;
  transition: all ease-in-out 0.2s;
  width: ${({ width }) => width || '100%'};

  label {
    color: ${({ theme }) => theme.lightGray};
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 600;
  }

  &:hover {
    background-color: transparent;
    border: 2px solid
      ${({ color, theme, reverse }) => color || (reverse ? theme.secondary : theme.primary)};
    color: ${({ fontColor, color, theme, reverse }) =>
      fontColor || color || (reverse ? theme.secondary : theme.primary)};

    label {
      color: ${({ fontColor, color, theme, reverse }) =>
        fontColor || color || (reverse ? theme.secondary : theme.primary)};
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
    &:hover {
      background-color: ${({ color, theme, reverse }) =>
        color || (reverse ? theme.secondary : theme.primary)};

      label {
        color: ${({ theme }) => theme.lightGray};
        cursor: not-allowed;
      }
    }
  }
`;

export const Label = styled.p`
  font-weight: 600;
  color: ${({ color, theme }) => color || theme.lightGray};
`;

export const SlimContainer = styled(Container)`
  height: 35px;
`;

export const BorderContainer = styled(Container)`
  background-color: ${({ color }) => 'transparent' || color};
  border: 1px solid ${({ brdColor, theme }) => brdColor || theme.secondary};

  label {
    color: ${({ theme, brdColor }) => brdColor || theme.secondary};
  }

  &:hover {
    background-color: ${({ brdColor, theme }) => brdColor || theme.secondary};
    border: 1px solid ${({ color, theme }) => color || theme.primary};

    label {
      color: ${({ color, theme }) => theme.lightGray || color};
    }
  }
`;

export const CheckContainer = styled.button`
  align-items: center;
  background-color: ${({ theme, full, activeColor, color }) =>
    full ? activeColor || theme.secondary : color || theme.primary};
  border-radius: ${({ radius }) => radius || '10px'};
  border: none;
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 5px 0;
  padding: 5px;
`;

export const CheckText = styled(SmallFont)`
  color: ${({ theme }) => theme.lightGray};
`;

export const DinamicContainer = styled.button`
  align-items: center;
  background-color: ${({ theme, color, full }) => (full ? color || theme.primary : 'transparent')};
  border-radius: ${({ radius }) => radius || '5px'};
  border: 1px solid ${({ theme, color }) => color || theme.primary};
  color: ${({ theme, color, full }) => (!full ? color || theme.primary : theme.lightGray)};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: ${({ height }) => height || '100%'};
  justify-content: center;
  line-height: 150%;
  padding: 0 1rem;
  width: ${({ width }) => width || '100%'};

  &:hover {
    background-color: ${({ theme, color, full }) =>
      !full ? color || theme.primary : 'transparent'};
    color: ${({ theme, activeColor, color, full }) =>
      full ? activeColor || color || theme.primary : theme.lightGray};
  }
`;

export const LinkContainer = styled.button(({ theme, large, reverse, slim, disabled }) => ({
  alignItems: 'center',
  backgroundColor: reverse ? theme.secondary : theme.primary,
  borderColor: reverse ? theme.secondary : theme.primary,
  borderRadius: 10,
  borderStyle: 'solid',
  borderWidth: 1,
  color: theme.white,
  cursor: disabled ? 'not-allowed' : 'pointer',
  display: 'flex',
  fontSize: 14,
  fontWeight: 600,
  height: slim ? 40 : 50,
  justifyContent: 'center',
  opacity: disabled ? 0.5 : 1,
  textDecoration: 'none',
  width: large ? '100%' : 280,

  '&:hover': {
    color: disabled ? theme.white : reverse ? theme.secondary : theme.primary,
    backgroundColor: disabled ? (reverse ? theme.secondary : theme.primary) : 'transparent',
    transition: 'all 0.3s ease-in-out',
  },
}));
