import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BREAKPOINT_LG_PX, BREAKPOINT_MD_PX } from '../../styles/globalStyles';
import { ProfileImage } from '../ListItens';

export const HeaderMain = styled.div`
  align-content: center;
  align-items: center;
  background: ${({ user }) =>
    ['professional', 'clinic'].includes(user)
      ? 'linear-gradient(90.04deg, #EEF1FD 1.26%, rgba(234, 236, 244, 0) 127.71%);'
      : 'linear-gradient(90.04deg, #0C1B5C 1.26%, #1E1E1E 127.71%);'};
  display: grid;
  grid-area: header;
  grid-template-columns: repeat(auto-fit, minmax(150px, auto));
  height: 9vh;
  justify-content: space-between;
  width: 100%;
`;

export const MenuSideBar = styled(IconButton)`
  visibility: ${({ isvisible }) => isvisible && isvisible};
  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    visibility: visible;
  }
`;

export const ContainerLogo = styled.div`
  display: flex;
  height: fit-content;
  justify-content: flex-end;
  width: fit-content;
  padding: 1rem;
  cursor: pointer;
`;

export const UserImage = styled(ProfileImage)`
  width: 3rem;
  height: 3rem;
  border-radius: 0.625rem;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
  }
`;

export const UserAvatar = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 3rem;
  width: 3rem;
  border-radius: 1rem;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    width: fit-content;
    height: fit-content;
  }
`;

export const ContainerUser = styled.div`
  align-items: center;
  display: flex;
  height: 2.5rem;
  margin-left: 4rem;
  width: 100%;

  .header-text {
    color: ${({ theme, user }) =>
      ['professional', 'clinic'].includes(user) ? theme.darkNavy : theme.white};
    margin-left: 1rem;
    text-align: left;
    font-size: 1.3rem;
    font-weight: 900;
  }

  @media only screen and (max-width: ${BREAKPOINT_LG_PX}) {
    .header-text {
      font-size: 1rem;
    }
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    display: none;
  }
`;

export const HeaderLogoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const HeaderConfigWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-right: 2rem;
  min-width: 600px;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    min-width: fit-content;
  }
`;

export const HeaderButtons = styled(Link)`
  align-items: center;
  background: ${({ theme, notify }) => (notify === 'true' ? 'transparent' : theme.white)};
  border-style: ${({ notify }) => (notify === 'true' ? 'none' : 'solid')};
  border-color: ${({ theme }) => theme.primary};
  border-radius: 0.625rem;
  border-width: 1px;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  text-decoration: none;
  transition: 0.3s;
  width: ${({ notify }) => notify === 'false' && '10rem'};

  .item-text {
    color: ${({ theme, setting }) => (setting === 'true' ? theme.primary : theme.white)};
  }
  .icon-notify {
    color: ${({ theme, user }) =>
      ['professional', 'clinic'].includes(user) ? theme.darkNavy : theme.white};
  }
  &:hover {
    background-color: ${({ theme, notify }) => (notify === 'true' ? 'transparent' : theme.primary)};
    .item-text {
      color: ${({ theme }) => theme.white};
      cursor: pointer;
    }
  }
`;

export const HeaderConfigButtons = styled(HeaderButtons)`
  background: ${({ theme, setting }) => (setting === 'true' ? theme.white : theme.primary)};
  color: ${({ theme, setting }) => (setting === 'true' ? theme.primary : theme.white)};
  padding: 0 1rem;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    display: none;
  }
`;

export const ContainerRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 10rem;
  width: fit-content;
`;

export const HeaderNotificationWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const IconArea = styled.div`
  align-items: center;
  color: ${({ activated, theme }) => (activated === 'true' ? theme.white : theme.darkNavy)};
  display: flex;
  justify-content: center;
  text-decoration: none;
  width: 24px;
`;

export const HeaderItemText = styled.label`
  color: ${({ theme }) => theme.white};
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 900;
  line-height: 150%;
`;

export const HeaderLogoImg = styled.img`
  margin-left: 1.8rem;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    margin-left: 0;
  }
`;
