/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { X } from 'react-feather';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Flatlist } from '../../../components/List';
import { FinanceItemList } from '../../../components/ListItens';
import { ColumnItem } from '../../../components/ListItens/styles';
import LoaderSpinner from '../../../components/LoaderSpinner';
import useAuth from '../../../hooks/auth';
import api from '../../../services/api';
import theme from '../../../theme';
import convertCurrencyPtBr from '../../../utils/convertCurrencyPtBr';
import {
  BoxModalWrapper,
  BoxWrapper,
  Button,
  ButtonMaxWithdraw,
  ButtonWithdraw,
  ButtonWithdrawWrapper,
  ContainerItem,
  ContainerItemLeft,
  Hr,
  IconArea,
  InputWithdraw,
  InputWrapper,
  MDFontDark,
  MDFontRed,
  MainContainer,
  PayOutContainer,
  SMFont,
} from './styles';

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export default function FinanceScreen() {
  const { user } = useAuth();
  const [userInfo, setUserInfo] = useState('');
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);

  const SERVICE_CHARGE = 3.67;

  const schema = yup.object().shape({
    withdraw: yup
      .number()
      .positive('Valor deve ser positivo.')
      .min(4.67, 'Valor mínimo para saque é de R$ 4,67.')
      .required('Campo Requerido.'),
  });

  const fetchFinances = async (controller) => {
    try {
      const { data } = await api.get('/professionals/transactions', {
        signal: controller?.signal,
      });
      if (data.data) {
        setUserInfo(data.data.attributes.balance);
        setPaymentHistory(data.data.attributes.payables);
      } else {
        setPaymentHistory([]);
      }
    } catch (error) {
      if (!controller?.signal?.aborted) toast.error('Erro ao carregar dados.');
    } finally {
      setLoading(false);
    }
  };

  const handleClick = async () => {
    try {
      setLoading(true);
      const request = {
        data: {
          attributes: {
            amount: (values.withdraw - 3.67) * 100,
          },
        },
      };
      const response = await api.post('/professionals/transactions/withdraw', request);
      if (response.status === 200) {
        toast.success('Saque realizado com sucesso!');
        setModal(false);
        fetchFinances();
      } else {
        toast.error('Erro ao realizar saque.');
        setModal(false);
      }
    } catch (error) {
      toast.error('Erro ao realizar saque');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, value) => {
    event.preventDefault();
    setValues({ withdraw: value }); // value without mask (ex: 1234.56)
  };

  const form = useFormik({
    initialValues: {
      withdraw: 0,
    },
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: (values) => {
      setTimeout(() => {
        handleClick(values);
      }, 400);
    },
    onChange: (event, value) => {
      handleChange(event, value);
    },
  });

  const { values, setValues, errors, touched } = form;

  useEffect(() => {
    const controller = new AbortController();
    fetchFinances(controller);

    return () => controller.abort();
  }, []);

  return (
    <MainContainer>
      {!user?.clinic?.id && (
        <ContainerItemLeft>
          <Flatlist
            type="transaction"
            data={paymentHistory}
            filterObject={(item) =>
              String(item?.pagarmeTransactionId) ||
              item?.client?.firstName ||
              item?.client?.lastName ||
              item?.client?.email
            }
            placeholder="Pesquise um pagamento"
            renderItem={(item, index) => <FinanceItemList key={index} data={item} />}
            loading={loading}
          />
        </ContainerItemLeft>
      )}
      <ContainerItem>
        <PayOutContainer style={{ alignItems: 'center' }}>
          <SMFont
            style={{
              color: theme.secondary,
              fontSize: '2rem',
              lineHeight: '2.5rem',
            }}
          >
            Resumo do Saldo
          </SMFont>
          {loading ? (
            <LoaderSpinner />
          ) : (
            <ColumnItem>
              <PayOutContainer className="funds">
                <MDFontRed>{convertCurrencyPtBr(userInfo?.waitingFunds)}</MDFontRed>
                <SMFont style={{ color: theme.darkNavy }}>Faturamento total</SMFont>
              </PayOutContainer>
              <PayOutContainer className="invoice">
                <MDFontDark>{convertCurrencyPtBr(userInfo?.transferred)}</MDFontDark>
                <SMFont style={{ color: theme.darkNavy }}>Valor transferido</SMFont>
              </PayOutContainer>
              <PayOutContainer className="withdraw">
                <MDFontDark>{convertCurrencyPtBr(userInfo?.available)}</MDFontDark>
                <SMFont style={{ color: theme.darkNavy, marginBottom: '0.325rem' }}>
                  Saldo Disponível para saque
                </SMFont>
                <Button title="Sacar valor disponível" onClick={() => setModal(true)} />
              </PayOutContainer>
            </ColumnItem>
          )}
        </PayOutContainer>
      </ContainerItem>
      {modal && (
        <Modal open>
          <BoxModalWrapper
            style={{
              width: '600px',
              height: '400px',
              padding: 0,
            }}
          >
            <BoxWrapper>
              <IconArea style={{ cursor: 'default' }}>
                <X
                  style={{ width: '2rem', height: '2rem', cursor: 'pointer' }}
                  onClick={() => setModal(false)}
                />
              </IconArea>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '1.2rem',
                  textAlign: 'center',
                  width: '80%',
                }}
              >
                Você tem um saldo disponivel de{' '}
                <span
                  style={{
                    fontSize: '1.5rem',
                    color: '#16B83E',
                    fontWeight: 'bold',
                  }}
                >
                  {convertCurrencyPtBr(userInfo?.available)}
                </span>
                . Informe o valor desejado para o saque!
              </Typography>
              <InputWrapper>
                <InputWithdraw
                  currency="BRL"
                  value={values.withdraw}
                  config={currencyConfig}
                  onChange={handleChange}
                />
                <ButtonMaxWithdraw
                  disabled={userInfo.available > SERVICE_CHARGE ? false : true}
                  onClick={() => setValues({ withdraw: userInfo.available })}
                >
                  Sacar tudo
                </ButtonMaxWithdraw>
              </InputWrapper>
              <Typography
                sx={{
                  color: '#FD6492',
                  fontSize: '0.8rem',
                  textAlign: 'center',
                  width: '80%',
                }}
              >
                {errors.withdraw || touched.withdraw}
              </Typography>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  textAlign: 'center',
                  width: '80%',
                  fontWeight: 700,
                }}
              >
                {`Taxa de serviço: ${convertCurrencyPtBr(SERVICE_CHARGE)} por saque.`}
              </Typography>
              {values.withdraw > userInfo?.available ? (
                <Typography
                  sx={{
                    color: '#FD6492',
                    fontSize: '0.8rem',
                    textAlign: 'center',
                    width: '80%',
                    fontWeight: 700,
                  }}
                >
                  Saldo insuficiente!
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: '0.8rem',
                    textAlign: 'center',
                    width: '80%',
                    fontWeight: 700,
                  }}
                >
                  {values.withdraw > 0 && (
                    <Fragment>
                      <p>
                        Valor debitado em carteira de{' '}
                        <span
                          style={{ color: '#FD6492' }}
                        >{`${convertCurrencyPtBr(values.withdraw)}.`}</span>
                      </p>
                      <p>
                        Valor creditado em conta{' '}
                        <span style={{ color: '#16B83E' }}>{`${convertCurrencyPtBr(
                          values.withdraw - SERVICE_CHARGE
                        )}.`}</span>
                      </p>
                    </Fragment>
                  )}
                </Typography>
              )}
              <ButtonWithdrawWrapper>
                <Hr />
                <ButtonWithdraw
                  disabled={
                    values.withdraw < SERVICE_CHARGE + 1 || values.withdraw > userInfo?.available
                  }
                  onClick={handleClick}
                >
                  Sacar
                </ButtonWithdraw>
              </ButtonWithdrawWrapper>
            </BoxWrapper>
          </BoxModalWrapper>
        </Modal>
      )}
    </MainContainer>
  );
}
